import React, {useEffect, useState} from "react";

import * as LinkFactory from "../../util/LinkFactory";
import * as FacultyApi from "../../api/FacultyApi";
import CommonLayout from "../layout/commonLayout";
import {toast} from "react-toastify";
import Loader from "../common/Loader";
import {useNavigate} from "react-router-dom";

function FacultyList() {

    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }

    const [loader, setLoader] = useState(true);
    const [faculties, setFaculties] = useState([]);
    const [id, setId] = useState(null);

    function getFacultyList() {
        setLoader(true);
        FacultyApi.getAllFaculties()
            .then(json => {
                setLoader(false);
                console.log(json);
                setFaculties(json.data);
            })
            .catch(errors => {
                setLoader(false);
                console.log(errors);
            });
    }

    useEffect(() => {
        getFacultyList();
    }, []);

    const notifyDelete = () => toast.success("Successfully Deleted!", {
        position: toast.POSITION.TOP_CENTER
    });

    const handleEdit = data => {
        navigateTo(LinkFactory.facultyEdit + "/" + data.id);
    };
    const handleNew = data => {
        navigateTo(LinkFactory.facultyNew);
    };
    const handleDelete = data => {
        setId(data.id);
    };

    const deleteData = () => {
        FacultyApi.deleteFacultyById(id)
        setId(null);
        notifyDelete();
        window.location.reload();
    };

    return (
        <>
            <CommonLayout>
                <main>
                    <div className="card-default faculty-list">
                        <div className="title-header ">
                            <div className="mb-0 d-flex align-items-center">
                                <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                    <a href="#" className="pl-0 back-button">
                                        <i className="fa fa-chevron-left"> </i> </a>
                                </div>
                                <p>Faculty</p>
                            </div>

                            <button
                                type="button"
                                className="btn btn-purple-md radius-25 float-right"
                                onClick={() => handleNew()}>
                                <svg width="15" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.429 15.5V9.071H0V6.93h6.429V.5H8.57v6.429H15V9.07H8.571V15.5H6.43Z"
                                          fill="#fff"/>
                                </svg>
                                Create new Faculty
                            </button>
                        </div>


                        <div className="table-responsive table-light ">
                            <table
                                className="table table-light table-hover table-striped"
                                id="dataTable"
                                width="100%"
                                cellSpacing="0"
                            >
                                <thead>
                                <tr>
                                    <th scope="col">Sl</th>
                                    <th scope="col">Faculty</th>
                                    <th scope="col" className=" " width="15%">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {faculties.length > 0
                                    ? faculties.map((data, index) => {
                                        return (
                                            <tr>
                                                <th scope=" ">{index + 1}</th>
                                                <td>{data?.name}</td>
                                                <td className=" ">
                                                    <button
                                                        type="button"
                                                        title="Edit"
                                                        className="btn btn-sm bg-c-light-orange mr-1"
                                                        onClick={() => handleEdit(data)}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             width="18" height="15" fill="none">
                                                            <path fill="#000"
                                                                  d="m16.202 8.72-2.01-2.011L15.54 5.36l2.01 2.01-1.348 1.348Zm-7.687 5.676v-2.01l5.014-5.015 2.01 2.01-5.014 5.015h-2.01ZM0 9.666V7.772h6.623v1.892H0ZM0 5.88V3.99h10.407V5.88H0Zm0-3.784V.205h10.407v1.892H0Z"/>
                                                        </svg>
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                        type="button"
                                                        title="Delete"
                                                        className="btn btn-sm bg-c-red-light"
                                                        data-toggle="modal"
                                                        data-target="#deleteModal"
                                                        // onClick={() => handleDelete(data)}
                                                        onClick={() => {
                                                        }}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             width="13" height="14" fill="none">
                                                            <path fill="#D81414"
                                                                  d="M2.8 13.704c-.412 0-.766-.147-1.06-.44a1.442 1.442 0 0 1-.44-1.06v-9.75H.55v-1.5H4.3v-.75h4.5v.75h3.75v1.5h-.75v9.75c0 .413-.147.766-.441 1.06-.294.294-.647.441-1.059.44H2.8Zm7.5-11.25H2.8v9.75h7.5v-9.75Zm-6 8.25h1.5v-6.75H4.3v6.75Zm3 0h1.5v-6.75H7.3v6.75Z"/>
                                                        </svg>
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                    : <td colSpan="12" className="text-center">
                                        {loader ? <Loader className="my-5"/> : <h6 className="my-5">No data found</h6>}
                                    </td>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </main>
            </CommonLayout>

            <div
                className="modal fade"
                id="deleteModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="actionModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="circle-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="38" fill="none">
                                    <path fill="#D81414"
                                          d="M6.606 37.609c-1.144 0-2.123-.403-2.938-1.208-.815-.804-1.221-1.77-1.22-2.899V6.812H.369V2.705h10.394V.652h12.473v2.053H33.63v4.107h-2.079v26.69c0 1.13-.407 2.097-1.222 2.901-.815.805-1.793 1.207-2.935 1.206H6.606ZM27.394 6.812H6.606v26.69h20.788V6.812Zm-16.63 22.584h4.157V10.918h-4.158v18.478Zm8.314 0h4.158V10.918h-4.158v18.478Z"/>
                                </svg>
                            </div>
                            <div className="modal-body pt-4 pb-0">Are you sure you want to delete ?</div>
                        </div>

                        <div className="modal-footer">
                            <button
                                type="button"
                                className=" "
                                data-dismiss="modal"
                                onClick={deleteData}
                            >
                                Delete
                            </button>
                            <button
                                type="button"
                                className="btn btn-success"
                                data-dismiss="modal"

                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
                {/*<div className="modal-dialog modal-dialog-centered" role="document">*/}
                {/*    <div className="modal-content">*/}
                {/*        <div className="modal-header">*/}
                {/*            <h5 className="modal-title">*/}
                {/*                <b className="text-warning">&#9888;</b> Alert*/}
                {/*            </h5>*/}
                {/*            <button*/}
                {/*                type="button"*/}
                {/*                className="close"*/}
                {/*                data-dismiss="modal"*/}
                {/*                aria-label="Close"*/}
                {/*            >*/}
                {/*                <span aria-hidden="true">&times;</span>*/}
                {/*            </button>*/}
                {/*        </div>*/}
                {/*        <div className="modal-body">Are you sure you want to delete ?</div>*/}
                {/*        <div className="modal-footer">*/}
                {/*            <button*/}
                {/*                type="button"*/}
                {/*                className="btn btn-success"*/}
                {/*                data-dismiss="modal"*/}
                {/*            >*/}
                {/*                <b className="text-light">&#10094;</b> No, Go back*/}
                {/*            </button>*/}
                {/*            <button*/}
                {/*                type="button"*/}
                {/*                className="btn btn-danger"*/}
                {/*                data-dismiss="modal"*/}
                {/*                onClick={deleteData}*/}
                {/*            >*/}
                {/*                <b className="text-light">&#128465;</b> Confirm Delete*/}
                {/*            </button>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

            </div>
        </>
    );
}

export default FacultyList;
