import React, {useEffect, useState} from "react";
import ChannelBoard from "./ChannelBoard";
import Loader from "../../common/Loader";
import {useParams} from "react-router-dom";

function Index(props) {
    const [loader, setLoader] = useState(false);
    const params = useParams();
    console.log(params);
    useEffect(() => {
        setLoader(true);
        setTimeout(() => {
            setLoader(false);
        }, 1000);
    }, [params.id]);

    if (!loader) {
        return <ChannelBoard props={props} slug={params.id}/>;
    }
    return <div className="min-vh-100 d-flex align-items-center"><Loader/></div>;
}

export default Index;
