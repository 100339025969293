import {FETCH_NOTIFICATION_COUNTER_BEGIN, FETCH_NOTIFICATION_COUNTER_SUCCESS, FETCH_POST_NOTIFICATION_COUNTER_FAILURE} from "../action/notificationCounterAction";

const initialState = {
    notificationAssignmentCounter: null,
    notificationPostCounter: null,
    notificationChatCounter: null,
    loading: false,
    error: null
};

export default function NotificationCounterReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_NOTIFICATION_COUNTER_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_NOTIFICATION_COUNTER_SUCCESS:
            return {
                ...state,
                loading: false,
                notificationPostCounter: action.payload.notificationPostCounter,
                notificationAssignmentCounter: action.payload.notificationAssignmentCounter,
                notificationChatCounter: action.payload.notificationChatCounter
            };

        case FETCH_POST_NOTIFICATION_COUNTER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                notificationPostCounter: null
            };
        default:
            return state;
    }
}
