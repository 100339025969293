import React, {useEffect, useState} from "react";
import * as InstituteApi from "../../api/InstituteApi";

import CommonLayout from "../layout/commonLayout";
import {useNavigate, useParams} from "react-router-dom";

function InstituteView(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const params = useParams();
    const [institute, setInstitute] = useState([]);

    useEffect(() => {
        InstituteApi.getInstituteById(params.id)
            .then(json => {
                console.log(json);
                setInstitute(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);
    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h1 className="mt-4">Institute</h1>
                        <br/>
                        <div className="card mb-4">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table
                                        className="table table-bordered"
                                        id="dataTable"
                                        width="100%"
                                        cellSpacing="0"
                                    >
                                        <thead>
                                        <tr>
                                            <th scope="col">Id</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Address</th>
                                            <th scope="col">Code</th>
                                            <th scope="col">Phone</th>
                                            <th scope="col">Website</th>
                                            <th scope="col">Contact Email</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>{institute.id}</td>
                                            <td>{institute.name}</td>
                                            <td>{institute.address}</td>
                                            <td>{institute.code}</td>
                                            <td>{institute.phone}</td>
                                            <td>{institute.website}</td>
                                            <td>{institute.contactEmail}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default InstituteView;
