import React, {useEffect, useState} from "react";
import * as SemesterApi from "../../api/SemesterApi";
import {Field, Form, Formik} from "formik";

import CommonLayout from "../layout/commonLayout";
import TextFieldComponent from "../common/TextFieldComponent";
import DatePicker from "react-datepicker";
import {toast} from "react-toastify";

import * as LinkFactory from "../../util/LinkFactory";
import {useNavigate, useParams} from "react-router-dom";

function SemesterEdit(props) {

    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }

    const params = useParams();
    const [semester, setSemester] = useState({
        markingPeriodStart: "",
        markingPeriodEnd: "",
        semesterName: ""
    });
    const notifyUpdate = () => toast.success("Updated!", {
        position: toast.POSITION.TOP_CENTER
    });
    const notifyValidation = () => toast.success("All fields required", {
        position: toast.POSITION.TOP_CENTER
    });
    useEffect(() => {
        SemesterApi.getSemesterById(params.id)
            .then(json => {
                console.log(json);
                setSemester(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);

    const handleSubmit = data => {
        if(
            (null == data.markingPeriodStart || '' === data.markingPeriodStart) ||
            (null == data.markingPeriodEnd || '' === data.markingPeriodEnd) ||
            (null == data.semesterName || '' === data.semesterName)
        ) {
            notifyValidation();
        } else {
            SemesterApi.updateSemester(data).then(data => {
                notifyUpdate();
                navigateTo(LinkFactory.semesterList);
            });
        }
    };

    const DatePickerComponent = ({field, form: {touched, errors, setFieldValue}}) => {
        return (
            <div className="mr-2">
                <DatePicker
                    showTimeInput2
                    selected={Date.parse(field.value)}
                    placeholderText="Pick date and time"
                    onChange={value => setFieldValue(field.name, value)}
                    className="form-control btn-block w-100 overflow-auto "
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy HH:mm"
                    showTimeInput
                />
            </div>
        );
    };
    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h3 className="mt-4">Semester Edit</h3>
                        <div>
                            <div>
                                <Formik
                                    initialValues={semester}
                                    enableReinitialize={true}
                                    onSubmit={handleSubmit}>
                                    {props => (
                                        <Form>
                                            <div className="table-responsive table-light table-shadow radius-15 mt-4">
                                                <table
                                                    className="table table-light"
                                                    id="dataTable"
                                                    width="100%"
                                                    cellSpacing="0">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col">Semester Name</th>
                                                        <th scope="col">Marking Period Start</th>
                                                        <th scope="col">Marking Period End</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr className="input-default-white">
                                                        <td>
                                                            <Field
                                                                type="text"
                                                                name="semesterName"
                                                                placeholder="Semester Name"
                                                                component={TextFieldComponent}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Field
                                                                name="markingPeriodStart"
                                                                placeholder="Marking Period Start"
                                                                component={DatePickerComponent}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Field
                                                                name="markingPeriodEnd"
                                                                placeholder="Marking Period End"
                                                                component={DatePickerComponent}
                                                            />
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="text-right w-100">
                                                <button type="submit" className="btn btn-md btn-primary my-4 text-right">
                                                    Update
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default SemesterEdit;
