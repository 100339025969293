import React, {useEffect, useState} from "react";

import "react-datepicker/dist/react-datepicker.css";
import * as EventsApi from "../../api/EventsApi";

import * as LinkFactory from "../../util/LinkFactory";
import * as Utils from "../../util/Utils";
import ListGroup from "react-bootstrap/ListGroup";
import Loader from "../common/Loader";
import CommonLayout from "../layout/commonLayout";
import {convertUTCDateToLocalDate} from "../../util/DateUtils";
import {useNavigate, useParams} from "react-router-dom";

function AllEvents(props) {
    const params = useParams();

    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }

    const [upcomingEventsList, setUpcomingEventsList] = useState({});
    const [previousEventsList, setPreviousEventsList] = useState({});
    const [previousEventsLoading, setPreviousEventsLoading] = useState(true);
    const [upcomingEventsLoading, setUpcomingEventsLoading] = useState(true);

    function loadPreviousEvents() {
        EventsApi.findAllPreviousEventsByLoggedInUser().then(json => {
            setPreviousEventsList(json.data);
            setPreviousEventsLoading(false);
        }).catch(errors => {
            setPreviousEventsLoading(false);
        });
    }

    function loadUpcomingevents() {
        EventsApi.findAllUpcomingEventsByLoggedInUser().then(json => {
            setUpcomingEventsList(json.data);
            setUpcomingEventsLoading(false);
        }).catch(errors => {
            setUpcomingEventsLoading(false);
        });
    }

    function loadData() {
        setPreviousEventsLoading(true);
        setUpcomingEventsLoading(true);
        loadPreviousEvents();
        loadUpcomingevents();
    }

    useEffect(() => {
        loadData();
    }, []);

    const handleEventRoute = id => {
        if (Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY","ROLE_EXAM_CONTROLLER"]))
            navigateTo(LinkFactory.eventsSetupView + "/" + id);
        else if (Utils.checkRolePermission(["ROLE_USER"]))
            navigateTo(LinkFactory.eventsSetupViewForUser + "/" + id);
    };
    const handleEventCreatePage = () => {
        navigateTo(LinkFactory.eventsNew + "/" + params.id);
    };

    function handleDelete(data) {
        EventsApi.deleteEventsById(data.id).then((data) => {
            loadData();
        })
    }

    return (
        <>
            <CommonLayout headerName="Events">
                <main>
                    <div>
                        <div className="card-default all-events p-0">
                            <div className="card-heading">
                                <div className="title px-5 justify-content-between w-100">
                                    <div className="mb-0 d-flex align-items-center">
                                        <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                            <a href=" " className="pl-0 back-button">
                                                <i className="fa fa-chevron-left"> </i> </a>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="23" fill="none">
                                            <path fill="#475467"
                                                  d="M18 10.506V4.502c0-1.1-.9-2-2-2h-1V.5h-2v2.001H5V.5H3v2.001H2c-1.1 0-2 .9-2 2.001V18.51c0 1.1.9 2.001 2 2.001h7v-2H2V8.504h14v2h2Zm-2-4.003H2v-2h14v2ZM12.64 18.51a3.506 3.506 0 0 0 3.861 2.461A3.504 3.504 0 0 0 19.5 17.51a3.505 3.505 0 0 0-3.5-3.502c-.95 0-1.82.38-2.45 1H15v1.501h-4v-4.002h1.5v1.43c.9-.88 2.14-1.43 3.5-1.43 2.76 0 5 2.241 5 5.003a5.006 5.006 0 0 1-4.5 4.965 5 5 0 0 1-5.4-3.965h1.54Z"/>
                                        </svg>
                                        <p>All Events</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <ul
                                            className="nav nav-pills pr-4"
                                            id="pills-tab"
                                            role="tablist">
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link active"
                                                    id="pills-home-tab"
                                                    data-toggle="pill"
                                                    href="#pills-home"
                                                    role="tab"
                                                    aria-controls="pills-home"
                                                    aria-selected="true">
                                                    Upcoming
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    id="pills-profile-tab"
                                                    data-toggle="pill"
                                                    href="#pills-profile"
                                                    role="tab"
                                                    aria-controls="pills-profile"
                                                    aria-selected="false">
                                                    Previous
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="form-group mb-0 pr-4  ml-4 has-search">
                                            <span className="fa fa-search form-control-feedback"> </span>
                                            <input type="text" className="form-control"
                                                   placeholder="Search"/>
                                        </div>
                                    </div>
                                </div>

                                {/*{Utils.checkRolePermission(["ROLE_ADMIN"]) ? (*/}
                                {/*    <div className="action">*/}
                                {/*        <div className="add-more">*/}
                                {/*            <button*/}
                                {/*                onClick={() => handleEventCreatePage()}*/}
                                {/*                className="btn-circle-orange">*/}
                                {/*                <i className="fa fa-plus"/>*/}
                                {/*            </button>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*) : null}*/}
                            </div>
                            <div className="tab-orange">
                                <div className="tabs">

                                    <div className="tab-content" id="pills-tabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="pills-home"
                                            role="tabpanel"
                                            aria-labelledby="pills-home-tab">
                                            <div className="table-responsive table-light">
                                                <table
                                                    className="table table-light  "
                                                    id="dataTable"
                                                    width="100%"
                                                    cellSpacing="0">
                                                    <thead>
                                                    <tr>
                                                        {/*<th scope="col" width="5%">sl</th>*/}
                                                        <th scope="col" className="pl-5">Event</th>
                                                        <th scope="col">Event Date</th>
                                                        <th scope="col">Event Venue</th>
                                                        <th scope="col" className="text-center">Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {upcomingEventsList && upcomingEventsList.length > 0 ? (
                                                            upcomingEventsList.map((data, index) => {
                                                                return (
                                                                    <tr>
                                                                        {/*<th scope="row">{index + 1}</th>*/}
                                                                        <td>
                                                                            <div>
                                                                                <h6 className="mb-1">{data.eventName}</h6>
                                                                                <p>{data?.channel?.channelName}</p>
                                                                            </div>
                                                                        </td>
                                                                        <td>{convertUTCDateToLocalDate(new Date(data.eventStartTime))} - {convertUTCDateToLocalDate(new Date(data.eventEndTime))}</td>
                                                                        <td>{data?.eventVenue}</td>
                                                                        <td className="text-center ">
                                                                            <button
                                                                                type="button"
                                                                                title="View"
                                                                                className="btn btn-sm bg-c-blue-light mr-1"
                                                                                onClick={() => handleEventRoute(data.id)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                     width="17" height="12" fill="none">
                                                                                    <path fill="#4575EE"
                                                                                          d="M8.5 1.75A7.55 7.55 0 0 1 15.316 6 7.541 7.541 0 0 1 8.5 10.25 7.541 7.541 0 0 1 1.685 6 7.55 7.55 0 0 1 8.5 1.75Zm0-1.545C4.636.205 1.337 2.608 0 6c1.337 3.392 4.636 5.795 8.5 5.795S15.663 9.392 17 6C15.663 2.608 12.364.205 8.5.205Zm0 3.863a1.932 1.932 0 1 1 0 3.864 1.932 1.932 0 0 1 0-3.864Zm0-1.545A3.483 3.483 0 0 0 5.023 6 3.483 3.483 0 0 0 8.5 9.477 3.483 3.483 0 0 0 11.977 6 3.483 3.483 0 0 0 8.5 2.523Z"/>
                                                                                </svg>
                                                                            </button>
                                                                            &nbsp;
                                                                            <button
                                                                                type="button"
                                                                                title="Edit"
                                                                                className="btn btn-sm bg-c-light-orange mr-1"
                                                                            >
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                     width="18" height="15" fill="none">
                                                                                    <path fill="#000"
                                                                                          d="m16.202 8.72-2.01-2.011L15.54 5.36l2.01 2.01-1.348 1.348Zm-7.687 5.676v-2.01l5.014-5.015 2.01 2.01-5.014 5.015h-2.01ZM0 9.666V7.772h6.623v1.892H0ZM0 5.88V3.99h10.407V5.88H0Zm0-3.784V.205h10.407v1.892H0Z"/>
                                                                                </svg>
                                                                            </button>
                                                                            &nbsp;
                                                                            <button
                                                                                type="button"
                                                                                title="Delete"
                                                                                className="btn btn-sm bg-c-red-light"
                                                                                data-toggle="modal"
                                                                                data-target="#deleteModal"
                                                                                onClick={() => handleDelete(data)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                     width="13" height="14" fill="none">
                                                                                    <path fill="#D81414"
                                                                                          d="M2.8 13.704c-.412 0-.766-.147-1.06-.44a1.442 1.442 0 0 1-.44-1.06v-9.75H.55v-1.5H4.3v-.75h4.5v.75h3.75v1.5h-.75v9.75c0 .413-.147.766-.441 1.06-.294.294-.647.441-1.059.44H2.8Zm7.5-11.25H2.8v9.75h7.5v-9.75Zm-6 8.25h1.5v-6.75H4.3v6.75Zm3 0h1.5v-6.75H7.3v6.75Z"/>
                                                                                </svg>
                                                                            </button>

                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        ) :
                                                        (upcomingEventsLoading ? (
                                                            <Loader/>
                                                        ) : (
                                                            <ListGroup>
                                                                <ListGroup.Item>
                                                                    <h6>No Upcoming Events</h6>
                                                                </ListGroup.Item>
                                                            </ListGroup>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div
                                            className="tab-pane fade"
                                            id="pills-profile"
                                            role="tabpanel"
                                            aria-labelledby="pills-profile-tab">
                                            <div className="table-responsive table-light">
                                                <table
                                                    className="table table-light"
                                                    id="dataTable"
                                                    width="100%"
                                                    cellSpacing="0">
                                                    <thead>
                                                    <tr>
                                                        {/*<th scope="col" width="pl-5">sl</th>*/}
                                                        <th scope="col" className="pl-5">Event</th>
                                                        <th scope="col">Event Date</th>
                                                        <th scope="col">Event Venue</th>
                                                        <th scope="col" className=" ">Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {previousEventsList && previousEventsList.length > 0 ? (
                                                            previousEventsList.map((data, index) => {
                                                                return (
                                                                    <tr>
                                                                        {/*<th scope="row">{index + 1}</th>*/}
                                                                        <td>
                                                                            <div>
                                                                                <h6 className="mb-1">{data.eventName}</h6>
                                                                                <p>{data?.channel?.channelName}</p>
                                                                            </div>
                                                                        </td>
                                                                        <td>{convertUTCDateToLocalDate(new Date(data.eventStartTime))} - {convertUTCDateToLocalDate(new Date(data.eventEndTime))}</td>
                                                                        <td>{data?.eventVenue}</td>
                                                                        <td className=" ">
                                                                            <button
                                                                                type="button"
                                                                                title="View"
                                                                                className="btn btn-sm bg-c-blue-light mr-1"
                                                                                onClick={() => handleEventRoute(data.id)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                     width="17" height="12" fill="none">
                                                                                    <path fill="#4575EE"
                                                                                          d="M8.5 1.75A7.55 7.55 0 0 1 15.316 6 7.541 7.541 0 0 1 8.5 10.25 7.541 7.541 0 0 1 1.685 6 7.55 7.55 0 0 1 8.5 1.75Zm0-1.545C4.636.205 1.337 2.608 0 6c1.337 3.392 4.636 5.795 8.5 5.795S15.663 9.392 17 6C15.663 2.608 12.364.205 8.5.205Zm0 3.863a1.932 1.932 0 1 1 0 3.864 1.932 1.932 0 0 1 0-3.864Zm0-1.545A3.483 3.483 0 0 0 5.023 6 3.483 3.483 0 0 0 8.5 9.477 3.483 3.483 0 0 0 11.977 6 3.483 3.483 0 0 0 8.5 2.523Z"/>
                                                                                </svg>
                                                                            </button>
                                                                            &nbsp;
                                                                            <button
                                                                                type="button"
                                                                                title="Edit"
                                                                                className="btn btn-sm bg-c-light-orange mr-1"
                                                                            >
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                     width="18" height="15" fill="none">
                                                                                    <path fill="#000"
                                                                                          d="m16.202 8.72-2.01-2.011L15.54 5.36l2.01 2.01-1.348 1.348Zm-7.687 5.676v-2.01l5.014-5.015 2.01 2.01-5.014 5.015h-2.01ZM0 9.666V7.772h6.623v1.892H0ZM0 5.88V3.99h10.407V5.88H0Zm0-3.784V.205h10.407v1.892H0Z"/>
                                                                                </svg>
                                                                            </button>
                                                                            &nbsp;
                                                                            <button
                                                                                type="button"
                                                                                title="Delete"
                                                                                className="btn btn-sm bg-c-red-light"
                                                                                data-toggle="modal"
                                                                                data-target="#deleteModal"
                                                                                onClick={() => handleDelete(data)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                     width="13" height="14" fill="none">
                                                                                    <path fill="#D81414"
                                                                                          d="M2.8 13.704c-.412 0-.766-.147-1.06-.44a1.442 1.442 0 0 1-.44-1.06v-9.75H.55v-1.5H4.3v-.75h4.5v.75h3.75v1.5h-.75v9.75c0 .413-.147.766-.441 1.06-.294.294-.647.441-1.059.44H2.8Zm7.5-11.25H2.8v9.75h7.5v-9.75Zm-6 8.25h1.5v-6.75H4.3v6.75Zm3 0h1.5v-6.75H7.3v6.75Z"/>
                                                                                </svg>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        ) :
                                                        (previousEventsLoading ? (
                                                            <Loader/>
                                                        ) : (
                                                            <ListGroup>
                                                                <ListGroup.Item>
                                                                    <h6>No Previous Events</h6>
                                                                </ListGroup.Item>
                                                            </ListGroup>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>
            <div
                className="modal fade all-events"
                id="deleteModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="actionModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header  ">
                            <div className="circle-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="38" fill="none">
                                    <path fill="#D81414"
                                          d="M6.606 37.609c-1.144 0-2.123-.403-2.938-1.208-.815-.804-1.221-1.77-1.22-2.899V6.812H.369V2.705h10.394V.652h12.473v2.053H33.63v4.107h-2.079v26.69c0 1.13-.407 2.097-1.222 2.901-.815.805-1.793 1.207-2.935 1.206H6.606ZM27.394 6.812H6.606v26.69h20.788V6.812Zm-16.63 22.584h4.157V10.918h-4.158v18.478Zm8.314 0h4.158V10.918h-4.158v18.478Z"/>
                                </svg>

                            </div>
                            <div className="modal-body pt-4 pb-0">Are you sure you want to delete ?</div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className=" "
                                data-dismiss="modal"
                            >
                                Delete
                            </button>
                            <button
                                type="button"
                                className="btn btn-success"
                                data-dismiss="modal">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AllEvents;
