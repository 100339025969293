import React, {useState} from "react";
import * as InstituteApi from "../../api/InstituteApi";
import {Field, Form, Formik} from "formik";
import * as LinkFactory from "../../util/LinkFactory";

import CommonLayout from "../layout/commonLayout";
import TextFieldComponent from "../common/TextFieldComponent";
import {useNavigate} from "react-router-dom";

function InstituteNew(props) {
  let history = useNavigate();

  function navigateTo(pathname) {
    history(pathname);
  }

  const [institute, setInstitute] = useState({
    name: "",
    address: "",
    code: "",
    phone: "",
    website: "",
    contactEmail: "",
    id: ""
  });

  const handleSubmit = data => {
    console.log(data);
    InstituteApi.saveInstitute(data).then(data => {
      navigateTo(LinkFactory.institute);
    });
  };

  return (
    <>
      <CommonLayout>
        <main>
          <div>
            <h3 className="mt-4">Institute New</h3>
            <br />
            <div className="card-body">
              <Formik
                initialValues={institute}
                enableReinitialize={true}
                onSubmit={handleSubmit}
              >
                {props => (
                  <Form>
                    <div className="table-responsive">
                      <table
                        className="table table-bordered"
                        id="dataTable"
                        width="100%"
                        cellSpacing="0"
                      >
                        <thead className="bg-light">
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Address</th>
                            <th scope="col">Post code</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Website</th>
                            <th scope="col">Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Field
                                type="text"
                                name="name"
                                placeholder="Name"
                                component={TextFieldComponent}
                              />
                            </td>
                            <td>
                              <Field
                                type="text"
                                name="address"
                                placeholder="address"
                                component={TextFieldComponent}
                              />
                            </td>
                            <td>
                              <Field
                                type="text"
                                name="code"
                                placeholder="code"
                                component={TextFieldComponent}
                              />
                            </td>
                            <td>
                              <Field
                                type="text"
                                name="phone"
                                placeholder="phone"
                                component={TextFieldComponent}
                              />
                            </td>
                            <td>
                              <Field
                                type="text"
                                name="website"
                                placeholder="website"
                                component={TextFieldComponent}
                              />
                            </td>
                            <td>
                              <Field
                                type="text"
                                name="contactEmail"
                                placeholder="contactEmail"
                                component={TextFieldComponent}
                              />
                            </td>
                            <td></td>
                          </tr>

                          <tr>
                            <td colSpan="12">
                              <button
                                type="submit"
                                className="btn btn-lg btn-primary float-right"
                              >
                                Save
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </main>
      </CommonLayout>
    </>
  );
}

export default InstituteNew;
