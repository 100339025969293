import {combineReducers} from "redux";
import modalReducer from "./modalReducer";
import formReducer from "./formReducer";
import SystemErrorReducer from "./systemErrorReducer";
import authReducer from "./authReducer";
import ChannelReducer from "../channel/reducer/channelReducer";
import NotificationCounterReducer from "../NotificationCounter/reducer/notificationCounterReducer";
import RoomBoardSearchReducer from "../RoomBoardSearch/reducer/roomBoardSearchReducer";

const rootReducer = combineReducers({
    form: formReducer,
    modal: modalReducer,
    systemError: SystemErrorReducer,
    auth: authReducer,
    channels: ChannelReducer,
    notificationCounter: NotificationCounterReducer,
    roomBoardSearchReducer: RoomBoardSearchReducer
});

export default rootReducer;
