import React, {useEffect, useState} from "react";
import * as ChannelApi from "../../api/ChannelApi";
import {Form, Formik} from "formik";
import {FILES_URL} from "../../util/LinkFactory";
import Loader from "../common/Loader";
import moment from "moment";
import * as Utils from "../../util/Utils";
import LikedUserModal from "./LikedUserModal";
import * as ModalActions from "../../action/ModalActions";
import {useDispatch} from "react-redux";
import SingleAttachmentComponent from "./Components/SingleAttachmentComponent";
import TwoAttachmentsComponent from "./Components/TwoAttachmentsComponent";
import ThreeAttachmentsComponent from "./Components/ThreeAttachmentsComponent";
import FourAttachmentComponent from "./Components/FourAttachmentComponent";
import MoreThanFourAttachmentComponent from "./Components/MoreThanFourAttachmentComponent";
import CommonLayout from "../layout/commonLayout";
import CachingImage from "../Image/cachingImage";
import {useParams} from "react-router-dom";

const initialComment = {messageId: null, comment: "", file: null};

function ChannelSinglePostView(props) {
    const params = useParams();
    const dispatch = useDispatch();
    const [channel, setChannel] = useState({});
    const [hasError, setHasError] = useState(false);
    const [post, setPost] = useState([]);
    const [comment, setComment] = useState(initialComment);

    const [postLoading, setPostLoading] = useState(true);

    const [user, setUser] = useState({});

    useEffect(() => {
        setUser(Utils.getUserFromLocalStorage());
        setChannel({});
        setPost([]);
        setPostLoading(true);
        ChannelApi.getChannelPostByPostId(params.id).then(response => {
            const status = response?.response?.status;
            if (status === 500) {
                console.error("got error ");
                setPostLoading(false);
                setHasError(true);
            } else {
                setPost(response.data);
                setPostLoading(false);
            }
        }).catch(errors => {
            console.error(errors);
            setPostLoading(false);
            setHasError(true);
        })

        ;
    }, [params.id]);

    const submitComment = (data, resetForm, post) => {
        let channelMessage = {
            id: data.messageId
        };

        let groupMessageCommentDTO = {
            comment: data.comment,
            channelMessages: channelMessage
        };
        let groupMessageCommentDTOToPushInArray = {
            comment: data.comment,
            channelMessages: channelMessage,
            user: Utils.getUserFromLocalStorage()
        };
        const newPosts = [...post];
        newPosts[post?.indexOf(post)].channelPostCommentDTOList.push(
            groupMessageCommentDTOToPushInArray
        );

        setPost(newPosts);
        ChannelApi.saveMessageComment(groupMessageCommentDTO).then(response => {
            console.log(response);
            const newPosts = [...post];
            newPosts[post?.indexOf(post)].channelPostCommentDTOList = response.data;
            setPost(newPosts);
        });
        resetForm(initialComment);
    };
    const registerLike = data => {
        console.log(data);
        let channelMessage = {
            id: data.groupMessageId
        };
        let ChannelPostLikeLogDTO = {
            likeEvent: !data.likedByCurrentUser,
            channelMessages: channelMessage
        };
        const newPosts = [...post];
        newPosts[post?.indexOf(data)].likedByCurrentUser = !newPosts[
            post?.indexOf(data)
            ].likedByCurrentUser;
        newPosts[post?.indexOf(data)].channelPostLikeLogList.push(
            ChannelPostLikeLogDTO
        );
        setPost(newPosts);
        ChannelApi.registerMessageLike(ChannelPostLikeLogDTO).then(response => {
            const newPosts = [...post];
            newPosts[post?.indexOf(data)].channelPostLikeLogList = response.data;
            setPost(newPosts);
        });
    };
    const submitCommentState = data => {
        setComment(data);
    };
    const viewLikedUserModal = post => {
        console.log("hello");
        console.log(post);
        dispatch(
            ModalActions.showModalWithoutFooter(
                null,
                <LikedUserModal likeLogs={post?.channelPostLikeLogList}/>,
                {className: "link-user-modal"}
            )
        );
    };

    return (
        <>
            <CommonLayout id="layoutSidenav_content">
                <main>
                    <div id="contentArea">
                        <div className="block-center">
                            {!postLoading ?
                                (hasError ?
                                    <h2>Something went wrong</h2> :
                                    (<div className="card-default">
                                            <div className="view-post-area">
                                                <div className="">
                                                    <div className="post-content">
                                                        <div className="post-by">
                                                            <div className="avatar">
                                                                <CachingImage
                                                                    alt="Image"
                                                                    src={FILES_URL + post?.postBy?.imageUrl}
                                                                />
                                                            </div>
                                                            <div className="post-by-info">
                                                                <h6>{post?.postBy?.firstName}</h6>
                                                                <p>
                                                                    {post?.postDate != null
                                                                        ? moment(post?.postDate).format(
                                                                            "DD/MM/YY hh:mm a"
                                                                        )
                                                                        : null}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <p>{post?.message}</p>
                                                        {post?.attachmentName?.length === 1 ? (
                                                            <SingleAttachmentComponent
                                                                imageurl={post?.attachmentName[0]}
                                                            />
                                                        ) : null}
                                                        {post?.attachmentName?.length === 2 ? (
                                                            <TwoAttachmentsComponent
                                                                imageurl={post?.attachmentName}
                                                            />
                                                        ) : null}
                                                        {post?.attachmentName?.length === 3 ? (
                                                            <ThreeAttachmentsComponent
                                                                imageurl={post?.attachmentName}
                                                            />
                                                        ) : null}
                                                        {post?.attachmentName?.length === 4 ? (
                                                            <FourAttachmentComponent
                                                                imageurl={post?.attachmentName}
                                                            />
                                                        ) : null}
                                                        {post?.attachmentName?.length > 4 ? (
                                                            <MoreThanFourAttachmentComponent
                                                                imageurl={post?.attachmentName}
                                                            />
                                                        ) : null}
                                                        {/*<SingleAttachmentComponent/>*/}
                                                        <div
                                                            className="post-status"
                                                            onClick={() => {
                                                                viewLikedUserModal(post);
                                                            }}
                                                        >
                                                            {post?.channelPostLikeLogList?.length > 0
                                                                ? post?.channelPostLikeLogList?.map(
                                                                    (likedUser, index) => {
                                                                        if (index < 3)
                                                                            return (
                                                                                <span>
                                                                                {likedUser?.createUser?.firstName}
                                                                                    {index > 0 ? post?.channelPostLikeLogList?.length > 1 ? ", " : null : null}
                                                                            </span>
                                                                            );
                                                                    }
                                                                )
                                                                : null}{" "}
                                                            {post?.channelPostLikeLogList?.length > 3
                                                                ? " and " +
                                                                (post?.channelPostLikeLogList?.length -
                                                                    3) +
                                                                " others liked"
                                                                : post?.channelPostLikeLogList?.length > 1
                                                                    ? "liked"
                                                                    : null}
                                                        </div>
                                                        <div className="like-comment-share">
                                                            <div className="box">
                                                                <i className={
                                                                    post?.likedByCurrentUser
                                                                        ? "fa fa-thumbs-up"
                                                                        : "far fa-thumbs-up"
                                                                }
                                                                   onClick={Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_STUDENT","ROLE_EXAM_CONTROLLER"]) ? () => {
                                                                       registerLike(post);
                                                                   } : null}
                                                                />
                                                                <p>
                                                                    <b>
                                                                        {post?.channelPostLikeLogList?.length}
                                                                    </b>{" "}
                                                                    Likes
                                                                </p>
                                                            </div>
                                                            <div className="box comment-count">
                                                                <i className="fa fa-comment-dots"/>
                                                                <p>
                                                                    <b>
                                                                        {post?.channelPostCommentDTOList?.length}
                                                                    </b>{" "}
                                                                    Comments
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="mb-2 mt-2"
                                                        >
                                                            {post?.channelPostCommentDTOList?.length > 0
                                                                ? post?.channelPostCommentDTOList?.map(
                                                                    (comment, index) => {
                                                                        return (
                                                                            <div className="comment-area">
                                                                                <div className="avatar">
                                                                                    <img
                                                                                        src={
                                                                                            FILES_URL +
                                                                                            comment?.user?.imageUrl
                                                                                        }
                                                                                    />
                                                                                </div>

                                                                                <div className="card-alt">
                                                                                    <h6>
                                                                                        {comment?.user?.firstName}{" "}
                                                                                        {comment?.user?.lastName}
                                                                                        <span>
                                                                                        {" "}
                                                                                            <small>{moment(
                                                                                                comment?.createTimeDate
                                                                                            ).fromNow()
                                                                                            }</small>
                                                                                    </span>
                                                                                    </h6>
                                                                                    <p>{comment?.comment}</p>
                                                                                    {/*<div className="like-comment-share">*/}
                                                                                    {/*    <div className="box">*/}
                                                                                    {/*        <img src="img/like.png"/>*/}
                                                                                    {/*        <p>{comment?.likedUserList?.length}</p>*/}
                                                                                    {/*    </div>*/}
                                                                                    {/*</div>*/}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                )
                                                                : null}
                                                        </div>
                                                        {Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY", "ROLE_STUDENT","ROLE_EXAM_CONTROLLER"]) ?
                                                            <Formik
                                                                initialValues={
                                                                    comment.post != null &&
                                                                    comment.messageId === post?.groupMessageId
                                                                        ? comment
                                                                        : initialComment
                                                                }
                                                                enableReinitialize={true}
                                                                onSubmit={(
                                                                    data,
                                                                    {
                                                                        setSubmitting,
                                                                        setErrors,
                                                                        setStatus,
                                                                        resetForm
                                                                    }
                                                                ) => {
                                                                    const value = {
                                                                        ...data,
                                                                        messageId: post?.groupMessageId
                                                                    };
                                                                    submitComment(value, resetForm, post);
                                                                }}
                                                            >
                                                                {({
                                                                      values,
                                                                      handleSubmit,
                                                                      setFieldValue
                                                                  }) => (
                                                                    <Form>
                                                                        <div className="add-comment-area">
                                                                            <div className="avatar">
                                                                                <CachingImage
                                                                                    alt="image"
                                                                                    src={FILES_URL + user?.imageUrl}
                                                                                />
                                                                            </div>
                                                                            <div className="input-default">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="comment"
                                                                                    aria-describedby="emailHelp"
                                                                                    name="comment"
                                                                                    placeholder="Write your comment here"
                                                                                    value={values.comment}
                                                                                    onChange={event => {
                                                                                        setFieldValue(
                                                                                            "comment",
                                                                                            event.target.value
                                                                                        );
                                                                                        submitCommentState({
                                                                                            ...values,
                                                                                            comment: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                                <button
                                                                                    type="submit"
                                                                                    className="btn-purple-md float-right"
                                                                                >
                                                                                    <i className="fa fa-send"/>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </Form>
                                                                )}
                                                            </Formik> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )) : postLoading ? (<Loader/>) : null
                            }
                            <br/>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default ChannelSinglePostView;
