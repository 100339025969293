import React, {useEffect, useState} from "react";

import "react-datepicker/dist/react-datepicker.css";
import * as ChannelApi from "../../api/ChannelApi";
import * as RoutineApi from "../../api/RoutineApi";
import {Field, FieldArray, Form, Formik} from "formik";
import TextFieldComponent from "../common/TextFieldComponent";
import SelectDropdown from "../common/SelectDropwodn";
import * as GradingApi from "../../api/GradingApi";
import CommonLayout from "../layout/commonLayout";

import * as LinkFactory from "../../util/LinkFactory";
import {toast} from "react-toastify";
import LoaderOverlay from "../common/LoaderOverlay";
import {useNavigate, useParams} from "react-router-dom";

function RoutineEdit(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const params = useParams();
    const [loader, setLoader] = useState(false);
    const [channel, setChannel] = useState({});
    const [selectedGradingBreakdown, setSelectedGradingBreakdown] = useState({});
    const [weekdays, setWeekdays] = useState([{day: "Friday"}, {day: "Saturday"}, {day: "Sunday"}, {day: "Monday"}, {day: "Tuesday"}, {day: "Wednesday"}, {day: "Thursday"}]);
    const [gradings, setGradings] = useState([]);
    const [routineDays, setRoutineDays] = useState({days: [], Grading: {}});

    const notifyUpdate = () => toast.success("Updated!", {
        position: toast.POSITION.TOP_CENTER
    });

    useEffect(() => {
        ChannelApi.getChannelById(params.id).then(json => {
            setChannel(json.data);
        });
        GradingApi.findGradingListByChannelId(params.id)
            .then(json => {
                setGradings(json.data);
                RoutineApi.getRoutineById(params.routineId).then(json => {
                    let routineVar = json.data;
                    setSelectedGradingBreakdown(json.data.grading);
                    RoutineApi.getRoutineDaysByRoutineId(params.routineId)
                        .then(json => {
                            const routineDays = json.data;

                            let days = [];
                            routineDays.forEach(routineDay => {
                                days.push({
                                    dayName: {day: routineDay.dayName},
                                    startTime: routineDay.startTime,
                                    endTime: routineDay.endTime
                                })
                            })
                            setRoutineDays({
                                Grading: routineVar.grading, days: days
                            })
                        })
                        .catch(errors => {
                            console.log(errors);
                        });
                });
            })
            .catch(errors => {
                console.log(errors);
            });

    }, []);

    const handleBreakdownSelected = (fieldName, value) => {
        setSelectedGradingBreakdown(value);
    };
    const handleSubmit = values => {
        setLoader(true);
        let routineDays = [];
        values.days.forEach(value => {
            routineDays.push({
                weekday: value.dayName.day, startTime: value.startTime, endTime: value.endTime
            });
        });
        let routine = {
            channel: channel,
            grading: selectedGradingBreakdown,
            routineDaysDTOList: routineDays,
            id: params.routineId
        };
        RoutineApi.saveRoutine(routine).then(response => {
            setLoader(false);
            notifyUpdate();
            navigateTo(LinkFactory.routineListByChannelId + "/" + params.id);
        });
    };

    return (<>
        <CommonLayout>
            <main>
                <div className="card-default routine-edit">
                    {loader ? <LoaderOverlay/> : null}
                    <div className="title-header">
                        <div className="mb-0 d-flex align-items-center">
                            <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                <a href="#" className="pl-0 back-button">
                                    <i className="fa fa-chevron-left"> </i> </a>
                            </div>
                            <p className=" ">
                                Create new Routine for :{" "}
                                {channel.id !== null ? channel.channelName : ""}
                            </p>
                        </div>
                    </div>

                    <br/>
                    <div className="   ">
                        <div className="card-body">
                            <p>Select Days & Time</p>
                            <Formik
                                initialValues={routineDays}
                                enableReinitialize={true}
                                onSubmit={handleSubmit}>
                                {({props, values}) => (<Form>
                                    <div>
                                        <FieldArray
                                            name="days"
                                            render={arrayHelpers => (
                                                <div className="routine-calendar-box d-flex align-items-center">
                                                {values.days && values.days.length > 0 ? values.days.map((day, index) => (
                                                    <span key={index} className="mt-3 ">
                                                          <div className="routine-calendar">
                                                              <svg width="21" height="21" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="10.5" cy="10.5" r="9.75" fill="#fff" stroke="#4575EE" stroke-width="1.5"/><circle cx="10.5" cy="10.5" r="5.75" fill="#4575EE" stroke="#fff" stroke-width="1.5"/></svg>
                                                            <div className=" ">
                                                              <div className="d-flex flex-column align-items-center mt-2">
                                                                <label>Weekday:</label>
                                                                <Field
                                                                    type="text"
                                                                    id={`days[${index}].dayName`}
                                                                    name={`days[${index}].dayName`}
                                                                    placeholder="Select Weekday"
                                                                    fieldName={`day`}
                                                                    options={weekdays || []}
                                                                    component={SelectDropdown}
                                                                />
                                                              </div>
                                                            </div>

                                                            <div className=" ">
                                                              <div className="d-flex mt-4">
                                                                <label>Start:</label>
                                                                <Field
                                                                    name={`days[${index}].startTime`}
                                                                    placeholder="07:00 AM"
                                                                    component={TextFieldComponent}
                                                                />
                                                              </div>
                                                            </div>
                                                              <div className=" ">
                                                              <div className="d-flex mt-1">
                                                                <label>End:</label>
                                                                <Field
                                                                    name={`days[${index}].endTime`}
                                                                    placeholder="08:00 AM"
                                                                    component={TextFieldComponent}
                                                                />
                                                              </div>
                                                            </div>
                                                               <div className=" ">
                                                            <div className=" ">
                                                              <div className="form-group">
                                                                <button
                                                                    className={"btn btn-danger"}
                                                                    type="button"
                                                                    onClick={() => arrayHelpers.remove(index)}>
                                                                  {" "}
                                                                    <i className="fa fa-trash"/>{" "}
                                                                    Delete
                                                                </button>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>


                                                        </span>)) : ""}
                                                  <div className=" ">
                                                    <div className=" ">
                                                        <div className="form-group">
                                                            <button
                                                                type="button"
                                                                className={"btn btn-primary mt-2"}
                                                                onClick={() => arrayHelpers.push({
                                                                    dayName: "", time: ""
                                                                })}>
                                                                <i className="fa fa-plus-circle"/>
                                                                Add more day
                                                            </button>
                                                        </div>
                                                    </div>
                                                  </div>
                                         </div>)}
                                        />
                                    </div>

                                    <div className="form-row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <button
                                                    type="submit"
                                                    className="btn btn-lg btn-purple-md ">
                                                    {/*<i className="fa fa-save"/> */}
                                                    Save Changes
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </Form>)}
                            </Formik>
                        </div>
                    </div>
                </div>
            </main>
        </CommonLayout>
    </>);
}

export default RoutineEdit;
