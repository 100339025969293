import React, {useEffect, useState} from "react";
import * as EventsApi from "../../api/EventsApi";
import moment from "moment-timezone";
import CommonLayout from "../layout/commonLayout";
import {FILES_URL} from "../../util/LinkFactory";
import {Form, Formik} from "formik";
import CachingImage from "../Image/cachingImage";
import {Tab, Tabs} from "react-bootstrap";
import * as Utils from "../../util/Utils";
import * as EventCommentsApi from "../../api/EventCommentsApi";
import {convertUTCDateToLocalDate} from "../../util/DateUtils";
import {useParams} from "react-router-dom";

function EventsView(props) {
    const params = useParams();
    const initialComment = {events: null, comment: ""};
    const [event, setEvent] = useState([]);
    const [eventMembersList, setEventMembersList] = useState([]);
    const [user, setUser] = useState([]);
    const [eventComments, setEventComments] = useState([]);
    const [comment, setComment] = useState(initialComment);
    const [eventAcceptedMembersList, setEventAcceptedMembersList] = useState([]);
    const [eventInvitedMembersList, setEventInvitedMembersList] = useState([]);
    const [eventRejectedMembersList, setEventRejectedMembersList] = useState([]);
    const [currentUserEvent, setCurrentUserEvent] = useState({});
    const submitCommentState = data => {
        setComment(data);
    };
    useEffect(() => {
        setUser(Utils.getUserFromLocalStorage());
    }, []);
    useEffect(() => {
        EventsApi.getEventsById(params.id)
            .then(json => {
                console.log(json);
                setEvent(json.data);
                EventCommentsApi.findCommentListByEventId(params.id).then(json => {
                    setEventComments(json.data)
                });
                EventsApi.findEventMemberListByChannelIdAndEventStatus(
                    params.id,
                    'ACCEPTED',
                    'STUDENT'
                ).then(json => {
                    setEventAcceptedMembersList(json.data);
                });
                EventsApi.findEventMemberListByChannelIdAndEventStatus(
                    params.id,
                    'INVITED',
                    'STUDENT'
                ).then(json => {
                    setEventInvitedMembersList(json.data);
                });
                EventsApi.findEventMemberListByChannelIdAndEventStatus(
                    params.id,
                    'REJECTED',
                    'STUDENT'
                ).then(json => {
                    setEventRejectedMembersList(json.data);
                });
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);
    const submitComment = (data, resetForm) => {
        console.log(data);
        let events = {
            id: params.id
        };

        let eventComment = {
            comment: data.comment,
            events: events,
            user: user
        };

        let tempEventComments = [];
        tempEventComments = [...eventComments];
        tempEventComments.push(eventComment);
        setEventComments(tempEventComments);

        EventCommentsApi.saveEventComments(eventComment).then(response => {
            const tempEventComments = [...eventComments];
            tempEventComments.add(response.data);
            setEventComments(tempEventComments);
        });
        resetForm(initialComment);
    };
    return (
        <>
            <CommonLayout headerName={Utils.getChannelName()}>
                <main>
                    <div className="event-detail card-default ">
                        <div className="">
                            <div className="eventview-header">
                                <button className="card-header p-0 m-0 ml-1 bg-c-light-grey">
                                    <a href="#" className="pl-0 back-button">
                                        <i className="fa fa-chevron-left"> </i> Back </a>
                                </button>
                                <div>
                                    <div className="block-group ">
                                        <div className="block-25">
                                            <div className=" d-flex">
                                                <div className="circle-icon bg-c-orange-light">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14"
                                                         fill="none">
                                                        <path fill="#E68F06" stroke="#E68F06"
                                                              d="M14.667 13.1h.5V.9H.833v12.2H10.5v-.7h2.333v.7h1.834Zm-1.834-3.902V9.3H3.167v-.102c0-.056.058-.224.386-.411.3-.173.714-.286 1.114-.286.3 0 .609.064.878.173l.301.123.235-.226c.388-.375 1.153-.672 1.919-.672s1.531.297 1.92.672l.233.226.302-.123c.27-.11.579-.173.878-.173.4 0 .813.113 1.114.286.328.187.386.355.386.411ZM.667.5h14.666c.036 0 .076.015.11.05.034.036.057.09.057.15v12.6c0 .06-.023.114-.057.15a.151.151 0 0 1-.11.05H.667a.151.151 0 0 1-.11-.05.218.218 0 0 1-.057-.15V.7C.5.64.523.586.557.55A.151.151 0 0 1 .667.5ZM7.36 5.397c0-.193.073-.373.196-.502A.613.613 0 0 1 8 4.7c.333 0 .64.29.64.697 0 .416-.31.704-.64.704s-.64-.288-.64-.704ZM4.307 6.503c0-.244.185-.403.36-.403a.34.34 0 0 1 .246.11c.07.073.114.178.114.293 0 .239-.18.396-.36.396s-.36-.157-.36-.396Zm6.666 0c0-.115.044-.22.114-.294a.34.34 0 0 1 .246-.109.34.34 0 0 1 .246.11c.07.073.114.178.114.293 0 .239-.18.396-.36.396s-.36-.157-.36-.396Z"/>
                                                    </svg>
                                                </div>
                                                <div className="ml-2 pl-1">
                                                    <div className="event-title">Event Name</div>
                                                    <div className="event-desc">{event.eventName}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="block-40">
                                            <div className="d-flex">
                                                <div className="circle-icon bg-c-purple-light">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21"
                                                         fill="none">
                                                        <path fill="#722CFF"
                                                              d="M9.99.5C4.47.5 0 4.98 0 10.5s4.47 10 9.99 10c5.53 0 10.01-4.48 10.01-10S15.52.5 9.99.5Zm.01 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8Zm.5-13H9v6l5.25 3.15.75-1.23-4.5-2.67V5.5Z"/>
                                                    </svg>
                                                </div>
                                                <div className="ml-2 pl-1">
                                                    <div className="event-title">Event Date & Time</div>
                                                    <div className="event-desc">
                                                        <div>
                                                            {convertUTCDateToLocalDate(new Date(event.eventStartTime))} - {convertUTCDateToLocalDate(new Date(event.eventEndTime))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="block-25">
                                            <div className="d-flex">
                                                <div className="circle-icon bg-c-blue-light">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18"
                                                         fill="none">
                                                        <path fill="#4575EE"
                                                              d="M7 9c.481 0 .893-.176 1.236-.53.343-.351.514-.775.514-1.27a1.76 1.76 0 0 0-.514-1.272A1.664 1.664 0 0 0 7 5.4c-.481 0-.893.176-1.236.528A1.76 1.76 0 0 0 5.25 7.2c0 .495.171.919.514 1.27C6.107 8.825 6.52 9 7 9Zm0 6.615c1.78-1.68 3.099-3.206 3.96-4.58.86-1.371 1.29-2.59 1.29-3.655 0-1.635-.507-2.974-1.52-4.017C9.715 2.321 8.472 1.8 7 1.8c-1.473 0-2.716.521-3.73 1.563C2.257 4.406 1.75 5.745 1.75 7.38c0 1.065.43 2.284 1.29 3.656.861 1.373 2.18 2.899 3.96 4.579ZM7 18c-2.348-2.055-4.101-3.964-5.26-5.727C.58 10.511 0 8.88 0 7.38c0-2.25.704-4.043 2.111-5.377C3.518.667 5.148 0 7 0c1.852 0 3.482.667 4.889 2.002C13.296 3.338 14 5.13 14 7.38c0 1.5-.58 3.131-1.739 4.893C11.101 14.036 9.348 15.945 7 18Z"/>
                                                    </svg>
                                                </div>
                                                <div className="ml-2 pl-1">
                                                    <div className="event-title">Event Venue</div>
                                                    <div className="event-desc">{event.eventVenue}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="comment-section">
                                {eventComments.length > 0
                                    ? eventComments.map(
                                        (comment, index) => {
                                            return (
                                                <div className="comment-area-event ">

                                                    <div className="d-flex">
                                                        <div className="avatar">
                                                            <img
                                                                src={
                                                                    FILES_URL +
                                                                    comment.user.imageUrl
                                                                }
                                                            />
                                                        </div>

                                                        <div className="card-alt-event">
                                                            {/*<h6>*/}
                                                            {/*    {comment.user.firstName}{" "}*/}
                                                            {/*    {comment.user.lastName}*/}
                                                            {/*    <span>*/}
                                                            {/*    {" "}*/}
                                                            {/*        <p>*/}
                                                            {/*            {moment(*/}
                                                            {/*                comment.createTimeDate*/}
                                                            {/*            ).format("MM/DD/YY")}*/}
                                                            {/*        </p>*/}
                                                            {/*</span>*/}
                                                            {/*</h6>*/}
                                                            <p className="card-p-event">{comment.comment}</p>
                                                            <p className="card-p-date">
                                                                {moment(
                                                                    comment.createTimeDate
                                                                ).format("MM/DD/YY")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="event-comment-buttons">

                                                        <button
                                                            type="button"
                                                            title="Edit"
                                                            className="btn btn-sm mr-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18"
                                                                 height="15"
                                                                 fill="none">
                                                                <path fill="#141944"
                                                                      d="m16.652 8.92-2.01-2.011L15.99 5.56 18 7.57l-1.348 1.348Zm-7.687 5.676v-2.01l5.014-5.015 2.01 2.01-5.014 5.015h-2.01ZM.45 9.866V7.972h6.623v1.892H.45Zm0-3.785V4.189h10.407V6.08H.45Zm0-3.785V.404h10.407v1.892H.45Z"/>
                                                            </svg>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            title="Edit"
                                                            className="btn btn-sm mr-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                 height="14"
                                                                 fill="none">
                                                                <path fill="#D81414"
                                                                      d="M2.25 13.904c-.413 0-.766-.147-1.06-.44a1.442 1.442 0 0 1-.44-1.06v-9.75H0v-1.5h3.75v-.75h4.5v.75H12v1.5h-.75v9.75c0 .413-.147.766-.441 1.06-.294.294-.647.44-1.059.44h-7.5Zm7.5-11.25h-7.5v9.75h7.5v-9.75Zm-6 8.25h1.5v-6.75h-1.5v6.75Zm3 0h1.5v-6.75h-1.5v6.75Z"/>
                                                            </svg>
                                                        </button>
                                                    </div>

                                                </div>
                                            );
                                        }
                                    )
                                    : null}
                                <div>
                                    <Formik
                                        initialValues={initialComment}
                                        enableReinitialize={true}
                                        onSubmit={(
                                            data,
                                            {
                                                setSubmitting,
                                                setErrors,
                                                setStatus,
                                                resetForm
                                            }
                                        ) => {
                                            const value = {
                                                ...data
                                            };
                                            submitComment(value, resetForm);
                                        }}
                                    >
                                        {({
                                              values,
                                              handleSubmit,
                                              setFieldValue
                                          }) => (
                                            <Form>
                                                <div className="add-comment-area">
                                                    <div className="avatar">
                                                        <CachingImage
                                                            alt="image"
                                                            src={FILES_URL + user?.imageUrl}
                                                        />
                                                    </div>
                                                    <div className="input-default">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="comment"
                                                            aria-describedby="emailHelp"
                                                            name="comment"
                                                            placeholder="Write your comment here"
                                                            value={values.comment}
                                                            onChange={event => {
                                                                setFieldValue(
                                                                    "comment",
                                                                    event.target.value
                                                                );
                                                                submitCommentState({
                                                                    ...values,
                                                                    comment: event.target.value
                                                                });
                                                            }}
                                                        />
                                                        <button
                                                            type="submit"
                                                            className="btn-purple-md float-right"
                                                        >
                                                            SUBMIT
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>

                            </div>
                            <div className="invitation-status">
                                <p>
                                    Invitation Status
                                </p>

                            </div>

                            <Tabs defaultActiveKey="accepted" id="uncontrolled-tab-example" transition={true}
                                  className="mb-3 pb-3 ml-4">
                                <Tab eventKey="accepted" title="Coming" className="pl-2">
                                    {eventAcceptedMembersList.length > 0 ?
                                        <div>
                                            {/*<h5 className="fw-600 mb-3 mt-4 ml-1">*/}
                                            {/*    Name*/}
                                            {/*</h5>*/}
                                            {eventAcceptedMembersList.length > 0
                                                ? eventAcceptedMembersList.map((data, index) => {
                                                    return (
                                                        <ul className="ml-4">
                                                            <ul>
                                                                <li>
                                                                    {data.user.firstName} {data.user.lastName}
                                                                </li>
                                                            </ul>
                                                        </ul>
                                                    );
                                                })
                                                : null}
                                        </div> : (
                                            <div className=" d-flex justify-content-center">
                                                <div className="no-members-available">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="259" height="220"
                                                         fill="none">
                                                        <circle cx="122.871" cy="108.26" r="98.418" fill="#EAECF0"/>
                                                        <circle cx="19.532" cy="34.446" r="9.842" fill="#F2F4F7"/>
                                                        <circle cx="231.131" cy="164.85" r="7.381" fill="#F2F4F7"/>
                                                        <circle cx="18.302" cy="179.613" r="12.302" fill="#F2F4F7"/>
                                                        <circle cx="245.894" cy="66.432" r="12.302" fill="#F2F4F7"/>
                                                        <circle cx="222.52" cy="23.374" r="8.612" fill="#F2F4F7"/>
                                                        <g filter="url(#a)">
                                                            <path fill="#F9FAFB"
                                                                  d="m122.871 67.605 8.504-3.562-8.503 3.622v101.407l78.352-32.833V34.787l.382-.16-.382-.17v-.17l-.196.084L123.489 0 44.137 33.802l.375.162-.375 101.04 78.734 34.008V67.605Z"/>
                                                            <path fill="url(#b)"
                                                                  d="M122.871 67.605v101.407l-78.734-34.008.375-101.04 78.359 33.64Z"/>
                                                            <path fill="url(#c)"
                                                                  d="M122.873 67.665v101.407l78.351-32.832V34.287l-78.351 33.378Z"/>
                                                            <path fill="#F2F4F7"
                                                                  d="m122.871 67.605 78.735-32.978L123.489 0 44.137 33.802l78.734 33.803Z"/>
                                                            <path fill="#EAECF0"
                                                                  d="m73.816 21.16 78.435 34.139.712 24.878 21.945-9.055-.664-25.035L93.49 12.779l-19.674 8.38Z"/>
                                                        </g>
                                                        <path stroke="#fff" stroke-linecap="round"
                                                              stroke-linejoin="round"
                                                              stroke-width="4.364"
                                                              d="m135.789 175.307-5.023-5.023m3.588-8.612c0 6.738-5.462 12.2-12.2 12.2-6.738 0-12.2-5.462-12.2-12.2 0-6.738 5.462-12.2 12.2-12.2 6.738 0 12.2 5.462 12.2 12.2Z"/>
                                                        <defs>
                                                            <linearGradient id="b" x1="44.137" x2="75.231" y1="135.514"
                                                                            y2="51.302" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#DEE1E8"/>
                                                                <stop offset="1" stop-color="#EFF1F5"/>
                                                            </linearGradient>
                                                            <linearGradient id="c" x1="122.872" x2="211.652" y1="69.65"
                                                                            y2="87.325" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#E4E7EC"/>
                                                                <stop offset="1" stop-color="#F2F4F7"/>
                                                            </linearGradient>
                                                            <filter id="a" width="244.741" height="256.345" x=".5" y="0"
                                                                    color-interpolation-filters="sRGB"
                                                                    filterUnits="userSpaceOnUse">
                                                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                                                <feColorMatrix in="SourceAlpha" result="hardAlpha"
                                                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                                                <feMorphology in="SourceAlpha" radius="8.727"
                                                                              result="effect1_dropShadow_455_10594"/>
                                                                <feOffset dy="17.454"/>
                                                                <feGaussianBlur stdDeviation="8.727"/>
                                                                <feColorMatrix
                                                                    values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"/>
                                                                <feBlend in2="BackgroundImageFix"
                                                                         result="effect1_dropShadow_455_10594"/>
                                                                <feColorMatrix in="SourceAlpha" result="hardAlpha"
                                                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                                                <feMorphology in="SourceAlpha" radius="8.727"
                                                                              result="effect2_dropShadow_455_10594"/>
                                                                <feOffset dy="43.636"/>
                                                                <feGaussianBlur stdDeviation="26.182"/>
                                                                <feColorMatrix
                                                                    values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"/>
                                                                <feBlend in2="effect1_dropShadow_455_10594"
                                                                         result="effect2_dropShadow_455_10594"/>
                                                                <feBlend in="SourceGraphic"
                                                                         in2="effect2_dropShadow_455_10594"
                                                                         result="shape"/>
                                                            </filter>
                                                        </defs>
                                                    </svg>
                                                    <p>No Members Available</p>
                                                </div>
                                            </div>
                                        )}
                                </Tab>
                                <Tab eventKey="invited" title="Invited" className="pl-2">
                                    {eventInvitedMembersList.length > 0 ?
                                        <div>
                                            {/*<h5 className="fw-600 mb-3 mt-4 ml-1">*/}
                                            {/*    Name*/}
                                            {/*</h5>*/}
                                            {eventInvitedMembersList.length > 0
                                                ? eventInvitedMembersList.map((data, index) => {
                                                    return (
                                                        <ul className="ml-4">
                                                            <ul>
                                                                <li>
                                                                    {data.user.firstName} {data.user.lastName}
                                                                </li>
                                                            </ul>
                                                        </ul>
                                                    );
                                                })
                                                : null}
                                        </div> : (
                                            <div className=" d-flex justify-content-center">
                                                <div className="no-members-available">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="259" height="220"
                                                         fill="none">
                                                        <circle cx="122.871" cy="108.26" r="98.418" fill="#EAECF0"/>
                                                        <circle cx="19.532" cy="34.446" r="9.842" fill="#F2F4F7"/>
                                                        <circle cx="231.131" cy="164.85" r="7.381" fill="#F2F4F7"/>
                                                        <circle cx="18.302" cy="179.613" r="12.302" fill="#F2F4F7"/>
                                                        <circle cx="245.894" cy="66.432" r="12.302" fill="#F2F4F7"/>
                                                        <circle cx="222.52" cy="23.374" r="8.612" fill="#F2F4F7"/>
                                                        <g filter="url(#a)">
                                                            <path fill="#F9FAFB"
                                                                  d="m122.871 67.605 8.504-3.562-8.503 3.622v101.407l78.352-32.833V34.787l.382-.16-.382-.17v-.17l-.196.084L123.489 0 44.137 33.802l.375.162-.375 101.04 78.734 34.008V67.605Z"/>
                                                            <path fill="url(#b)"
                                                                  d="M122.871 67.605v101.407l-78.734-34.008.375-101.04 78.359 33.64Z"/>
                                                            <path fill="url(#c)"
                                                                  d="M122.873 67.665v101.407l78.351-32.832V34.287l-78.351 33.378Z"/>
                                                            <path fill="#F2F4F7"
                                                                  d="m122.871 67.605 78.735-32.978L123.489 0 44.137 33.802l78.734 33.803Z"/>
                                                            <path fill="#EAECF0"
                                                                  d="m73.816 21.16 78.435 34.139.712 24.878 21.945-9.055-.664-25.035L93.49 12.779l-19.674 8.38Z"/>
                                                        </g>
                                                        <path stroke="#fff" stroke-linecap="round"
                                                              stroke-linejoin="round"
                                                              stroke-width="4.364"
                                                              d="m135.789 175.307-5.023-5.023m3.588-8.612c0 6.738-5.462 12.2-12.2 12.2-6.738 0-12.2-5.462-12.2-12.2 0-6.738 5.462-12.2 12.2-12.2 6.738 0 12.2 5.462 12.2 12.2Z"/>
                                                        <defs>
                                                            <linearGradient id="b" x1="44.137" x2="75.231" y1="135.514"
                                                                            y2="51.302" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#DEE1E8"/>
                                                                <stop offset="1" stop-color="#EFF1F5"/>
                                                            </linearGradient>
                                                            <linearGradient id="c" x1="122.872" x2="211.652" y1="69.65"
                                                                            y2="87.325" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#E4E7EC"/>
                                                                <stop offset="1" stop-color="#F2F4F7"/>
                                                            </linearGradient>
                                                            <filter id="a" width="244.741" height="256.345" x=".5" y="0"
                                                                    color-interpolation-filters="sRGB"
                                                                    filterUnits="userSpaceOnUse">
                                                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                                                <feColorMatrix in="SourceAlpha" result="hardAlpha"
                                                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                                                <feMorphology in="SourceAlpha" radius="8.727"
                                                                              result="effect1_dropShadow_455_10594"/>
                                                                <feOffset dy="17.454"/>
                                                                <feGaussianBlur stdDeviation="8.727"/>
                                                                <feColorMatrix
                                                                    values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"/>
                                                                <feBlend in2="BackgroundImageFix"
                                                                         result="effect1_dropShadow_455_10594"/>
                                                                <feColorMatrix in="SourceAlpha" result="hardAlpha"
                                                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                                                <feMorphology in="SourceAlpha" radius="8.727"
                                                                              result="effect2_dropShadow_455_10594"/>
                                                                <feOffset dy="43.636"/>
                                                                <feGaussianBlur stdDeviation="26.182"/>
                                                                <feColorMatrix
                                                                    values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"/>
                                                                <feBlend in2="effect1_dropShadow_455_10594"
                                                                         result="effect2_dropShadow_455_10594"/>
                                                                <feBlend in="SourceGraphic"
                                                                         in2="effect2_dropShadow_455_10594"
                                                                         result="shape"/>
                                                            </filter>
                                                        </defs>
                                                    </svg>
                                                    <p>No Members Available</p>
                                                </div>
                                            </div>
                                        )}
                                </Tab>
                                <Tab eventKey="notcoming" title="Not Coming" className="pl-2">
                                    {eventRejectedMembersList.length > 0 ?
                                        <div>
                                            {/*<h5 className="fw-600 mb-3 mt-4 ml-1">*/}
                                            {/*    Name*/}
                                            {/*</h5>*/}
                                            {eventRejectedMembersList.length > 0
                                                ? eventRejectedMembersList.map((data, index) => {
                                                    return (
                                                        <ul className="ml-4">
                                                            <ul>
                                                                <li>
                                                                    {data.user.firstName} {data.user.lastName}
                                                                </li>
                                                            </ul>
                                                        </ul>
                                                    );
                                                })
                                                : null}
                                        </div> : (
                                            <div className=" d-flex justify-content-center">
                                                <div className="no-members-available">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="259" height="220"
                                                         fill="none">
                                                        <circle cx="122.871" cy="108.26" r="98.418" fill="#EAECF0"/>
                                                        <circle cx="19.532" cy="34.446" r="9.842" fill="#F2F4F7"/>
                                                        <circle cx="231.131" cy="164.85" r="7.381" fill="#F2F4F7"/>
                                                        <circle cx="18.302" cy="179.613" r="12.302" fill="#F2F4F7"/>
                                                        <circle cx="245.894" cy="66.432" r="12.302" fill="#F2F4F7"/>
                                                        <circle cx="222.52" cy="23.374" r="8.612" fill="#F2F4F7"/>
                                                        <g filter="url(#a)">
                                                            <path fill="#F9FAFB"
                                                                  d="m122.871 67.605 8.504-3.562-8.503 3.622v101.407l78.352-32.833V34.787l.382-.16-.382-.17v-.17l-.196.084L123.489 0 44.137 33.802l.375.162-.375 101.04 78.734 34.008V67.605Z"/>
                                                            <path fill="url(#b)"
                                                                  d="M122.871 67.605v101.407l-78.734-34.008.375-101.04 78.359 33.64Z"/>
                                                            <path fill="url(#c)"
                                                                  d="M122.873 67.665v101.407l78.351-32.832V34.287l-78.351 33.378Z"/>
                                                            <path fill="#F2F4F7"
                                                                  d="m122.871 67.605 78.735-32.978L123.489 0 44.137 33.802l78.734 33.803Z"/>
                                                            <path fill="#EAECF0"
                                                                  d="m73.816 21.16 78.435 34.139.712 24.878 21.945-9.055-.664-25.035L93.49 12.779l-19.674 8.38Z"/>
                                                        </g>
                                                        <path stroke="#fff" stroke-linecap="round"
                                                              stroke-linejoin="round"
                                                              stroke-width="4.364"
                                                              d="m135.789 175.307-5.023-5.023m3.588-8.612c0 6.738-5.462 12.2-12.2 12.2-6.738 0-12.2-5.462-12.2-12.2 0-6.738 5.462-12.2 12.2-12.2 6.738 0 12.2 5.462 12.2 12.2Z"/>
                                                        <defs>
                                                            <linearGradient id="b" x1="44.137" x2="75.231" y1="135.514"
                                                                            y2="51.302" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#DEE1E8"/>
                                                                <stop offset="1" stop-color="#EFF1F5"/>
                                                            </linearGradient>
                                                            <linearGradient id="c" x1="122.872" x2="211.652" y1="69.65"
                                                                            y2="87.325" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#E4E7EC"/>
                                                                <stop offset="1" stop-color="#F2F4F7"/>
                                                            </linearGradient>
                                                            <filter id="a" width="244.741" height="256.345" x=".5" y="0"
                                                                    color-interpolation-filters="sRGB"
                                                                    filterUnits="userSpaceOnUse">
                                                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                                                <feColorMatrix in="SourceAlpha" result="hardAlpha"
                                                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                                                <feMorphology in="SourceAlpha" radius="8.727"
                                                                              result="effect1_dropShadow_455_10594"/>
                                                                <feOffset dy="17.454"/>
                                                                <feGaussianBlur stdDeviation="8.727"/>
                                                                <feColorMatrix
                                                                    values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"/>
                                                                <feBlend in2="BackgroundImageFix"
                                                                         result="effect1_dropShadow_455_10594"/>
                                                                <feColorMatrix in="SourceAlpha" result="hardAlpha"
                                                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                                                <feMorphology in="SourceAlpha" radius="8.727"
                                                                              result="effect2_dropShadow_455_10594"/>
                                                                <feOffset dy="43.636"/>
                                                                <feGaussianBlur stdDeviation="26.182"/>
                                                                <feColorMatrix
                                                                    values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"/>
                                                                <feBlend in2="effect1_dropShadow_455_10594"
                                                                         result="effect2_dropShadow_455_10594"/>
                                                                <feBlend in="SourceGraphic"
                                                                         in2="effect2_dropShadow_455_10594"
                                                                         result="shape"/>
                                                            </filter>
                                                        </defs>
                                                    </svg>
                                                    <p>No Members Available</p>
                                                </div>
                                            </div>
                                        )}
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default EventsView;
