import AxiosAgent from "./AxiosAgent";
import { API_MAIN_ROOT } from "../util/LinkFactory";
import * as Cookies from "js-cookie";

export const searchStudent = search => {
  return AxiosAgent.requests.post(API_MAIN_ROOT + "/student/find-by-org-info", search);
};

export const saveStudent = student => {
  return AxiosAgent.requests.post(API_MAIN_ROOT + "/student/", student);
};
export const saveStudentDTO = student => {
  return AxiosAgent.requests.post(
    API_MAIN_ROOT + "/student-register/",
    student
  );
};

export const isAlreadyRegisteredByEmail = user => {
  return AxiosAgent.requests.post(
    API_MAIN_ROOT + "/users/userEmailNumberDuplicacyCheck",
    user
  );
};

export const updateStudent = student => {
  return AxiosAgent.requests.put(API_MAIN_ROOT + "/student/", student);
};
export const getStudentById = id => {
  return AxiosAgent.requests.get(API_MAIN_ROOT + "/student/" + id);
};
export const deleteStudentById = id => {
  return AxiosAgent.requests.delete(API_MAIN_ROOT + "/student/" + id);
};
export const getAll = userId => {
  return AxiosAgent.requests.get(API_MAIN_ROOT + "/student");
};

export const searchStudents = search => {
  return AxiosAgent.requests.post(
    API_MAIN_ROOT + "/search-students",
    search
  );
};
export const getStudentListBySectionId = id => {
  return AxiosAgent.requests.get(API_MAIN_ROOT + "/student/by-section/" + id);
};
export const getStudentListByGroupId = id => {
  return AxiosAgent.requests.get(API_MAIN_ROOT + "/student/by-section/" + id);
};
export const getStudentListBySectionAndGroupId = (sectionId, groupId) => {
  return AxiosAgent.requests.get(
    API_MAIN_ROOT + "/student/by-grade-section/" + sectionId + "/" + groupId
  );
};
export const studentSearchForTypeahead = query => {
  return AxiosAgent.requests.get(API_MAIN_ROOT + "/student/search/" + query);
};

export const getStudentsForAdmin = id => {
  return AxiosAgent.requests.get(API_MAIN_ROOT + "/student");
};

export const getStudentInfoById = id => {
  return AxiosAgent.requests.get(API_MAIN_ROOT + "/student-info/" + id);
};
export const getStudentInfoByUserId = id => {
  return AxiosAgent.requests.get(API_MAIN_ROOT + "/student-info/by-user-id/" + id);
};

export const updateStudentInfo = async (data) => {
  return AxiosAgent.requests.put(API_MAIN_ROOT + "/student-info/", data);

};

export const getAllStudentByLoginParent = parentId => {
  return AxiosAgent.requests.get(API_MAIN_ROOT + "/student-info-by-parent/" + parentId);
};
export const uploadStudentProfilePicture = (data) => {
  return AxiosAgent.requests.put(API_MAIN_ROOT + "/student/upload-profile-picture/", data);
};

export const studentRegistrationXlsx = (file) => {
  const formData = new FormData();
  formData.append('file',file)
  return  AxiosAgent.requests.postMultipart(API_MAIN_ROOT + `/student/upload-xlsx`, formData);
}
