import React, {useEffect, useState} from "react";
import * as AttendanceApi from "../../api/AttendanceApi";
import * as ChannelApi from "../../api/ChannelApi";
import Loader from "../common/Loader";
import CommonLayout from "../layout/commonLayout";
import * as Utils from "../../util/Utils";
import {useParams} from "react-router-dom";
import ReactPaginate from "react-paginate";

function AttendanceReport(props) {
    const params = useParams();
    const [channelMembers, setChannelMembers] = useState([]);
    const [classCount, setClassCount] = useState(0);
    const [channel, setChannel] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        AttendanceApi.attendanceReport(params.id)
            .then(json => {
                setChannelMembers(json.data);
            })
            .catch(e => console.error(e))
            .finally(() => setLoading(false));
        AttendanceApi.currentRoutinesTotalClassByChannelId(
            params.id
        ).then(json => {
            setClassCount(json.data.classCount);
        });
        ChannelApi.getChannelById(params.id).then(json => {
            setChannel(json.data);
        });
    }, []);

    return (
        <>
            <CommonLayout headerName={Utils.getChannelName()}>
                <main>
                    <div className="attendance-report card-default ">
                        <div className="title  d-flex justify-content-between">
                            <div className="card-header p-0 m-0 mr-3 bg-c-light-grey d-flex align-items-center">
                                <div className="d-flex align-items-center">
                                    <a href="#" className="pl-0 back-button">
                                        <i className="fa fa-chevron-left pr-3"> </i> </a>
                                    <p className=" ">
                                        Attendance Report
                                    </p>

                                </div>

                                <p className="float-right ">Total Class Count :{" "}
                                    {classCount}{" "}
                                </p>
                            </div>
                            <button
                                type="button"
                                className="btn bg-c-blue-light float-right"
                            >
                                <svg width="13" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.625 0C1.194 0 .781.18.476.498A1.74 1.74 0 0 0 0 1.7v13.6c0 .45.171.883.476 1.202A1.59 1.59 0 0 0 1.625 17h9.75c.431 0 .844-.18 1.149-.498A1.74 1.74 0 0 0 13 15.3V5.1L8.125 0h-6.5Zm0 1.7h5.688v4.25h4.062v9.35h-9.75V1.7ZM3.25 8.5v1.7h6.5V8.5h-6.5Zm0 3.4v1.7h4.063v-1.7H3.25Z" fill="#4575EE"/></svg>
                                Download Report
                            </button>
                        </div>

                        <br/>
                        {loading ? (
                            <Loader/>
                        ) : (
                            <div className="table-responsive table-light">
                                <table
                                    className="table table-light table-striped table-hover"
                                    id="dataTable"
                                    width="100%"
                                    cellSpacing="0"
                                >
                                    <thead>
                                    <tr>
                                        {/*<th scope="col">sl</th>*/}
                                        <th scope="col">Student Name</th>
                                        <th scope="col">Student ID</th>
                                        <th scope="col" width="12%">Present</th>
                                        <th scope="col" width="12%">Late</th>
                                        <th scope="col" width="12%">Absent</th>
                                        <th scope="col" width="12%">Excused</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {channelMembers.length > 0
                                        ? channelMembers.map((data, index) => {
                                            return (
                                                <tr>
                                                    {/*<th scope="row">{index + 1}</th>*/}
                                                    <td>

                                                        {data.studentName}
                                                    </td>
                                                    <td>181564</td>
                                                    <td>{null != data.presentCount ? data.presentCount : 0}</td>
                                                    <td>{null != data.latePresentCount ? data.latePresentCount : 0}</td>
                                                    <td>{null != data.absentCount ? data.absentCount : 0}</td>
                                                    <td>{null != data.absentExcusedCount ? data.absentExcusedCount : 0}</td>
                                                </tr>
                                            );
                                        })
                                        : ""}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </main>
            </CommonLayout>
        </>
    )
        ;
}

export default AttendanceReport;
