import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveParents = parents => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/parents/", parents);
};
export const saveParentsDTO = parents => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/parents-dto/", parents);
};

export const updateParents = student => {
    return AxiosAgent.requests.put(API_MAIN_ROOT + "/parents/", student);
};
export const getParentsById = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/parents/" + id);
};
export const deleteParentsById = id => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/parents/" + id);
};
export const getAll = userId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/parents");
};
export const getParentsListBySectionId = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/parents/by-section/" + id);
};
export const getParentsListByGroupId = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/parents/by-section/" + id);
};
export const getParentsListBySectionAndGroupId = (sectionId, groupId) => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/parents/by-grade-section/" + sectionId + "/" + groupId
    );
};

export const registerParent = parent => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/parents-register",
        parent
    );
};

export const parentRegistrationApproval = parents => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/search-parents",
        parents
    );
};

export const parentApproval = parent => {
    return AxiosAgent.requests.put(
        API_MAIN_ROOT + "/approve-parent",
        parent
    );
};

