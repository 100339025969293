import React, {Fragment, useEffect, useState} from "react";
import {Login, LogOut} from "../header/NavMenuComponents";
import * as Utils from "../../util/Utils";
import * as NotificationsApi from "../../api/NotificationsApi";

import * as LinkFactory from "../../util/LinkFactory";
import {useNavigate} from "react-router-dom";
import {TAG_ASSIGNMENT, TAG_MESSAGE, TAG_POST} from "../../constants/Constants";
import {dateTimeForNotification} from "../../util/DateUtils";
import {connect, useDispatch} from "react-redux";
import {storeSearchQuery} from "../../storeManager/RoomBoardSearch/middleware/RoomBoardSearchMiddleware";
import {fetchNotificationCounter} from "../../storeManager/NotificationCounter/middleware/NotificationCounterMiddleware";
import SideNavMenu from "./sideNavMenu";

function NoNotification({message}) {
    return (
        <div className="mx-auto my-auto p-5 ">
            <p> {message ? message : "No notification"}</p>
        </div>
    );
}

function TopNavComponent(props) {
    let history = useNavigate();
    const dispatch = useDispatch();

    function navigateTo(pathname) {
        history(pathname);
    }

    const setupRoute = [
        {
            name: "Departments",
            path: LinkFactory.departmentList,
            role: "DEPARTMENT_LEFT_MENU"
        },
        {
            name: "Employees",
            path: LinkFactory.employeeList,
            role: "EMPLOYEE_LEFT_MENU"
        },
        {
            name: "Evaluation Question Set",
            path: LinkFactory.evaluationQuestionMainList,
            role: "EVALUATION_LEFT_MENU"
        },
        {
            name: "Evaluation Question Type List",
            path: LinkFactory.evaluationQuestionTypeList,
            role: "EVALUATION_LEFT_MENU"
        },
        {
            name: "Evaluation Question List",
            path: LinkFactory.evaluationQuestionList,
            role: "EVALUATION_LEFT_MENU"
        },
        {
            name: "Evaluation Question Option List",
            path: LinkFactory.evaluationQuestionOptionList,
            role: "EVALUATION_LEFT_MENU"
        },
        {
            name: "Evaluation Info List",
            path: LinkFactory.evaluationInfoList,
            role: "EVALUATION_LEFT_MENU"
        },
        {
            name: "Faculty",
            path: LinkFactory.facultyList,
            role: "FACULTY_LEFT_MENU"
        },
        {
            name: "Letter Grades",
            path: LinkFactory.letterGradeNew,
            role: "LETTER_GRADE_LEFT_MENU"
        },
        {
            name: "Programs",
            path: LinkFactory.programList,
            role: "PROGRAM_LEFT_MENU"
        },
        // {
        //     name: "Subject ",
        //     path: LinkFactory.subject
        // },
        // {
        //     name: "Institute District ",
        //     path: LinkFactory.instituteDistrict
        // },
        // {
        //     name: "Institute ",
        //     path: LinkFactory.institute
        // },
        {
            name: "Room Setup",
            path: LinkFactory.channelList,
            role: "ROOM_LEFT_MENU"

        },
        {
            name: "Students",
            path: LinkFactory.studentsList,
            role: "STUDENT_LEFT_MENU"
        },
        {
            name: "Semesters",
            path: LinkFactory.semesterList,
            role: "SEMESTER_LEFT_MENU"
        },
        {
            name: "Search Student Result",
            path: LinkFactory.studentCourseHistory,
            role: "STUDENT_RESULT_LEFT_MENU"
        },
        // ,
        // {
        //     name: "Parent Registration Approval",
        //     path: LinkFactory.parentRegistrationApproval
        // }
    ];

    //const user = useSelector(state => state.auth.user);
    const [user, setUser] = useState({});
    const showLogOut = () => !!user;
    const showLogin = () => !user;
    const [hide, setHide] = useState(false);
    const [assignmentCount, setAssignmentCount] = useState(0);
    // const [eventCount, setEventCount] = useState(0);
    // const [postCount, setPostCount] = useState(0);
    const [assignmentNotifications, setAssignmentNotifications] = useState([]);
    const [postNotifications, setPostNotifications] = useState([]);
    const [eventNotifications, setEventNotifications] = useState([]);
    const SERVER_NAME = process.env.REACT_APP_FILES_URL;

    const router = useNavigate();

    const handleHide = () => {
        if (hide === true) {
            props.hide(false);
            setHide(false);
        } else {
            props.hide(true);
            setHide(true);
        }
    };
    const handleMessageRoute = () => {
        clearNotificationCount(TAG_MESSAGE);
        navigateTo(LinkFactory.messageThread);
    };
    const handleParentChannelsRoute = () => {
        navigateTo(LinkFactory.parentsChannels);
    };

    const loadAssignmentNotifications = () => {
        NotificationsApi.notificationListByUserIdAndTag(
            Utils.getUserFromLocalStorage().id,
            "assignment"
        ).then(json => {
            setAssignmentNotifications(json.data);
        });
    };
    const loadPostNotifications = () => {
        let tags = [];
        tags.push("event");
        tags.push("post");
        let notificationsListReqDto = {
            userId: Utils.getUserFromLocalStorage().id,
            tagList: tags
        };
        NotificationsApi.notificationByTags(notificationsListReqDto).then(res => {
            setPostNotifications(res.data);
        })
    };
    const updateNotificationReadStatus = notification => {
        let notificationReadStatusDto = {
            id: notification.id,
            readStatus: true
        };
        NotificationsApi.updateNotificationReadStatus(notificationReadStatusDto).then(res => {
        })
    }

    const markAllAsReadNotificationStatus = (tag) => {
        let notificationReadStatusDto = {
            userId: Utils.getUserFromLocalStorage().id,
            readStatus: false,
            tag: tag
        };
        NotificationsApi.updateAllNotificationReadStatus(notificationReadStatusDto).then(res => {
            console.log(res.data);
        });
    }

    const redirectionController = notification => {
        updateNotificationReadStatus(notification);
        if (notification.tag === "assignment") {
            if (notification.assignmentInd === true) {
                if (notification.assignmentSubmittedInd === true) {
                    navigateTo(LinkFactory.assignmentSubmittedView + "/" + notification.url);
                } else {
                    navigateTo(LinkFactory.assignmentSubmit + "/" + notification.url);
                }
            }
            if (notification.mcqInd === true) {
                if (notification.assignmentSubmittedInd === true) {
                    navigateTo(LinkFactory.mcqSubmittedView + "/" + notification.url);
                } else {
                    navigateTo(LinkFactory.mcqExam + "/" + notification.url);
                }
            }
        } else if (notification.tag === "event") {
            if (notification.eventInd) {
                if (notification.url.includes("/")) {
                    let eventId = notification.url.split("/")[1];
                    navigateTo(LinkFactory.eventsSetupViewForUser + "/" + eventId);
                } else
                    navigateTo(
                        LinkFactory.eventsSetupViewForUser + "/" + notification.url
                    );
            }
        } else if (
            notification.tag === "post" ||
            notification.tag === "post-comment" ||
            notification.tag === "post-like" ||
            notification.postInd === true
        ) {
            if (notification.url.includes("/")) {
                let postId = notification.url.split("/")[1];
                navigateTo(LinkFactory.channelPostView + "/" + postId);
            } else
                navigateTo(LinkFactory.channelPostView + "/" + notification.url);
        }
    };
    const handleAllNotificationRoute = () => {
        navigateTo(LinkFactory.allNotifications);
    }
    useEffect(() => {

    }, [props.notificationPostCounter, props.notificationAssignmentCounter])
    useEffect(() => {
        setUser(Utils.getUserFromLocalStorage());
        loadAssignmentNotifications();
        loadPostNotifications();
    }, []);
    const handleRedirectToHomePage = () => {
        navigateTo(LinkFactory.channelBoard + "/9")
    }
    const handleProfileRoute = () => {
        if (!Utils.checkRolePermission(["ROLE_STUDENT"]) === true)
            navigateTo(LinkFactory.teacherProfile)
        else
            navigateTo(LinkFactory.studentProfile)
    }

    const changePassword = () => {
        navigateTo(LinkFactory.changePassword);
    }

    function handleAssignmentCountClear() {
        clearNotificationCount(TAG_ASSIGNMENT);
    }

    function handlePostCountClear() {
        clearNotificationCount(TAG_POST);
    }

    function clearNotificationCount(tag) {
        NotificationsApi.clearNotification(Utils.getUserFromLocalStorage().id, tag).then((res) => {
            dispatch(fetchNotificationCounter());
        })
    }

    function handleRoomRedirect(headerName) {
        if (headerName.length > 0)
            navigateTo(LinkFactory.channelBoard + "/" + Utils.getChannelId())
    }

    function handleRoomContentSearch(query) {
        dispatch(storeSearchQuery(query.target.value));
    }

    return (
        <div>
            <div className="desktop">
                <div id="topBar">
                    <div className="bar-area">
                        <div className="block-group">
                            <div className="inline-block">
                                <div className="logo-icon">
                                    <a onClick={() => handleRedirectToHomePage()}><img src="img/logo-thumb.png"/></a>
                                </div>
                                <h5 onClick={() => handleRoomRedirect(props.headerName ? props.headerName : "")}>{props.headerName ? props.headerName : ""}</h5>
                            </div>
                            <div className="inline-block">
                                <div className="form-group mb-0 ml-4 has-search">
                                    <span className="fa fa-search form-control-feedback"> </span>
                                    <input type="text" className="form-control" onChange={handleRoomContentSearch}
                                           placeholder="Search"/>
                                </div>
                            </div>
                            <div className="inline-block">
                                <div className="notifications">
                                    <div className="display-lg sidenav-menu">
                                        <div className="msg">
                                            <div className="dropdown ">
                                                <button
                                                    className="btn-none dropdown-toggle"
                                                    type="button"
                                                    id="dropdownMenuButton"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <i className="fa fa-bars"/>
                                                </button>
                                                <div className="dropdown-menu dropdown-responsive"
                                                     aria-labelledby="dropdownMenuButton">
                                                    {props.channels && props.channels.length > 0 ? (
                                                        <div className="sidenav-last-child">
                                                            <SideNavMenu
                                                                route={props.channels
                                                                    .filter(obj => obj.channelType === "departmental")
                                                                    .map(function (data) {
                                                                        return {
                                                                            name: data.channelName,
                                                                            path: data.id
                                                                        };
                                                                    })}
                                                                title={"Department"}
                                                            />
                                                            <SideNavMenu
                                                                route={props.channels
                                                                    .filter(obj => obj.channelType === "faculty")
                                                                    .map(function (data) {
                                                                        return {
                                                                            name: data.channelName,
                                                                            path: data.id
                                                                        };
                                                                    })}
                                                                title={"Faculty"}
                                                            />
                                                            <SideNavMenu
                                                                route={props.channels
                                                                    .filter(obj => obj.channelType === "class")
                                                                    .map(function (data) {
                                                                        return {
                                                                            name: null != data.courseCode ? data.courseCode + " :  " + data.channelName : data.channelName,
                                                                            path: data.id
                                                                        };
                                                                    })}
                                                                title={"Courses"}
                                                            />
                                                            <SideNavMenu
                                                                route={props.channels
                                                                    .filter(obj => obj.channelType === "co-currullar")
                                                                    .map(function (data) {
                                                                        return {
                                                                            name: data.channelName,
                                                                            path: data.id
                                                                        };
                                                                    })}
                                                                title={"Co-curricular"}
                                                            />

                                                            {Utils.checkRolePermission(["ROLE_ADMIN","ROLE_EXAM_CONTROLLER"]) ? (
                                                                <SideNavMenu route={setupRoute} title={"Setup"}/>
                                                            ) : (
                                                                ""
                                                            )}

                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="assignment">
                                        <div className="dropdown">
                                            <button
                                                onClick={handleMessageRoute}
                                                className="btn-none dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false">
                                                <div className="bell-icon">
                                                    <i className="fa fa-comments"> </i>
                                                    {props.notificationChatCounter ? (
                                                        <circle cx="18" cy="6" r="4" fill="#F82B60"/>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {props.notificationChatCounter ?
                                                        <div className="notify-dot">
                                                            <span> {props.notificationChatCounter} </span>
                                                        </div>
                                                        : ''}
                                                </div>
                                                <p>Chat</p>

                                            </button>
                                        </div>
                                    </div>
                                    {Utils.checkRolePermission(["ROLE_PARENT"]) ?
                                        <div>
                                            <div className="dropdown">
                                                <button
                                                    onClick={handleParentChannelsRoute}
                                                    className="btn-none dropdown-toggle"
                                                    type="button"
                                                    id="dropdownMenuButton"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <i className="fa fa-bell"/>
                                                    <p>Chat</p>
                                                </button>
                                            </div>
                                        </div> : null}

                                    <div>
                                        <div className="dropdown">
                                            <button
                                                onClick={handleAssignmentCountClear}
                                                className="btn-none dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false">
                                                <div className="bell-icon">
                                                    <i className="fa fa-file-alt"> </i>
                                                    {props.notificationAssignmentCounter ? (
                                                        <circle cx="18" cy="6" r="4" fill="#F82B60"/>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {props.notificationAssignmentCounter ?
                                                        <div className="notify-dot">
                                                            <span> {props.notificationAssignmentCounter} </span>
                                                        </div>
                                                        : ''}
                                                </div>
                                                <p>Assignment</p>
                                            </button>
                                            <div
                                                className="dropdown-menu "
                                                aria-labelledby="dropdownMenuButton">
                                                <div className="notification-badge">
                                                    <div className="btns">
                                                        <button type="button"
                                                                className="btn btn-primary bg-transparent">
                                                            Assignment
                                                        </button>
                                                        <div className="unread-messages">
                                                            <p>{assignmentCount} unread</p>
                                                        </div>

                                                    </div>
                                                    {/*<div className="mark-allread opacity-50">*/}
                                                    {/*    <svg xmlns="http://www.w3.org/2000/svg"*/}
                                                    {/*         className="my-svg"*/}
                                                    {/*         viewBox="0 0 10 6"*/}
                                                    {/*         fill="none">*/}
                                                    {/*        <path fill="#8C8C8C"*/}
                                                    {/*              d="M2.973 6a.405.405 0 0 1-.178-.04.497.497 0 0 1-.155-.12L.63 3.575a.554.554 0 0 1-.13-.382c0-.156.044-.283.13-.381a.426.426 0 0 1 .334-.148c.134 0 .245.05.333.147l1.688 1.902.666.75-.345.375a.497.497 0 0 1-.154.12.405.405 0 0 1-.179.041Zm2.688-.013a.413.413 0 0 1-.333-.147L3.306 3.562a.554.554 0 0 1-.13-.382c0-.157.043-.284.13-.382a.426.426 0 0 1 .333-.147c.135 0 .246.049.333.147l1.689 1.902L9.703.147A.426.426 0 0 1 10.036 0c.135 0 .246.05.333.147.087.099.131.226.131.382a.554.554 0 0 1-.13.382L5.992 5.839a.413.413 0 0 1-.332.148Zm0-2.8-.678-.75L7.004.162a.432.432 0 0 1 .339-.148c.139 0 .252.05.339.148.087.098.13.223.13.375a.544.544 0 0 1-.13.375L5.66 3.187Z"/>*/}
                                                    {/*    </svg>*/}
                                                    {/*    <a onClick={() => markAllAsReadNotificationStatus(['assignment'])}>>Mark all as read</a>*/}
                                                    {/*</div>*/}
                                                </div>
                                                <ul className="scroll-area">
                                                    {assignmentNotifications.length > 0 ? (
                                                        assignmentNotifications.map((data, index) => {
                                                            return (
                                                                <a

                                                                    key={index}
                                                                    onClick={() => redirectionController(data)}>
                                                                    {data.readStatus === false ?
                                                                        <div className="notification-block">
                                                                            <div className="notify-content">
                                                                                <div className="circle-icon">
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="16" height="18"
                                                                                        fill="none">
                                                                                        <path fill="#475467"
                                                                                              d="m6.756 13.489 6.266-6.267-1.244-1.244L6.756 11 4.222 8.467 2.978 9.71l3.778 3.778ZM1.778 16.11h12.444V3.667H1.778V16.11ZM0 17.89v-16h5.511c.193-.533.515-.963.967-1.289A2.537 2.537 0 0 1 8 .111c.563 0 1.07.163 1.523.489.451.326.773.756.966 1.289H16v16H0ZM8 3a.648.648 0 0 0 .667-.667.65.65 0 0 0-.189-.478A.65.65 0 0 0 8 1.667a.647.647 0 0 0-.667.666A.645.645 0 0 0 8 3Z"/>
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="desc">
                                                                                    {/*<h4>{data.body}</h4>*/}
                                                                                    <h4><strong>{data.body} </strong></h4>
                                                                                    <p>
                                                                                        <span>{dateTimeForNotification(new Date(data.timestamp).getTime())}</span>
                                                                                    </p>

                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="read-notification-block">
                                                                            <div className="notify-content">
                                                                                <div className="circle-icon">
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="16" height="18"
                                                                                        fill="none">
                                                                                        <path fill="#475467"
                                                                                              d="m6.756 13.489 6.266-6.267-1.244-1.244L6.756 11 4.222 8.467 2.978 9.71l3.778 3.778ZM1.778 16.11h12.444V3.667H1.778V16.11ZM0 17.89v-16h5.511c.193-.533.515-.963.967-1.289A2.537 2.537 0 0 1 8 .111c.563 0 1.07.163 1.523.489.451.326.773.756.966 1.289H16v16H0ZM8 3a.648.648 0 0 0 .667-.667.65.65 0 0 0-.189-.478A.65.65 0 0 0 8 1.667a.647.647 0 0 0-.667.666A.645.645 0 0 0 8 3Z"/>
                                                                                    </svg>

                                                                                </div>
                                                                                <div className="desc">
                                                                                    {/*<h4>{data.body}</h4>*/}
                                                                                    <h4><strong>{data.body} </strong></h4>
                                                                                    <p>
                                                                                        <span>{dateTimeForNotification(new Date(data.timestamp).getTime())}</span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                </a>

                                                            );
                                                        })
                                                    ) : (
                                                        <div className="text-center">
                                                            <NoNotification message={"No new assignment"}/>
                                                        </div>
                                                    )}
                                                </ul>

                                                <div className="all-button-area">
                                                    <button className="all-button"
                                                            onClick={handleAllNotificationRoute}> All Notifications
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="dropdown">
                                            <button
                                                onClick={handlePostCountClear}
                                                className="btn-none dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <div className="bell-icon">
                                                    <i className="fa fa-bell"> </i>
                                                    {props.notificationPostCounter ? <circle cx="18" cy="6" r="4" fill="#F82B60"/> : ""}
                                                    {props.notificationPostCounter ? (
                                                        <div className="notify-dot">
                                                            <span>{props.notificationPostCounter}</span>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <p>Notification</p>
                                            </button>
                                            <div
                                                className="dropdown-menu "
                                                aria-labelledby="dropdownMenuButton"
                                            >
                                                <div className="notification-badge">
                                                    <div className="btns">
                                                        <button type="button"
                                                                className="btn btn-primary bg-transparent">
                                                            Post/Event
                                                        </button>
                                                        <div className="unread-messages">
                                                            <p>{props.notificationPostCounter} unread</p>
                                                        </div>
                                                    </div>

                                                    {/*<div className="mark-allread opacity-50">*/}
                                                    {/*    <svg xmlns="http://www.w3.org/2000/svg"*/}
                                                    {/*         className="my-svg"*/}
                                                    {/*         viewBox="0 0 10 6"*/}
                                                    {/*         fill="none">*/}
                                                    {/*        <path fill="#8C8C8C"*/}
                                                    {/*              d="M2.973 6a.405.405 0 0 1-.178-.04.497.497 0 0 1-.155-.12L.63 3.575a.554.554 0 0 1-.13-.382c0-.156.044-.283.13-.381a.426.426 0 0 1 .334-.148c.134 0 .245.05.333.147l1.688 1.902.666.75-.345.375a.497.497 0 0 1-.154.12.405.405 0 0 1-.179.041Zm2.688-.013a.413.413 0 0 1-.333-.147L3.306 3.562a.554.554 0 0 1-.13-.382c0-.157.043-.284.13-.382a.426.426 0 0 1 .333-.147c.135 0 .246.049.333.147l1.689 1.902L9.703.147A.426.426 0 0 1 10.036 0c.135 0 .246.05.333.147.087.099.131.226.131.382a.554.554 0 0 1-.13.382L5.992 5.839a.413.413 0 0 1-.332.148Zm0-2.8-.678-.75L7.004.162a.432.432 0 0 1 .339-.148c.139 0 .252.05.339.148.087.098.13.223.13.375a.544.544 0 0 1-.13.375L5.66 3.187Z"/>*/}
                                                    {/*    </svg>*/}
                                                    {/*    <a onClick={() => markAllAsReadNotificationStatus(['post', 'event'])}>>Mark all as read</a>*/}
                                                    {/*</div>*/}
                                                </div>

                                                <ul className="scroll-area">
                                                    {postNotifications.length > 0 ? (
                                                        postNotifications.map((data, index) => {
                                                            return (
                                                                <a

                                                                    key={index}
                                                                    onClick={() => redirectionController(data)}>
                                                                    {data.readStatus === false ?
                                                                        <div className="notification-block">
                                                                            <div className="notify-content">
                                                                                <div className="circle-icon">
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="15" height="15"
                                                                                        fill="none">
                                                                                        <path fill="#475467"
                                                                                              d="M1.502 7.121v5.368h2.232l.113.013c2.073.492 3.446.847 4.13 1.067.91.292 1.249.363 1.866.405.461.032.985-.149 1.224-.396.131-.136.23-.417.27-.852a.517.517 0 0 1 .298-.422 1.07 1.07 0 0 0 .464-.405c.118-.176.194-.503.208-.976a.519.519 0 0 1 .25-.428c.429-.252.641-.537.685-.872.05-.37-.069-.786-.378-1.26a.519.519 0 0 1 .13-.702c.296-.211.458-.505.498-.922.065-.665-.351-1.156-1.294-1.254a6.824 6.824 0 0 0-2.5.212.493.493 0 0 1-.513-.168.515.515 0 0 1-.067-.544c.369-.794.574-1.449.622-1.956.062-.672-.09-1.16-.447-1.562-.27-.304-.703-.478-.865-.442-.214.046-.353.172-.534.613-.107.261-.159.483-.247 1.001-.085.492-.131.714-.228 1.005-.29.882-1.005 1.797-1.963 2.427-.672.442-1.4.788-2.164 1.028a.49.49 0 0 1-.148.022H1.502Zm-.031 6.39a1.01 1.01 0 0 1-.63-.175c-.23-.162-.337-.419-.339-.714L.504 7.13a.93.93 0 0 1 .26-.761.932.932 0 0 1 .693-.27h1.61a7.68 7.68 0 0 0 1.846-.886c.773-.509 1.341-1.238 1.559-1.895.074-.226.114-.414.19-.855.1-.592.164-.867.31-1.221C7.274.506 7.671.146 8.217.025c.538-.119 1.321.197 1.817.755.548.618.79 1.391.701 2.347-.038.41-.15.87-.335 1.383a7.608 7.608 0 0 1 1.9-.042c1.479.154 2.31 1.134 2.188 2.373-.053.534-.243.988-.566 1.344.272.533.38 1.06.312 1.575-.078.592-.402 1.086-.941 1.469-.042.52-.16.935-.367 1.245-.164.25-.378.464-.627.626-.08.513-.247.914-.52 1.196-.454.47-1.264.749-2.003.698-.702-.048-1.118-.135-2.098-.45-.646-.208-1.984-.553-4.001-1.033H1.471Zm1.253-6.623a.518.518 0 0 1 .147-.362.5.5 0 0 1 .354-.15.494.494 0 0 1 .354.151.51.51 0 0 1 .146.361v5.758a.518.518 0 0 1-.146.361.5.5 0 0 1-.354.15.494.494 0 0 1-.354-.15.51.51 0 0 1-.147-.36V6.887Z"/>
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="desc">
                                                                                    {/*<h4>{data.body}</h4>*/}
                                                                                    <h4><strong>{data.body} </strong></h4>
                                                                                    <p>
                                                                                        {/*<span> Friday at 11:30 pm </span>
                                                                                        <span>19 minutes ago</span>*/}
                                                                                        <span>{dateTimeForNotification(new Date(data.timestamp).getMilliseconds())}</span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="read-notification-block">
                                                                            <div className="notify-content">
                                                                                <div className="circle-icon">
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="15" height="15"
                                                                                        fill="none">
                                                                                        <path fill="#475467"
                                                                                              d="M1.502 7.121v5.368h2.232l.113.013c2.073.492 3.446.847 4.13 1.067.91.292 1.249.363 1.866.405.461.032.985-.149 1.224-.396.131-.136.23-.417.27-.852a.517.517 0 0 1 .298-.422 1.07 1.07 0 0 0 .464-.405c.118-.176.194-.503.208-.976a.519.519 0 0 1 .25-.428c.429-.252.641-.537.685-.872.05-.37-.069-.786-.378-1.26a.519.519 0 0 1 .13-.702c.296-.211.458-.505.498-.922.065-.665-.351-1.156-1.294-1.254a6.824 6.824 0 0 0-2.5.212.493.493 0 0 1-.513-.168.515.515 0 0 1-.067-.544c.369-.794.574-1.449.622-1.956.062-.672-.09-1.16-.447-1.562-.27-.304-.703-.478-.865-.442-.214.046-.353.172-.534.613-.107.261-.159.483-.247 1.001-.085.492-.131.714-.228 1.005-.29.882-1.005 1.797-1.963 2.427-.672.442-1.4.788-2.164 1.028a.49.49 0 0 1-.148.022H1.502Zm-.031 6.39a1.01 1.01 0 0 1-.63-.175c-.23-.162-.337-.419-.339-.714L.504 7.13a.93.93 0 0 1 .26-.761.932.932 0 0 1 .693-.27h1.61a7.68 7.68 0 0 0 1.846-.886c.773-.509 1.341-1.238 1.559-1.895.074-.226.114-.414.19-.855.1-.592.164-.867.31-1.221C7.274.506 7.671.146 8.217.025c.538-.119 1.321.197 1.817.755.548.618.79 1.391.701 2.347-.038.41-.15.87-.335 1.383a7.608 7.608 0 0 1 1.9-.042c1.479.154 2.31 1.134 2.188 2.373-.053.534-.243.988-.566 1.344.272.533.38 1.06.312 1.575-.078.592-.402 1.086-.941 1.469-.042.52-.16.935-.367 1.245-.164.25-.378.464-.627.626-.08.513-.247.914-.52 1.196-.454.47-1.264.749-2.003.698-.702-.048-1.118-.135-2.098-.45-.646-.208-1.984-.553-4.001-1.033H1.471Zm1.253-6.623a.518.518 0 0 1 .147-.362.5.5 0 0 1 .354-.15.494.494 0 0 1 .354.151.51.51 0 0 1 .146.361v5.758a.518.518 0 0 1-.146.361.5.5 0 0 1-.354.15.494.494 0 0 1-.354-.15.51.51 0 0 1-.147-.36V6.887Z"/>
                                                                                    </svg>

                                                                                </div>
                                                                                <div className="desc">
                                                                                    {/*<h4>{data.body}</h4>*/}
                                                                                    <h4><strong>{data.body}</strong></h4>
                                                                                    <p>
                                                                                        {/*<span> Friday at 11:30 pm </span>
                                                                                        <span>19 minutes ago</span>*/}
                                                                                        <span>{dateTimeForNotification(new Date(data.timestamp).getTime())}</span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                </a>
                                                            );
                                                        })
                                                    ) : (
                                                        <NoNotification message={"No new post"}/>
                                                    )}
                                                </ul>
                                                <div className="all-button-area">
                                                    <button className="all-button"
                                                            onClick={handleAllNotificationRoute}> All Notifications
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="profile-dropdown">
                                    <div className="avatar">
                                        {user?.imageUrl ? (
                                            <img
                                                onClick={handleProfileRoute}
                                                src={SERVER_NAME + user.imageUrl}
                                                alt="new"
                                            />
                                        ) : (
                                            <img
                                                src={"img/Avatar.png"}
                                                alt="Default Avatar"
                                            />
                                        )}
                                    </div>
                                    <div className="dropdown show">
                                        <a
                                            className="dropdown-toggle"
                                            href="#"
                                            role="button"
                                            id="dropdownMenuLink"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            {user?.firstName} {user?.lastName}
                                        </a>

                                        <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuLink"
                                        >
                                            <a className="dropdown-item" onClick={changePassword}>
                                                Change password
                                            </a>
                                            <a className="dropdown-item" onClick={handleProfileRoute}>
                                                My Profile
                                            </a>
                                            {showLogin() ? <Login/> : <Fragment/>}
                                            {showLogOut() ? <LogOut/> : <Fragment/>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobile">
                <div id="topBar">
                    <div className="bar-area">
                        <div className="block-group">
                            <div className="block-50">
                                <h5>{props.headerName ? props.headerName : ""}</h5>
                            </div>
                            <div className="block-50">
                                <div className="notifications">
                                    <div className="assignment">
                                        <div className="dropdown">
                                            <button
                                                onClick={() => router.push("/message-thread")}
                                                className="btn-none dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false">
                                                <svg
                                                    width="22px"
                                                    height="22px"
                                                    viewBox="0 0 48 48"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <rect
                                                        width="48"
                                                        height="48"
                                                        fill="black"
                                                        fillOpacity="0.01"
                                                    />
                                                    <path
                                                        d="M44 24C44 35.0457 35.0457 44 24 44C18.0265 44 4 44 4 44C4 44 4 29.0722 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24Z"
                                                        fill="black"
                                                        stroke="white"
                                                        strokeWidth="4"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M14 18L32 18"
                                                        stroke="white"
                                                        strokeWidth="4"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M14 26H32"
                                                        stroke="white"
                                                        strokeWidth="4"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M14 34H24"
                                                        stroke="white"
                                                        strokeWidth="4"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </button>
                                            {/*<div className="dropdown-menu " aria-labelledby="dropdownMenuButton" style={{ width:"150px"}}>*/}
                                            {/*    <ul className="scroll-area px-3">*/}
                                            {/*        {assignmentNotifications.length > 0 ? assignmentNotifications.map((data, index) => {*/}
                                            {/*            return (*/}
                                            {/*                <a className="dropdown-item border-bottom-1"*/}
                                            {/*                   onClick={() => redirectionController(data)}>{data.body}</a>*/}
                                            {/*            )*/}
                                            {/*        }) : <NoNotification message={"No  new notification"}/>}*/}
                                            {/*    </ul>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>

                                    <div className="assignment">
                                        <div className="dropdown">
                                            <button
                                                className="btn-none dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false">
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="black"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    style={{color: "black", fill: "black"}}>
                                                    <path
                                                        d="M12.0312 21.9844C13.1313 21.9844 14.0312 21.0844 14.0312 19.9844H10.0312C10.0312 21.0844 10.9313 21.9844 12.0312 21.9844ZM18.0312 15.9844V10.9844C18.0312 7.91438 16.4012 5.34437 13.5312 4.66437V3.98438C13.5312 3.15438 12.8612 2.48438 12.0312 2.48438C11.2013 2.48438 10.5312 3.15438 10.5312 3.98438V4.66437C7.67125 5.34437 6.03125 7.90438 6.03125 10.9844V15.9844L4.03125 17.9844V18.9844H20.0312V17.9844L18.0312 15.9844ZM16.0312 16.9844H8.03125V10.9844C8.03125 8.50437 9.54125 6.48438 12.0312 6.48438C14.5212 6.48438 16.0312 8.50437 16.0312 10.9844V16.9844Z"
                                                        fill="black"
                                                    />
                                                    {props.notificationAssignmentCounter ? (
                                                        <circle cx="18" cy="6" r="4" fill="#F82B60"/>
                                                    ) : (
                                                        ""
                                                    )}
                                                </svg>

                                                {/*<div className="notify-dot">*/}
                                                {/*    <span>{assignmentCount ? assignmentCount : 0 }</span>*/}
                                                {/*</div>*/}
                                            </button>
                                            <div
                                                className="dropdown-menu "
                                                aria-labelledby="dropdownMenuButton">
                                                <h6 className="mx-4 font-weight-bold border-bottom-1 mt-3">
                                                    Assignment
                                                </h6>
                                                <ul className="scroll-area">
                                                    {assignmentNotifications.length > 0 ? (
                                                        assignmentNotifications.map((data, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        onClick={() => redirectionController(data)}>
                                                                        {data.body}
                                                                    </a>
                                                                </div>
                                                            );
                                                        })
                                                    ) : (
                                                        <div className="text-center">
                                                            <NoNotification message={"No new assignment"}/>
                                                        </div>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="msg">
                                        <div className="dropdown">
                                            <button
                                                className="btn-none dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    enableBackground="new 0 0 24 24"
                                                    height="24px"
                                                    viewBox="0 0 24 24"
                                                    width="24px"
                                                    fill="#000000">
                                                    <g>
                                                        <rect fill="none" height="24" width="24"/>
                                                    </g>
                                                    <g>
                                                        <g/>
                                                        <g>
                                                            <path
                                                                d="M17,19.22H5V7h7V5H5C3.9,5,3,5.9,3,7v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-7h-2V19.22z"/>
                                                            <path
                                                                d="M19,2h-2v3h-3c0.01,0.01,0,2,0,2h3v2.99c0.01,0.01,2,0,2,0V7h3V5h-3V2z"/>
                                                            <rect height="2" width="8" x="7" y="9"/>
                                                            <polygon points="7,12 7,14 15,14 15,12 12,12"/>
                                                            <rect height="2" width="8" x="7" y="15"/>
                                                        </g>
                                                    </g>
                                                    {props.notificationPostCounter ? (
                                                        <circle cx="18" cy="6" r="4" fill="#F82B60"/>
                                                    ) : (
                                                        ""
                                                    )}
                                                </svg>
                                                <div className="notify-dot">
                                                    <span>6</span>
                                                </div>
                                            </button>
                                            <div
                                                className="dropdown-menu "
                                                aria-labelledby="dropdownMenuButton">
                                                <h6 className="mx-4 font-weight-bold border-bottom-1 mt-3">
                                                    Posts
                                                </h6>
                                                <ul className="scroll-area">
                                                    {postNotifications.length > 0 ? (
                                                        postNotifications.map((data, index) => {
                                                            return (
                                                                <a
                                                                    className="dropdown-item"
                                                                    key={index}
                                                                    onClick={() => redirectionController(data)}
                                                                >
                                                                    {data.body}
                                                                </a>
                                                            );
                                                        })
                                                    ) : (
                                                        <NoNotification message={"No new post"}/>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="profile-dropdown">
                                    <div className="avatar">
                                        <img onClick={handleProfileRoute} src={SERVER_NAME + user?.imageUrl} alt="new"/>
                                    </div>
                                    <div className="dropdown show">
                                        <a
                                            className="dropdown-toggle"
                                            href="#"
                                            role="button"
                                            id="dropdownMenuLink"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false">
                                            {user?.firstName} {user?.lastName}
                                        </a>
                                        <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuLink">
                                            <button onClick={handleProfileRoute}>
                                                My Profile
                                            </button>
                                            <button onClick={changePassword}>
                                                Change Password
                                            </button>
                                            {showLogin() ? <Login/> : <Fragment/>}
                                            {showLogOut() ? <LogOut/> : <Fragment/>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

// export default TopNavComponent;
const mapStateToProps = state => ({
    channels: state.channels.channels,
    notificationPostCounter: state.notificationCounter.notificationPostCounter,
    notificationAssignmentCounter: state.notificationCounter.notificationAssignmentCounter,
    notificationChatCounter: state.notificationCounter.notificationChatCounter,
    loading: state.channels.loading,
    error: state.channels.error
});

export default connect(mapStateToProps)(TopNavComponent);
