import React, {useEffect, useState} from "react";
import * as EventsApi from "../../api/EventsApi";
import * as EventCommentsApi from "../../api/EventCommentsApi";

import FooterComponent from "../Nav/footer";
import moment from "moment-timezone";
import * as Utils from "../../util/Utils";
import CommonLayout from "../layout/commonLayout";
import {FILES_URL} from "../../util/LinkFactory";
import {Form, Formik} from "formik";
import CachingImage from "../Image/cachingImage";
import {Tab, Tabs} from "react-bootstrap";
import {convertUTCDateToLocalDate} from "../../util/DateUtils";
import {useParams} from "react-router-dom";

function EventsViewForUser(props) {
    const params = useParams();
    const initialComment = {events: null, comment: ""};
    const [event, setEvent] = useState([]);
    const [eventAcceptedMembersList, setEventAcceptedMembersList] = useState([]);
    const [eventInvitedMembersList, setEventInvitedMembersList] = useState([]);
    const [eventRejectedMembersList, setEventRejectedMembersList] = useState([]);
    const [currentUserEvent, setCurrentUserEvent] = useState({});
    const [user, setUser] = useState([]);
    const [eventComments, setEventComments] = useState([]);
    const [comment, setComment] = useState(initialComment);
    const submitCommentState = data => {
        setComment(data);
    };
    useEffect(() => {
        setUser(Utils.getUserFromLocalStorage());
    }, []);
    useEffect(() => {
        if (user?.id)
            EventsApi.getEventsById(params.id)
                .then(json => {
                    setEvent(json.data);
                    EventsApi.findEventsMembersByUserIdAndEventId(
                        user.id,
                        params.id
                    ).then(json => {
                        setCurrentUserEvent(json.data);
                    });
                    EventCommentsApi.findCommentListByEventId(params.id).then(json => {
                        setEventComments(json.data)
                    });
                    EventsApi.findEventMemberListByChannelIdAndEventStatus(
                        params.id,
                        'ACCEPTED',
                        'STUDENT'
                    ).then(json => {
                        setEventAcceptedMembersList(json.data);
                    });
                    EventsApi.findEventMemberListByChannelIdAndEventStatus(
                        params.id,
                        'INVITED',
                        'STUDENT'
                    ).then(json => {
                        setEventInvitedMembersList(json.data);
                    });
                    EventsApi.findEventMemberListByChannelIdAndEventStatus(
                        params.id,
                        'REJECTED',
                        'STUDENT'
                    ).then(json => {
                        setEventRejectedMembersList(json.data);
                    });
                })
                .catch(errors => {
                    console.log(errors);
                });
    }, [user]);

    const handleInvitation = status => {
        EventsApi.updateEventUserStatusByEventIdUserIdStatus(
            params.id,
            user.id,
            status
        ).then(json => {
            window.location.reload();
        });
    };
    const submitComment = (data, resetForm) => {
        console.log(data);
        let events = {
            id: params.id
        };

        let eventComment = {
            comment: data.comment,
            events: events,
            user: user
        };

        let tempEventComments = [];
        tempEventComments = [...eventComments];
        tempEventComments.push(eventComment);
        setEventComments(tempEventComments);

        EventCommentsApi.saveEventComments(eventComment).then(response => {
            const tempEventComments = [...eventComments];
            tempEventComments.add(response.data);
            setEventComments(tempEventComments);
        });
        resetForm(initialComment);
    };
    return (
        <>
            <CommonLayout headerName={Utils.getChannelName()}>
                <main>
                    <div className="event-detail">
                        <div className="card-default">
                            <h5 className="mb-4 fw-600">Events Details</h5>
                            <div className="block-group">
                                <div className="block-25">
                                    <div className="card-sm">
                                        <div className="event-title">Event Name</div>
                                        <div className="event-desc">{event.eventName}</div>
                                    </div>
                                </div>
                                <div className="block-33">
                                    <div className="card-sm">
                                        <div className="event-title">Event Date & Time</div>
                                        <div className="event-desc">
                                            <div>
                                                {convertUTCDateToLocalDate(new Date(event.eventStartTime))} - {convertUTCDateToLocalDate(new Date(event.eventEndTime))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="block-25">
                                    <div className="card-sm">
                                        <div className="event-title">Event Venue</div>
                                        <div className="event-desc">{event.eventVenue}</div>
                                    </div>
                                </div>
                            </div>
                            {eventComments.length > 0
                                ? eventComments.map(
                                    (comment, index) => {
                                        return (
                                            <div className="comment-area-event">
                                                <div className="avatar">
                                                    <img
                                                        src={
                                                            FILES_URL +
                                                            comment.user.imageUrl
                                                        }
                                                    />
                                                </div>

                                                <div className="card-alt-event">
                                                    <h6>
                                                        {comment.user.firstName}{" "}
                                                        {comment.user.lastName}
                                                        <span>
                                                        {" "}
                                                            <p>
                                                                {moment(
                                                                    comment.createTimeDate
                                                                ).format("MM/DD/YY")}
                                                            </p>
                                                  </span>
                                                    </h6>
                                                    <p className="card-p-event">{comment.comment}</p>
                                                </div>
                                            </div>
                                        );
                                    }
                                )
                                : null}
                            <br/>
                            <Formik
                                initialValues={initialComment}
                                enableReinitialize={true}
                                onSubmit={(
                                    data,
                                    {
                                        setSubmitting,
                                        setErrors,
                                        setStatus,
                                        resetForm
                                    }
                                ) => {
                                    const value = {
                                        ...data
                                    };
                                    submitComment(value, resetForm);
                                }}
                            >
                                {({
                                      values,
                                      handleSubmit,
                                      setFieldValue
                                  }) => (
                                    <Form>
                                        <div className="add-comment-area">
                                            <div className="avatar">
                                                <CachingImage
                                                    alt="image"
                                                    src={FILES_URL + user?.imageUrl}
                                                />
                                            </div>
                                            <div className="input-default">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="comment"
                                                    aria-describedby="emailHelp"
                                                    name="comment"
                                                    placeholder="Write your comment here"
                                                    value={values.comment}
                                                    onChange={event => {
                                                        setFieldValue(
                                                            "comment",
                                                            event.target.value
                                                        );
                                                        submitCommentState({
                                                            ...values,
                                                            comment: event.target.value
                                                        });
                                                    }}
                                                />
                                                <button
                                                    type="submit"
                                                    className="btn-purple-md float-right"
                                                >
                                                    <i className="fa fa-send"/>
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                            <br/>

                            <div className="table-responsive w-50 rounded mt-3">
                                {Object.keys(currentUserEvent).length !== 0 &&
                                currentUserEvent.eventStatus === 'INVITED' ? (
                                    <div>
                                        <p className="font-weight-bold">
                                            You have been invited to this event
                                        </p>
                                        <button
                                            type="button"
                                            className="btn btn-success mr-4"
                                            data-dismiss="modal"
                                            onClick={() => handleInvitation('ACCEPTED')}
                                        >
                                            Accept Invitation
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            data-dismiss="modal"
                                            onClick={() => handleInvitation('REJECTED')}
                                        >
                                            Decline Invitation
                                        </button>
                                    </div>
                                ) : null}
                                <br/>
                            </div>

                            <Tabs defaultActiveKey="accepted" id="uncontrolled-tab-example" className="mb-3">
                                <Tab tabClassName="text-success" eventKey="accepted" title="Accepted">
                                    {eventAcceptedMembersList.length > 0 ? <table
                                        className="table table-bordered"
                                        id="dataTable"
                                        width="100%"
                                        cellSpacing="0"
                                    >
                                        <thead>
                                        <tr className="table-warning">
                                            <th>Name</th>
                                        </tr>
                                        </thead>

                                        {eventAcceptedMembersList.length > 0
                                            ? eventAcceptedMembersList.map((data, index) => {
                                                return (
                                                    <tbody>
                                                    <tr>
                                                        <td>{data.user.firstName} {data.user.lastName}</td>
                                                    </tr>
                                                    </tbody>
                                                );
                                            })
                                            : null}
                                    </table> : "No members"
                                    }
                                </Tab>
                                <Tab tabClassName="text-primary" eventKey="invited" title="Invited">
                                    {eventInvitedMembersList.length > 0 ?
                                        <table
                                            className="table table-bordered"
                                            id="dataTable"
                                            width="100%"
                                            cellSpacing="0">
                                            <thead>
                                            <tr className="table-warning">
                                                <th>Name</th>
                                            </tr>
                                            </thead>

                                            {eventInvitedMembersList.length > 0
                                                ? eventInvitedMembersList.map((data, index) => {
                                                    return (
                                                        <tbody>
                                                        <tr>
                                                            <td>{data.user.firstName} {data.user.lastName}</td>
                                                        </tr>
                                                        </tbody>
                                                    );
                                                })
                                                : null}
                                        </table> : "No members"
                                    }
                                </Tab>
                                <Tab eventKey="notcoming" title="Not Coming">
                                    {eventRejectedMembersList.length > 0 ? <table
                                        className="table table-bordered"
                                        id="dataTable"
                                        width="100%"
                                        cellSpacing="0"
                                    >
                                        <thead>
                                        <tr className="table-warning">
                                            <th>Name</th>
                                        </tr>
                                        </thead>

                                        {eventRejectedMembersList.length > 0
                                            ? eventRejectedMembersList.map((data, index) => {
                                                return (
                                                    <tbody>
                                                    <tr>
                                                        <td>{data.user.firstName} {data.user.lastName}</td>
                                                    </tr>
                                                    </tbody>
                                                );
                                            })
                                            : null}
                                    </table> : "No members"
                                    }
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </main>
                <FooterComponent/>
            </CommonLayout>
        </>
    );
}

export default EventsViewForUser;
