import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveInstituteDistrict = instituteDistrict => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/institute-district/",
        instituteDistrict
    );
};

export const updateInstituteDistrict = instituteDistrict => {
    return AxiosAgent.requests.put(
        API_MAIN_ROOT + "/institute-district/",
        instituteDistrict
    );
};
export const getInstituteDistrictById = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/institute-district/" + id);
};
export const deleteInstituteDistrictById = id => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/institute-district", id);
};
export const getAll = () => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/institute-district");
};
