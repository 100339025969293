import React, {useState} from "react";
import * as UserTypeApi from "../../api/UserTypeApi";
import {Field, Form, Formik} from "formik";

import * as LinkFactory from "../../util/LinkFactory";

import CommonLayout from "../layout/commonLayout";
import {useNavigate} from "react-router-dom";

function UserTypeNew(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [userType, setUserType] = useState({userType: "", id: ""});

    const handleSubmit = data => {
        console.log(data);
        UserTypeApi.saveUserType(data).then(data => {
            navigateTo(LinkFactory.userType);
        });
    };

    const TextField = ({field, form: {touched, errors}}) => {
        return (
            <div className="input-group mb-3">
                <input
                    {...field}
                    type="text"
                    className="form-control"
                    aria-label="industry"
                    aria-describedby="basic-addon1"
                />
            </div>
        );
    };
    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h1 className="mt-4">User Type Edit</h1>
                        <br/>
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-pencil-square-o mr-2"/>
                                Edit here
                            </div>
                            <div className="card-body">
                                <Formik
                                    initialValues={userType}
                                    enableReinitialize={true}
                                    onSubmit={handleSubmit}
                                >
                                    {props => (
                                        <Form>
                                            <div className="table-responsive">
                                                <table
                                                    className="table table-bordered"
                                                    id="dataTable"
                                                    width="100%"
                                                    cellSpacing="0"
                                                >
                                                    <tr>
                                                        <td>User type</td>
                                                        <td>
                                                            <Field
                                                                type="text"
                                                                name="userType"
                                                                placeholder="userType"
                                                                component={TextField}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <td colSpan="12">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-lg btn-primary pull-right"
                                                        >
                                                            Save
                                                        </button>
                                                    </td>
                                                </table>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default UserTypeNew;
