import React, {useEffect, useState} from "react";
import {ErrorMessage, Field, Form, Formik} from "formik";
import SelectDropdown from "../common/SelectDropwodn";
import * as DepartmentApi from "../../api/DepartmentApi";
import * as ProgramApi from "../../api/ProgramApi";
import * as FacultyApi from "../../api/FacultyApi";
import * as SemesterApi from "../../api/SemesterApi";
import CommonLayout from "../layout/commonLayout";
import * as GradingCalculatedResultApi from "../../api/GradingCalculatedResultApi";
import Loader from "../common/Loader";
import {SERVER_NAME_URL} from "../../constants/Constants";
import {toast} from "react-toastify";


function StudentsGradeBookComponent() {
    const [resultSearch, setResultSearch] = useState({
        facultyName: {}, departmentName: {}, programName: {}, semester: {}, marks_highest: "", marks: "", resultTypeEnum: ""

    });
    const [loader, setLoader] = useState(true);
    const [faculties, setFaculties] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [semesterList, setSemesterList] = useState([]);
    const [channelList, setChannelList] = useState([]);

    const [faculty, setFaculty] = useState({});
    const [department, setDepartment] = useState({});
    const [program, setProgram] = useState({});
    const [session, setSession] = useState("");
    const [semester, setSemester] = useState({});
    const [channel, setChannel] = useState({});
    const [isSearch, setSearch] = useState(false);
    const FILE_URL = SERVER_NAME_URL;

    const [studentGradeBookList, setStudentGradeBookList] = useState([]);
    const setFacultyObj = value => {
        setFaculty(value);
        getDepartmentsByFaculty(value.id);
        handleSearchChannelList();
    }
    useEffect(() => {
        loadFaculty();
        loadAllSemester();
        setLoader(false);
        //searchGradingCalculatedResult({});

    }, []);
    const getDepartmentsByFaculty = (facultyId) => {
        DepartmentApi.getAllByFacultyId(facultyId).then(json => {
            setDepartments(json.data);
        });
    }
    const searchGradingCalculatedResult = (param) => {
        GradingCalculatedResultApi.searchGradingCalculatedResult(param).then(response => {
            setStudentGradeBookList(response.data);
            setLoader(false);
            setSearch(true);
        });
    }

    const downloadGradingCalculatedResult = (param) => {
        if (null == channel.id) {
            toast("Please select Course first!");
        } else {
            GradingCalculatedResultApi.downloadGradingCalculatedResult({
                programId: program?.id,
                semesterId: semester?.id,
                facultyId: faculty?.id,
                departmentId: department?.id,
                channelId: channel?.id
            }).then(response => {
                window.open(FILE_URL + "students-grade-book.xlsx")
                setLoader(false);
            });
        }
    }
    
    const getStudentsGradeBookXlsx = (param) => {
      GradingCalculatedResultApi.getStudentsGradeBookXlsx(param).then(response => {
          console.log(response.data);
          /*const blob = new Blob([response.response.data], {type: 'application/vnd.ms.excel'});
          const file = new File([blob], 'students-grade-book'.concat('.xlsx'), {type: 'application/vnd.ms.excel'});
          saveAs(file);*/

      });
    }

    const searchChannelList = (param) => {
        GradingCalculatedResultApi.searchChannelList(param).then(response => {
            setChannelList(response.data);
        });
    }

    const handleSemesterSelection = option => {
        setSemester(option);
        handleSearchChannelList();
    };
    const handleChannelSelection = option => {
        setChannel(option)
    };
    function loadAllSemester() {
        SemesterApi.getAll().then(response => {
            setSemesterList(response.data);
        });
    }
    function loadFaculty() {
        FacultyApi.getAllFaculties().then(json => {
            setFaculties([...json.data, ...[{id: 0, name: "Unassigned"}]]);
        });
    }
    const handleSearch = values => {
        if (null == channel.id) {
            toast("Please select Course first!");
        } else {
            searchGradingCalculatedResult({
                programId: program?.id,
                semesterId: semester?.id,
                facultyId: faculty?.id,
                departmentId: department?.id,
                channelId: channel?.id
            });

        }
    }

    const url = null!== channel.id ? FILE_URL + "students-grade-book.xlsx" : null;

    const handleSearchChannelList = () => {
        console.log(faculty.id)
        searchChannelList({programId: program?.id, semesterId: semester?.id, facultyId: faculty?.id, departmentId: department?.id})
    }

    const downloadExcel = values => {
        getStudentsGradeBookXlsx({programId: program?.id, semesterId: semester?.id, facultyId: faculty?.id, departmentId: department?.id})
    }
    const setDepartmentObj = value => {
        setDepartment(value)
        getProgramsByDepartmentId(value.id);
        handleSearchChannelList();
    }
    const getProgramsByDepartmentId = (departmentId) => {
        if (departmentId !== undefined && departmentId !== 0) {
            ProgramApi.getAllByDepartmentId(departmentId).then(json => {
                setPrograms([...json.data, ...[{id: 0, name: "Unassigned"}]]);
            });
        }
    }


        return (
            <CommonLayout headerName={"Students Grade Book"}>
            <main>
                <div className="card-default result-statistics">
                    <div className="title ">
                        <div className="mb-0 d-flex align-items-center">
                            <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                <a href="#" className="pl-0 back-button">
                                    <i className="fa fa-chevron-left"> </i> </a>
                            </div>

                            <p>Students Grade Book</p>
                        </div>
                    </div>
                    <div className="result-dropdown-area">
                        <div>
                            <Formik
                                initialValues={resultSearch}
                                enableReinitialize={true}
                                onSubmit={handleSearch}>
                                {({props, values, setFieldValue}) => {
                                    return (<Form>
                                        <div className="row">
                                            <div className="col-2">
                                                <div className="form-group input-default-white">
                                                    <label
                                                        className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                        {" "}
                                                        Faculty{" "}
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        name="facultyName"
                                                        placeholder={"Select Faculty"}
                                                        fieldName={"name"}
                                                        label="facultyName"
                                                        handleChangeSection={(fieldName, value) => {
                                                            setFacultyObj(value)
                                                        }}
                                                        required
                                                        options={faculties || []}
                                                        component={SelectDropdown}
                                                    />
                                                    <ErrorMessage
                                                        name="facultyName"/>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="form-group input-default-white">
                                                    <label
                                                        className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                        {" "}
                                                        Department{" "}
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        name="departmentName"
                                                        placeholder={"Select Department"}
                                                        fieldName={"name"}
                                                        label="departmentName"
                                                        handleChangeSection={(fieldName, value) => {

                                                            setDepartmentObj(value)
                                                        }}

                                                        options={departments || []}
                                                        component={SelectDropdown}
                                                    />
                                                    <ErrorMessage
                                                        name="departmentName"/>

                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="form-group input-default-white">
                                                    <label
                                                        className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                        {" "}
                                                        Program{" "}
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        name="programName"
                                                        placeholder={"Select Program"}
                                                        fieldName={"name"}
                                                        label="departmentName"
                                                        handleChangeSection={(fieldName, value) => {

                                                            setProgram(value)
                                                        }

                                                        }
                                                        options={programs || []}
                                                        component={SelectDropdown}
                                                    />
                                                    <ErrorMessage
                                                        name="programName"/>

                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="form-group input-default-white">
                                                    <label
                                                        className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                        {" "}
                                                        Semester{" "}
                                                    </label>
                                                    {/*<p>Semester</p>*/}
                                                    <Field
                                                        type="text"
                                                        name="semester"
                                                        placeholder={"Select Semester"}
                                                        fieldName={"semesterName"}
                                                        label="semester"
                                                        handleChangeSection={(fieldName, value) => {
                                                            handleSemesterSelection(value)
                                                        }}
                                                        required
                                                        options={semesterList || []}
                                                        component={SelectDropdown}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-2">
                                                <div className="form-group input-default-white">
                                                    <label
                                                        className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                        {" "}
                                                        Course{" "}
                                                    </label>
                                                    {/*<p>Semester</p>*/}
                                                    <Field
                                                        type="text"
                                                        name="channelName"
                                                        placeholder={"Select Course"}
                                                        fieldName={"channelName"}
                                                        label="channelName"
                                                        handleChangeSection={(fieldName, value) => {
                                                            handleChannelSelection(value)
                                                        }}
                                                        required
                                                        options={channelList || []}
                                                        component={SelectDropdown}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-4">
                                                <div className="form-group input-default-white">
                                                    <label className="d-block">&nbsp;</label>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-dark radius-25">
                                                        <i className="fa fa-search mr-1"/>
                                                        Search
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group input-default-white">
                                                    <label className="d-block">&nbsp;</label>
                                                    <button
                                                        type="button"
                                                        onClick={downloadGradingCalculatedResult}
                                                        className="btn btn-dark radius-25">
                                                        <i className="fa fa-search mr-1"/>
                                                        Download Excel
                                                    </button>
                                                    {/*{channel?.id && isSearch ?
                                                    <a title="Download"
                                                       className="btn btn-dark radius-25"
                                                       target="_blank"
                                                       href={FILE_URL + "students-grade-book.xlsx"}>
                                                        Export Xlsx
                                                    </a>
                                                    : null}*/}
                                                </div>
                                            </div>
                                        </div>
                                    </Form>)
                                }}</Formik>
                        </div>
                    </div>
                    <div className="table-responsive table-light">
                        <table
                            className="table table-light table-hover table-striped"
                            id="dataTable"
                            width="100%"
                            cellSpacing="0"
                        >
                            <thead>
                            <tr>
                                <th scope="col">Sl</th>
                                <th>Student Name</th>
                                <th scope="col">Course Code</th>
                                <th scope="col">Room</th>
                                <th scope="col">Department</th>
                                <th scope="col">Faculty</th>
                                <th scope="col">Semester</th>
                                <th scope="col">Program</th>
                                <th scope="col">Full Score</th>
                                <th scope="col">Obtained Score</th>
                                <th scope="col">Grade</th>
                            </tr>
                            </thead>
                            <tbody>
                            {studentGradeBookList.length > 0
                                ? studentGradeBookList.map((data, index) => {
                                    return (
                                        <tr>
                                            <td scope=" ">{index + 1}</td>
                                <td>{data?.firstName + " " +data?.lastName}</td>
                                <td>{data?.courseName}</td>
                                <td>{data?.roomName}</td>
                                <td>{data?.departmentName}</td>
                                <td>{data?.facultyName}</td>
                                <td>{data?.semesterName}</td>
                                <td>{data?.programName}</td>
                                <td>{data?.fullScore}</td>
                                <td>{data?.obtainedScore}</td>
                                <td>{data?.letterGrade}</td>
                                        </tr>
                                    );
                                })
                                : <td colSpan="12" className="text-center">
                                    {loader ? <Loader className="my-5"/> : <h6 className="my-5">No data found</h6>}
                                </td>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </main>
            </CommonLayout>
        );
}

export default StudentsGradeBookComponent;