import React, {useCallback, useEffect, useState} from "react";
import CommonLayout from "../layout/commonLayout";
import {useSelector} from "react-redux";
import {getAttendanceReportForPrinciple} from "../../api/PrincipleAttendanceApi";
import {isArray} from "lodash";
import Loader from "../common/Loader";
import {toast} from "react-toastify";
import {ErrorMessage, Field, Form, Formik} from "formik";
import SelectDropdown from "../common/SelectDropwodn";
import TextFieldComponent from "../common/TextFieldComponent";
import * as DepartmentApi from "../../api/DepartmentApi";
import * as ProgramApi from "../../api/ProgramApi";
import * as SemesterApi from "../../api/SemesterApi";
import * as FacultyApi from "../../api/FacultyApi";
import ReactPaginate from "react-paginate";


const attendanceTypes = [
    {
        value: "Present",
        label: "Present"
    },
    {
        value: "Absent",
        label: "Absent"
    },
    {
        label: "Absent(Excused)",
        value: "Absent(Excused)"
    }
];
const AttendanceHistory = props => {
    const channels = useSelector(
        state =>
            state?.channels?.channels?.filter(obj => obj.channelType === "class") ||
            []
    );
    const [resultSearch, setResultSearch] = useState({
        facultyName: {}, departmentName: {}, programName: {}, semester: {}, greaterLess: "", number: "", attendanceType: ""

    });

    const imageBase = process.env.REACT_APP_FILES_URL;
    const [studentsData, setStudentsData] = useState([]);
    const [attendanceType, setAttendanceType] = useState("Present");
    const [channelId, setChannelId] = useState(null);
    const [attendanceGreaterSmaller, setAttendanceGreaterSmaller] = useState(
        null
    );
    const [loading, setLoading] = useState(false);
    const [attendanceHighestType, setAttendanceHighest] = useState(null);
    const [days, setDays] = useState(null);
    const [pageNo, setPageNo] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [searchBody, setSearchBody] = useState(null);
    const [faculty, setFaculty] = useState({});
    const [department, setDepartment] = useState({});
    const [program, setProgram] = useState({});
    const [session, setSession] = useState("");
    const [faculties, setFaculties] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [semesterList, setSemesterList] = useState([]);
    const [semester, setSemester] = useState({});
    const handleSemesterSelection = option => {
        setSemester(option)
    };

    useEffect(() => {
        loadFaculty();
        loadAllSemester();

    }, []);


    const setFacultyObj = value => {
        setFaculty(value);
        getDepartmentsByFaculty(value.id);
    }
    const getDepartmentsByFaculty = (facultyId) => {
        DepartmentApi.getAllByFacultyId(facultyId).then(json => {
            setDepartments(json.data);
        });
    }
    const setDepartmentObj = value => {
        setDepartment(value)
        getProgramsByDepartmentId(value.id);
    }
    const getProgramsByDepartmentId = (departmentId) => {
        if (departmentId !== undefined && departmentId !== 0) {
            ProgramApi.getAllByDepartmentId(departmentId).then(json => {
                setPrograms([...json.data, ...[{id: 0, name: "Unassigned"}]]);
            });
        }
    }

    function loadAllSemester() {
        SemesterApi.getAll().then(response => {
            setSemesterList(response.data);
        });
    }

    function loadFaculty() {
        FacultyApi.getAllFaculties().then(json => {
            setFaculties([...json.data, ...[{id: 0, name: "Unassigned"}]]);
        });
    }

    useEffect(() => {
        if (searchBody) getStudentDataFromApi();
    }, [searchBody]);

    const getStudentDataFromApi = useCallback(() => {
        setLoading(true);
        if (attendanceGreaterSmaller !== null && attendanceHighestType !== null) {
            console.log("please select one type of search");
            toast("please select one type of search");
        } else if (attendanceGreaterSmaller === null && attendanceHighestType === null) {
            console.log("please select atleast one type of search");
            toast("please select atleast one type of search");
        } else if ((attendanceGreaterSmaller !== '' || attendanceGreaterSmaller !== null) && (days === null)) {
            console.log("please enter date to search by higher or lower from day(s)");
            toast("please enter date to search by higher or lower from day(s)");
        } else {
            getAttendanceReportForPrinciple(searchBody)
                .then(response => {
                    if (response.response.data.length === 0) {
                        setHasMore(false);
                    } else {
                        console.log(studentsData);
                        console.log([...studentsData, ...response.response.data]);
                        setStudentsData([...studentsData, ...response.response.data]);
                    }

                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [searchBody]);

    const handleSearch = values => {
        console.log(values);
        const searchParams = {
            number: values.number,
            greaterLess: values.greaterLess,
            attendanceType: values.attendanceType,
            facultyId: values.facultyName.id,
            departmentId: values.departmentName.id,
            programId: values.programName.id,
            semesterId: values.semester.id
        }
        console.log(searchParams);
        if (values.greaterLess === null || values.greaterLess === '') {
            toast("Please select higher or lower filter");
        } else if (values.attendanceType === null || values.attendanceType === '') {
            toast("Please select attendance type");
        } else if (values.number === null || values.number === '') {
            toast("Please input attendance days");
        } else {
            getAttendanceReportForPrinciple(searchParams)
                .then(response => {
                    if (response.response.data.length === 0) {
                        setHasMore(false);
                    } else {
                        console.log(studentsData);
                        console.log([...studentsData, ...response.response.data]);
                        setStudentsData([...studentsData, ...response.response.data]);
                    }

                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }


    const handleAttendanceType = event => {
        console.log(event.target.value);
        if (null === event.target.value || '' === event.target.value) {
            setAttendanceGreaterSmaller("")
            setDays(null);
        } else {
            setAttendanceGreaterSmaller(event.target.value);
        }
    };
    const handlePresentHigherLower = event => {
        if (null === event.target.value || '' === event.target.value) {
            setAttendanceHighest("")
        } else {
            setAttendanceHighest(event.target.value);
        }

    };
    const handleDays = event => {
        setDays(event.target.value);
    };


    return (
        <CommonLayout headerName={"Attendance Statistics"}>
            <main>
                <div className="card-default attendance-statistics">
                    <div className="title ">
                        <div className="mb-0 d-flex align-items-center">
                            <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                <a href="#" className="pl-0 back-button">
                                    <i className="fa fa-chevron-left"> </i> </a>
                            </div>

                            <p>Attendance Statistics</p>
                        </div>
                    </div>
                    <div className="attendance-dropdown-area">
                        <Formik
                            initialValues={resultSearch}
                            enableReinitialize={true}
                            onSubmit={handleSearch}>
                            {({props, values, setFieldValue}) => {
                                return (<Form>
                                    <div className="row">
                                        <div className="col-2">
                                            <div className="form-group input-default-white">
                                                <label
                                                    className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                    {" "}
                                                    Select Faculty{" "}
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="facultyName"
                                                    placeholder={"Select Faculty"}
                                                    fieldName={"name"}
                                                    label="facultyName"
                                                    handleChangeSection={(fieldName, value) => {
                                                        setFacultyObj(value)
                                                    }}
                                                    required
                                                    options={faculties || []}
                                                    component={SelectDropdown}
                                                />
                                                <ErrorMessage
                                                    name="facultyName"/>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group input-default-white">
                                                <label
                                                    className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                    {" "}
                                                    Semester{" "}
                                                </label>

                                                {/*<p>Semester</p>*/}
                                                <Field
                                                    type="text"
                                                    name="semester"
                                                    placeholder={"Select Semester"}
                                                    fieldName={"semesterName"}
                                                    label="semester"
                                                    handleChangeSection={(fieldName, value) => {
                                                        handleSemesterSelection(value)
                                                    }}
                                                    required
                                                    options={semesterList || []}
                                                    component={SelectDropdown}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group input-default-white">
                                                <label
                                                    className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                    {" "}
                                                    Department{" "}
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="departmentName"
                                                    placeholder={"Select Department"}
                                                    fieldName={"name"}
                                                    label="departmentName"
                                                    handleChangeSection={(fieldName, value) => {

                                                        setDepartmentObj(value)
                                                    }}

                                                    options={departments || []}
                                                    component={SelectDropdown}
                                                />
                                                <ErrorMessage
                                                    name="departmentName"/>

                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group input-default-white">
                                                <label
                                                    className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                    {" "}
                                                    Program{" "}
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="programName"
                                                    placeholder={"Select Program"}
                                                    fieldName={"name"}
                                                    label="departmentName"
                                                    handleChangeSection={(fieldName, value) => {

                                                        setProgram(value)
                                                    }

                                                    }
                                                    options={programs || []}
                                                    component={SelectDropdown}
                                                />
                                                <ErrorMessage
                                                    name="programName"/>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-2 input-secondary">
                                            <label className="font-weight-medium text-gray-25 text-lg-left mb-2 d-flex align-items-center">Attendance Type</label>
                                            <div>
                                                <Field
                                                    type="radio"
                                                    name="attendanceType"
                                                    label="attendanceType"
                                                    value="Present"
                                                    id="Present"
                                                />
                                                <label htmlFor="Present">Present </label>
                                            </div>
                                            <div className="mr-4">
                                                <Field
                                                    type="radio"
                                                    name="attendanceType"
                                                    label="attendanceType"
                                                    value="Absent"
                                                    id="Absent"
                                                />
                                                <label htmlFor="Absent">Absent </label>
                                            </div>
                                            <div className="mr-4">
                                                <Field
                                                    type="radio"
                                                    name="attendanceType"
                                                    label="attendanceType"
                                                    value="Absent(Excused)"
                                                    id="absent-excused"
                                                />
                                                <label htmlFor="Absent(Excused)">Absent(Excused) </label>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group input-default-white">
                                                <label>Value</label>
                                                <Field
                                                    className="py-2 form-control"
                                                    min="0"
                                                    type="number"
                                                    name="number"
                                                    placeholder="Set a value (No. of Days)"
                                                    component={TextFieldComponent}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-2 attendance-check-box input-secondary d-flex">
                                            {/*<label className="font-weight-medium text-gray-25 text-lg-left mb-2 d-flex align-items-center">Higher/Lower than</label>*/}
                                            <div>
                                                <Field
                                                    type="radio"
                                                    name="greaterLess"
                                                    label="Greater"
                                                    value="Greater"
                                                    id="Greater"
                                                />
                                                <label htmlFor="Greater">More than </label>
                                            </div>
                                            <div>
                                                <Field
                                                    type="radio"
                                                    name="greaterLess"
                                                    label="Less"
                                                    value="Less"
                                                    id="Less"
                                                />
                                                <label htmlFor="Less">Less than</label>
                                            </div>

                                        </div>

                                        <div className="col-4">
                                            <div className="form-group input-default-white">
                                                <label className="d-block">&nbsp;</label>
                                                <button
                                                    type="submit"
                                                    className="btn btn-dark radius-25">
                                                    <i className="fa fa-search mr-1"/>
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>)
                            }}</Formik>
                    </div>

                    <div className="min-vh-100">
                        {loading ? (
                            <div className="container">
                                <Loader/>
                            </div>
                        ) : (
                            <div className="result-area">
                                {!loading && studentsData.length > 0 && isArray(studentsData) ? (
                                    studentsData.map((stdObj, index) => (
                                        <div key={index} className="mt-5">
                                            <div>
                                                <h5 className="text-muted mt-4">Room
                                                    Name: {stdObj?.channel?.channelName + " (" + stdObj?.userAndCountList[0]?.teacherName + ")" || ""}{" "}</h5>

                                                <div className=" mb-4">
                                                    <div className="card-body">
                                                        <div className="table-responsive table-light  mt-4">
                                                            <table className="table table-light"
                                                                   id="dataTable"
                                                                   width="100%"
                                                                   cellSpacing="0"
                                                            >
                                                                <thead>
                                                                <tr>
                                                                    <th>Student Name</th>
                                                                    <th>Teacher's Name</th>
                                                                    <th>Count</th>
                                                                    <th>Message</th>
                                                                </tr>
                                                                {stdObj.userAndCountList && isArray(stdObj.userAndCountList)
                                                                    ? stdObj.userAndCountList.map((student, i) => {
                                                                        return (
                                                                            <tr>
                                                                                {/*<td>{index + 1}</td>*/}
                                                                                <td>
                                                                                    <img
                                                                                        alt={"Student Picture"}
                                                                                        src={student?.studentUser?.imageUrl ? imageBase + student?.studentUser?.imageUrl : "img/maleStudent.svg"}
                                                                                        style={{
                                                                                            width: "35px", height: "35px", objectFit: "cover"
                                                                                        }}
                                                                                        className="rounded-circle p-1"
                                                                                    />
                                                                                    {student.studentUser?.firstName || ""} {student.studentUser?.lastName || ""}
                                                                                </td>
                                                                                <td>{student?.teacherName || ""}{" "}</td>
                                                                                <td>{student.attendanceCount}</td>
                                                                                <td>
                                                                                    <div className='box-icon bg-c-blue-light'>
                                                                                        <svg width="15" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path
                                                                                                d="M1.9 1.4h11.2v8.4H2.719l-.819.819V1.4Zm0-1.4C1.13 0 .507.63.507 1.4L.5 14l2.8-2.8h9.8c.77 0 1.4-.63 1.4-1.4V1.4c0-.77-.63-1.4-1.4-1.4H1.9Zm1.4 7h8.4v1.4H3.3V7Zm0-2.1h8.4v1.4H3.3V4.9Zm0-2.1h8.4v1.4H3.3V2.8Z"
                                                                                                fill="#4575EE"/>
                                                                                        </svg>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>);
                                                                    })
                                                                    : null}

                                                                </thead>
                                                                <tbody></tbody>
                                                            </table>
                                                            <div className="page-navigation">
                                                                <ReactPaginate
                                                                    previousLabel={"Previous"}
                                                                    nextLabel={"Next"}
                                                                    pageCount={10}
                                                                    onPageChange={() => {
                                                                    }}
                                                                    containerClassName={"paginationBttns"}
                                                                    previousLinkClassName={"previousBttn"}
                                                                    nextLinkClassName={"nextBttn"}
                                                                    disabledClassName={"paginationDisabled"}
                                                                    activeClassName={"paginationActive"}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div>
                                        <div className="body-water-mark">
                                            <img src="img/no_serach_results_icon .png" alt={"Picture of a Search Icon"}/>
                                            <h5 className="mb-0 py-3 text-opacity-25">
                                                Your search result will
                                                appear here, please select your desired filters
                                            </h5>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>

            </main>
        </CommonLayout>
    )
        ;
};

export default AttendanceHistory;
