import React, {useEffect, useState} from "react";
import * as CompanyPartnerApi from "../../api/CompanyPartnerApi";

import CommonLayout from "../layout/commonLayout";
import {useNavigate, useParams} from "react-router-dom";

function InstituteDistrictView(props) {
    const params = useParams();
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [companyPartner, setCompanyPartner] = useState([]);

    useEffect(() => {
        CompanyPartnerApi.getCompanyPartnerById(params.id)
            .then(json => {
                console.log(json);
                setCompanyPartner(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);
    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h1 className="mt-4">Company View</h1>
                        <br/>
                        <div className="card mb-4">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table
                                        className="table table-bordered"
                                        id="dataTable"
                                        width="100%"
                                        cellSpacing="0"
                                    >
                                        <thead>
                                        <tr>
                                            <th scope="col">Id</th>
                                            <th scope="col">companyName</th>
                                            <th scope="col">effectiveDate</th>
                                            <th scope="col">link</th>
                                            <th scope="col">Industry</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>{companyPartner.id}</td>
                                            <td>{companyPartner.companyName}</td>
                                            <td>{companyPartner.effectiveDate}</td>
                                            <td>{companyPartner.link}</td>
                                            <td>{companyPartner.industry?.industry}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default InstituteDistrictView;
