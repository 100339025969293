import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveUserType = userType => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/user-info/", userType);
};

export const updateUserType = userType => {
    return AxiosAgent.requests.put(API_MAIN_ROOT + "/user-info/", userType);
};
export const getUserTypeById = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/user-info/" + id);
};
export const deleteUserTypeById = id => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/user-info/" + id);
};
export const getAll = userId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/user-infos");
};
