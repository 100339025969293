import * as Utils from "../../../util/Utils";
import * as NotificationsApi from "../../../api/NotificationsApi";
import {fetchNotificationCounterBegin, fetchNotificationCounterSuccess} from "../action/notificationCounterAction";

export function fetchNotificationCounter() {
    return dispatch => {
        dispatch(fetchNotificationCounterBegin());
        NotificationsApi.notificationCount(Utils.getUserFromLocalStorage().id).then((res) => {
            dispatch(fetchNotificationCounterSuccess(res.data));
        });
    };
}

export function fetchNotificationCounterById(id) {
    return dispatch => {
        console.log(id);
        dispatch(fetchNotificationCounterBegin());
        NotificationsApi.notificationCount(id).then((res) => {
            dispatch(fetchNotificationCounterSuccess(res.data));
        });
    };
}
