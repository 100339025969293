import CachingImage from "../../Image/cachingImage";
import {FILES_URL} from "../../../util/LinkFactory";
import moment from "moment";
import SingleAttachmentComponent from "../Components/SingleAttachmentComponent";
import TwoAttachmentsComponent from "../Components/TwoAttachmentsComponent";
import ThreeAttachmentsComponent from "../Components/ThreeAttachmentsComponent";
import FourAttachmentComponent from "../Components/FourAttachmentComponent";
import MoreThanFourAttachmentComponent from "../Components/MoreThanFourAttachmentComponent";
import * as Utils from "../../../util/Utils";
import {Form, Formik} from "formik";
import React from "react";
import {convertUTCDateToLocalDatePostFormat} from "../../../util/DateUtils";
import AdSense from "react-adsense";

const PostContainer = ({
                           postList,
                           user,
                           comment,
                           viewLikedUserModal,
                           registerLike,
                           initialComment,
                           submitComment,
                           submitCommentState
                       }) => {
    return (
        <>
            {postList.length > 0
                ? postList.map((post, index) => {
                    return (
                        <div className="card-default table-shadow box-border-sm">
                            <div className="view-post-area">
                                {(index !== 0 && index % 3 === 0) ? <AdSense.Google
                                    client="ca-pub-2042111752026323"
                                    slot="6135172396"
                                    format="auto"
                                    style={{display: 'block'}}
                                    responsive="true"
                                    layoutKey='-gw-1+2a-9x+5c'
                                /> : null}
                                <div className="">
                                    <div className="post-content">
                                        <div className="post-by">
                                            <div className="avatar">
                                                {post.postBy.imageUrl ? (
                                                    <CachingImage alt="Image" src={FILES_URL + post.postBy.imageUrl}/>
                                                ) : (
                                                    <img src={"img/Avatar.png"} alt="Default Avatar"/>
                                                )}
                                            </div>
                                            <div className="post-by-info">
                                                <h6>{post.postBy.firstName} {post.postBy.lastName}</h6>
                                                <p>
                                                    {post.postDate != null
                                                        ? convertUTCDateToLocalDatePostFormat(new Date(post.postDate))
                                                        : null}
                                                </p>
                                            </div>
                                        </div>
                                        <p>{post.message}</p>
                                        {post.attachmentName.length === 1 ? (
                                            <SingleAttachmentComponent
                                                imageurl={post.attachmentName[0]}
                                            />
                                        ) : null}
                                        {post.attachmentName.length === 2 ? (
                                            <TwoAttachmentsComponent
                                                imageurl={post.attachmentName}
                                            />
                                        ) : null}
                                        {post.attachmentName.length === 3 ? (
                                            <ThreeAttachmentsComponent
                                                imageurl={post.attachmentName}
                                            />
                                        ) : null}
                                        {post.attachmentName.length === 4 ? (
                                            <FourAttachmentComponent
                                                imageurl={post.attachmentName}
                                            />
                                        ) : null}
                                        {post.attachmentName.length > 4 ? (
                                            <MoreThanFourAttachmentComponent
                                                imageurl={post.attachmentName}
                                            />
                                        ) : null}
                                        {/*<SingleAttachmentComponent/>*/}
                                        <div
                                            className="post-status"
                                            onClick={() => {
                                                viewLikedUserModal(post);
                                            }}
                                        >
                                            {post?.channelPostLikeLogList?.length > 0
                                                ? post?.channelPostLikeLogList?.map(
                                                    (likedUser, index) => {
                                                        if (index < 3)
                                                            return (
                                                                <span>
                                      {likedUser?.createUser?.firstName}
                                                                    {(index >= 0 && index < 2) ? ((post?.channelPostLikeLogList?.length > 1) ? ", " : null) : null}
                                    </span>
                                                            );
                                                    }
                                                )
                                                : null}{" "}
                                            {post?.channelPostLikeLogList?.length > 3
                                                ? " and " + (post?.channelPostLikeLogList?.length - 3) + " others liked this"
                                                : post?.channelPostLikeLogList?.length >= 1 ? "liked this" : null}
                                        </div>
                                        <div className="like-comment-share">
                                            <div className="box" onClick={() => {
                                                if (Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY", "ROLE_STUDENT","ROLE_EXAM_CONTROLLER"])) {
                                                    registerLike(post);
                                                }
                                            }}>
                                                <i
                                                    className={post.likedByCurrentUser ? "fa fa-thumbs-up" : "far fa-thumbs-up"}
                                                />
                                                <p>
                                                    {/*<b>{post.channelPostLikeLogList.length}</b> */}
                                                    Like
                                                </p>
                                            </div>

                                            <div className="box comment-count">
                                                {/*<i className="fa fa-comment-dots" />*/}
                                                <p>
                                                    <b>{post.channelPostCommentDTOList.length}</b>{" "}
                                                    Comment
                                                </p>
                                            </div>
                                        </div>

                                        <div className="mb-2 mt-2">
                                            {post.channelPostCommentDTOList.length > 0
                                                ? post.channelPostCommentDTOList.map(
                                                    (comment, index) => {
                                                        return (
                                                            <div className="comment-area">
                                                                <div className="avatar">
                                                                    {comment.user.imageUrl ? (
                                                                        <img src={FILES_URL + comment.user.imageUrl} alt="User Avatar"/>
                                                                    ) : (
                                                                        <img src={"img/Avatar.png"} alt="Default Avatar"/>
                                                                    )}
                                                                </div>

                                                                <div className="card-alt">
                                                                    <h6>
                                                                        {comment.user.firstName}{" "}
                                                                        {comment.user.lastName}
                                                                        <span>
                                          {" "}
                                                                            <small>
                                            {moment(
                                                comment.createTimeDate
                                            ).fromNow()}
                                          </small>
                                        </span>
                                                                    </h6>
                                                                    <p>{comment.comment}</p>
                                                                    {/*<div className="like-comment-share">*/}
                                                                    {/*    <div className="box">*/}
                                                                    {/*        <img src="img/like.png"/>*/}
                                                                    {/*        <p>{comment?.likedUserList?.length}</p>*/}
                                                                    {/*    </div>*/}
                                                                    {/*</div>*/}
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )
                                                : null}
                                        </div>
                                        {Utils.checkRolePermission([
                                            "ROLE_ADMIN",
                                            "ROLE_STUDENT", "ROLE_FACULTY","ROLE_EXAM_CONTROLLER"
                                        ]) ? (
                                            <Formik
                                                initialValues={
                                                    comment.post != null &&
                                                    comment.messageId === post.groupMessageId
                                                        ? comment
                                                        : initialComment
                                                }
                                                enableReinitialize={true}
                                                onSubmit={(
                                                    data,
                                                    {setSubmitting, setErrors, setStatus, resetForm}
                                                ) => {
                                                    const value = {
                                                        ...data,
                                                        messageId: post.groupMessageId
                                                    };
                                                    submitComment(value, resetForm, post);
                                                }}
                                            >
                                                {({values, handleSubmit, setFieldValue}) => (
                                                    <Form>
                                                        <div className="add-comment-area">
                                                            <div className="avatar">
                                                                {user?.imageUrl ? (
                                                                    <CachingImage
                                                                        alt="image"
                                                                        src={FILES_URL + user.imageUrl}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src={"img/Avatar.png"}
                                                                        alt="Default Avatar"
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="input-default">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="comment"
                                                                    aria-describedby="emailHelp"
                                                                    name="comment"
                                                                    placeholder="Comment here..."
                                                                    value={values.comment}
                                                                    onChange={event => {
                                                                        setFieldValue(
                                                                            "comment",
                                                                            event.target.value
                                                                        );
                                                                        submitCommentState({
                                                                            ...values,
                                                                            comment: event.target.value
                                                                        });
                                                                    }}
                                                                />
                                                                <button
                                                                    type="submit"
                                                                    className="btn-purple-md float-right"
                                                                >
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
                : ""}
        </>
    );
};

export default PostContainer;
