import React, {useEffect, useState} from "react";
import * as Utils from "../../util/Utils";

function ParentsPending(props) {
    const [user, setUser] = useState([]);
    useEffect(() => {
        setUser(Utils.getUserFromLocalStorage());
    }, []);
    return (
        <>
            <div id="regCoolingPeriod">
                <div className="middle-center">
                    <div className="content-wrap">
                        <div className="illustration">
                            <img src="img/meet.png"/>
                        </div>
                        <h5>
                            Dear {null!=user? user.firstName: "Parent"},
                        </h5>
                        <p>
                            Please wait for your registration to be approved by authority.
                            <br/>
                            If you find anything inappropriate contact your institute authority
                        </p>
                        <br/>
                        <a>
                            Contact Authority
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ParentsPending;
