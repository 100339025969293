import React, {useEffect, useState} from "react";
import CommonLayout from "../layout/commonLayout";
import * as StudentApi from "../../api/StudentApi";
import * as GradingCalculatedResultApi from "../../api/GradingCalculatedResultApi";
import Loader from "../common/Loader";
import {SERVER_NAME_URL} from "../../constants/Constants";
import {useParams} from "react-router-dom";
import {FILES_URL} from "../../util/LinkFactory";
import PageError from "../ErrorPage";
import * as ChannelApi from "../../api/ChannelApi";
import GradingTree from "../Grading/graddingTree";
import * as Utils from "../../util/Utils";
import {toast} from "react-toastify";

const defaultData = {
    id: "",
    firstName: "",
    middleName: "",
    lastName: "",
    grade: "",
    section: "",
    fatherName: "",
    motherName: "",
    permanentAddress: "",
    presentAddress: "",
    photoName: "",
    regId: "",
    emergencyContactPerson: "",
    emergencyContact: "",
    bloodGroup: "",
    uuid: "",
    userId: null
};
toast.configure();

function StudentProfile() {
    const params = useParams();
    const [gradingLoader, setGradingLoader] = useState(false);
    const [studentInfo, setStudentInfo] = useState(defaultData);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const [channelList, setChannelList] = useState(false);
    const [gradeBookList, setGradeBookList] = useState(false);
    const [gradeBooks, setGradeBooks] = useState(null);
    const [resultHistory, setResultHistory] = useState(null);
    const [user, setUser] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    let fileBase64s = [];
    const [files, setFiles] = useState([]);
    let PhotoRootUrl;

    useEffect(() => {
        setUser(Utils.getUserFromLocalStorage());
        getStudentInfo(Utils.getUserFromLocalStorage().id);
    }, []);

    const getChannelLIst = id => {
        ChannelApi.getChannelListByUserId(id).then(
            json => {
                setChannelList(json.data);
            }
        )
    }
    const gradeBookListByUserId = userId => {
        setGradingLoader(true);
        GradingCalculatedResultApi.getGradingForCalculatedResultByUserId(userId).then(res => {
            console.log(res.data);
            setGradeBooks(res.data);
            setGradingLoader(false);
        })
    }

    const resultSheetCalculationByStudentId = userId => {
        GradingCalculatedResultApi.resultSheetCalculationByStudentId(userId).then(res => {
            console.log(res.data);
            setResultHistory(res.data);
        })
    }

    const generateReportForResultSheetCalculationByUserId = userId => {
        GradingCalculatedResultApi.generateReportForResultSheetCalculationByUserId(userId).then(response => {
            console.log(response)
            if (response.response.status === 200) {
                let blob = new Blob([response.data], {type: 'application/pdf'}),
                    url = window.URL.createObjectURL(blob)

                window.open(url)
            }
        })
    }

    const exportReport = () => {
        generateReportForResultSheetCalculationByUserId(Utils.getUserFromLocalStorage().id);
    }

    const channelTypeConversion = channelType => {
        if (channelType === 'class')
            return "Class"
        else if (channelType === 'co-currullar')
            return "Co - Curricular"
        else if (channelType === 'organizational')
            return "Institute Room"
    }

    const getStudentInfo = id => {
        setLoader(true);
        StudentApi.getStudentInfoByUserId(id)
            .then(json => {
                PhotoRootUrl = SERVER_NAME_URL + json.data.photoName;
                getChannelLIst(json.data.userId);
                gradeBookListByUserId(json.data.userId)
                resultSheetCalculationByStudentId(json.data.userId)
                let response = json.data || defaultData;
                setStudentInfo(response);
            })
            .catch(errors => {
                setError(true);
            })
            .finally(() => setLoader(false));
    };
    const handleProfilePicInputChange = event => {
        fileBase64s = [];
        setFiles(fileBase64s);
        setSelectedFiles(event.target.files);
        Array.from(event.target.files).forEach(file => {
            fileToDataUri(file).then(data => {
                fileBase64s.push({blob: data.split("base64,")[1], type: file.type, fileName: file.name});
            });
        });
        console.log(fileBase64s);
        setFiles(fileBase64s);
    };


    const fileToDataUri = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = event => {
                resolve(event.target.result);
            };
            reader.readAsDataURL(file);
        });


    function proPicUpload() {
        console.log(files);
        let profilePicUploadDto = {
            photoBlob: files[0].blob,
            photoBlobType: files[0].type,
            userId: studentInfo.userId
        };
        uploadProfilePicture(profilePicUploadDto).then(r => {
            toast("Profile Picture Uploaded!")
        });
    }

    async function uploadProfilePicture(profilePicUploadDto) {
        await StudentApi.uploadStudentProfilePicture(profilePicUploadDto).then(
            response => {
                flushFileUpload();
                getStudentInfo(Utils.getUserFromLocalStorage().id);
            }
        );
    }

    function flushFileUpload() {
        setFiles([]);
        setSelectedFiles([]);
        fileBase64s = [];
    }

    if (studentInfo && !loader) {
        return (
            <div className=" student-profile">
                <CommonLayout headerName={"Student Info"}>
                    <main>
                        <div className=" pt-3 pb-3 p-2">
                            {error ? (
                                <PageError title={"No data to show"}/>
                            ) : (
                                <div className="card-default ">
                                    <div className="card-heading">
                                        <div className="title">
                                            <p>Student Information</p>
                                        </div>
                                    </div>
                                    <div className="tab-blue">
                                        <div className="tabs">
                                            <ul
                                                className="nav nav-pills mb-4"
                                                id="pills-tab3"
                                                role="tablist"
                                            >
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link active"
                                                        id="pills-assignment-upcoming"
                                                        data-toggle="pill"
                                                        href="#pills-assignment"
                                                        role="tab"
                                                        aria-controls="pills-assignment"
                                                        aria-selected="true"
                                                    >
                                                        Student Info
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        id="pills-assignment-inprogress"
                                                        data-toggle="pill"
                                                        href="#pills-inprogress"
                                                        role="tab"
                                                        aria-controls="pills-inprogress"
                                                        aria-selected="false"
                                                    >
                                                        Enrolled Rooms Info
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        id="pills-student-grade"
                                                        data-toggle="pill"
                                                        href="#pills-grade"
                                                        role="tab"
                                                        aria-controls="pills-grade"
                                                        aria-selected="false"
                                                    >
                                                        Grade Book
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        id="pills-student-result"
                                                        data-toggle="pill"
                                                        href="#pills-result"
                                                        role="tab"
                                                        aria-controls="pills-result"
                                                        aria-selected="false"
                                                    >
                                                        Result Sheet
                                                    </a>
                                                </li>
                                            </ul>
                                            <div className="tab-content" id="pills-tabContent">
                                                <div
                                                    className="tab-pane fade show active"
                                                    id="pills-assignment"
                                                    role="tabpane2"
                                                    aria-labelledby="pills-assignment-upcoming">
                                                    <div className=" mx-auto">
                                                        <div className="table-responsive mt-4">
                                                            <table
                                                                className="table table-bordered"
                                                                id="dataTable"
                                                                width="100%"
                                                                cellSpacing="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td> Student Full Name</td>
                                                                    <td>
                                                                        {studentInfo?.firstName} {studentInfo?.middleName}{" "}
                                                                        {studentInfo?.lastName}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td> Student Photo</td>
                                                                    <td>
                                                                        {studentInfo?.photoName ? (
                                                                            <img
                                                                                style={{width: "200px", height: "auto"}}
                                                                                src={FILES_URL + studentInfo?.photoName}
                                                                                alt={studentInfo?.firstName}
                                                                            />
                                                                        ) : (
                                                                            "No photo available"
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td> Upload Photo</td>
                                                                    <td>
                                                                        <div className="row m-0">
                                                                            <div className="col-6 p-0">
                                                                                <input
                                                                                    className="custom-file-input"
                                                                                    id="file"
                                                                                    name="file"
                                                                                    type="file"
                                                                                    multiple
                                                                                    onChange={handleProfilePicInputChange}
                                                                                />
                                                                                <label className="custom-file-label"
                                                                                       htmlFor="file">
                                                                                    Choose file
                                                                                </label>
                                                                                <div className="submit-area mt-4">
                                                                                    {Array.from(selectedFiles).length > 0 ? Array.from(selectedFiles).map((data, index) => {
                                                                                        return (
                                                                                            <p className="mb-0 text-primary pt-1">{(index + 1) + ". " + data.name}</p>);
                                                                                    }) : ""}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn-dark radius-25"

                                                                                    disabled={Array.from(selectedFiles).length === 0}
                                                                                    onClick={() => proPicUpload()}>
                                                                                    <i className="fa fa-upload mr-2"/> Upload
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td> Father Name</td>
                                                                    <td>{studentInfo?.fatherName}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td> Mother Name</td>
                                                                    <td>{studentInfo?.motherName}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td> Grade</td>
                                                                    <td>{studentInfo?.grade?.gradeName}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td> Section</td>
                                                                    <td>{studentInfo?.section?.sectionName}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td> Permanent Address</td>
                                                                    <td>{studentInfo?.permanentAddress}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td> Present Address</td>
                                                                    <td>{studentInfo?.presentAddress}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td> Emergency Contact Person</td>
                                                                    <td>{studentInfo?.emergencyContactPerson}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td> Emergency Contact Number</td>
                                                                    <td>{studentInfo?.emergencyContact}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Blood Group</td>
                                                                    <td>{studentInfo?.bloodGroup}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Health Concern</td>
                                                                    <td>{studentInfo?.healthConcern}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Contact Number</td>
                                                                    <td>{studentInfo?.contactNo}</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    className="tab-pane fade"
                                                    id="pills-inprogress"
                                                    role="tabpane2"
                                                    aria-labelledby="pills-assignment-inprogress"
                                                >
                                                    <div className="table-responsive mt-4">
                                                        <table
                                                            className="table"
                                                            id="dataTable"
                                                            width="100%"
                                                            cellSpacing="0"
                                                        >
                                                            <thead className="bg-light">
                                                            <tr>
                                                                <th scope="col">id</th>
                                                                <th scope="col">Room Name</th>
                                                                <th scope="col">Room Type</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {channelList.length > 0
                                                                ? channelList.map((data, index) => {
                                                                    return (
                                                                        <tr>
                                                                            <th scope="row">{index + 1}</th>
                                                                            <td>{data.channelName}</td>
                                                                            <td>{channelTypeConversion(data.channelType)}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                                : ""}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="pills-grade"
                                                    role="tabpane3"
                                                    aria-labelledby="pills-student-grade">
                                                    <h5 className="mt-4">Grade Book</h5>
                                                    {!gradingLoader ? <div className="">
                                                        {gradeBooks?.length > 0 ? (
                                                            gradeBooks.map((gradeBook, index) => {
                                                                return <>
                                                                    <div className="table-responsive mt-4">
                                                                        <table
                                                                            className="table table-bordered"
                                                                            id="dataTable"
                                                                            width="100%"
                                                                            cellSpacing="1"
                                                                        >
                                                                            <thead className="bg-light"
                                                                                   style={{color: "gray"}}>
                                                                            <tr>
                                                                                <th scope="col">Room Name</th>
                                                                                {gradeBook?.gradingBreakdownList?.length > 0 ? (
                                                                                    gradeBook.gradingBreakdownList.map(
                                                                                        (gradeBreakdown, index) => {
                                                                                            return (
                                                                                                <th scope="col">
                                                                                                    {gradeBreakdown.evaluationName +
                                                                                                        (null != gradeBreakdown.fullScore ? "(" +
                                                                                                            gradeBreakdown.fullScore +
                                                                                                            ")" : "")}
                                                                                                </th>
                                                                                            );
                                                                                        }
                                                                                    )
                                                                                ) : (
                                                                                    <div/>
                                                                                )}
                                                                            </tr>
                                                                            </thead>

                                                                            <tbody>

                                                                            <tr key={index}>
                                                                                <td>
                                                                                    {gradeBook?.channel?.channelName || ""}
                                                                                </td>

                                                                                {gradeBook?.gradingResultStudentDTOList?.length > 0 ? gradeBook?.gradingResultStudentDTOList[0]?.gradeBookBreakdownDTOList.map(
                                                                                    (gradingBreakdown, index) => {
                                                                                        console.log(gradingBreakdown);
                                                                                        return (
                                                                                            <>
                                                                                                <td>
                                                                                                    <GradingTree
                                                                                                        gradeBooks={gradingBreakdown}
                                                                                                    />
                                                                                                </td>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                ) : ""}
                                                                            </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </>;
                                                            })) : ""
                                                        }

                                                    </div> : <Loader/>}
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="pills-result"
                                                    role="tabpane2"
                                                    aria-labelledby="pills-student-result">
                                                    <h3>CGPA: {resultHistory ? Utils.twoDecimalPoint(resultHistory?.cgpa) : 0.0}
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-dark radius-25 float-right"
                                                            onClick={() => exportReport()}
                                                        >
                                                            <i className="fa fa-check-circle mr-2"/>
                                                            Export Result Sheet
                                                        </button>
                                                    </h3>
                                                    {resultHistory && resultHistory?.resultSemesterDtoList?.length > 0 ? (
                                                        resultHistory?.resultSemesterDtoList?.map((result, index) => {
                                                            return <>
                                                                <div className="table-responsive mt-4">
                                                                    <h6 className="mb-4 fw-600">{result.semesterName + " " + new Date().getFullYear(result.semesterYear) + " (SGPA " + Utils.twoDecimalPoint(result.sgpa) + ")"}</h6>
                                                                    <table
                                                                        className="table"
                                                                        id="dataTable"
                                                                        width="100%"
                                                                        cellSpacing="0">

                                                                        <thead className="bg-light">
                                                                        <tr>
                                                                            <th scope="col">Subject</th>
                                                                            <th scope="col">GPA</th>
                                                                            <th scope="col">Grade</th>
                                                                            <th scope="col">Obtained Score</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {result.resultSubjectWiseDtoList.length > 0 ? result.resultSubjectWiseDtoList.map((data, index) => {
                                                                            return (
                                                                                <tr>
                                                                                    <th scope="row">{data.subjectName}</th>
                                                                                    <td>{Utils.twoDecimalPoint(data?.obtainedScore?.gradePoint)}</td>
                                                                                    <td>{data?.obtainedScore?.obtainedLetterGrade}</td>
                                                                                    <td>{"(" + data.obtainedScore.obtainedScore + "/" + data.obtainedScore.fullScore + ")"}</td>
                                                                                </tr>
                                                                            );
                                                                        }) : ""
                                                                        }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </>;

                                                        })) : ""
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </main>
                </CommonLayout>
            </div>
        );
    }
    return (
        <div className="h-100 my-auto mx-auto pt-4  ">
            <Loader/>
        </div>
    );
}

export default StudentProfile;
