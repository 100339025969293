import React, { useEffect, useState } from "react";
import * as LinkFactory from "../../util/LinkFactory";
import { useNavigate } from 'react-router-dom';

function SideNavMenu({ route, title, icon }) {

    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);

    function handleDropdownMenuClick() {
        setIsDropdownMenuOpen(!isDropdownMenuOpen);
    }

    const handleChannelRoute = (rPath) => {
        if (!isNaN(+rPath)) {
            navigateTo(LinkFactory.channelBoard + "/" + rPath)
        } else navigateTo(rPath)
    }

    useEffect(() => {
        // if (path && route.filter(obj => obj.path === path).length > 0) {
        //     if (isDropdownMenuOpen === false) setIsDropdownMenuOpen(true);
        // }
    }, []);

    return (
        <div key={route.title} className="sidenav-border">
            <button
                className={`dropdown-sidenav py-2 d-inline-flex align-items-center justify-content-between ${isDropdownMenuOpen ? 'open' : ''}`}
                onClick={handleDropdownMenuClick}
                aria-haspopup="true"
            >
                <span className="d-inline-flex  align-items-center">
                    <h6 className="mr-2 mb-0 fw-700">{title}</h6>
                    <div aria-hidden="true">

                            <i className="fa fa-chevron-down ml-5" />


                    </div>
                </span>

            </button>

            <div className={`dropdown-content ${isDropdownMenuOpen ? 'open' : ''}`}>
                <ul className="sidenav-submenu" aria-label="submenu">
                    {route.map(r => (
                        <a key={r.name}>
                            <li onClick={() => handleChannelRoute(r.path)}>
                                <div className="d-inline-flex align-items-baseline" role="button">
                                    <div className="sb-nav-link-icon">
                                        <i className="fa fa-circle"> </i>
                                    </div>
                                    <p className="ml-0 fw-400 my-1-0 pl-2"> {r.name}</p>
                                </div>
                            </li>
                        </a>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default SideNavMenu;
