import React, {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import SelectDropdown from "../common/SelectDropwodn";
import * as Utils from "../../util/Utils";
import * as ChannelApi from "../../api/ChannelApi";
import * as MessageApi from "../../api/MessageApi";
import "./message.css";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";
import MyModal from "../common/MyModal";

import * as LinkFactory from "../../util/LinkFactory";
import CommonLayoutForChatPage from "../layout/commonLayoutForChatPage";
import Loader from "../common/Loader";
import {Multiselect} from "multiselect-react-dropdown";

const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-success mx-3", cancelButton: "btn btn-secondary mr-1"
    }, buttonsStyling: false
});


function DirectMessageFirstPage(props) {
    let history = useNavigate();

    function onSelect(selectedList, selectedItem) {
        let selectedUsers = selectedList;
        setSelectedRecipients(selectedUsers);
    }

    function onRemove(selectedList, removedItem) {
        let selectedUsers = selectedList;
        setSelectedRecipients(selectedUsers);
    }

    function navigateTo(pathname) {
        history(pathname);
    }

    const [messageInit, setMessageInit] = useState({
        states: "", id: "", message: ""
    });
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [threadList, setThreadList] = useState(null);
    const [recipientList, setRecipientList] = useState([]);
    const [receiver, setReceiver] = useState(undefined);
    const [selectedChannel, setSelectedChannel] = useState([]);
    const [selectedRole, setSelectedRole] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);
    const [selectedRecipients, setSelectedRecipients] = useState([]);
    const [channelList, setChannelList] = useState([]);
    const [roles, setRoles] = useState([{role: "Student"}, {role: "Employee"}, {role: "Parents"}]);
    const [message, setMessage] = useState("");
    const [smallCalenderData, setSmallCalenderData] = useState([]);
    const [error, setError] = useState(false);
    const IMAGE_BASE_URL = process.env.REACT_APP_FILES_URL;
    useEffect(() => {
        if (!threadList) {
            setLoading(true);
            MessageApi.threadAll()
                .then(json => {
                    if (json.response.status === 200) {
                        setThreadList(json.data);
                    } else setError(true);
                })
                .catch(errors => {
                    console.log(errors);
                })
                .finally(() => setLoading(false));
        }
        // ChannelApi.getSmallCalenderDataAll().then(calenderData => {
        //     console.log(calenderData.data);
        //     setSmallCalenderData(calenderData.data);
        // });
        if (Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY", "ROLE_EXAM_CONTROLLER"])) {
            ChannelApi.getChannelListForAdmin()
                .then(json => {
                    setChannelList(json.data);
                })
                .catch(errors => {
                    console.log(errors);
                });
        } else if (!Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY", "ROLE_EXAM_CONTROLLER"]) && Utils.checkRolePermission(["ROLE_USER"])) {
            ChannelApi.getChannelListByLoggedUser()
                .then(json => {
                    setChannelList(json.data);
                })
                .catch(errors => {
                    console.log(errors);
                });
        }
    }, []);

    const handleSubmit = data => {
        console.log(message);
        console.log(message.length);
        console.log(selectedRecipients);
        setLoader(true);
        if (message.trim().length === 0) {
            swalWithBootstrapButtons
                .fire({
                    title: "Oops...", text: "You forget to type message..", icon: "error", confirmButtonText: "Okay", reverseButtons: true
                }).then(result => {
                setLoader(false)
            });
        } else {
            let directGroupMessageThreadDTO = {
                sender: Utils.getUserFromLocalStorage(), message: message, receiverList: selectedRecipients
            };
            MessageApi.sendGroupMessage(directGroupMessageThreadDTO).then(data => {
                setMessage("");
                console.log(data);
                // let slug = "?u="+receiver.id+'&&th='+data.data.directMessageThread.id;
                let slug = "?th=" + data.data.directMessageThread.id;
                setLoader(false);
                swalWithBootstrapButtons
                    .fire({
                        title: "Success", text: "Message sent", icon: "success", showCancelButton: true, confirmButtonText: "Go to messages", cancelButtonText: "Okay", reverseButtons: true
                    })
                    .then(result => {
                        if (result.isConfirmed) {
                            navigateTo("/message-thread/" + slug);
                        }
                    });
            });
        }

    };

    const handleChannelSelection = (fieldName, value) => {
        console.log(value);
        setSelectedChannel(value);
        handleRecipientListLoading(value, "");
    };
    const handleRoleSelection = (fieldName, value) => {
        setSelectedRole(value);
        console.log(value);
        console.log(selectedChannel);
        handleRecipientListLoading(selectedChannel, value);
    };
    const handleRecipientSelection = (e, user) => {
        // console.log(user,'adfs');
        setReceiver(user);
    };

    const handleCalender = () => {
        navigateTo(LinkFactory.calenderPage);
    };

    function displayNameConverter(data) {
        data.forEach(d => {
            d.displayValue = d.firstName + " " + d.lastName
        });
        return data;
    }

    const handleRecipientListLoading = (channel, role) => {
        if (Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY", "ROLE_EXAM_CONTROLLER"])) {
            if (channel != null && role != null) {
                if (role.role === "Student") {
                    ChannelApi.getChannelMemberListByChannelId(channel.id)
                        .then(json => {
                            setRecipientList(displayNameConverter(json.data));
                        })
                        .catch(errors => {
                            console.log(errors);
                        });
                } else if (role.role === "Employee") {
                    ChannelApi.getChannelModeratorListByChannelId(channel.id).then(json => {
                        setRecipientList(displayNameConverter(json.data));
                    });
                } else if (role.role === "Parents") {
                }
            }
        } else if (!Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY", "ROLE_EXAM_CONTROLLER"]) && Utils.checkRolePermission(["ROLE_USER"])) {
            if ("Student" === role.role) {
                ChannelApi.getChannelMemberListByChannelId(channel.id)
                    .then(json => {
                        setRecipientList(displayNameConverter(json.data));
                    })
                    .catch(errors => {
                        console.log(errors);
                    });
            } else if ("Employee" === role.role) {
                ChannelApi.getChannelModeratorListByChannelId(channel.id).then(json => {
                    setRecipientList(displayNameConverter(json.data));
                });
            }
        }
    };

    // console.log(Formik.values,'formik')
    function handleThreadSelect(data) {

        const newLink = "/message-thread/?th=" + data.threadId;
        console.log(newLink);
        // setSelectedThread(data.threadId)
        navigateTo(newLink);
    }

    return (<>
        <CommonLayoutForChatPage headerName="Messages">
            <main>
                <MyModal/>
                <div id="contentAreaChatPage">
                    <div className="block-center">
                        <div className="pt-2 px-2 container-fluid">
                            <div className="row radius-25 overflow-hidden bg-white">
                                <div className="left-message-body col-3 col-sm-4 px-0">
                                    <div>
                                        <div className=" ">
                                            <div className="title-header">
                                                <div className=" card-header p-0 m-0 mr-3 d-flex align-items-center">
                                                    <a href="#" className="pl-0 back-button">
                                                        <i className="fa fa-chevron-left pr-1"> </i> Back</a>
                                                </div>
                                            </div>
                                            <div className="left-section-heading py-2 px-2 pr-4">
                                                <p className=" px-4 h5 mb-0 py-3 fw-600">Recent Chats</p>
                                            </div>
                                            <div className="form-group mt-1 mb-3 w-100 d-flex justify-content-center">
                                                <div className="has-search w-80">
                                                    <span className="fa fa-search form-control-feedback"> </span>
                                                    <input type="text"
                                                           className="form-control"
                                                           placeholder="Search Chat"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="messages-box">
                                            {threadList && threadList.length && threadList.map(thread => (<div key={thread.threadId}>
                                                <div className="list-group rounded-0">
                                                    <div
                                                        onClick={() => handleThreadSelect(thread)}
                                                        className="list-group-item list-group-item-action list-group-item-light rounded-0"
                                                    >
                                                        <div className=" d-flex justify-content-between align-items-center ">
                                                            <div className="d-flex">
                                                                <div className='avatar'>
                                                                    {thread && thread.recipients[0] && thread.recipients[0].imageUrl ? (<img
                                                                        src={IMAGE_BASE_URL + thread.recipients[0].imageUrl}
                                                                        alt="user"
                                                                        width="50"
                                                                        height="50"
                                                                        className="rounded-circle"
                                                                        style={{objectFit: "cover"}}
                                                                    />) : <img
                                                                        src={"img/Avatar.png"}
                                                                        alt="user"
                                                                        width="50"
                                                                        height="50"
                                                                        className="rounded-circle"
                                                                        style={{objectFit: "cover"}}
                                                                    />}
                                                                </div>
                                                                <div className="media-body ml-3 my-auto align-items-center ">
                                                                    <h6>
                                                                        {thread.recipients.length > 0 && thread.recipients.map((recipient, index) => (recipient.firstName + " " + recipient.lastName + (index !== (thread?.recipients?.length - 1) ? ", " : "")))}
                                                                        {/*{thread && thread.recipients[0] && thread.recipients[0].firstName ? thread.recipients[0].firstName + " " + thread.recipients[0].lastName : "No name"}{" "}*/}
                                                                    </h6>
                                                                    <span> </span>
                                                                </div>
                                                            </div>
                                                            <div className="unread-time">
                                                                <span> 4m</span>
                                                                <svg width="10" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="5" cy="5" r="5" fill="#4575EE"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>))}
                                        </div>
                                    </div>
                                </div>

                                <div className="right-message-body create-new-chat col-9 col-sm-8 px-0">
                                    <div className="avatar title-avatar d-flex align-items-center">

                                        <div className="name">
                                            <p> Create new chat</p>
                                        </div>
                                    </div>
                                    <div className="px-4 py-5 chat-box bg-white">
                                        <Formik
                                            initialValues={messageInit}
                                            enableReinitialize={true}
                                            onSubmit={handleSubmit}
                                        >
                                            {props => (<Form>
                                                <div className="row col-12">
                                                    <div className="col-4">
                                                        <div className="form-group">
                                                            <label>Room</label>
                                                            <Field
                                                                type="text"
                                                                name="channelName"
                                                                placeholder="Select Room"
                                                                fieldName={"channelName"}
                                                                options={channelList || []}
                                                                handleChangeSection={handleChannelSelection}
                                                                component={SelectDropdown}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="form-group">
                                                            <label>Role</label>
                                                            <Field
                                                                type="text"
                                                                name="Role"
                                                                placeholder="Select Role"
                                                                fieldName={"role"}
                                                                handleChangeSection={handleRoleSelection}
                                                                options={roles || []}
                                                                component={SelectDropdown}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        {recipientList.length > 0 ? (<Multiselect
                                                                onRemove={onRemove}
                                                                onSelect={onSelect}
                                                                options={recipientList}
                                                                selectedValues={selectedValue}
                                                                displayValue="displayValue"
                                                            />

                                                            // <div className=" ">
                                                            //     <div className="form-group">
                                                            //         <label>Select Recipient</label>
                                                            //         <Field
                                                            //             type="text"
                                                            //             name="Recipient"
                                                            //             placeholder="Select Recipient"
                                                            //             fieldName={"firstName"}
                                                            //             handleChangeSection={handleRecipientSelection}
                                                            //             options={recipientList || []}
                                                            //             component={SelectDropdown}
                                                            //         />
                                                            //     </div>
                                                            // </div>

                                                        ) : null}
                                                    </div>

                                                </div>

                                                <div className="row col-12 mt-2">
                                                    {selectedRecipients.length > 0 ? (<div className="col-12">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="exampleFormControlTextarea1">
                                                                    Message
                                                                </label>
                                                                <textarea
                                                                    placeholder="Type your message"
                                                                    name="message"
                                                                    className="form-control"
                                                                    onChange={e => setMessage(e.target.value)}
                                                                    rows="3"
                                                                />
                                                            </div>
                                                        </div>

                                                    ) : ("")}
                                                </div>
                                                <div className="row col-12 mt-3">
                                                    <div className="form-group">
                                                        {loader ? <Loader/> : <div className="col-12">
                                                            <button
                                                                disabled={receiver === undefined && message.length === 0}
                                                                type="submit"
                                                                className="btn btn-purple-md radius-25 px-4 mr-3">

                                                                Create
                                                            </button>
                                                            <button
                                                                type="cancel"
                                                                className="btn btn-cancel radius-25 px-4">
                                                                Cancel
                                                            </button>
                                                        </div>}

                                                    </div>
                                                </div>


                                            </Form>)}
                                        </Formik>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </CommonLayoutForChatPage>
    </>);
}

export default DirectMessageFirstPage;
