import React, {useEffect, useState} from "react";

import * as ChannelApi from "../../api/ChannelApi";
import * as RoutineApi from "../../api/RoutineApi";
import "react-datepicker/dist/react-datepicker.css";
import * as AttendanceApi from "../../api/AttendanceApi";

import * as LinkFactory from "../../util/LinkFactory";
import DatePicker from "react-datepicker";
import CommonLayout from "../layout/commonLayout";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Alert} from "react-bootstrap";
import * as Utils from "../../util/Utils";
import FormTitle from "../Typhography/formTitle";
import LoaderOverlay from "../common/LoaderOverlay";
import {useNavigate, useParams} from "react-router-dom";
import ReactPaginate from "react-paginate";

function AttendanceEntry(props) {
    const params = useParams();

    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }

    const [loader, setLoader] = useState(true);
    const [channelMembers, setChannelMembers] = useState([]);
    const [routineDays, setRoutineDays] = useState([]);
    const [routine, setRoutine] = useState([]);
    const [channel, setChannel] = useState({});
    const [enableForEntry, setEnableForEntry] = useState(false);
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState(null);
    const [weekdayNumbers, setWeekdayNumbers] = useState([]);
    const [attendanceDate, setAttendanceDate] = useState(new Date());
    const [attendanceInfo, setAttendanceInfo] = useState([]);
    const imageBase = process.env.REACT_APP_FILES_URL;
    function getChannel() {
        ChannelApi.getChannelById(params.id).then(json => {
            setChannel(json.data);
        });
    }

    function getRoom(day) {
        setLoading(true);
        RoutineApi.getRoutineDaysByCurrentActivatedGrading(
            params.id
        ).then(json => {

            setLoading(false);
            setRoutineDays(json.data);
            if (json.data.length > 0) {
                setRoutine(json.data);
                loadAttendance(json.data);
                json.data.forEach(d => {
                    addWeekDayNumbersToArray(d.dayName);
                    if (d.dayName === day) {

                        setEnableForEntry(true);
                    } else {

                    }
                });
            }


        });
    }

    useEffect(() => {
        var days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
        ];
        var now = new Date();
        var day = days[now.getDay()];
        getChannel();
        getRoom(day);

    }, []);
    useEffect(() => {
    }, [attendanceInfo]);

    const addWeekDayNumbersToArray = weekDayName => {
        var weekDayLower = weekDayName.toString().toLowerCase();

        let oldArray = weekdayNumbers;
        switch (weekDayLower) {
            case "sunday":
                oldArray.push(0);
                setWeekdayNumbers(oldArray);
                break;
            case "monday":
                oldArray.push(1);
                setWeekdayNumbers(oldArray);
                break;
            case "tuesday":
                oldArray.push(2);
                setWeekdayNumbers(oldArray);
                break;
            case "wednesday":
                oldArray.push(3);
                setWeekdayNumbers(oldArray);
                break;
            case "thursday":
                oldArray.push(4);
                setWeekdayNumbers(oldArray);
                break;
            case "friday":
                oldArray.push(5);
                setWeekdayNumbers(oldArray);
                break;
            case "saturday":
                oldArray.push(6);
                setWeekdayNumbers(oldArray);
                break;
        }

        oldArray = weekdayNumbers;
        oldArray.sort((a, b) => a - b);
        setWeekdayNumbers(oldArray);

    };
    const pickAttendanceDate = data => {
        setAttendanceDate(data);
        if (weekdayNumbers.includes(data.getDay())) {
            loadAttendanceWithDate(routine, data);
            setEnableForEntry(true);
        } else {
            setEnableForEntry(false);
        }

    };
    const notifyPresentSuccess = (data) => toast(data?.studentUser?.firstName + "'s Present Recorded", {autoClose: 1000});
    const notifyAbsentSuccess = (data) => toast(data?.studentUser?.firstName + "'s Absent Recorded", {autoClose: 1000});
    const notifyExcusedSuccess = (data) => toast(data?.studentUser?.firstName + "'s Excused Present Recorded", {autoClose: 1000});
    const notifyLatePresentSuccess = (data) => toast(data?.studentUser?.firstName + "'s Late Present Recorded", {autoClose: 1000});


    function loadAttendance(routine) {
        setLoader(true);
        setAttendanceInfo([]);
        let attendanceSearchDto = {
            channelId: params.id,
            routineId: routine[0]?.routine.id,
            attendanceDate:
                ("0" + (attendanceDate.getMonth() + 1)).slice(-2) +
                "/" +
                ("0" + attendanceDate.getDate()).slice(-2) +
                "/" +
                attendanceDate.getFullYear()

        };
        AttendanceApi.attendanceUserListByParams(attendanceSearchDto).then((res) => {
            setAttendanceInfo(res.data);
            setLoader(false);
        })
    }

    function loadAttendanceWithDate(routine, date) {
        setLoader(true);
        setAttendanceInfo([]);
        let attendanceSearchDto = {
            channelId: params.id,
            routineId: routine[0]?.routine.id,
            attendanceDate:
                ("0" + (new Date(date).getMonth() + 1)).slice(-2) +
                "/" +
                ("0" + new Date(date).getDate()).slice(-2) +
                "/" +
                new Date(date).getFullYear()

        };
        AttendanceApi.attendanceUserListByParams(attendanceSearchDto).then((res) => {
            setLoader(false);
            setAttendanceInfo(res.data);
        })
    }

    const handlePresent = (data, index) => {

        let attendanceDTO = {
            channelId: params.id,
            studentUserId: data.studentUser.id,
            attendanceType: "Present",
            routineId: routineDays[0].routine.id,
            attendanceDate:
                ("0" + (attendanceDate.getMonth() + 1)).slice(-2) +
                "/" +
                ("0" + attendanceDate.getDate()).slice(-2) +
                "/" +
                attendanceDate.getFullYear()
        };
        updateAttendanceType(attendanceDTO.attendanceType, index);
        AttendanceApi.registerAttendance(attendanceDTO).then(data => {

            notifyPresentSuccess(data.data);
        });
    };
    const handleLate = (data, index) => {

        let attendanceDTO = {
            channelId: params.id,
            studentUserId: data.studentUser.id,
            attendanceType: "Late",
            routineId: routineDays[0].routine.id,
            attendanceDate:
                ("0" + (attendanceDate.getMonth() + 1)).slice(-2) +
                "/" +
                ("0" + attendanceDate.getDate()).slice(-2) +
                "/" +
                attendanceDate.getFullYear()
        };
        updateAttendanceType(attendanceDTO.attendanceType, index);
        AttendanceApi.registerAttendance(attendanceDTO).then(data => {
            notifyLatePresentSuccess(data.data);
        });
    };
    const handleAbsent = (data, index) => {

        let attendanceDTO = {
            channelId: params.id,
            studentUserId: data.studentUser.id,
            attendanceType: "Absent",
            routineId: routineDays[0].routine.id,
            attendanceDate:
                ("0" + (attendanceDate.getMonth() + 1)).slice(-2) +
                "/" +
                ("0" + attendanceDate.getDate()).slice(-2) +
                "/" +
                attendanceDate.getFullYear()
        };
        updateAttendanceType(attendanceDTO.attendanceType, index);
        AttendanceApi.registerAttendance(attendanceDTO).then(data => {

            notifyAbsentSuccess(data.data);
        });
    };
    const handleCaused = (data, index) => {

        let attendanceDTO = {
            channelId: params.id,
            studentUserId: data.studentUser.id,
            attendanceType: "Absent(Excused)",
            routineId: routineDays[0].routine.id,
            attendanceDate:
                ("0" + (attendanceDate.getMonth() + 1)).slice(-2) +
                "/" +
                ("0" + attendanceDate.getDate()).slice(-2) +
                "/" +
                attendanceDate.getFullYear()
        };
        updateAttendanceType(attendanceDTO.attendanceType, index);

        AttendanceApi.registerAttendance(attendanceDTO).then(data => {

            notifyExcusedSuccess(data.data)
        });
    };
    const updateAttendanceType = (attendanceType, index) => {
        const temp = [...attendanceInfo];
        temp[index].attendanceType = attendanceType;
        setAttendanceInfo(temp);
    }


    const handleReportingRoute = () => {
        navigateTo(
            LinkFactory.attendanceReport + "/" + params.id
        );
    };
    const nextDay = event => {
        if (null == date) {
            var resultDate = new Date();
            setDate(new Date());
            return;
        } else {
            var resultDate = date;
            var nextWeekDay;
            if (weekdayNumbers.indexOf(date.getDay()) + 1 === weekdayNumbers.length)
                nextWeekDay = weekdayNumbers[0];
            else {
                nextWeekDay = weekdayNumbers[weekdayNumbers.indexOf(date.getDay()) + 1];
            }
            if (resultDate.getDay() === nextWeekDay) {
                resultDate.setDate(resultDate.getDate() + 7);
            } else {
                resultDate.setDate(
                    resultDate.getDate() + ((7 + nextWeekDay - resultDate.getDay()) % 7)
                );
            }
        }

        setDate(resultDate);


    };
    const previousDay = event => {
        if (null == date) {
            var resultDate = new Date();
            setDate(new Date());
        } else {
            var resultDate = date;
            var nextWeekDay;
            if (weekdayNumbers.indexOf(date.getDay()) === 0)
                nextWeekDay = weekdayNumbers[weekdayNumbers.length - 1];
            else {
                nextWeekDay = weekdayNumbers[weekdayNumbers.indexOf(date.getDay()) - 1];
            }

            if (resultDate.getDay() === nextWeekDay) {
                resultDate.setDate(resultDate.getDate() - 7);
            } else {

                if (weekdayNumbers.indexOf(date.getDay()) - 1) {
                    nextWeekDay = getPreviousIndex(weekdayNumbers, resultDate.getDay());

                }
                resultDate.setDate(
                    resultDate.getDate() - ((7 + nextWeekDay - resultDate.getDay()) % 7)
                );
            }
        }

        setDate(resultDate);


    };

    function getNextHighestIndex(arr, value) {
        var i = arr.length;
        while (arr[--i] > value) ;
        return ++i;
    }

    function getPreviousIndex(arr, value) {


        for (var i = arr.length; i < 0; i--) {


            if (arr[i] < value) {
                return i;
            }
        }
    }


    return (
        <>
            <CommonLayout headerName={Utils.getChannelName()}>
                <main>
                    <div className="attendance-page card-default">
                        {loader ? <LoaderOverlay/> : null}
                        <ToastContainer/>
                        <div className="title mt-3  d-flex justify-content-between">
                            <div className="card-header p-0 m-0 mr-3 bg-c-light-grey d-flex align-items-center">
                                <a href="#" className="pl-0 back-button">
                                    <i className="fa fa-chevron-left pr-3"> </i> </a>
                                <p className=" ">
                                    Attendance Entry for {channel?.channelName}{" "}
                                    {null != date ? date.toDateString() : null}
                                </p>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="search-sort d-flex align-items-center mr-4 pr-4">
                                    <div className="form-group has-search mb-0 mr-3">
                                        <span className="fa fa-search form-control-feedback"> </span>
                                        <input type="text" className="form-control" placeholder="Search"/>
                                    </div>
                                    <div className="sort-by">
                                        <button className="sort-by-button">
                                            <p>Sort by</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13"
                                                 fill="none">
                                                <path stroke="#475467" stroke-linecap="round"
                                                      stroke-linejoin="round" stroke-width="2"
                                                      d="M3.75 6.5h9.5M1.375 1.75h14.25m-9.5 9.5h4.75"/>
                                            </svg>
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li>1</li>
                                            <li>2</li>
                                            <li>3</li>
                                        </ul>
                                    </div>
                                </div>

                                <button
                                    type="button"
                                    className="btn bg-c-blue-light float-right ml-4"
                                    onClick={() => handleReportingRoute()}
                                >
                                    <svg width="13" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M1.625 0C1.194 0 .781.18.476.498A1.74 1.74 0 0 0 0 1.7v13.6c0 .45.171.883.476 1.202A1.59 1.59 0 0 0 1.625 17h9.75c.431 0 .844-.18 1.149-.498A1.74 1.74 0 0 0 13 15.3V5.1L8.125 0h-6.5Zm0 1.7h5.688v4.25h4.062v9.35h-9.75V1.7ZM3.25 8.5v1.7h6.5V8.5h-6.5Zm0 3.4v1.7h4.063v-1.7H3.25Z"
                                            fill="#4575EE"/>
                                    </svg>
                                    Attendance Report
                                </button>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-3 ">
                                <div className=" ">
                                    <div className="form-group input-default-white mt-4">
                                        <FormTitle>Please select Attendance Date first</FormTitle>
                                        {weekdayNumbers.length > 0 ? (
                                            <DatePicker
                                                selected={attendanceDate}
                                                onChange={value => pickAttendanceDate(value)}
                                                className="form-control btn-block w-100"
                                                isClearable
                                                dateFormat="MM/dd/yyyy"
                                                popperProps={{
                                                    positionFixed: true
                                                }}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="col-8">
                                <div className=" h-100">
                                    {enableForEntry === true ? (
                                            <div className="table-responsive table-light ">
                                                <table
                                                    className="table table-light table-striped table-hover"
                                                    id="dataTable"
                                                    width="100%"
                                                    cellSpacing="0">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col">Student Name</th>
                                                        <th scope="col">Student ID</th>
                                                        <th scope="col" width="10%">Present</th>
                                                        <th scope="col" width="10%">Late</th>
                                                        <th scope="col" width="10%">Absent</th>
                                                        <th scope="col" width="10%">Excused</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {attendanceInfo.length > 0
                                                        ? attendanceInfo.map((attndncInf, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <img
                                                                            alt={"Student Picture"}
                                                                            src={attndncInf?.studentUser?.imageUrl ? imageBase + attndncInf?.studentUser?.imageUrl : "img/Avatar.png"}
                                                                            style={{
                                                                                width: "35px", height: "35px", objectFit: "cover"
                                                                            }}
                                                                            className="rounded-circle p-1"
                                                                        />
                                                                        {attndncInf?.studentUser?.firstName}{" "}
                                                                        {attndncInf?.studentUser?.lastName}
                                                                    </td>
                                                                    <td>
                                                                        {attndncInf?.studentId}
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            title="Present"
                                                                            type="button"
                                                                            onClick={() => handlePresent(attndncInf, index)}
                                                                            className={attndncInf.attendanceType === "Present" ? "btn  btn-present-check" : "btn  btn-present"}>
                                                                            <i className="fa fa-check"/>
                                                                        </button>
                                                                    </td>
                                                                    <td>

                                                                        <button
                                                                            title="Late"
                                                                            type="button"
                                                                            onClick={() => handleLate(attndncInf, index)}
                                                                            className={attndncInf.attendanceType === "Late" ? "btn btn-late-check" : "btn btn-late"}>
                                                                            <i className="fa fa-check"/>{" "}
                                                                        </button>
                                                                    </td>
                                                                    <td>

                                                                        <button
                                                                            title="Absent"
                                                                            type="button"
                                                                            onClick={() => handleAbsent(attndncInf, index)}
                                                                            className={attndncInf.attendanceType === "Absent" ? "btn btn-absent-check" : "btn btn-absent"}>
                                                                            <i className="fa fa-check"/>
                                                                        </button>
                                                                    </td>
                                                                    <td className="text-center">

                                                                        <button
                                                                            title="Absent(Excused)"
                                                                            type="button"
                                                                            onClick={() => handleCaused(attndncInf, index)}
                                                                            className={attndncInf.attendanceType === "Absent(Excused)" ? "btn btn-excused-check" : "btn btn-excused"}>
                                                                            <i className="fa fa-check"/>{" "}
                                                                        </button>
                                                                    </td>

                                                                </tr>
                                                            );
                                                        })
                                                        : ""}
                                                    </tbody>
                                                </table>
                                                <div className="page-navigation">
                                                    <ReactPaginate
                                                        previousLabel={"Previous"}
                                                        nextLabel={"Next"}
                                                        pageCount={10}
                                                        onPageChange={() => {
                                                        }}
                                                        containerClassName={"paginationBttns"}
                                                        previousLinkClassName={"previousBttn"}
                                                        nextLinkClassName={"nextBttn"}
                                                        disabledClassName={"paginationDisabled"}
                                                        activeClassName={"paginationActive"}
                                                    />
                                                </div>
                                            </div>
                                        ) :
                                        <div className="no-schedule">
                                            {routineDays?.length === 0 ? <>
                                                <Alert variant="danger">
                                                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                                                    <h6>
                                                        No Routine found, please configure routine
                                                    </h6>
                                                </Alert>
                                            </> : <div className="d-flex flex-column justify-content-center align-items-center">
                                                <img src="img/no schedule.png" alt="No class scheduled"/>
                                                <p>There is no class scheduled for today</p>
                                            </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default AttendanceEntry;
