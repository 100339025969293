import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveCompanyPartner = companyPartner => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/company-partner/",
        companyPartner
    );
};

export const updateCompanyPartner = companyPartner => {
    return AxiosAgent.requests.put(
        API_MAIN_ROOT + "/company-partner/",
        companyPartner
    );
};
export const getCompanyPartnerById = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/company-partner/" + id);
};
export const deleteCompanyPartnerById = id => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/company-partner/" + id);
};
export const getAll = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/company-partners");
};
