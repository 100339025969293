import React, {useEffect, useState} from "react";
import * as ChannelApi from "../../api/ChannelApi";
import * as GradingBreakdownApi from "../../api/GradingBreakdownApi";
import FormTitle from "../Typhography/formTitle";
import CommonLayout from "../layout/commonLayout";
import Select from "react-select";
import AssignmentForm from "./AssignmentForm";
import McqNew from "../Mcq/McqNew";
import {isEmpty} from "lodash";
import {useParams} from "react-router-dom";

const initialExamTypes = [
    {value: "exam", label: "Taken"},
    {value: "assignment", label: "Regular"},
    {value: "mcq", label: "MCQ / True-False"}
];

function AssignmentNewContainer(props) {
    const params = useParams();
    const [channel, setChannel] = useState({});
    const [gradingBreakdowns, setGradingBreakdowns] = useState([]);
    const [selectedGradingBreakdown, setSelectedGradingBreakdown] = useState({});
    const [selectedAssessmentType, setSelectedAssessmentType] = useState(null);

    useEffect(() => {
        ChannelApi.getChannelById(params.id)
            .then(json => {
                setChannel(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
        GradingBreakdownApi.findGradingBreakdownByChannelId(
            params.id
        ).then(res => {
            setGradingBreakdowns(res.data[0].gradingBreakdownList);
        });
    }, [params.id]);

    const handleExamTypeSelection = option => {
        setSelectedAssessmentType(option.value);
    };
    const handleEvaluationSelection = option => {
        setSelectedGradingBreakdown(option);
    };

    return (
        <>
            <CommonLayout headerName={"Assignment"}>
                <main>
                    <div className="card-default create-assignment">
                        <div className="assignment-heading  ">
                            <div className="assignment-header d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <div className=" mr-3 bg-c-light-grey">
                                        <a href="#" className=" back-button">
                                            <i className="fa fa-chevron-left"> </i> </a>
                                    </div>
                                    <p>
                                        Create new Assignment for :{" "}
                                        {channel.id !== null ? channel.channelName : ""}
                                    </p>
                                </div>
                                <button
                                    title="Edit"
                                    type="button"
                                    className="btn  bg-c-blue-light mr-1">
                                <span>
                                    <svg width="18" height="19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.22 7.97a.75.75 0 1 1 1.06 1.06l-3 3a.75.75 0 0 1-1.06 0l-3-3a.75.75 0 1 1 1.06-1.06L8 9.69V1.5a.75.75 0 0 1 1.5 0v8.19l1.72-1.72Z" fill="#4575EE"/><path d="M17.5 9.5a.75.75 0 1 0-1.5 0 7.25 7.25 0 1 1-14.5 0 .75.75 0 0 0-1.5 0 8.75 8.75 0 0 0 17.5 0Z" fill="#4575EE"/></svg>
                                    Import from archive question
                                </span>
                                </button>
                            </div>
                        </div>

                        <div className=" ">
                            <div className="card-body">

                                <div className="left-col">
                                    <div className=" ">
                                        <div className="form-group">
                                            <FormTitle>Evaluation</FormTitle>

                                            <Select
                                                placeholder="Select Evaluation"
                                                onChange={handleEvaluationSelection}
                                                getOptionLabel={option => option.evaluationName}
                                                getOptionValue={option => option}
                                                options={gradingBreakdowns || []}
                                            />
                                        </div>
                                    </div>
                                    <div className=" ">
                                        <div className="form-group">
                                            <FormTitle>Assessment Type</FormTitle>
                                            <Select
                                                placeholder="Select Assessment Type"
                                                onChange={handleExamTypeSelection}
                                                options={initialExamTypes || []}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {selectedAssessmentType && selectedAssessmentType === "exam" ? (
                                    <AssignmentForm
                                        channel={channel}
                                        selectedGradingBreakdown={selectedGradingBreakdown}
                                        selectedAssessmentType={selectedAssessmentType}
                                    />
                                ) : (
                                    ""
                                )}
                                {selectedAssessmentType &&
                                selectedAssessmentType === "assignment" ? (
                                    <AssignmentForm
                                        channel={channel}
                                        selectedGradingBreakdown={selectedGradingBreakdown}
                                        selectedAssessmentType={selectedAssessmentType}
                                    />
                                ) : (
                                    ""
                                )}

                                {selectedAssessmentType &&
                                selectedAssessmentType === "mcq" &&
                                !isEmpty(selectedGradingBreakdown) ? (
                                    <McqNew
                                        selectedGradingBreakdown={selectedGradingBreakdown}
                                        channel={channel}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default AssignmentNewContainer;
