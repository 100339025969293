import React, {useEffect, useState} from "react";
import * as InstituteApi from "../../api/InstituteApi";
import {Field, Form, Formik} from "formik";

import CommonLayout from "../layout/commonLayout";
import TextFieldComponent from "../common/TextFieldComponent";
import {useParams} from "react-router-dom";

function InstituteEdit(props) {
  const params = useParams();
  const [institute, setInstitute] = useState({
    name: "",
    address: "",
    code: "",
    phone: "",
    website: "",
    contactEmail: "",
    id: ""
  });
  useEffect(() => {
    InstituteApi.getInstituteById(params.id)
      .then(json => {
        console.log(json);
        setInstitute(json.data);
      })
      .catch(errors => {
        console.log(errors);
      });
  }, []);

  const handleSubmit = data => {
    console.log(data);
    InstituteApi.updateInstitute(data);
  };

  const TextField = ({ field, form: { touched, errors } }) => {
    return (
      <div className="input-group mb-3">
        <input
          {...field}
          type="text"
          className="form-control"
          // placeholder="industry"
          aria-label="industry"
          aria-describedby="basic-addon1"
        />
      </div>
    );
  };
  return (
    <>
      <CommonLayout>
        <main>
          <div>
            <h3 className="mt-4">Institute Edit</h3>
            <br />
            <div className="card mb-4">
              <div className="card-header">
                <i className="fas fa-pencil-square-o mr-2" />
                Edit here
              </div>
              <div className="card-body">
                <Formik
                  initialValues={institute}
                  enableReinitialize={true}
                  onSubmit={handleSubmit}
                >
                  {props => (
                    <Form>
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellSpacing="0"
                        >
                          <thead className="bg-light">
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Address</th>
                              <th scope="col">Post code</th>
                              <th scope="col">Phone</th>
                              <th scope="col">Website</th>
                              <th scope="col">Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Field
                                  type="text"
                                  name="name"
                                  placeholder="Name"
                                  component={TextFieldComponent}
                                />
                              </td>
                              <td>
                                <Field
                                  type="text"
                                  name="address"
                                  placeholder="address"
                                  component={TextFieldComponent}
                                />
                              </td>
                              <td>
                                <Field
                                  type="text"
                                  name="code"
                                  placeholder="code"
                                  component={TextFieldComponent}
                                />
                              </td>
                              <td>
                                <Field
                                  type="text"
                                  name="phone"
                                  placeholder="phone"
                                  component={TextFieldComponent}
                                />
                              </td>
                              <td>
                                <Field
                                  type="text"
                                  name="website"
                                  placeholder="website"
                                  component={TextFieldComponent}
                                />
                              </td>
                              <td>
                                <Field
                                  type="text"
                                  name="contactEmail"
                                  placeholder="contact mail"
                                  component={TextFieldComponent}
                                />
                              </td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                        <button
                          className="btn btn-lg btn-primary"
                          type="submit"
                        >
                          Update
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </main>
      </CommonLayout>
    </>
  );
}

export default InstituteEdit;
