import React, {useCallback, useEffect, useRef, useState} from "react";
import * as EmployeeRoleApi from "../../api/EmployeeRoleApi";
import {Field, Form, Formik} from "formik";
import TextFieldComponent from "../common/TextFieldComponent";
import SelectDropdown from "../common/SelectDropwodn";
import * as EmployeeApi from "../../api/EmployeeApi";

import * as LinkFactory from "../../util/LinkFactory";
import PasswordFieldComponent from "../common/PasswordFieldComponent";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";

function EmployeeRegistration(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [employee, setEmployee] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        employeeRole: "",
        email: "",
        password: "",
        id: ""
    });
    const [employeeRoles, setEmployeeRoles] = useState([]);
    const [employeeRole, setEmployeeRole] = useState([]);
    const [files, setFiles] = useState([]);
    const handleDrop = useCallback(async acceptedFiles => {
        setFiles(prevFiles => [...prevFiles].concat(acceptedFiles));
    }, []);

    const notifyValidation = () => toast.success("All fields required", {
        position: toast.POSITION.TOP_CENTER
    });

    useEffect(() => {
        EmployeeRoleApi.getAll().then(json => {
            setEmployeeRoles(json.data);
        });
    }, []);
    const handleSubmit = data => {
        if(
            (null == data.firstName || '' === data.firstName) ||
            (null == data.lastName || '' === data.lastName) ||
            (null == data.email || '' === data.email) ||
            (null == data.password || '' === data.password) ||
            (Object.keys(data.employeeRole).length ===0)
        ) {
            notifyValidation();
        } else {
            EmployeeApi.registerEmployee(data).then(data => {
                if (data.response.status === 201 || data.response.status === 200)
                    Swal.fire(data.email, "Employee registration complete", "success");
                else
                    Swal.fire(data.email, "Registration Failed", "error");
                navigateTo(LinkFactory.login);

            });
        }
    };
    const [showPassword, setShowPassword] = useState(false);

    const passwordInputRef = useRef(null);

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    return (
        <>
            <div className="employee-registration bg-default d-flex">
                <div id="reg_layout">
                    <div id="reg_content" >
                        <main>
                            <div id="registrations">
                                <div id="employeeReg">
                                    <div className="container">
                                        <div className="brand">
                                            <h2>{process.env.REACT_APP_ENV_NAME}</h2>
                                            <img src="img/Edooket.png" alt={"Edoocate logo"}/>
                                            <h3>Employee Registration</h3>
                                        </div>
                                        <div className="card-default">
                                            <div className="input-reg">

                                                <Formik
                                                    initialValues={employee}
                                                    enableReinitialize={true}
                                                    onSubmit={handleSubmit}
                                                >
                                                    {props => (
                                                        <Form>
                                                            <div className="block-group">
                                                                <div className="block-50">
                                                                    <label
                                                                        htmlFor="firstName"
                                                                        className="form-label"
                                                                    >
                                                                        First Name
                                                                    </label>
                                                                    <Field
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="firstName"
                                                                        placeholder="   "
                                                                        component={TextFieldComponent}
                                                                    />
                                                                </div>
                                                                {/*<div className="block-50">
                                                                    <label className="form-label">
                                                                        Middle Name
                                                                    </label>
                                                                    <Field
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="middleName"
                                                                        placeholder="middleName"
                                                                        component={TextFieldComponent}
                                                                    />
                                                                </div>*/}
                                                                <div className="block-50">
                                                                    <label className="form-label">Last Name</label>
                                                                    <Field
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="lastName"
                                                                        placeholder=" "
                                                                        component={TextFieldComponent}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="block-group">
                                                                <div className="block-50">
                                                                    <label className="form-label">Contact No</label>
                                                                    <Field
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="phoneNo"
                                                                        autoComplete="off"
                                                                        placeholder=" "
                                                                    />
                                                                </div>
                                                                <div className="block-50">
                                                                    <label className="form-label">Email</label>
                                                                    <Field
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="email"
                                                                        autoComplete="email"
                                                                        placeholder=" "
                                                                        component={TextFieldComponent}
                                                                    />
                                                                </div>

                                                            </div>
                                                            <div className="block-group">
                                                                <div className="block-50">
                                                                    <label className="form-label">
                                                                        Employee Role
                                                                    </label>
                                                                    <Field
                                                                        type="text"
                                                                        id="employeeRole"
                                                                        name="employeeRole"
                                                                        placeholder="Select Role"
                                                                        fieldName={`employeeRole`}
                                                                        options={employeeRoles || []}
                                                                        component={SelectDropdown}
                                                                    />
                                                                    {/*<Field*/}
                                                                    {/*    type="text"*/}
                                                                    {/*    className="form-control"*/}
                                                                    {/*    fieldName={"employeeRole"}*/}
                                                                    {/*    handleChangeSection={handleRoleSelection}*/}
                                                                    {/*    options={employeeRoles || []}*/}
                                                                    {/*    component={SelectDropdown}*/}
                                                                    {/*/>*/}
                                                                </div>
                                                                <div className="block-50">
                                                                    <label className="form-label">Registration ID</label>
                                                                    <Field
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="registrationID"
                                                                        autoComplete="off"
                                                                        placeholder=" "
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="block-group">
                                                                <div className="block-50 password-box">
                                                                    <label className="form-label">Password</label>
                                                                    <Field
                                                                        type={showPassword ? "text" : "password"}
                                                                        className="form-control"
                                                                        name="password"
                                                                        autoComplete="new-password"
                                                                        placeholder=" "
                                                                        // component={PasswordFieldComponent}
                                                                        ref={passwordInputRef}
                                                                    />
                                                                    <svg
                                                                        className="password-icon"
                                                                        width="13" height="9" fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        onClick={togglePasswordVisibility}
                                                                    >
                                                                        <path
                                                                            d="M6.5 2.7c.47 0 .921.19 1.254.527.332.338.519.796.519 1.273 0 .477-.187.935-.52 1.273A1.76 1.76 0 0 1 6.5 6.3c-.47 0-.921-.19-1.254-.527A1.814 1.814 0 0 1 4.727 4.5c0-.477.187-.935.52-1.273A1.76 1.76 0 0 1 6.5 2.7Zm0-2.7c2.955 0 5.478 1.866 6.5 4.5C11.978 7.134 9.455 9 6.5 9S1.022 7.134 0 4.5C1.022 1.866 3.545 0 6.5 0ZM1.288 4.5a5.862 5.862 0 0 0 2.14 2.408A5.738 5.738 0 0 0 6.5 7.8c1.086 0 2.15-.31 3.071-.893a5.862 5.862 0 0 0 2.14-2.408 5.862 5.862 0 0 0-2.14-2.408A5.738 5.738 0 0 0 6.5 1.199c-1.086 0-2.15.31-3.071.893A5.862 5.862 0 0 0 1.289 4.5Z"
                                                                            fill="#878787"/>
                                                                    </svg>
                                                                </div>
                                                                <div className="block-50 password-box">
                                                                    <label className="form-label">Confirm Password</label>
                                                                    <Field
                                                                        type={showPassword ? "text" : "password"}
                                                                        className="form-control"
                                                                        name="confirmPassword"
                                                                        autoComplete="off"
                                                                        placeholder=" "
                                                                        // component={PasswordFieldComponent}

                                                                    />
                                                                    <svg
                                                                        className="password-icon"
                                                                        width="13" height="9" fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        onClick={togglePasswordVisibility}
                                                                    >
                                                                        <path
                                                                            d="M6.5 2.7c.47 0 .921.19 1.254.527.332.338.519.796.519 1.273 0 .477-.187.935-.52 1.273A1.76 1.76 0 0 1 6.5 6.3c-.47 0-.921-.19-1.254-.527A1.814 1.814 0 0 1 4.727 4.5c0-.477.187-.935.52-1.273A1.76 1.76 0 0 1 6.5 2.7Zm0-2.7c2.955 0 5.478 1.866 6.5 4.5C11.978 7.134 9.455 9 6.5 9S1.022 7.134 0 4.5C1.022 1.866 3.545 0 6.5 0ZM1.288 4.5a5.862 5.862 0 0 0 2.14 2.408A5.738 5.738 0 0 0 6.5 7.8c1.086 0 2.15-.31 3.071-.893a5.862 5.862 0 0 0 2.14-2.408 5.862 5.862 0 0 0-2.14-2.408A5.738 5.738 0 0 0 6.5 1.199c-1.086 0-2.15.31-3.071.893A5.862 5.862 0 0 0 1.289 4.5Z"
                                                                            fill="#878787"/>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            <div className="block-group">
                                                                <div className="block-50 m-auto">
                                                                    <button type="submit" className="btn-deep-blue-lg box-btn">
                                                                        <p>Register</p>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                        </div>
                                        <div className="footer-instruction">
                                            <p>
                                                Already Registered?
                                                <div>
                                                    <a href=" " className="back-button p-0">
                                                        <i
                                                            className="fa fa-arrow-left "> </i>
                                                        Back to Log in
                                                    </a>
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                        {/*<FooterComponent/>*/}
                    </div>
                </div>

                <div className="login-image">
                    <div className="gradient-background">
                        <div className="h-100 flex-column d-flex justify-content-center align-item-center">
                            <div className="d-flex  justify-content-center">
                                <p>The first ever <span>smart school solution </span> to automate your whole institution</p>
                            </div>

                            <div className="image-container">
                                <img src="img/login-page_Sun.png" alt="Sun" className="sun"/>
                                <img src="img/login-page_Clouds.png" alt="Clouds" className="clouds" />
                                <img src="img/login-page_Birds.png"  alt="Flying Birds" className="birds"/>
                                <img src="img/login-page_man-hovering-with-books.png" alt="Person with Books" className="person"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default EmployeeRegistration;
