import React from "react";
import CachingImage from "../../Image/cachingImage";

const SingleAttachmentComponent = props => {
    const url = props.imageurl;
    return (<div className="w-100">
        <div className="grid-fixed-1">
            {(url?.includes(".jpeg") || url?.includes(".jpg") || url?.includes(".png") || url?.includes(".webp")) ? <CachingImage
                    src={process.env.REACT_APP_FILES_URL + url}
                    alt="Post Image"
                /> :
                <a title="Download"
                   className="btn btn-sm btn-success radius-15 mx-2 my-1"
                   target="_blank"
                   href={process.env.REACT_APP_FILES_URL + url}>
                    <i className="fa fa-file-download"/>{" "}
                </a>}
        </div>
    </div>);
};

export default SingleAttachmentComponent;
