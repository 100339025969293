import React from "react";
import CachingImage from "../../Image/cachingImage";

const FourAttachmentComponent = props => {
    const urls = props.imageurl;
    return (
        <div className="w-100">
            <div className="grid-fixed-4">
                {urls.length > 0
                    ? urls.map((attachment, index) => {
                        return (
                            <>
                                {(attachment?.includes(".jpeg") || attachment?.includes(".jpg") || attachment?.includes(".png") || attachment?.includes(".webp")) ? <CachingImage
                                        src={process.env.REACT_APP_FILES_URL + attachment}
                                        alt="Post Image"
                                    /> :
                                    <a title="Download"
                                       className="btn btn-sm btn-success radius-15 mx-2 my-1"
                                       target="_blank"
                                       href={process.env.REACT_APP_FILES_URL + attachment}>
                                        <i className="fa fa-file-download"/>{" "}
                                    </a>}
                            </>
                        );
                    })
                    : null}
            </div>
        </div>
    );
};

export default FourAttachmentComponent;
