import React, {useEffect, useState} from "react";
import * as ClassSetupApi from "../../api/ClassSetupApi";
import * as EmployeeApi from "../../api/EmployeeApi";
import * as ParentsApi from "../../api/ParentsApi";
import * as StudentApi from "../../api/StudentApi";
import {Field, Form, Formik} from "formik";
import DatePicker from "react-datepicker";
import * as ChannelApi from "../../api/ChannelApi";
import MUIDataTable from "mui-datatables";
import Loader from "../common/Loader";
import {TextArea} from "semantic-ui-react";
import CommonLayout from "../layout/commonLayout";
import * as EventsApi from "../../api/EventsApi";

import * as LinkFactory from "../../util/LinkFactory";
import LoaderOverlay from "../common/LoaderOverlay";
import * as Utils from "../../util/Utils";
import {useNavigate, useParams} from "react-router-dom";
import {FormikTextField} from "../FormikTextField";

function EventsNew(props) {

    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }

    const params = useParams();
    const [events, setEvents] = useState({
        eventName: "",
        eventEndTime: new Date(),
        eventStartTime: new Date(),
        userList: "",
        id: "",
        userIds: ""
    });
    const {timeZone} = Intl.DateTimeFormat();
    const [channel, setChannel] = useState({});
    const [classSetup, setClassSetup] = useState(0);
    const [memberList, setMemberList] = useState([]);
    const [parentsList, setParentsList] = useState([]);
    const [moderatorList, setModeratorList] = useState([]);
    const [mainOption, setMainOption] = useState("");
    const [saving, setSaving] = useState(false);
    const [selectedModerators, setSelectedModerators] = React.useState([]);
    const [selectedMembers, setSelectedMembers] = React.useState([]);
    const [createEventLoader, setCreateEventLoader] = useState(false);
    const [memberLoader, setMemberLoader] = useState(true);
    const [moderatorLoader, setModeratorLoader] = useState(true);
    useEffect(() => {
        ChannelApi.getChannelById(params.id)
            .then(json => {
                setChannel(json.data);
                setMemberLoader(true);
                setModeratorLoader(true);
                ChannelApi.getChannelMemberListByChannelId(json.data.id).then(json => {
                    setMemberList(json.data);
                    setMemberLoader(false);
                });
                ChannelApi.getChannelModeratorListByChannelId(json.data.id).then(
                    json => {
                        setModeratorList(json.data);
                        setModeratorLoader(false)
                    }
                );
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);

    const handleChangeSection = (fieldName, value) => {
        console.log(value);
        setClassSetup(value.id);
    };
    useEffect(() => {
        if (classSetup !== 0)
            ClassSetupApi.getStudentListByClassSetupId(classSetup).then(json => {
                console.log(json.data);
                setMemberList(json.data);
                console.log(memberList);
            });
    }, [classSetup]);

    useEffect(() => {
        console.log(mainOption);
        if (mainOption === "all_parents") {
            handleParentsOnlySelection();
        } else if (mainOption === "all_teachers") {
            handleEmployeeOnlySelection();
        } else if (mainOption === "all_student") {
            handleStudentOnlySelection();
        }
    }, [mainOption]);


    const handleMainOptions = event => {
        setMainOption(event.target.value);
    };
    const handleToAddInMainSelection = data => {
        setMainOption(data);
    };
    const initAll = () => {
        setModeratorList([]);
        setMemberList([]);
        setParentsList([]);
    };

    const handleEmployeeOnlySelection = () => {
        initAll();
        EmployeeApi.getAll()
            .then(json => {
                console.log(json);
                setModeratorList(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    };
    const handleStudentOnlySelection = () => {
        initAll();
        console.log("handleStudentOnlySelection");
        StudentApi.getAll()
            .then(json => {
                console.log(json);
                setMemberList(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    };
    const handleParentsOnlySelection = () => {
        initAll();
        ParentsApi.getAll()
            .then(json => {
                console.log(json);
                setParentsList(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    const handleSubmit = data => {
        setCreateEventLoader(true);
        setSaving(true);
        var memberIds = "";
        var moderatorIds = "";
        selectedMembers.forEach(value => {
            if (memberIds.length > 0) memberIds = memberIds + ",";
            memberIds = memberIds + memberList[value].id.toString();
        })

        selectedModerators.forEach(value => {
            if (moderatorIds.length > 0) moderatorIds = moderatorIds + ",";
            moderatorIds = moderatorIds + moderatorList[value].id.toString();
        })
        const eventsSave = {
            eventName: data.eventName,
            eventVenue: data.eventVenue,
            eventDesc: data.eventDesc,
            eventStartTime: data.eventStartTime,
            eventEndTime: data.eventEndTime,
            id: "",
            users: memberIds,
            moderators: moderatorIds,
            eventDate: data.eventStartTime,
            channelId: channel.id
        };
        const timezoneOffset = (new Date()).getTimezoneOffset();
        EventsApi.saveEvents(eventsSave).then(data => {
            setSaving(false);
            setCreateEventLoader(false);
            navigateTo(LinkFactory.channelBoard + "/" + params.id);
        });
    };
    const DatePickerComponent = ({
                                     field,
                                     form: {touched, errors, setFieldValue}
                                 }) => {
        return (
            <div className="mr-2">
                <DatePicker
                    showTimeInput2
                    selected={Date.parse(field.value)}
                    onChange={value => setFieldValue(field.name, value)}
                    className="form-control btn-block w-100 overflow-auto "
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy HH:mm"
                    showTimeInput
                />
            </div>
        );
    };

    const TextField = ({field, form: {touched, errors}}) => {
        return (
            <div className="input-group mb-3">
                <input
                    {...field}
                    type="text"
                    className="form-control"

                    aria-describedby="basic-addon1"
                />
            </div>
        );
    };
    const TextAreaComponent = ({field, form: {touched, errors}}) => {
        return (
            <div className="input-group mb-3">
                <TextArea
                    {...field}
                    type="text"
                    cols="50"
                    className="form-control"
                    placeholder="Write the event description here..."
                    aria-label="industry"
                    aria-describedby="basic-addon1"
                />
            </div>
        );
    };

    const column = [
        {
            name: "firstName",
            label: "First name",
            options: {
                filter: true,
                sort: true,
                setCellHeaderProps: value => ({
                    style: {fontWeight: "bold", color: "#008080"}
                })
            }
        },
        {
            name: "lastName",
            label: "Last name",
            options: {
                filter: true,
                sort: true,
                setCellHeaderProps: value => ({
                    style: {fontWeight: "bold", color: "#008080"}
                })
            }
        }
    ];

    const optionsModerator = {
        filter: true,
        filterType: "checkbox",
        responsive: "standard",
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 20, 50, 100],
        onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
            setSelectedModerators(rowsSelected);
        }
    };
    const optionsMembers = {
        filter: true,
        filterType: "checkbox",
        responsive: "standard",
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 20, 50, 100],
        onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
            setSelectedMembers(rowsSelected);
        }
    };
    const handleRowClick = (rowData, rowState) => {
        console.log(rowData, rowState);
    }

    return (
        <>
            <CommonLayout headerName={Utils.getChannelName() + " : New Event "}>
                <main>
                    <div class="create-event card-default">
                        <div>
                            <div>
                                <h3 className="mt-4 mb-5 loader ">
                                    {createEventLoader ? <LoaderOverlay/> : null}
                                </h3>
                                <div>
                                    <div>
                                        <Formik
                                            initialValues={events}
                                            enableReinitialize={true}
                                            onSubmit={handleSubmit}>
                                            {props => (
                                                <Form>
                                                    {/*<h5>Event Details</h5>*/}
                                                    <div className="title ">
                                                        <div className="mb-0 d-flex align-items-center">
                                                            <div className="card-header p-0 m-0 mr-1 bg-c-light-grey">
                                                                <a href="#" className="pl-0 back-button">
                                                                    <i className="fa fa-chevron-left"> </i> </a>
                                                            </div>
                                                            <svg width="18" height="17" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M9.429 13.421c0 .304.034.6.077.895H1.714c-.454 0-.89-.189-1.212-.524A1.83 1.83 0 0 1 0 12.526V1.79C0 .796.763 0 1.714 0h5.143l1.714 1.79h6.858c.454 0 .89.188 1.212.524.321.335.502.79.502 1.265v5.198a5.183 5.183 0 0 0-1.714-.644V3.58H1.714v8.947h7.792c-.043.296-.077.59-.077.895Zm6-.895V9.842h-1.715v2.684h-2.571v1.79h2.571V17h1.715v-2.684H18v-1.79h-2.571Z"
                                                                    fill="#475467"/>
                                                            </svg>
                                                            <p>Create New Event</p>
                                                        </div>
                                                    </div>
                                                    <div className="event-names">
                                                        <p>Event Details</p>
                                                        <div className="form-row">
                                                            <div className="col-3">
                                                                <div className="form-group input-default-white">
                                                                    <label>Event Name</label>
                                                                    <Field
                                                                        type="text"
                                                                        name="eventName"
                                                                        placeholder="Type event name"
                                                                        component={TextField}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className="form-group input-default-white">
                                                                    <label>Event Venue</label>
                                                                    <Field
                                                                        type="text"
                                                                        name="eventVenue"
                                                                        placeholder="Event venue"
                                                                        component={TextField}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className="form-group input-default-white">
                                                                    <label>Event Start time</label>
                                                                    <Field
                                                                        name="eventStartTime"
                                                                        placeholder="Event Start Date"
                                                                        component={DatePickerComponent}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className="form-group input-default-white">
                                                                    <label>Event End time</label>
                                                                    <Field
                                                                        name="eventEndTime"
                                                                        placeholder="Event End Date"
                                                                        component={DatePickerComponent}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="event-description">
                                                                <div className="form-group input-default-white">
                                                                    {/*<label>Event Description</label>*/}
                                                                    <Field
                                                                        type="text"
                                                                        name="eventDesc"
                                                                        placeholder="Write your answer here..."
                                                                        component={TextAreaComponent}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    {moderatorList.length > 0 ? (
                                                            <MUIDataTable

                                                                className="  table-light "
                                                                title={"Moderator List"}
                                                                data={moderatorList}
                                                                columns={column}
                                                                options={optionsModerator}
                                                            />
                                                        ) :
                                                        <td colSpan="12" className="text-center">
                                                            {moderatorLoader ? <Loader/> : <h5>No moderator found</h5>}
                                                        </td>}
                                                    <br/>
                                                    <br/>
                                                    {memberList.length > 0 ? (
                                                        <MUIDataTable
                                                            className="  table-light"
                                                            title={"Student List"}
                                                            data={memberList}
                                                            columns={column}
                                                            options={optionsMembers}
                                                        />
                                                    ) : (
                                                        <td colSpan="12" className="text-center">
                                                            {memberLoader ? <Loader/> : <h5>No member found</h5>}
                                                        </td>
                                                    )}

                                                    {/*{parentsList.length > 0 ? <MUIDataTable*/}
                                                    {/*    title={'Parent List'}*/}
                                                    {/*    data={parentsList}*/}
                                                    {/*    columns={column}*/}
                                                    {/*    options={options}*/}
                                                    {/*/> : ""}*/}
                                                    <br/>
                                                    <div className=" text-right pb-4 pr-4">
                                                        <button
                                                            type="submit"
                                                            disabled={saving}
                                                            className="btn btn-dark radius-25 px-4 mt-4">
                                                            <i className="fa fa-chevron-circle-right mr-2"/>
                                                            Submit
                                                        </button>
                                                    </div>

                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default EventsNew;
