import React, {useEffect, useState} from "react";
import * as ChannelApi from "../../api/ChannelApi";
import CommonLayout from "../layout/commonLayout";
import * as Utils from "../../util/Utils";
import {useParams} from "react-router-dom";
import ReactPaginate from "react-paginate";

function ChannelApproval(props) {
    const params = useParams();
    const [postList, setPostList] = useState([]);
    const [postStatus, setPostStatus] = useState("");
    const [postId, setPostId] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        loadPosts();
    }, [params.id]);

    const changePage = ({selected}) => {
        console.log(selected);
        setPageNumber(selected);
        loadPosts();
    }

    const loadPosts = () => {
        ChannelApi.getChannelPostListWithoutAttachment(
            {
                channelId: params.id,
                page: pageNumber
            }
        ).then(
            json => {
                setPostList(json.data.content);
                setPageCount(json?.data?.totalPages);
            }
        );
    };

    const handlePostStatusChangeRadioButton = event => {
        setPostStatus(event.target.value);
    };
    const handleChangeStatus = () => {
        console.log("clicked");
        var channelPostApprovalDTO = {
            postId: postId,
            approveStatus: postStatus
        };
        ChannelApi.channelPostApproval(channelPostApprovalDTO).then(data => {
            console.log(data);
            loadPosts();
        });
    };

    function handleDelete(data) {
        ChannelApi.deletePost(data.id).then(() => {
            console.log("delete");
            loadPosts();
        })

    }

    return (
        <>
            <CommonLayout headerName={Utils.getChannelName()}>
                <main>
                    <div className="card-default post-management ">
                        <div className="title-header ">
                            <div className="mb-0 d-flex align-items-center">
                                <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                    <a href="#" className="pl-0 back-button">
                                        <i className="fa fa-chevron-left"> </i> </a>
                                </div>
                                <p>Post Management</p>
                            </div>
                            <div className="sort-by">
                                <button className="sort-by-button">
                                    <p>Sort by</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13"
                                         fill="none">
                                        <path stroke="#475467" stroke-linecap="round"
                                              stroke-linejoin="round" stroke-width="2"
                                              d="M3.75 6.5h9.5M1.375 1.75h14.25m-9.5 9.5h4.75"/>
                                    </svg>
                                </button>
                                <ul className="dropdown-menu">
                                    <li>1</li>
                                    <li>2</li>
                                    <li>3</li>
                                </ul>
                            </div>

                        </div>


                        <div className="mb-4">
                            <div>
                                <div className="table-responsive table-light ">
                                    <table
                                        className="table table-light table-hover table-striped"
                                        id="dataTable"
                                        width="100%"
                                        cellSpacing="0">
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Post</th>
                                            <th>User</th>
                                                <th>Post Date</th>
                                                <th>Current Status</th>
                                                <th className=" " width="15%">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {postList.length > 0
                                            ? postList.map((data, index) => {
                                                return (

                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{data.message}</td>
                                                            <td>{data.createBy.firstName}</td>
                                                            <td>{data.createDate}</td>
                                                            <td>{data.approveStatus}</td>
                                                            {/*<td className="text-right">*/}
                                                            {/*    <button*/}
                                                            {/*        type="button"*/}
                                                            {/*        className="btn btn-sm btn-warning radius-15 text-dark"*/}
                                                            {/*        data-toggle="modal"*/}
                                                            {/*        data-target="#changeStatusModal"*/}
                                                            {/*        onClick={() => setPostId(data.id)}*/}
                                                            {/*        title="Change Status">*/}
                                                            {/*        <i className="fa fa-random"/>*/}
                                                            {/*    </button>*/}
                                                            {/*    &nbsp;*/}
                                                            {/*    <button*/}
                                                            {/*        type="button"*/}
                                                            {/*        className="btn btn-sm btn-danger radius-15 text-light"*/}
                                                            {/*        onClick={() => handleDelete(data)}>*/}
                                                            {/*        <i className="fa fa-trash"/>*/}
                                                            {/*    </button>*/}
                                                            {/*</td>*/}
                                                            <td>
                                                                <button
                                                                    title="View Submits"
                                                                    type="button"
                                                                    className="btn-md btn-secondary radius-25 border-grey">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="17"
                                                                         height="14" fill="none">
                                                                        <path fill="#475467"
                                                                              d="M6.5 1.333a5.297 5.297 0 0 1 3.074.987c.922.649 1.663 1.578 2.138 2.68-.975 2.247-2.967 3.667-5.212 3.667-2.245 0-4.237-1.42-5.212-3.667.475-1.102 1.216-2.031 2.138-2.68A5.297 5.297 0 0 1 6.5 1.333ZM6.5 0C3.545 0 1.022 2.073 0 5c1.022 2.927 3.545 5 6.5 5s5.478-2.073 6.5-5c-1.022-2.927-3.545-5-6.5-5Zm0 3.333c.392 0 .768.176 1.045.489.277.312.432.736.432 1.178 0 .442-.155.866-.432 1.179a1.398 1.398 0 0 1-1.045.488c-.392 0-.768-.176-1.045-.488A1.781 1.781 0 0 1 5.023 5c0-.442.155-.866.432-1.178A1.398 1.398 0 0 1 6.5 3.333ZM6.5 2C5.035 2 3.84 3.347 3.84 5S5.036 8 6.5 8s2.66-1.347 2.66-3S7.964 2 6.5 2Z"/>
                                                                    </svg>
                                                                    View Details
                                                                </button>
                                                            </td>
                                                        </tr>

                                                );

                                            })
                                            : null}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="page-navigation">
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        pageCount={pageCount}
                                        onPageChange={changePage}
                                        containerClassName={"paginationBttns"}
                                        previousLinkClassName={"previousBttn"}
                                        nextLinkClassName={"nextBttn"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>


            <div
                className="modal fade"
                id="changeStatusModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="actionModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                <b className="text-warning">&#9888;</b> Alert
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body fw-500">
                            Are you sure you want to change status ?
                            <div className="mt-3 d-flex flex-column align-items-baseline"
                                 onChange={handlePostStatusChangeRadioButton}>
                                <div className="mb-2">
                                    <input type="radio" name="site_name" value="PENDING"/>
                                    <span className="pl-1"> Keep Pending </span>

                                </div>

                                <div>
                                    <input type="radio" name="site_name" value="APPROVED"/>
                                    <span className="pl-1"> Approve Post </span>
                                </div>

                            </div>
                        </div>

                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-danger"
                                data-dismiss="modal">
                                <b className="text-light">&#10094;</b> No, Go back
                            </button>
                            <button
                                type="button"
                                className="btn btn-success"
                                data-dismiss="modal"
                                onClick={handleChangeStatus}>
                                <b className="text-light">&#128465;</b> Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChannelApproval;
