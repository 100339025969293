import React, {useState} from "react";
import * as MessageApi from "../../api/MessageApi";
import "../DirectMessage/message.css";
import Swal from "sweetalert2";
import {useFormik} from "formik";
import Loader from "../common/Loader";

const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-success mx-3",
        cancelButton: "btn btn-secondary mr-1"
    },
    buttonsStyling: false
});

function MessageSendingForm({sender, receiver, threadId, refetch}) {
    const [loader, setLoader] = useState(false);

    const formik = useFormik({
        initialValues: {
            message: ""
        },
        onSubmit: values => {
            setLoader(true);
            if (
                threadId &&
                receiver &&
                receiver.filter(
                    recipients => recipients.threadId === parseInt(threadId)
                )[0].recipients[0]
            ) {
                let directMessageThreadDTO = {
                    sender: sender,
                    receiver: receiver.filter(
                        recipients => recipients.threadId === parseInt(threadId)
                    )[0].recipients[0],
                    message: values.message,
                    directMessageThread: {id: threadId}
                };
                MessageApi.sendMessage(directMessageThreadDTO).then(data => {
                    refetch();
                    setLoader(false);
                    formik.resetForm();
                });
            }
        }
    });

    return (
        <form
            className="pb-4 pt-2 px-3 w-100 d-inline-flex  chat-form"
            onSubmit={formik.handleSubmit}
        >
            <div className=" input-group">
                <input
                    className="form-control"
                    aria-describedby="button-addon2"
                    id="message"
                    placeholder="Type a message"
                    name="message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                />
            </div>
            {loader ? <Loader/> :
                <div className="input-group-append ml-2">
                    <button id="button-addon2" type="submit" className="btn circle-icon bg-c-blue">
                        <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.75 10.25 16.5 1.5m-8.644 9.023 2.19 5.632c.193.496.29.744.429.817.12.063.264.063.384 0 .14-.072.236-.32.43-.816L16.78 2.083c.175-.448.262-.672.215-.815a.416.416 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L1.844 6.711c-.496.194-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.14.32.236.817.429l5.631 2.19a.9.9 0 0 1 .194.089c.037.026.07.06.097.097.03.042.05.093.089.193Z" stroke="#fff" stroke-width="1.667" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </button>
                </div>
            }

        </form>
    );
}

export default MessageSendingForm;
