import React, {useEffect, useState} from "react";
import * as AssessmentApi from "../../api/AssessmentApi";

import * as LinkFactory from "../../util/LinkFactory";
import * as Utils from "../../util/Utils";
import ListGroup from "react-bootstrap/ListGroup";
import Loader from "../common/Loader";
import CommonLayout from "../layout/commonLayout";
import {convertUTCDateToLocalDate} from "../../util/DateUtils";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import SelectDropdown from "../common/SelectDropwodn";
import * as ChannelApi from "../../api/ChannelApi";

function AllAssessmentList(props) {
    const params = useParams();
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }


    const [channelList, setChannelList] = useState([]);
    const [selectedChannelId, setSelectedChannelId] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [channel, setChannel] = useState({});
    const [assessmentList, setAssessmentList] = useState([]);
    const [upcomingAssignmentList, setUpcomingAssignmentList] = useState({});
    const [previousAssignmentList, setPreviousAssignmentList] = useState({});
    const [previousAssignmentLoading, setPreviousAssignmentLoading] = useState(
        true
    );
    const [upcomingAssignmentLoading, setUpcomingAssignmentLoading] = useState(
        true
    );
    const getChannelLIst = id => {
        ChannelApi.getChannelListByUserId(id).then(
            json => {
                setChannelList(json.data);
            }
        )
    }

    function loadAllPreviousAssignments(channelId) {
        AssessmentApi.allPreviousAssessmentsByLoggedInUserAndChannelId(channelId)
            .then(json => {
                setPreviousAssignmentList(json.data);
            })
            .catch(e => console.log(e))
            .finally(() => setPreviousAssignmentLoading(false));
    }

    function loadAllUpcomingAssignments(channelId) {
        AssessmentApi.allUpcomingAssessmentsByLoggedInUserAndChannelId(channelId)
            .then(json => {
                setUpcomingAssignmentList(json.data);
            })
            .catch(e => console.log(e))
            .finally(() => setUpcomingAssignmentLoading(false));
    }

    function loadAllAssignments(channelId) {
        loadAllPreviousAssignments(channelId);
        loadAllUpcomingAssignments(channelId);
    }

    useEffect(() => {
        getChannelLIst(Utils.getUserFromLocalStorage().id);
        loadAllAssignments(0);
    }, []);

    const handleAssessmentRoute = assignment => {
        if (assignment.submitted === true || Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY", "ROLE_EXAM_CONTROLLER"])) {
            if (assignment.examType === "ASSIGNMENT" || assignment.examType === "EXAM") {
                navigateTo(LinkFactory.assignmentSubmittedView + "/" + assignment.assignment.id);
            } else if (assignment.examType === 'MCQ') {
                navigateTo(LinkFactory.mcqSubmittedView + "/" + assignment.mcqMain.id);
            }
        } else if (Utils.checkRolePermission(["ROLE_STUDENT"])) {
            if (assignment.examType === 'MCQ') {
                navigateTo(LinkFactory.mcqExam + "/" + assignment.mcqMain.id);
            } else {
                navigateTo(LinkFactory.assignmentSubmit + "/" + assignment.assignment.id);
            }
        } else if (Utils.checkRolePermission(["ROLE_PARENT"])) {
            if (assignment.submitted && (assignment.examType === "ASSIGNMENT" || assignment.examType === "EXAM")) {
                navigateTo(LinkFactory.assignmentSubmit + "/" + assignment.assignment.id);
            } else {
                toast("Assignment isn't submitted yet!");
            }
        }
    };
    const handleSubmit = values => {
        console.log(values);
        if (values.examType === "Assignment")
            navigateTo(
                LinkFactory.assignmentSubmittedView + "/" + values.assignment.id
            );
        else
            navigateTo(
                LinkFactory.assessmentMarksEntry +
                "/" +
                params.id +
                "/" +
                values.id
            );
    };
    const handleAssignmentCreatePage = () => {
        navigateTo(LinkFactory.assignmentNew + "/" + params.id);
    };

    function handleEvaluationMarksEntryEditRoute(id) {
        navigateTo(LinkFactory.gradingEvaluationMarksEntry + "/" + id);
    }

    function handleChannelSelection(value) {
        console.log(value);
        setSelectedChannelId(value.id);
        loadAllAssignments(value.id);
    }

    const initialState = {
        channelName: ""
    }
    return (
        <>
            <CommonLayout headerName={"Assignments"}>
                <main>
                    <div className=" all-assessment-list card-default">
                        <div>
                            <div>
                                <div className="title-header ">
                                    <div className="mb-0 d-flex align-items-center">
                                        <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                            <a href="#" className="pl-0 back-button">
                                                <i className="fa fa-chevron-left"> </i> </a>
                                        </div>
                                        <p>All Assignments</p>
                                    </div>
                                </div>
                                <div>
                                    <Formik
                                        initialValues={initialState}
                                        enableReinitialize={true}
                                        onSubmit={handleSubmit}>
                                        {formik => (
                                            <Form>
                                                <div className="col-3 ">
                                                    <div className="form-group input-default-white">
                                                        <Field
                                                            type="text"
                                                            name="channelName"
                                                            placeholder={"Select Room"}
                                                            fieldName={"channelName"}
                                                            label="Channel"
                                                            handleChangeSection={(fieldName, value) => {
                                                                handleChannelSelection(value)
                                                            }}
                                                            options={channelList || []}
                                                            component={SelectDropdown}
                                                        />
                                                    </div>
                                                </div>

                                            </Form>
                                        )}
                                    </Formik>


                                </div>
                                {Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY","ROLE_EXAM_CONTROLLER"]) ? (
                                    <div className="action">
                                        <div className="add-more">

                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="tab-blue">
                                <div className="tabs">
                                    <ul
                                        className="nav nav-pills mb-3 ml-4 pl-4"
                                        id="pills-tab2"
                                        role="tablist">
                                        <li className="nav-item">
                                            <a
                                                className="nav-link active"
                                                id="pills-assignment-upcoming"
                                                data-toggle="pill"
                                                href="#pills-assignment"
                                                role="tab"
                                                aria-controls="pills-assignment"
                                                aria-selected="true">
                                                To Do
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                id="pills-assignment-inprogress"
                                                data-toggle="pill"
                                                href="#pills-inprogress"
                                                role="tab"
                                                aria-controls="pills-inprogress"
                                                aria-selected="false">
                                                Previous
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="pills-tabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="pills-assignment"
                                            role="tabpane2"
                                            aria-labelledby="pills-assignment-upcoming">
                                            <div className="table-responsive table-light table-striped ">
                                                <table className="table table-light table-striped table-hover"
                                                       id="dataTable"
                                                       width="100%"
                                                       cellSpacing="0">
                                                    <thead>
                                                    <tr>
                                                        <th>Exam Name</th>
                                                        <th>Exam Type</th>
                                                        <th>Exam Time</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                    </thead>
                                                    {upcomingAssignmentList &&
                                                    upcomingAssignmentList.length > 0 ? (
                                                        upcomingAssignmentList.map((data, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                    <span
                                                                        className="c-assesment-success">
                                                                      {data.submitted === true && !Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY", "ROLE_EXAM_CONTROLLER"]) ? (
                                                                          <i className="fa fa-check-circle mr-3" title="Submitted"/>) : ("")}
                                                                        </span>
                                                                        <h6 className="mb-1">
                                                                            {data?.examType === "Assignment"
                                                                                ? data.assignment.name
                                                                                : data.title}
                                                                        </h6>
                                                                        <p>{data?.channel?.channelName}</p>
                                                                    </td>
                                                                    <td><p>{data.examType}</p></td>
                                                                    <td>
                                                                        <span className="date">
                                                                        {convertUTCDateToLocalDate(new Date(data.startDate))} - {convertUTCDateToLocalDate(new Date(data.endDate))}
                                                                        </span>
                                                                    </td>
                                                                    <td className=" " width="15%">
                                                                        <button type="button"
                                                                                className="btn btn-sm bg-c-blue-light mr-1"
                                                                                onClick={() => handleAssessmentRoute(data, data.type)}
                                                                                title="View Submissions">
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                 width="17" height="12" fill="none">
                                                                                <path fill="#4575EE"
                                                                                      d="M8.5 1.75A7.55 7.55 0 0 1 15.316 6 7.541 7.541 0 0 1 8.5 10.25 7.541 7.541 0 0 1 1.685 6 7.55 7.55 0 0 1 8.5 1.75Zm0-1.545C4.636.205 1.337 2.608 0 6c1.337 3.392 4.636 5.795 8.5 5.795S15.663 9.392 17 6C15.663 2.608 12.364.205 8.5.205Zm0 3.863a1.932 1.932 0 1 1 0 3.864 1.932 1.932 0 0 1 0-3.864Zm0-1.545A3.483 3.483 0 0 0 5.023 6 3.483 3.483 0 0 0 8.5 9.477 3.483 3.483 0 0 0 11.977 6 3.483 3.483 0 0 0 8.5 2.523Z"/>
                                                                            </svg>
                                                                        </button>
                                                                        {(data.examType !== "MCQ" && Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY","ROLE_EXAM_CONTROLLER"]) === true) ?
                                                                            <button type="button"
                                                                                    className="btn btn-sm bg-c-light-orange mr-1"
                                                                                    onClick={() => handleEvaluationMarksEntryEditRoute(data.id)}
                                                                                    title="Marks Entry">
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                     width="18" height="15" fill="none">
                                                                                    <path fill="#000"
                                                                                          d="m16.202 8.72-2.01-2.011L15.54 5.36l2.01 2.01-1.348 1.348Zm-7.687 5.676v-2.01l5.014-5.015 2.01 2.01-5.014 5.015h-2.01ZM0 9.666V7.772h6.623v1.892H0ZM0 5.88V3.99h10.407V5.88H0Zm0-3.784V.205h10.407v1.892H0Z"/>
                                                                                </svg>
                                                                            </button> : null}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : upcomingAssignmentLoading ? (
                                                        <Loader/>
                                                    ) : (
                                                        <ListGroup>
                                                            <ListGroup.Item>
                                                                <h6>No To Do Assignments</h6>
                                                            </ListGroup.Item>
                                                        </ListGroup>
                                                    )}
                                                </table>
                                            </div>
                                        </div>

                                        <div
                                            className="tab-pane fade"
                                            id="pills-inprogress"
                                            role="tabpane2"
                                            aria-labelledby="pills-assignment-inprogress">
                                            <div className="table-responsive table-light">
                                                <table className="table table-light table-striped table-hover "
                                                       id="dataTable"
                                                       width="100%"
                                                       cellSpacing="0">
                                                    <thead>
                                                    <tr>
                                                        <th>Exam Name</th>
                                                        <th>Exam Type</th>
                                                        <th>Exam Time</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                    </thead>
                                                    {previousAssignmentList.length > 0 ? (
                                                        previousAssignmentList.map((data, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                    <span className="c-assesment-success">
                                                                      {data.submitted === true && !Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY","ROLE_EXAM_CONTROLLER"]) ? (
                                                                          <i className="fa fa-check-circle mr-3" title="Submitted"/>) : ("")}
                                                                        </span>
                                                                        <h6 className="mb-1">
                                                                            {data.examType === "Assignment"
                                                                                ? data.assignment.name
                                                                                : data.title}
                                                                        </h6>
                                                                        <p>{data?.channel?.channelName}</p>
                                                                    </td>
                                                                    <td><p>{data.examType}</p></td>
                                                                    <td>
                                                                        <span className="date">
                                                                          {convertUTCDateToLocalDate(new Date(data.startDate))} - {convertUTCDateToLocalDate(new Date(data.endDate))}
                                                                        </span>
                                                                    </td>
                                                                    <td className=" " width="15%">
                                                                        <button type="button"
                                                                                className="btn btn-sm bg-c-blue-light mr-1"
                                                                                onClick={() => handleAssessmentRoute(data, data.type)}
                                                                                title="View Submissions">
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                 width="17" height="12" fill="none">
                                                                                <path fill="#4575EE"
                                                                                      d="M8.5 1.75A7.55 7.55 0 0 1 15.316 6 7.541 7.541 0 0 1 8.5 10.25 7.541 7.541 0 0 1 1.685 6 7.55 7.55 0 0 1 8.5 1.75Zm0-1.545C4.636.205 1.337 2.608 0 6c1.337 3.392 4.636 5.795 8.5 5.795S15.663 9.392 17 6C15.663 2.608 12.364.205 8.5.205Zm0 3.863a1.932 1.932 0 1 1 0 3.864 1.932 1.932 0 0 1 0-3.864Zm0-1.545A3.483 3.483 0 0 0 5.023 6 3.483 3.483 0 0 0 8.5 9.477 3.483 3.483 0 0 0 11.977 6 3.483 3.483 0 0 0 8.5 2.523Z"/>
                                                                            </svg>
                                                                        </button>
                                                                        {(data.examType !== "MCQ" && Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY","ROLE_EXAM_CONTROLLER"]) === true) ?
                                                                            <button type="button"
                                                                                    className="btn btn-sm bg-c-light-orange mr-1"
                                                                                    onClick={() => handleEvaluationMarksEntryEditRoute(data.id)}
                                                                                    title="Marks Entry">
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                     width="18" height="15" fill="none">
                                                                                    <path fill="#000"
                                                                                          d="m16.202 8.72-2.01-2.011L15.54 5.36l2.01 2.01-1.348 1.348Zm-7.687 5.676v-2.01l5.014-5.015 2.01 2.01-5.014 5.015h-2.01ZM0 9.666V7.772h6.623v1.892H0ZM0 5.88V3.99h10.407V5.88H0Zm0-3.784V.205h10.407v1.892H0Z"/>
                                                                                </svg>
                                                                            </button> : null}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : previousAssignmentLoading ? (
                                                        <Loader/>
                                                    ) : (
                                                        <ListGroup>
                                                            <ListGroup.Item>
                                                                <h6>No Previous Assignments</h6>
                                                            </ListGroup.Item>
                                                        </ListGroup>
                                                    )}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default AllAssessmentList;
