import React, {useEffect, useState} from "react";
import * as SemesterApi from "../../api/SemesterApi";

import CommonLayout from "../layout/commonLayout";
import {useParams} from "react-router-dom";

function SemesterView(props) {
    const params = useParams();
    const [semester, setSemester] = useState([]);

    useEffect(() => {
        SemesterApi.getSemesterById(params.id)
            .then(json => {
                console.log(json);
                setSemester(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);
    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h3 className="mt-4">Semester</h3>
                        <br/>
                        <div>
                            <div>
                                <div className="table-responsive table-light table-shadow radius-15 mt-4">
                                    <table
                                        className="table table-light"
                                        id="dataTable"
                                        width="100%"
                                        cellSpacing="0"
                                    >
                                        <thead>
                                        <tr>
                                            <th scope="col">Semester Name</th>
                                            <th scope="col">Marking Period Start</th>
                                            <th scope="col">Marking Period End</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>{semester.semesterName}</td>
                                            <td>{semester.markingPeriodStart}</td>
                                            <td>{semester.markingPeriodEnd}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default SemesterView;
