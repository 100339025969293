import Loader from "../common/Loader";
import React from "react";
import ListErrors from "../common/ListErrors";
import {Error} from "../CommonLayouts/Common";
import FormActions from "../../action/FormActions";
import {useDispatch, useSelector} from "react-redux";
import {ENVIRONMENT_NAME} from "../../constants/Constants";
import Agent from "../../api/Agent";
import * as Utils from "../../util/Utils";
import {toast} from "react-toastify";
import * as LinkFactory from "../../util/LinkFactory";

import {useNavigate} from "react-router-dom";


export default function FindYourAccountComponent(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const onChangeField = (ev) => dispatch(FormActions.setFieldRegister(ev.target.name, ev.target.value));
    const form = useSelector(state => state.form);
    const dispatch = useDispatch();

    const submitForm = form => ev => {
        ev.preventDefault();
        Agent.Auth.forgotPassword(form).then(res => {
            console.log(res.data);
            if (res.data.status === 200) {
                Utils.setEmail(res.data.userName);
                navigateTo(LinkFactory.resetPasswordByOtp);
                toast("Otp is sent to your email!");
            } else {
                toast("Error");
            }
        }, err => {
            toast(err.data);
        });
    };

    return (<div>
        <div>
            <div className="bg-default findyour-account d-flex">
                <div id="reg_layout">
                    <div id="reg_content">
                        <main>
                            <div id="registrations">
                                <div id="login">
                                    <div className="brand">
                                        <h2>{process.env.REACT_APP_ENV_NAME}</h2>
                                    </div>

                                    <div className="container">
                                        {/*<div className="brand">*/}
                                        {/*    {ENVIRONMENT_NAME === 'LOCAL' ? <></> :*/}
                                        {/*        <img src="img/logo.png" alt={"Edoocate logo"}/>}*/}
                                        {/*</div>*/}
                                        <div className="brand">

                                            <img src="img/Edooket.png" alt={"Edooket logo"} className="mb-4"/>
                                            <h3 className="mt-5 pt-5">Find your account</h3>
                                            <p className="mt-4">Please enter your email address for your account recovery</p>
                                        </div>
                                        {form.loading ? <Loader/> : <React.Fragment/>}
                                        <div className="card-default">
                                            <div className="input-reg">
                                                <ListErrors errors={form.errors}/>
                                                {/*<label className="form-label">Find your account</label>*/}
                                                <form onSubmit={submitForm(form)}>
                                                    <div className="block-group">
                                                        <div className="block">
                                                            <label className="form-label mt-3">Email
                                                            </label>
                                                            <input
                                                                className="form-control "
                                                                type="text"
                                                                placeholder="Enter your email"
                                                                id="email"
                                                                autoComplete="email"
                                                                name={"email"}
                                                                value={form.email}
                                                                onChange={ev => onChangeField(ev)}
                                                                onFocus={onChangeField}
                                                            />
                                                            <Error field={"email"} errors={form.errors}/>
                                                        </div>
                                                    </div>

                                                    <div className="block-group">
                                                        <div className="block-50 m-auto">
                                                            <button
                                                                type="submit"
                                                                className="btn-deep-blue-lg box-btn"
                                                                disabled={form.errors.length > 0}
                                                            >
                                                                <p>Submit</p>
                                                            </button>
                                                            <div className=" ">
                                                                <a href=" " className="back-button p-0">
                                                                    <i
                                                                        className="fa fa-arrow-left "> </i> Back to Log in</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
                <div className="login-image">
                    <div className="gradient-background">
                        <div className="h-100 flex-column d-flex justify-content-center align-item-center">
                            <div className="d-flex  justify-content-center">
                                <p>The first ever <span>smart school solution </span> to automate your whole institution</p>
                            </div>

                            <div className="image-container">
                                <img src="img/bottom_clouds.png" alt="bottom cloud" className="bottom_cloud"/>
                                <img src="img/bulb.png" alt="Bulb" className="bulb" />
                                <img src="img/clouds&sun.png" alt="cloud & sun" className="cloud-sun"/>
                                <img src="img/Paperplane.png"  alt="Paper Plane" className="paperplane"/>
                                <img src="img/man_riding_book.png" alt="Person with Books" className="person"/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>);
}
