import React, {useEffect, useState} from "react";
import * as AssignmentApi from "../../api/AssignmentApi";

import "react-datepicker/dist/react-datepicker.css";
import {TextArea} from "semantic-ui-react";
import Loader from "../common/Loader";

import * as LinkFactory from "../../util/LinkFactory";
import * as Utils from "../../util/Utils";
import CommonLayout from "../layout/commonLayout";
import {convertUTCDateToLocalDate} from "../../util/DateUtils";
import {useNavigate, useParams} from "react-router-dom";

function AssignmentSubmittedView(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const params = useParams();
    const [assignment, setAssignment] = useState({});
    const [assignmentTask, setAssignmentTask] = useState([]);
    const [assignmentSubmitReport, setAssignmentSubmitReport] = useState([]);
    const [userList, setUserList] = useState([]);
    const [totalSubmitCount, setTotalSubmitCount] = useState(0);

    useEffect(() => {
        AssignmentApi.getAssignmentTaskListByAssignmentId(params.id)
            .then(taskListResponse => {
                setAssignmentTask(taskListResponse.data);
                // AssignmentApi.getAssignmentSubmittedUserListByAssignmentId(response.data.id).then((userListResponse) => {
                //     setUserList(userListResponse.data);
                // });
                // AssignmentApi.getAssignmentTaskListByAssignmentId(params.id).then((taskListResponse) => {
                //     setAssignmentTask(taskListResponse.data);
                // });
            })
            .catch(errors => {
                console.log(errors);
            });
        if (Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY","ROLE_EXAM_CONTROLLER"]) === true)
            AssignmentApi.assignmentReportByAssignmentId(params.id)
                .then(response => {
                    setAssignmentSubmitReport(response.data);
                    var submissionCount = 0;
                    for (let i = 0; i < response.data.length; i++) {
                        if (null != response.data[i].deadlineMissed) {
                            submissionCount = submissionCount + 1;
                        }
                    }
                    setTotalSubmitCount(submissionCount);
                })
                .catch(errors => {
                    console.log(errors);
                });
    }, []);

    const handleAssignmentSubmitView = assignmentTask => {
        console.log(assignmentTask);
        navigateTo(
            LinkFactory.assignmentTaskSubmittedView + "/" + assignmentTask.id
        );
    };
    const TextAreaComponent = ({field, form: {touched, errors}}) => {
        return (
            <div className="input-group mb-3">
                <TextArea
                    {...field}
                    type="text"
                    cols="50"
                    className="form-control"
                    aria-label="industry"
                    aria-describedby="basic-addon1"
                />
            </div>
        );
    };
    return (
        <>
            <CommonLayout headerName={Utils.getChannelName()}>
                <main>
                    <div>
                        <div>
                            <div className="grid-fixed-4"
                                 style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>


                            </div>

                            <br/>

                            {Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY","ROLE_EXAM_CONTROLLER"]) === true ? (
                                <div className="mb-5">
                                    <div className="card-default">
                                        <div className="card-header">
                                            <a href="#" className="back-button">
                                                <i
                                                    className="fa fa-chevron-left"> </i> Back</a>
                                        </div>

                                        <div class="d-flex my-4 pb-4 top-box">
                                            <div className="d-flex assignment-name">
                                                <div className="circle-icon bg-c-orange-light">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22"
                                                         fill="none">
                                                        <path fill="#FAA31A"
                                                              d="M2.111 21.5a2.04 2.04 0 0 1-1.491-.617A2.013 2.013 0 0 1 0 19.4V4.7c0-.578.207-1.072.62-1.484.414-.411.911-.617 1.491-.616h4.433a3.154 3.154 0 0 1 1.149-1.523A3.027 3.027 0 0 1 9.5.5c.668 0 1.271.193 1.808.577.537.385.92.893 1.148 1.523h4.433c.58 0 1.078.206 1.491.617.414.412.62.906.62 1.483v14.7c0 .578-.207 1.072-.62 1.484-.414.411-.911.617-1.491.616H2.11Zm0-2.1H16.89V4.7H2.11v14.7Zm2.111-2.1h7.39v-2.1h-7.39v2.1Zm0-4.2h10.556V11H4.222v2.1Zm0-4.2h10.556V6.8H4.222v2.1ZM9.5 3.912a.768.768 0 0 0 .792-.787.76.76 0 0 0-.225-.564.776.776 0 0 0-.567-.224.768.768 0 0 0-.792.788.76.76 0 0 0 .225.564c.15.148.339.223.567.224Z"/>
                                                    </svg>
                                                </div>
                                                <div className="ml-2 pl-1">
                                                    <h6 className="fw-500 fs-1 text-muted">Assignment Name</h6>
                                                    <h5 className="fw-600 fs-1">{assignmentTask[0]?.assignment?.name}</h5>
                                                </div>

                                            </div>
                                            <div className="d-flex assignment-timeline">
                                                <div className="circle-icon bg-c-purple-light">
                                                    <i className="far fa-clock c-purple"/>
                                                </div>
                                                <div className="ml-2 pl-1">
                                                    <h6 className="fw-500 fs-1 text-muted">Assignment Timeline</h6>
                                                    <h5 className="fw-600 fs-1"> {assignmentTask[0]?.assignment?.gradingEvaluation?.examStartDate != null
                                                        ? (convertUTCDateToLocalDate(new Date(assignmentTask[0]?.assignment?.gradingEvaluation?.examStartDate)) + ' - ' +
                                                            convertUTCDateToLocalDate(new Date(assignmentTask[0]?.assignment?.gradingEvaluation?.examEndDate)))
                                                        : ""}
                                                    </h5>
                                                </div>

                                            </div>
                                            <div className="d-flex total-students">
                                                <div className="circle-icon bg-c-red-light">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20"
                                                         fill="none">
                                                        <path fill="#FD5750"
                                                              d="M20.445 3.638 10.753.536a.892.892 0 0 0-.505 0L.567 3.638h-.02l-.102.049h-.01l-.1.058c0 .01-.01.01-.02.02l-.082.067-.07.078c0 .01-.01.01-.01.019l-.06.087c0 .01 0 .01-.011.02l-.04.087-.03.106v.03a.345.345 0 0 0-.01.116v7.754a.76.76 0 0 0 .236.548.825.825 0 0 0 .57.227c.215 0 .42-.081.572-.227a.76.76 0 0 0 .236-.548V5.451L5.01 6.536a5.946 5.946 0 0 0-.97 3.267c0 1.05.279 2.083.809 3.002a6.317 6.317 0 0 0 2.22 2.251 9.638 9.638 0 0 0-4.694 3.625.759.759 0 0 0-.108.586.785.785 0 0 0 .35.49.816.816 0 0 0 .604.111.814.814 0 0 0 .506-.334 7.96 7.96 0 0 1 2.92-2.592 8.344 8.344 0 0 1 3.855-.94c1.346 0 2.67.324 3.854.94a7.96 7.96 0 0 1 2.92 2.592.797.797 0 0 0 .292.257.835.835 0 0 0 .818-.034.786.786 0 0 0 .35-.49.76.76 0 0 0-.108-.586 9.638 9.638 0 0 0-4.694-3.625 6.317 6.317 0 0 0 2.22-2.251 6.01 6.01 0 0 0 .809-3.002 5.945 5.945 0 0 0-.97-3.267l4.453-1.425a.807.807 0 0 0 .402-.282.755.755 0 0 0 0-.909.807.807 0 0 0-.402-.282Zm-5.099 6.165a4.56 4.56 0 0 1-1.419 3.29 4.95 4.95 0 0 1-3.426 1.362 4.95 4.95 0 0 1-3.427-1.362 4.56 4.56 0 0 1-1.42-3.29c.003-.993.331-1.96.94-2.763l3.654 1.173a.84.84 0 0 0 .505 0l3.655-1.173c.608.803.936 1.77.938 2.763Zm-.938-4.391h-.01L10.5 6.662l-3.897-1.25h-.01L3.363 4.375l7.138-2.288 7.137 2.288-3.23 1.037Z"/>
                                                    </svg>
                                                </div>
                                                <div className="ml-2 pl-1">
                                                    <h6 className="fw-500 fs-1 text-muted">Total Students</h6>
                                                    <h5 className="fw-600 fs-1">{assignmentSubmitReport?.length}</h5>
                                                </div>
                                            </div>
                                            <div className="d-flex total-submitted">
                                                <div className="circle-icon bg-c-blue-light">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15"
                                                         fill="none">
                                                        <path fill="#4575EE"
                                                              d="M5 14.546c-1.382 0-2.56-.476-3.536-1.428C.488 12.161 0 10.994 0 9.618a5.07 5.07 0 0 1 1.064-3.163 4.716 4.716 0 0 1 2.8-1.773C4.245 3.288 5.003 2.16 6.136 1.3A6.218 6.218 0 0 1 10 0c1.776 0 3.279.618 4.51 1.855 1.236 1.23 1.854 2.733 1.854 4.509 1.048.12 1.915.575 2.6 1.363A3.97 3.97 0 0 1 20 10.454c0 1.14-.397 2.107-1.19 2.9-.795.795-1.761 1.192-2.9 1.192h-5a1.75 1.75 0 0 1-1.283-.537 1.73 1.73 0 0 1-.536-1.282V8.045l-1.455 1.41-1.272-1.273L10 4.545l3.636 3.637-1.272 1.273-1.455-1.41v4.682h5c.636 0 1.173-.22 1.61-.663.442-.437.663-.973.663-1.61 0-.636-.221-1.172-.664-1.609a2.172 2.172 0 0 0-1.609-.663h-1.364V6.364c0-1.255-.442-2.328-1.327-3.219-.885-.884-1.957-1.327-3.218-1.327-1.255 0-2.327.443-3.218 1.327-.885.891-1.327 1.964-1.327 3.219H5c-.879 0-1.627.312-2.245.936a3.043 3.043 0 0 0-.937 2.245c0 .88.312 1.637.937 2.273.618.606 1.366.91 2.245.91h2.273v1.818"/>
                                                    </svg>
                                                </div>
                                                <div className="ml-2 pl-1">
                                                    <h6 className="fw-500 fs-1 text-muted">Total Submitted</h6>
                                                    <h5 className="fw-600 fs-1"> {totalSubmitCount} </h5>

                                                </div>

                                            </div>

                                        </div>


                                        <div className="table-responsive table-light radius-15">
                                            <h6 className="font-weight-bold mb-4 pl-2"
                                                style={{color: "#475467", fontSize: "18px"}}>Assignment Submission
                                                Status
                                            </h6>
                                            <table
                                                className="assignment-submission table table-light"
                                                id="dataTable"
                                                width="100%"
                                                cellSpacing="0">
                                                <thead>
                                                <tr>
                                                    <th>Student name</th>
                                                    <th>Submission Status</th>
                                                    <th>Assignment Submit Date</th>
                                                    {/*<th>Submission Type</th>*/}
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {assignmentSubmitReport.length > 0 ? (
                                                    assignmentSubmitReport.map((report, index) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    {" "}
                                                                    {report?.student?.firstName}{" "}
                                                                    {report?.student?.lastName}
                                                                </td>
                                                                <td>
                                                                    <i className={`submission-status fa fa-circle ${report.deadlineMissed == true ? 'missed' : report.deadlineMissed == false ? 'submitted' : 'not-submitted'}`}>
                                                                    </i>
                                                                    {" "}
                                                                    {report.deadlineMissed == true
                                                                        ? "Missed"
                                                                        : report.deadlineMissed == false
                                                                            ? "Submitted"
                                                                            : "No Yet Submitted"}{" "}
                                                                </td>
                                                                <td>
                                                                    {" "}
                                                                    {report?.assignmentTaskSubmitDateTime != null
                                                                        ? convertUTCDateToLocalDate(new Date(report?.assignmentTaskSubmitDateTime))
                                                                        : "N/A"}{" "}
                                                                </td>
                                                                {/*<td className="d-flex align-items-baseline">*/}
                                                                {/*    <i className="far fa-clock"> </i>*/}
                                                                {/*    <p className="pl-1">On Time</p>*/}
                                                                {/*</td>*/}

                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <Loader/>
                                                )}
                                                </tbody>
                                            </table>
                                            <div className="page-navigation">
                                                <ul className="pagination">
                                                    <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Previous">
                                                            <span aria-hidden="true">&larr;</span>
                                                            <span className="sr-only">Previous</span>
                                                        </a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-active" href="#">
                                                            1
                                                        </a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">
                                                            2
                                                        </a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">
                                                            3
                                                        </a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">
                                                            ...
                                                        </a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">
                                                            4
                                                        </a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">
                                                            5
                                                        </a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">
                                                            6
                                                        </a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Next">
                                                            <span aria-hidden="true">&rarr;</span>
                                                            <span className="sr-only">Next</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            ) : null}


                            <div className="card-default">

                                <div className="task-header d-flex my-4 px-2 justify-content-between">
                                    <div className="d-flex task-list">
                                        <div className="circle-icon bg-c-orange-light">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="21" fill="none">
                                                <path stroke="#FAA31A" stroke-linecap="round" stroke-linejoin="round"
                                                      stroke-width="2"
                                                      d="M7.74 3.3H22M1 3.043 2.037 4.07 4.63 1.5M1 10.757l1.037 1.029L4.63 9.214M1 18.471 2.037 19.5l2.593-2.571m3.11-5.915H22M7.74 18.73H22"/>
                                            </svg>
                                        </div>
                                        <div className="ml-2 pl-1">
                                            <h6 className="font-weight-bold my-3">Task List</h6>
                                        </div>
                                    </div>
                                    <div className="right-view w-60 d-flex justify-content-between align-items-center">
                                        <div className="d-flex total-tasks">
                                            <div className="circle-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24"
                                                     fill="none">
                                                    <path fill="#722CFF"
                                                          d="M13.461 19.308a2.693 2.693 0 1 1 0-5.386 2.693 2.693 0 0 1 0 5.386Zm0-3.847a1.153 1.153 0 1 0 0 2.307 1.153 1.153 0 0 0 0-2.307Z"/>
                                                    <path fill="#722CFF"
                                                          d="M13.461 23.154A6.538 6.538 0 1 1 20 16.615a6.546 6.546 0 0 1-6.539 6.539Zm0-11.539a5 5 0 1 0 0 10 5 5 0 0 0 0-10Z"/>
                                                    <path fill="#722CFF"
                                                          d="M15.385 3.154h-2.308v-.77A1.543 1.543 0 0 0 11.539.847H5.385a1.543 1.543 0 0 0-1.539 1.539v.769H1.538A1.543 1.543 0 0 0 0 4.692v16.154a1.543 1.543 0 0 0 1.538 1.539h3.847v-1.539H1.538V4.692h2.308V7h9.23V4.692h2.309v3.846h1.538V4.692a1.543 1.543 0 0 0-1.538-1.538Zm-3.846 2.308H5.385V2.385h6.154v3.077Z"/>
                                                </svg>
                                            </div>
                                            <div className="ml-2 pl-1">
                                                <h6 className=" text-muted ">Total Tasks</h6>
                                                <h4> {assignmentTask.length} </h4>
                                            </div>
                                        </div>

                                        <div
                                            className="search-sort w-60 d-flex justify-content-around align-items-baseline">
                                            <div className="form-group has-search">
                                                <span className="fa fa-search form-control-feedback"> </span>
                                                <input type="text" className="form-control" placeholder="Search"/>
                                            </div>
                                            <div className="sort-by">
                                                <button className="sort-by-button">
                                                    <p>Sort by</p>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13"
                                                         fill="none">
                                                        <path stroke="#475467" stroke-linecap="round"
                                                              stroke-linejoin="round" stroke-width="2"
                                                              d="M3.75 6.5h9.5M1.375 1.75h14.25m-9.5 9.5h4.75"/>
                                                    </svg>
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>Most Submitted</li>
                                                    <li>Least Submitted</li>
                                                    <li>Date Created</li>
                                                </ul>
                                            </div>
                                        </div>


                                    </div>

                                </div>


                                <div className="table-responsive table-light radius-15">

                                    <table
                                        className="table table-light"
                                        id="dataTable"
                                        width="100%"
                                        cellSpacing="0">
                                        <thead>
                                        <tr>
                                            <th>Task Name</th>
                                            <th>Total Submits</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {assignmentTask.length > 0 ? (
                                            assignmentTask.map((assignmentTask, index) => {
                                                return (
                                                    <tr>
                                                        <td className=""> {assignmentTask?.name}</td>
                                                        <td className=""> {assignmentTask?.totalSubmits}</td>

                                                        <td>
                                                            <button
                                                                title="View Submits"
                                                                type="button"
                                                                className="btn-md btn-secondary radius-25 border-grey"
                                                                onClick={() =>
                                                                    handleAssignmentSubmitView(assignmentTask)
                                                                }>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="17"
                                                                     height="14" fill="none">
                                                                    <path fill="#475467"
                                                                          d="M6.5 1.333a5.297 5.297 0 0 1 3.074.987c.922.649 1.663 1.578 2.138 2.68-.975 2.247-2.967 3.667-5.212 3.667-2.245 0-4.237-1.42-5.212-3.667.475-1.102 1.216-2.031 2.138-2.68A5.297 5.297 0 0 1 6.5 1.333ZM6.5 0C3.545 0 1.022 2.073 0 5c1.022 2.927 3.545 5 6.5 5s5.478-2.073 6.5-5c-1.022-2.927-3.545-5-6.5-5Zm0 3.333c.392 0 .768.176 1.045.489.277.312.432.736.432 1.178 0 .442-.155.866-.432 1.179a1.398 1.398 0 0 1-1.045.488c-.392 0-.768-.176-1.045-.488A1.781 1.781 0 0 1 5.023 5c0-.442.155-.866.432-1.178A1.398 1.398 0 0 1 6.5 3.333ZM6.5 2C5.035 2 3.84 3.347 3.84 5S5.036 8 6.5 8s2.66-1.347 2.66-3S7.964 2 6.5 2Z"/>
                                                                </svg>
                                                                View Submits
                                                            </button>
                                                        </td>

                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <Loader/>
                                        )}
                                        </tbody>
                                    </table>
                                    <div className="page-navigation">
                                        <ul className="pagination">
                                            <li className="page-item">
                                                <a className="page-link" href="#" aria-label="Previous">
                                                    <span aria-hidden="true">&larr;</span>
                                                    <span className="sr-only">Previous</span>
                                                </a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-active" href="#">
                                                    1
                                                </a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">
                                                    2
                                                </a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">
                                                    3
                                                </a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">
                                                    ...
                                                </a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">
                                                    4
                                                </a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">
                                                    5
                                                </a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">
                                                    6
                                                </a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#" aria-label="Next">
                                                    <span aria-hidden="true">&rarr;</span>
                                                    <span className="sr-only">Next</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default AssignmentSubmittedView;
