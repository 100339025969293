import React, {useEffect, useState} from "react";

import FooterComponent from "../Nav/footer";
import CommonLayout from "../layout/commonLayout";
import * as ParentsApi from "../../api/ParentsApi";

function ParentsRegistrationApproval(props) {
    const [parentList, setParentList] = useState([]);
    const [parentsStatus, setParentsStatus] = useState("");
    const [parentsId, setParentsId] = useState(null);

    const loadParents = () => {
        var parent = {
            searchTerm:"",
            registrationStatus:"PENDING"
        };
        ParentsApi.parentRegistrationApproval(parent).then(
            json => {
                setParentList(json.data.content);
            }
        );
    };

    const handleParentsStatusChangeRadioButton = event => {
        setParentsStatus(event.target.value);
    };

    const approveParent = () => {
        console.log("clicked");
        var parents = {
            id: parentsId,
            registrationStatus: parentsStatus
        };
        ParentsApi.parentApproval(parents).then(data => {
            console.log(data);
            loadParents();
        });
    };

    useEffect(()=> {
        loadParents();
    },[]);

    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h1 className="mt-4">Parents Registration Approval</h1>

                        <div className="card mb-4">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table
                                        className="table table-bordered"
                                        id="dataTable"
                                        width="100%"
                                        cellSpacing="0"
                                    >
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Student Info</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                        {parentList.length > 0
                                            ? parentList.map((data, index) => {
                                                return (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{data.firstName}</td>
                                                        <td>{data.studentInfoList}</td>
                                                        <td>{data.registrationStatus}</td>
                                                        <td><button
                                                            type="button"
                                                            className="btn btn-warning"
                                                            data-toggle="modal"
                                                            data-target="#changeStatusModal"
                                                            onClick={() => setParentsId(data.id)}
                                                        >
                                                            Change Status
                                                        </button></td>
                                                    </tr>
                                                );
                                            })
                                            : null}
                                        </thead>
                                        <tbody></tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <FooterComponent/>
            </CommonLayout>

            <div
                className="modal fade"
                id="changeStatusModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="actionModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                <b className="text-warning">&#9888;</b> Alert
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            Are you sure you want to change status ?
                            <div onChange={handleParentsStatusChangeRadioButton}>
                                <td>
                                    <input type="radio" name="site_name" value="PENDING"/>
                                    Keep Pending
                                </td>
                                &nbsp;
                                <td>
                                    <input type="radio" name="site_name" value="APPROVED"/>
                                    Approve Parent
                                </td>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-danger"
                                data-dismiss="modal"
                            >
                                <b className="text-light">&#10094;</b> No, Go back
                            </button>
                            <button
                                type="button"
                                className="btn btn-success"
                                data-dismiss="modal"
                                onClick={approveParent}
                            >
                                <b className="text-light">&#128465;</b> Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ParentsRegistrationApproval;
