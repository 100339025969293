import React, {useEffect, useState} from "react";
import * as UserTypeApi from "../../api/UserTypeApi";
import {Field, Form, Formik} from "formik";

import CommonLayout from "../layout/commonLayout";
import {useParams} from "react-router-dom";

function UserTypeEdit(props) {
    const params = useParams();
    const [userType, setUserType] = useState({states: "", id: ""});

    useEffect(() => {
        UserTypeApi.getUserTypeById(params.id)
            .then(json => {
                setUserType(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);

    const handleSubmit = data => {
        console.log(data);
        UserTypeApi.updateUserType(data);
    };

    const TextField = ({field, form: {touched, errors}}) => {
        return (
            <div className="input-group mb-3">
                <input
                    {...field}
                    type="text"
                    className="form-control"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                />
            </div>
        );
    };

    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h1 className="mt-4">User type Edit</h1>
                        <br/>
                        <div className="card-body">
                            <Formik
                                initialValues={userType}
                                enableReinitialize={true}
                                onSubmit={handleSubmit}
                            >
                                {props => (
                                    <Form>
                                        <div className="table-responsive">
                                            <table
                                                className="table table-bordered"
                                                id="dataTable"
                                                width="100%"
                                                cellSpacing="0"
                                            >
                                                <tr>
                                                    <td>Id</td>
                                                    <td>
                                                        <Field
                                                            type="number"
                                                            name="id"
                                                            placeholder="id"
                                                            readonly
                                                            component={TextField}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>User type</td>
                                                    <td>
                                                        <Field
                                                            type="text"
                                                            name="userType"
                                                            placeholder="userType"
                                                            component={TextField}
                                                        />
                                                    </td>
                                                </tr>
                                                <td colSpan="12">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-lg btn-primary pull-right"
                                                    >
                                                        Submit
                                                    </button>
                                                </td>
                                            </table>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default UserTypeEdit;
