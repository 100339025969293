import React, {useEffect, useState} from "react";
import * as SportsTypeApi from "../../api/SportsTypeApi";

import * as LinkFactory from "../../util/LinkFactory";

import CommonLayout from "../layout/commonLayout";
import {useNavigate} from "react-router-dom";

function SportsTypeList() {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [sportsType, setSportsType] = useState([]);
    const [id, setId] = useState(null);
    useEffect(() => {
        SportsTypeApi.getAll()
            .then(json => {
                setSportsType(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);

    const handleEdit = data => {
        navigateTo(LinkFactory.sportsType + "/" + data.id);
    };
    const handleView = data => {
        navigateTo(LinkFactory.sportsTypeView + "/" + data.id);
    };
    const handleNew = data => {
        navigateTo(LinkFactory.sportsTypeNew);
    };
    const handleDelete = data => {
        setId(data.id);
    };

    const deleteData = () => {
        SportsTypeApi.deleteSportsTypeById(id);
        setId(null);
        window.location.reload();
    };
    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h1 className="mt-4">Sports Type</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item active">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => handleNew()}
                                >
                                    Create new Sports Type
                                </button>
                            </li>
                        </ol>

                        <div className="card mb-4">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table
                                        className="table table-bordered"
                                        id="dataTable"
                                        width="100%"
                                        cellSpacing="0"
                                    >
                                        <thead>
                                        <tr>
                                            <th scope="col">id</th>
                                            <th scope="col">Sports Type</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {sportsType.length > 0
                                            ? sportsType.map((data, index) => {
                                                return (
                                                    <tr>
                                                        <th scope="row">{data.id}</th>
                                                        <td>{data.sportsType}</td>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn btn-info"
                                                                onClick={() => handleView(data)}
                                                            >
                                                                <i className="fa fa-eye"></i> View
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-warning"
                                                                onClick={() => handleEdit(data)}
                                                            >
                                                                <i className="fa fa-edit"></i>
                                                                Edit
                                                            </button>

                                                            <button
                                                                type="button"
                                                                className="btn btn-danger"
                                                                data-toggle="modal"
                                                                data-target="#deleteModal"
                                                                onClick={() => handleDelete(data)}
                                                            >
                                                                <i className="fa fa-trash"></i> Delete
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            : ""}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>

            <div
                className="modal fade"
                id="deleteModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="actionModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                <b className="text-warning">&#9888;</b> Alert
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">Are you sure you want to delete ?</div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-success"
                                data-dismiss="modal"
                            >
                                <b className="text-light">&#10094;</b> No, Go back
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger"
                                data-dismiss="modal"
                                onClick={deleteData}
                            >
                                <b className="text-light">&#128465;</b> Confirm Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SportsTypeList;
