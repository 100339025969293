import React, {useEffect, useState} from "react";
import * as RoutineApi from "../../api/RoutineApi";

import CommonLayout from "../layout/commonLayout";
import {useParams} from "react-router-dom";
import * as Utils from "../../util/Utils";

function RoutineDetails(props) {
    const params = useParams();
    const [routineDays, setRoutineDays] = useState([]);
    const [channel, setChannel] = useState({});

    useEffect(() => {
        RoutineApi.getRoutineDaysByRoutineId(params.id)
            .then(json => {
                setRoutineDays(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);

    return (
        <>
            <CommonLayout headerName={Utils.getChannelName()}>
                <main>
                    <div className="card-default routine-list">
                        <div className="title-header ">
                            <div className="mb-0 d-flex align-items-center">
                                <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                    <a href="#" className="pl-0 back-button">
                                        <i className="fa fa-chevron-left"> </i> </a>
                                </div>
                                <p>Routine</p>
                            </div>
                        </div>

                        <div className="table-responsive table-light ">
                            <table
                                className="table table-light table-hover table-striped"
                                id="dataTable"
                                width="100%"
                                cellSpacing="0"
                            >
                                <thead>
                                <tr>
                                    <th scope="col">Weekday</th>
                                    <th scope="col">Starting Time</th>
                                    <th scope="col">Ending Time</th>
                                </tr>
                                </thead>
                                <tbody>
                                {routineDays.length > 0
                                    ? routineDays.map((routineDay, index) => {
                                        return (
                                            <tr>
                                                <td>{routineDay.dayName}</td>
                                                <td td className="" width="30%">{routineDay.startTime}</td>
                                                <td className="" width="30%">{routineDay.endTime}</td>
                                            </tr>
                                        );
                                    })
                                    : ""}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default RoutineDetails;
