import React, {useEffect, useState} from "react";
import * as CountryApi from "../../api/CountryApi";

import * as LinkFactory from "../../util/LinkFactory";
import Loader from "../common/Loader";
import FooterComponent from "../Nav/footer";
import CommonLayout from "../layout/commonLayout";
import {useNavigate} from "react-router-dom";

function CountryList() {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [country, setCountry] = useState([]);
    const [initial, setInitial] = useState({name: "", phone: ""});
    const [id, setId] = useState(null);

    useEffect(() => {
        CountryApi.getAll()
            .then(json => {
                console.log(json);
                setCountry(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);

    const handleEdit = data => {
        navigateTo(LinkFactory.country + "/" + data.id);
    };
    const handleView = data => {
        navigateTo(LinkFactory.countryView + "/" + data.id);
    };
    const handleNew = data => {
        navigateTo(LinkFactory.countryNew);
    };

    const handleDelete = data => {
        setId(data.id);
    };

    const deleteData = () => {
        CountryApi.deleteCountryById(id);

        /// then set id as null again
        setId(null);
        window.location.reload();
    };

    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h1 className="mt-4">Country</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item active">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => handleNew()}
                                >
                                    Create new Country
                                </button>
                            </li>
                        </ol>

                        <div className="card mb-4">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table
                                        className="table table-bordered"
                                        id="dataTable"
                                        width="100%"
                                        cellSpacing="0"
                                    >
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Country</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {country.length > 0 ? (
                                            country.map((data, index) => {
                                                return (
                                                    <tr>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{data.country}</td>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn btn-info"
                                                                onClick={() => handleView(data)}
                                                            >
                                                                <i className="fa fa-eye"></i> View
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-warning"
                                                                onClick={() => handleEdit(data)}
                                                            >
                                                                <i className="fa fa-edit"></i>
                                                                Edit
                                                            </button>

                                                            <button
                                                                type="button"
                                                                className="btn btn-danger"
                                                                data-toggle="modal"
                                                                data-target="#deleteModal"
                                                                onClick={() => handleDelete(data)}
                                                            >
                                                                <i className="fa fa-trash"></i> Delete
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <Loader/>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <FooterComponent/>
            </CommonLayout>

            <div
                className="modal fade"
                id="deleteModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="actionModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                <b class="text-warning">&#9888;</b> Alert
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">Are you sure you want to delete ?</div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-success"
                                data-dismiss="modal"
                            >
                                <b className="text-light">&#10094;</b> No, Go back
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger"
                                data-dismiss="modal"
                                onClick={deleteData}
                            >
                                <b className="text-light">&#128465;</b> Confirm Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CountryList;
