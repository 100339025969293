import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveEvents = events => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/events/", events);
};

export const updateEvents = events => {
    return AxiosAgent.requests.put(API_MAIN_ROOT + "/events/", events);
};
export const getEventsById = eventsId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/events/" + eventsId);
};
export const deleteEventsById = eventsId => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/events/" + eventsId);
};
export const getAll = () => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/events");
};
export const findEventsListByChannelId = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/events-by-channel-id/" + id);
};

export const findMembersListByChannelId = id => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/event-members-list-event-id/" + id
    );
};
export const findUpcomingEventsByChannelIdAndCurrentDate = id => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/upcoming-events-by-channel-id/" + id
    );
};

export const findPreviousEventsByChannelIdAndCurrentDate = id => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/previous-events-by-channel-id/" + id
    );
};

export const findAllPreviousEventsByLoggedInUser = () => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/all-previous-events-by-logged-in-user"
    );
};
export const findAllUpcomingEventsByLoggedInUser = () => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/all-upcoming-events-by-logged-in-user"
    );
};
export const findAcceptedMembersListByChannelId = id => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/event-members-accepted-list-event-id/" + id
    );
};
export const findEventMemberListByChannelIdAndEventStatus = (id, eventStatus, memberType) => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/event-members-list-by-event-id-event-status/" + id + "/" + eventStatus + "/" + memberType
    );
};
export const findEventsMembersByUserIdAndEventId = (userId, eventId) => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT +
        "/event-member-by-user-id-event-id/" +
        userId +
        "/" +
        eventId
    );
};
export const updateEventUserStatusByEventIdUserIdStatus = (
    eventId,
    userId,
    status
) => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT +
        "/events-confirmation-status-update/" +
        eventId +
        "/" +
        userId +
        "/" +
        status
    );
};
