import React, {Fragment, useEffect} from "react";
import HomeComponent from "./HomeComponent";
import {Helmet} from "react-helmet/es/Helmet";
import * as Utils from "../../util/Utils";
import {useDispatch, useSelector} from "react-redux";
import FormActions from "../../action/FormActions";

const HomePage = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);

    useEffect(() => {
        if (Utils.isAuthenticated() && !user) {
            Utils.tryToMakeUserAvailable(dispatch);
        }
        return () => dispatch(FormActions.unloaded());
    }, []);

    return (
        <Fragment>
            <Helmet>
                <title>Edooket</title>
                <meta content="width=device-width, initial-scale=1.0" name="viewport"/>
                <meta content="" name="keywords"/>
                <meta content="" name="description"/>
                <link
                    href="https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap"
                    rel="stylesheet"
                />
            </Helmet>
            <HomeComponent/>
            {/*<FooterComponent/>*/}
        </Fragment>
    );
};

export default HomePage;
