import React, {useEffect, useRef, useState} from "react";
import * as MessageApi from "../../api/MessageApi";
import * as Utils from "../../util/Utils";
import {useLocation, useNavigate} from "react-router-dom";
import MessageSendingForm from "./messageSendForm";
import "../../style/css/style.scss";
import "./chat.scss";
import MyModal from "../common/MyModal";
import CommonLayoutForChatPage from "../layout/commonLayoutForChatPage";
import * as ChannelApi from "../../api/ChannelApi";
import * as EventsApi from "../../api/EventsApi";
import * as LinkFactory from "../../util/LinkFactory";
import Loader from "../common/Loader";
import {convertUTCDateToLocalDatePostFormat, dateTimeForChat} from "../../util/DateUtils";

function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function EmptyMessageScreen({loading}) {
    return (
        <div className="my-auto  d-flex align-items-center justify-content-center h-100">
            <h6>
                {loading ? <Loader/> : "Select any message thread to see messages"}{" "}
            </h6>
        </div>
    );
}

function MessagesThreads({threadId, receiverId}) {

    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }

    const [loading, setLoading] = useState(false);
    const own = Utils.getUserFromLocalStorage();
    const [messageList, setMessageList] = useState(null);
    const [receiver, setReceiver] = useState(null);
    const [threadList, setThreadList] = useState(null);
    const [error, setError] = useState(false);
    const IMAGE_BASE_URL = process.env.REACT_APP_FILES_URL;

    const scrollHeight = "725px";
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        console.log("scrollToBottom");
        bottomRef.current.scrollIntoView({behavior: "smooth"});
    };

    const [upcomingAssignmentList, setUpcomingAssignmentList] = useState({});
    const [previousAssignmentList, setPreviousAssignmentList] = useState({});
    const [upcomingEventsList, setUpcomingEventsList] = useState({});
    const [previousEventsList, setPreviousEventsList] = useState({});
    const [upcomingAssignmentLoading, setUpcomingAssignmentLoading] = useState(
        true
    );
    const [upcomingEventsLoading, setUpcomingEventsLoading] = useState(true);
    const [previousAssignmentLoading, setPreviousAssignmentLoading] = useState(
        true
    );
    const [previousEventsLoading, setPreviousEventsLoading] = useState(true);
    const [smallCalenderData, setSmallCalenderData] = useState([]);
    const [user, setUser] = useState({});
    const [channel, setChannel] = useState({});
    const [selectedThread, setSelectedThread] = useState({});
    // useEffect(() => {
    //     if (messageList) scrollToBottom()
    // }, [messageList]);

    useEffect(() => {
        setUser(Utils.getUserFromLocalStorage());
        setPreviousAssignmentList([]);
        setUpcomingAssignmentList([]);
        setSmallCalenderData([]);
        setUpcomingEventsList([]);
        setPreviousEventsList([]);
        if (!threadList) {
            MessageApi.threadAll()
                .then(json => {
                    if (json.response.status === 200) {
                        setThreadList(json.data);
                    } else setError(true);
                })
                .catch(errors => {
                    console.log(errors);
                })
                .finally(() => setLoading(false));
        }
        ChannelApi.getChannelById(9)
            .then(json => {
                setChannel(json.data);
            });
        ChannelApi.getSmallCalenderDataAll().then(calenderData => {
            console.log(calenderData.data);
            setSmallCalenderData(calenderData.data);
        });

        setUpcomingEventsLoading(true);
        EventsApi.findAllUpcomingEventsByLoggedInUser(
            9
        ).then(eventListResponse => {
            console.log(eventListResponse.data);
            setUpcomingEventsList(eventListResponse.data);
            setUpcomingEventsLoading(false);
        });
        setPreviousEventsLoading(true);
        EventsApi.findAllPreviousEventsByLoggedInUser(
            9
        ).then(eventListResponse => {
            console.log(eventListResponse.data);
            setPreviousEventsList(eventListResponse.data);
            setPreviousEventsLoading(false);
        });
    }, []);


    useEffect(() => {
        if (threadId) {

            callMessageListApi();
        }
    }, [history, threadId]);

    function callMessageListApi() {
        setLoading(true);
        MessageApi.getMessagesByThreadId(threadId)
            .then(json => {
                if (json.response.status === 200) {
                    setMessageList(json.data);
                    scrollToBottom();
                } else setError(true);
            })
            .catch(errors => {
                setError(true);
            })
            .finally(() => setLoading(false));
    }

    function handleThreadSelect(data) {
        const newLink = "/message-thread/?th=" + data.threadId;
        setSelectedThread(data)
        setLoading(true);
        navigateTo(newLink);
    }

    function messageFirstPage() {
        navigateTo(LinkFactory.messageFirstPage);
    }

    function handleRefetch() {
        callMessageListApi();
    }

    const bottomRef = useRef(null);

    const handleEventRoute = id => {
        if (Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY", "ROLE_EXAM_CONTROLLER"]))
            navigateTo(LinkFactory.eventsSetupView + "/" + id);
        else if (Utils.checkRolePermission(["ROLE_USER"]))
            navigateTo(LinkFactory.eventsSetupViewForUser + "/" + id);
    };
    const handleEventCreatePage = () => {
        navigateTo(LinkFactory.eventsNew + "/" + 9);
    };
    const handleStudyMaterialView = () => {
        navigateTo(LinkFactory.channelStudyMaterialView + "/" + 9);
    };
    const handleStudyMaterialUpload = () => {
        navigateTo(LinkFactory.channelStudyMaterialUpload + "/" + 9);
    };

    const handleGradeBook = () => {
        navigateTo(LinkFactory.gradeBook + "/" + 9);
    };
    const handleBreakdown = () => {
        navigateTo(
            LinkFactory.gradingByChannelId + "/" + 9
        );
    };

    const handleAttendance = () => {
        navigateTo(LinkFactory.attendanceEntry + "/" + 9);
    };
    const handleRoutine = () => {
        navigateTo(
            LinkFactory.routineListByChannelId + "/" + 9
        );
    };
    const handlePostApproval = () => {
        navigateTo(
            LinkFactory.channelPostApproval + "/" + 9
        );
    };
    const handleCalender = () => {
        navigateTo(LinkFactory.calenderPage);
    };
    const handleStudentsHistory = () => {
        navigateTo(LinkFactory.studentsHistory);
    };

    const handleAttendanceHistory = () => {
        navigateTo(LinkFactory.attendanceHistory);
    };
    const handleAllEvents = () => {
        navigateTo(LinkFactory.allEventsList);
    };
    return (
        <>
            <CommonLayoutForChatPage headerName="Messages">
                <main>
                    <MyModal/>
                    <div id="contentAreaChatPage">
                        <div className="block-center">
                            <div className="pt-2 px-2 container-fluid">
                                <div className="row radius-25 overflow-hidden bg-white">
                                    <div className="left-message-body col-3 col-sm-4 px-0">
                                        <div>
                                            <div className=" ">
                                                <div className="title-header">
                                                    <div className=" card-header p-0 m-0 mr-3 d-flex align-items-center">
                                                        <a href="#" className="pl-0 back-button">
                                                            <i className="fa fa-chevron-left pr-1"> </i> Back</a>
                                                    </div>
                                                </div>
                                                <div className="left-section-heading py-2 px-2 pr-4 d-flex justify-content-between align-items-center">

                                                    <p className=" px-4 h5 mb-0 py-3 fw-600">Chats</p>
                                                    <button
                                                        type="button"
                                                        className="btn  bg-c-light-orange d-flex align-items-center radius-5"
                                                        onClick={messageFirstPage}
                                                    >
                                                        <svg width="22" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M12.062.5a.788.788 0 0 0-.555.252l-.386.37 2.267 2.265.37-.385a.775.775 0 0 0 0-1.125L12.633.752A.823.823 0 0 0 12.062.5Zm-1.31.94-.589.488 2.419 2.417.537-.537-2.368-2.367Zm-.908.873L4.99 7.115a.525.525 0 0 0-.068.067l-.033.016a.524.524 0 0 0-.118.236l-.655 2.3a.523.523 0 0 0 .655.654l2.301-.655a.524.524 0 0 0 .353-.285l4.771-4.717-.907-.974v.05l-.639-.638h.05l-.856-.856Zm-9.407.37A.538.538 0 0 0 0 3.22v10.743a.537.537 0 0 0 .538.537h10.75a.538.538 0 0 0 .538-.537V8.054a.537.537 0 0 0-1.075 0v5.372H1.075v-9.67h5.376a.538.538 0 1 0 0-1.074H.437Zm5.275 5.27.74.05.05.806-.74.218-.285-.284.235-.79Z"
                                                                fill="#E68F06"/>
                                                        </svg>
                                                        <p className="c-orange mb-0 fw-500">Create Chat </p>
                                                    </button>
                                                </div>
                                                <div className="form-group mt-1 mb-3 w-100 d-flex justify-content-center">
                                                    <div className="has-search w-80">
                                                        <span className="fa fa-search form-control-feedback"> </span>
                                                        <input type="text"
                                                               className="form-control"
                                                               placeholder="Search Chat"
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="messages-box">
                                                {threadList &&
                                                    threadList.length > 0 &&
                                                    threadList.map(thread => (
                                                        <div key={thread.threadId}>
                                                            <div className="list-group rounded-0">
                                                                <div
                                                                    onClick={() => handleThreadSelect(thread)}
                                                                    className="list-group-item list-group-item-action list-group-item-light rounded-0"
                                                                >
                                                                    <div className=" d-flex justify-content-between align-items-center ">
                                                                        <div className="d-flex">
                                                                            <div className='avatar'>
                                                                                {thread &&
                                                                                thread.recipients.length === 1 &&
                                                                                thread.recipients[0].imageUrl ? (
                                                                                    <img
                                                                                        src={
                                                                                            IMAGE_BASE_URL +
                                                                                            thread.recipients[0].imageUrl
                                                                                        }
                                                                                        alt="user"
                                                                                        width="50"
                                                                                        height="50"
                                                                                        className="rounded-circle"
                                                                                        style={{objectFit: "cover"}}
                                                                                    />
                                                                                ) : <img
                                                                                    src={"img/Avatar.png"}
                                                                                    alt="user"
                                                                                    width="50"
                                                                                    height="50"
                                                                                    className="rounded-circle"
                                                                                    style={{objectFit: "cover"}}
                                                                                />}
                                                                            </div>
                                                                            <div className="media-body ml-3 my-auto align-items-center ">
                                                                                <h6>
                                                                                    {/*{thread &&*/}
                                                                                    {/*thread.recipients[0] &&*/}
                                                                                    {/*thread.recipients[0].firstName*/}
                                                                                    {/*    ? thread.recipients[0].firstName +*/}
                                                                                    {/*    " " +*/}
                                                                                    {/*    thread.recipients[0].lastName*/}
                                                                                    {/*    : "No name"}{" "}*/}
                                                                                    {/*todo: need to make a function*/}
                                                                                    {thread.recipients.length > 0 && thread.recipients.map((recipient, index) => (recipient.firstName + " " + recipient.lastName + (index !== (thread?.recipients?.length - 1) ? ", " : "")))}
                                                                                </h6>
                                                                                <span> {thread.lastMessage.length > 10 ? thread.lastMessage.substring(0, 20) + " ..." : thread.lastMessage}  </span>
                                                                                <p> {dateTimeForChat(thread.lastMessageTime)}  </p>
                                                                            </div>
                                                                        </div>
                                                                        {/*<div className="unread-time">*/}
                                                                        {/*    <span> 4m</span>*/}
                                                                        {/*    <svg width="10" height="10" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="5" cy="5" r="5" fill="#4575EE"/></svg>*/}
                                                                        {/*</div>*/}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="right-message-body col-9 col-sm-8 px-0">
                                        <div className="avatar title-avatar d-flex align-items-center">
                                            <img
                                                src="img/Avatar.png"
                                                alt="user"
                                                width="50"
                                                height="50"
                                                className="rounded-circle"
                                            />
                                            <div className="name">
                                                {/*need to set selected name here*/}
                                                <p> {selectedThread?.recipients?.length > 0 && selectedThread?.recipients?.map((recipient, index) => (recipient.firstName + " " + recipient.lastName + (index !== (selectedThread?.recipients?.length - 1) ? ", " : "")))}</p>
                                            </div>
                                        </div>

                                        <div className="chat-box px-4 py-5  bg-white">
                                            {messageList && messageList.length > 0 ? (
                                                messageList.map((messageObject, index) => {
                                                    return (
                                                        <div key={index} ref={bottomRef}>
                                                            {messageObject.sender.id !== own.id ? (
                                                                <div className="media mb-1">
                                                                    <img
                                                                        src={IMAGE_BASE_URL + messageObject.sender?.imageUrl}
                                                                        alt="user"
                                                                        width="45px"
                                                                        height="45px"
                                                                        className="avatar rounded-circle"
                                                                    />
                                                                    <div className="media-body ml-3">
                                                                        <p className="small text-muted mb-1">
                                                                            {messageObject.createDate
                                                                                ? convertUTCDateToLocalDatePostFormat(new Date(messageObject.createDate))
                                                                                : null}
                                                                        </p>
                                                                        <div
                                                                            className="sender-messagebox">
                                                                            <p>
                                                                                {messageObject.message
                                                                                    ? messageObject.message
                                                                                    : ""}
                                                                            </p>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="media ml-auto mb-1">
                                                                    <div className="media-body">
                                                                        <p className="small text-muted mb-1">
                                                                            {messageObject.createDate
                                                                                ? convertUTCDateToLocalDatePostFormat(new Date(messageObject.createDate))
                                                                                : null}
                                                                        </p>
                                                                        <div
                                                                            className="receiver-messagebox ">
                                                                            <p className="text-small">
                                                                                {messageObject.message
                                                                                    ? messageObject.message
                                                                                    : null}
                                                                            </p>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <EmptyMessageScreen loading={loading}/>
                                            )}
                                        </div>

                                        {threadId && (
                                            <MessageSendingForm
                                                sender={own}
                                                receiver={threadList}
                                                threadId={threadId}
                                                refetch={handleRefetch}
                                            />
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </CommonLayoutForChatPage>
        </>
    );
}

export default MessagesThreads;
