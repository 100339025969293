import React, {useEffect, useState} from "react";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as EmployeeApi from "../../api/EmployeeApi";
import * as StudentApi from "../../api/StudentApi";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import CommonLayout from "../layout/commonLayout";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {DeleteOutlineRounded} from "@mui/icons-material";
import * as ChannelApi from "../../api/ChannelApi";
import {useNavigate, useParams} from "react-router-dom";
import * as DepartmentApi from "../../api/DepartmentApi";
import * as FacultyApi from "../../api/FacultyApi";
import * as ProgramApi from "../../api/ProgramApi";
import SelectDropdown from "../common/SelectDropwodn";
import {FormikTextField} from "../FormikTextField";
import {useDispatch} from "react-redux";
import LoaderOverlay from "../common/LoaderOverlay";
import * as SemesterApi from "../../api/SemesterApi";
import TextFieldComponent from "../common/TextFieldComponent";

import * as LinkFactory from "../../util/LinkFactory";
import {fetchChannelsApi} from "../../storeManager/channel/middleware/ChannelMiddleware";
import Swal from "sweetalert2";
import * as Utils from "../../util/Utils";
import FormTitle from "../Typhography/formTitle";
import MUIDataTable from "mui-datatables";
import Loader from "../common/Loader";

function ChannelNew(props) {
    const dispatch = useDispatch();
    const params = useParams();

    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }

    const slug = useParams();
    const [room, setRoom] = useState({
        channelType: "",
        channelName: "",
        facultyName: {},
        departmentName: {},
        programName: {},
        semester: {},
        creditHour: "",
        gradeName: "",
        sectionName: "",
        session: "",
        courseCode: ""
    });

    const [memberSelectedOption, setMemberSelectedOption] = React.useState([]);
    const [studentList, setStudentList] = React.useState([]);
    const [searchStudentList, setSearchStudentList] = React.useState([]);
    const [moderatorSelectedOption, setModeratorSelectedOption] = React.useState([]);
    const [employeeSelectedOption, setEmployeeSelectedOption] = React.useState([]);
    const [members, setMembers] = React.useState([]);
    const [moderators, setModerators] = React.useState([]);
    const [isLoadingMembers, setIsLoadingMembers] = useState(false);
    const [isLoadingModerators, setIsLoadingModerators] = useState(false);
    const [studentTypeAheadResults, setStudentTypeAheadResults] = useState([]);
    const [moderatorTypeAheadResults, setModeratorTypeAheadResults] = useState([]);
    const [deletedMembers, setDeletedMembers] = useState([]);
    const [deletedModerators, setDeletedModerators] = useState([]);
    const [faculties, setFaculties] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [searchDepartments, setSearchDepartments] = useState([]);
    const [searchPrograms, setSearchPrograms] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [invalid, setInvalid] = useState(false);
    const [createRoomLoader, setCreateRoomLoader] = useState(false);
    const [semesterList, setSemesterList] = useState([]);
    const [semester, setSemester] = useState({});
    const [studentLoader, setStudentLoader] = useState(false);
    const [selectedMembers, setSelectedStudents] = React.useState([]);
    //
    // let validate = Yup.object({
    //     channelName: Yup.string()
    //         .required('Channel Name is required'),
    //     channelType: Yup.string().required('Channel type is required.'),
    //     facultyName: Yup.object().shape({
    //         id: Yup.number().required('Channel type is required.'),
    //         name: Yup.string().required('Channel type is required.')
    //     }).nullable().required(),
    //     creditHour: Yup.string().required("Credit Hour is required")
    // });
    function loadAllSemester() {
        SemesterApi.getAll().then(response => {
            setSemesterList(response.data);
        });
    }

    function loadFaculty() {
        FacultyApi.getAllFaculties().then(json => {
            setFaculties([...json.data, ...[{id: 0, name: "Unassigned"}]]);
        });
    }

    const handleSemesterSelection = option => {
        setSemester(option)
    };

    const handleSessionType = value => {
        setSession(value.currentTarget.value);
    }
    const handleSearchSessionType = value => {
        setSearchSession(value.currentTarget.value);
    }

    useEffect(() => {
        if (slug.id) {
            setStudentLoader(true);
            ChannelApi.getChannelById(slug.id)
                .then(json => {
                    const res = json?.data || null;
                    if (res) {
                        if (res?.department) {
                            console.log("2");
                            DepartmentApi.getAllByFacultyId(res?.department?.faculty.id).then(json => {
                                console.log("3");
                                setDepartments(json.data);
                                if (res?.program) {
                                    console.log("4");
                                    ProgramApi.getAllByDepartmentId(res?.department?.id).then(json => {
                                        setPrograms(json.data);
                                        const newValues = {
                                            channelType: res?.channelType || "class",
                                            channelName: res?.channelName || "",
                                            facultyName: res?.department?.faculty || "",
                                            departmentName: res?.department || "",
                                            programName: res?.program || "",
                                            creditHour: res?.creditHour || "",
                                            session: res?.session || "",
                                            semester: res?.semester || "",
                                            courseCode: res?.courseCode || "",
                                        };
                                        setRoom(newValues);
                                        setSession(res?.session);
                                        setFaculty(res?.department?.faculty);
                                        setDepartment(res?.department);
                                        setProgram(res?.program);
                                        // searchStudentWithParams(res?.department?.faculty?.id, res?.department?.id, res?.program?.id, res?.session);
                                    });
                                } else {
                                    //handling departmental channels
                                    const newValues = {
                                        channelType: res?.channelType,
                                        channelName: res?.channelName || "",
                                        facultyName: res?.department?.faculty || "",
                                        departmentName: res?.department || "",
                                        programName: res?.program || "",
                                        creditHour: res?.creditHour || "",
                                        session: res?.session || "",
                                        semester: res?.semester || "",
                                        courseCode: res?.courseCode || "",
                                    };
                                    setRoom(newValues);
                                    setSession(res?.session);
                                    setFaculty(res?.department?.faculty);
                                    setDepartment(res?.department);
                                }
                            });
                        } else {
                            // handling for organizational channel
                            const newValues = {
                                channelName: res?.channelName || "",
                                channelType: res?.channelType,
                            };
                            setRoom(newValues);
                        }
                    }
                })
                .catch(errors => {
                    console.error(errors);
                });
            ChannelApi.getChannelMemberListByChannelId(slug.id)
                .then(json => {
                    const res = json?.data.length > 0 ? json.data : [];
                    const options =
                        res?.map(i => ({
                            fullObject: {user: i},
                            label: i.firstName + " " + i.lastName,
                            value: i.id
                        })) || [];
                    const students =
                        res?.map(i => ({
                            user: i,
                            firstName: i.firstName,
                            lastName: i.lastName

                        })) || [];
                    setStudentLoader(false);
                    setMemberSelectedOption(students);
                    setMembers(options);
                    setStudentList(students);
                })
                .catch(errors => {
                    console.error(errors);
                });
            ChannelApi.getChannelModeratorListByChannelId(slug.id)
                .then(json => {
                    const res = json?.data.length > 0 ? json.data : [];
                    const options =
                        res?.map(i => ({
                            fullObject: {user: i},
                            label: i.firstName + " " + i.lastName,
                            value: i.id
                        })) || [];
                    setModeratorSelectedOption(options);
                    setModerators(options);
                })
                .catch(errors => {
                    console.error(errors);
                });
            ChannelApi.getChannelEmployeeListByChannelId(slug.id)
                .then(json => {
                    const res = json?.data.length > 0 ? json.data : [];
                    const options =
                        res?.map(i => ({
                            fullObject: {user: i},
                            label: i.firstName + " " + i.lastName,
                            value: i.id
                        })) || [];
                    setEmployeeSelectedOption(options);
                })
                .catch(errors => {
                    console.error(errors);
                });
        }
    }, [slug]);

    useEffect(() => {
        loadFaculty();
        loadAllSemester();

    }, []);

    const handleSubmit = values => {
        console.log(values);
        setCreateRoomLoader(true);
        if (
            Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(values.channelType) === false ||
            Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(values.channelName) === false ||
            (values.channelType !== 'organizational' &&
                ((values.channelType !== 'departmental' && moderatorSelectedOption.length === 0) ||
                    (values.channelType !== 'departmental' && memberSelectedOption.length === 0) ||
                    Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(values.channelName) === false ||
                    Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(values.channelType) === false ||
                    ((values.channelType === 'class') && Object.keys(values.semester).length === 0) ||
                    ((values.channelType === 'class' || values.channelType === 'departmental') && Object.keys(values.facultyName).length === 0) ||
                    ((values.channelType === 'class' || values.channelType === 'departmental') && Object.keys(values.departmentName).length === 0) ||
                    (values.channelType === 'class' && (null == values.creditHour || '' === values.creditHour))))
        ) {
            Swal.fire("Missing Required fields", "Please fill all the fields", "error");
            setCreateRoomLoader(false);
        } else {
            let memberIds = [];
            let moderatorIds = [];
            let employeeIds = [];
            moderatorSelectedOption.forEach(member => {
                moderatorIds.push(member.fullObject.user.id);
            });
            memberSelectedOption.forEach(member => {
                memberIds.push(member.user.id);
            });
            employeeSelectedOption.forEach(member => {
                employeeIds.push(member.fullObject.user.id);
            });
            const data = {
                id: undefined !== slug.id ? slug.id : null,
                channelName: values.channelName,
                moderatorsList: moderatorIds.toString(),
                membersList: memberIds.toString(),
                employeeList: employeeIds.toString(),
                channelType: values.channelType,
                gradeId: Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(values.gradeSetup) ? values.gradeSetup.id.toString() : null,
                sectionId: Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(values.sectionSetup) ? values.sectionSetup.id.toString() : null,
                creditHour: Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(values.creditHour) ? values.creditHour.toString() : null,
                departmentId: Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(values.departmentName) && Object.keys(values.departmentName).length !== 0 ? values.departmentName.id.toString() : null,
                programId: Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(values.programName) && Object.keys(values.programName).length !== 0 ? values.programName.id.toString() : null,
                semesterId: Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(values.semester) && Object.keys(values.semester).length !== 0 ? values.semester.id.toString() : null,
                session: Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(values.semester) ? values.session : null,
                courseCode: Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(values.semester) ? values.courseCode : null
            };
            ChannelApi.saveChannel(data)
                .then(response => {
                    if (slug.id)
                        Swal.fire(values.channelName, "Room Updated", "success");
                    else
                        Swal.fire(values.channelName, "Room Created", "success");
                    dispatch(fetchChannelsApi());
                    setCreateRoomLoader(false);
                    navigateTo(LinkFactory.channelList);
                })
                .catch(err => console.error(err));
        }


    };
    const TextField = ({field, form: {touched, errors}}) => {
        return (
            <>
                <div className={`input-group mb-3`}>
                    <input
                        {...field}
                        type="text"
                        className="form-control"
                        aria-label="industry"
                        aria-describedby="basic-addon1"
                    />
                </div>

            </>
        );
    };

    const handleModeratorSearch = query => {
        setIsLoadingModerators(true);

        EmployeeApi.employeeSearchForTypeahead(query)
            .then(json => {
                const result = json?.response?.data || [];
                const options = result.map(i => ({
                    fullObject: i,
                    label: i.firstName + " " + i.lastName,
                    value: i.user.id
                }));
                setModeratorTypeAheadResults(options);
            })
            .catch(err => console.error(err))
            .finally(() => setIsLoadingModerators(false));
    };
    const typeaheadSelectMember = data => {
        const deletedItems = members
            .filter(x => !memberSelectedOption.includes(x))
            .concat(memberSelectedOption.filter(x => !members.includes(x)));
        setMemberSelectedOption(data);
    };
    const typeaheadSelectModerator = data => {
        const deletedItems = moderators
            .filter(x => !moderatorSelectedOption.includes(x))
            .concat(moderatorSelectedOption.filter(x => !moderators.includes(x)));
        setModeratorSelectedOption(data);
    };
    const typeaheadSelectEmployee = data => {
        console.log(data);
        const deletedItems = moderators
            .filter(x => !employeeSelectedOption.includes(x))
            .concat(employeeSelectedOption.filter(x => !moderators.includes(x)));
        setEmployeeSelectedOption(data);
    };
    const setFacultySearch = value => {
        setSearchFaculty(value);
        getSearchDepartmentsByFaculty(value.id);
    }
    const setFacultyObj = value => {
        setFaculty(value);
        getDepartmentsByFaculty(value.id);
    }

    const setDepartmentObj = value => {
        setDepartment(value)
        getProgramsByDepartmentId(value.id);
    }
    const setSearchDepartmentObj = value => {
        setSearchDepartment(value)
        getSearchProgramsByDepartmentId(value.id);
    }
    const getDepartmentsByFaculty = (facultyId) => {
        DepartmentApi.getAllByFacultyId(facultyId).then(json => {
            setDepartments(json.data);
        });
    }
    const getSearchDepartmentsByFaculty = (facultyId) => {
        if (facultyId !== undefined && facultyId !== 0) {
            DepartmentApi.getAllByFacultyId(facultyId).then(json => {
                setSearchDepartments([...json.data, ...[{id: 0, name: "Unassigned"}]]);
            });
        }
    }
    const getProgramsByDepartmentId = (departmentId) => {
        if (departmentId !== undefined && departmentId !== 0) {
            ProgramApi.getAllByDepartmentId(departmentId).then(json => {
                setPrograms([...json.data, ...[{id: 0, name: "Unassigned"}]]);
            });
        }
    }
    const getSearchProgramsByDepartmentId = (departmentId) => {
        ProgramApi.getAllByDepartmentId(departmentId).then(json => {
            setSearchPrograms(json.data);
        });
    }
    const deleteMember = id => {
        deletedMembers.push(id);
        const users = memberSelectedOption.filter(
            member => member.user.id !== id
        );
        setMemberSelectedOption(users?.length > 0 ? users : []);
    };
    const deleteModerator = id => {
        deletedModerators.push(id);
        const users = moderatorSelectedOption.filter(
            member => member.fullObject.user.id !== id
        );
        console.log(users);
        setModeratorSelectedOption(users?.length > 0 ? users : []);
    };
    const deleteEmployee = id => {
        deletedModerators.push(id);
        const users = employeeSelectedOption.filter(
            member => member.fullObject.user.id !== id
        );
        console.log(users);
        setEmployeeSelectedOption(users?.length > 0 ? users : []);
    };
    const [faculty, setFaculty] = useState({});
    const [department, setDepartment] = useState({});
    const [program, setProgram] = useState({});
    const [session, setSession] = useState("");


    const [searchFaculty, setSearchFaculty] = useState({});
    const [searchDepartment, setSearchDepartment] = useState({});
    const [searchProgram, setSearchProgram] = useState({});
    const [searchSession, setSearchSession] = useState("");


    function searchStudent() {
        const search = {
            facultyId: searchFaculty.id,
            departmentId: searchDepartment.id,
            programId: searchProgram.id,
            session: searchSession
        };
        StudentApi.searchStudent(search)
            .then(response => {
                setSearchStudentList(response.data);
                setMemberSelectedOption(response.data)
                console.log(response);
            })
            .catch(err => console.error(err));
    }

    function searchStudentWithParams(facId, deptId, proId, sess) {
        const search = {
            facultyId: facId,
            departmentId: deptId,
            programId: proId,
            session: sess
        };
        StudentApi.searchStudent(search)
            .then(response => {
                setStudentList(response.data);
                setMemberSelectedOption(response.data)
                console.log(response);
            })
            .catch(err => console.error(err));
    }

    function handleCheckBox(data, id) {
        if (data?.target?.checked === false) {
            deleteMember(id);
        }
    }

    const column = [
        {
            name: "firstName",
            label: "First name",
            options: {
                filter: true,
                sort: true,
                setCellHeaderProps: value => ({
                    style: {fontWeight: "bold", color: "#008080"}
                })
            }
        },
        {
            name: "lastName",
            label: "Last name",
            options: {
                filter: true,
                sort: true,
                setCellHeaderProps: value => ({
                    style: {fontWeight: "bold", color: "#008080"}
                })
            }
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: true,
                sort: true,
                setCellHeaderProps: value => ({
                    style: {fontWeight: "bold", color: "#008080"}
                })
            }
        },
        {
            name: "Active",
            options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <input
                            type="checkbox"
                            defaultChecked={true}
                            onChange={(e) => {
                                handleCheckBox(e, tableMeta.rowData[3])
                            }}
                            style={{
                                marginLeft: 12,
                                marginTop: 12,
                                alignItems: "center",
                                fontSize: "26px",
                                size: "26px"
                            }}
                            // component={CheckBoxComponent}
                        />
                    );
                }
            }
        }
    ];

    const optionsStudents = {
        filter: true,
        filterType: "checkbox",
        responsive: "standard",
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 20, 50, 100],
        onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
            setSelectedStudents(rowsSelected);
        }
    };

    const filterBy = () => true;
    return (
        <>
            <CommonLayout headerName={"New Room"}>
                <main>
                    <div className="card-default create-room">
                        <div className="title-header">
                            <div className="card-header p-0 m-0 mr-3 bg-c-light-grey d-flex align-items-center">
                                <a href="#" className="pl-0 back-button">
                                    <i className="fa fa-chevron-left pr-3"> </i> </a>
                                <p className=" ">{slug.id ? "Update" : "Add"} Room</p>
                            </div>
                        </div>

                        {createRoomLoader ? <LoaderOverlay/> : null}
                        <div className="room-body">
                            <Formik
                                initialValues={room}
                                enableReinitialize={true}
                                onSubmit={handleSubmit}>
                                {({props, values, setFieldValue}) => {
                                    return (
                                        <Form className=" ">
                                            <div className="row">
                                                <div className="row top-row">
                                                    {values.channelType !== "organizational" && values.channelType !== "departmental" ?
                                                        <div className="col-3 ">
                                                            <div className=" ">
                                                                <div className="form-group input-default-white">
                                                                    <p>Semester</p>
                                                                    <Field
                                                                        type="text"
                                                                        name="semester"
                                                                        placeholder={values?.name || "Select Semester"}
                                                                        fieldName={"semesterName"}
                                                                        label="semester"
                                                                        handleChangeSection={(fieldName, value) => {
                                                                            handleSemesterSelection(value)
                                                                        }}
                                                                        required
                                                                        options={semesterList || []}
                                                                        component={SelectDropdown}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> : null}
                                                    <div className="col-4 d-flex mb-3">
                                                        <div className="w-50 mr-3 ">
                                                            <div className="form-group input-default-white">
                                                                <FormikTextField label="Room Name"
                                                                                 name="channelName"
                                                                                 type="text"/>
                                                            </div>
                                                        </div>
                                                        {values.channelType !== "organizational" ?
                                                            <div className="w-50 ">
                                                                <div className="form-group input-default-white">
                                                                    <FormTitle>Room Type</FormTitle>
                                                                    <Field
                                                                        as="select"
                                                                        name="channelType"
                                                                        className="form-control">
                                                                        <option selected>Select Type</option>
                                                                        <option value="class">Course</option>
                                                                        <option value="co-currullar">Co Curricular
                                                                        </option>
                                                                        <option value="departmental">Department
                                                                        </option>
                                                                    </Field>
                                                                    <ErrorMessage
                                                                        name="channelType"/>
                                                                </div>
                                                            </div>
                                                            : null}
                                                    </div>
                                                </div>

                                                {(values.channelType !== "co-currullar" && values.channelType !== "organizational") ?
                                                    <div className="row top-row">
                                                        <div className=" col-3">
                                                            <div className="form-group input-default-white">
                                                                <label
                                                                    className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                    {" "}
                                                                    Select Faculty{" "}
                                                                </label>
                                                                <Field
                                                                    type="text"
                                                                    name="facultyName"
                                                                    placeholder={values?.name || "Select Faculty"}
                                                                    fieldName={"name"}
                                                                    label="facultyName"
                                                                    handleChangeSection={(fieldName, value) => {
                                                                        setFacultyObj(value)
                                                                    }}
                                                                    required
                                                                    options={faculties || []}
                                                                    component={SelectDropdown}
                                                                />
                                                                <ErrorMessage
                                                                    name="facultyName"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-3 ">
                                                            <div className="form-group input-default-white">
                                                                <label
                                                                    className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                    {" "}
                                                                    Select Department{" "}
                                                                </label>
                                                                <Field
                                                                    type="text"
                                                                    name="departmentName"
                                                                    placeholder={values?.name || "Select Department"}
                                                                    fieldName={"name"}
                                                                    label="departmentName"
                                                                    handleChangeSection={(fieldName, value) => {
                                                                        setFieldValue("department", value);
                                                                        setDepartmentObj(value)
                                                                    }}

                                                                    options={departments || []}
                                                                    component={SelectDropdown}
                                                                />
                                                                <ErrorMessage
                                                                    name="departmentName"/>

                                                            </div>
                                                        </div>
                                                        {values.channelType !== "departmental" ?
                                                            <div className="col-3 ">
                                                                <div className="form-group input-default-white">
                                                                    <label
                                                                        className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                        {" "}
                                                                        Select Program{" "}
                                                                    </label>
                                                                    <Field
                                                                        type="text"
                                                                        name="programName"
                                                                        placeholder={values?.name || "Select Program"}
                                                                        fieldName={"name"}
                                                                        label="departmentName"
                                                                        handleChangeSection={(fieldName, value) => {
                                                                            setFieldValue("programName", value);
                                                                            setProgram(value)
                                                                        }

                                                                        }
                                                                        options={programs || []}
                                                                        component={SelectDropdown}
                                                                    />
                                                                    <ErrorMessage
                                                                        name="programName"/>

                                                                </div>
                                                            </div> : null}
                                                        {values.channelType === "class" ? (
                                                            <>
                                                                <div className=" col-3">
                                                                    <div className="form-group input-default-white">
                                                                        <label
                                                                            className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                            {" "}
                                                                            Session{" "}
                                                                        </label>
                                                                        <Field
                                                                            type="text"
                                                                            name="session"
                                                                            placeholder="Session"
                                                                            onChange={value => {
                                                                                setFieldValue("session", value.currentTarget.value);
                                                                                handleSessionType(value)
                                                                            }}
                                                                            component={TextFieldComponent}
                                                                        />
                                                                        <ErrorMessage
                                                                            name="session"/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-3 ">
                                                                    <div className="form-group input-default-white">
                                                                        <label
                                                                            className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                            {" "}
                                                                            Course Code{" "}
                                                                        </label>
                                                                        <Field
                                                                            type="text"
                                                                            name="courseCode"
                                                                            placeholder="Course Code"
                                                                            onChange={value => {
                                                                                setFieldValue("courseCode", value.currentTarget.value);
                                                                            }}
                                                                            component={TextFieldComponent}
                                                                        />
                                                                        <ErrorMessage
                                                                            name="courseCode"/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-3 ">
                                                                    <div className="form-group input-default-white">
                                                                        <label
                                                                            className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                            {" "}
                                                                            Credit Hour{" "}
                                                                        </label>
                                                                        <Field
                                                                            type="text"
                                                                            name="creditHour"
                                                                            placeholder="Credit Hour"
                                                                            component={TextField}
                                                                        />
                                                                        <ErrorMessage
                                                                            name="creditHour"/>
                                                                    </div>
                                                                </div>

                                                            </>) : null}
                                                    </div>
                                                    : null}
                                            </div>

                                            <div className="student-search">
                                                <div className="student-search-box">
                                                    <p>Student Search</p>
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <div className="form-group input-default-white">
                                                                <label
                                                                    className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                    {" "}
                                                                    Select Faculty{" "}
                                                                </label>
                                                                <Field
                                                                    type="text"
                                                                    name="searchFaculty"
                                                                    placeholder={values?.name || "Select Faculty"}
                                                                    fieldName={"name"}
                                                                    label="facultyName"
                                                                    handleChangeSection={(fieldName, value) => {
                                                                        setFacultySearch(value)
                                                                    }}
                                                                    required
                                                                    options={faculties || []}
                                                                    component={SelectDropdown}
                                                                />
                                                                <ErrorMessage
                                                                    name="searchFaculty"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="form-group input-default-white">
                                                                <label
                                                                    className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                    {" "}
                                                                    Select Department{" "}
                                                                </label>
                                                                <Field
                                                                    type="text"
                                                                    name="searchDepartment"
                                                                    placeholder={values?.name || "Select Department"}
                                                                    fieldName={"name"}
                                                                    label="departmentName"
                                                                    handleChangeSection={(fieldName, value) => {
                                                                        setFieldValue("searchDepartment", value);
                                                                        setSearchDepartmentObj(value)
                                                                    }}

                                                                    options={searchDepartments || []}
                                                                    component={SelectDropdown}
                                                                />
                                                                <ErrorMessage
                                                                    name="searchDepartment"/>

                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="form-group input-default-white">
                                                                <label
                                                                    className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                    {" "}
                                                                    Select Program{" "}
                                                                </label>
                                                                <Field
                                                                    type="text"
                                                                    name="searchProgram"
                                                                    placeholder={values?.name || "Select Program"}
                                                                    fieldName={"name"}
                                                                    label="departmentName"
                                                                    handleChangeSection={(fieldName, value) => {
                                                                        setFieldValue("searchProgram", value);
                                                                        setSearchProgram(value)
                                                                    }}
                                                                    options={searchPrograms || []}
                                                                    component={SelectDropdown}
                                                                />
                                                                <ErrorMessage
                                                                    name="searchProgram"/>

                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="form-group input-default-white">
                                                                <label
                                                                    className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                    {" "}
                                                                    Session{" "}
                                                                </label>
                                                                <Field
                                                                    type="text"
                                                                    name="searchSession"
                                                                    placeholder="Session"
                                                                    onChange={value => {
                                                                        setFieldValue("searchSession", value.currentTarget.value);
                                                                        handleSearchSessionType(value)
                                                                    }}
                                                                    component={TextFieldComponent}
                                                                />
                                                                <ErrorMessage
                                                                    name="session"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-2">
                                                            <button
                                                                type="button"
                                                                onClick={searchStudent}
                                                                className="btn btn-purple-md radius-25 ">
                                                                Search
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {searchStudentList.length > 0 ? (
                                                    <div className=" student-name-table">
                                                        <div className="student-table ">
                                                            <p>
                                                                {values.channelType === "class"
                                                                    ? "Search Student"
                                                                    : "Search Member"}{" "}
                                                                List
                                                            </p>
                                                            <div
                                                                className="table-responsive table-light">
                                                                <table
                                                                    className="table table-light "
                                                                    id="dataTable"
                                                                    width="100%"
                                                                    cellSpacing="0"
                                                                >
                                                                    <thead>
                                                                    <tr>
                                                                        <th scope="col" className=" ">
                                                                            Name
                                                                        </th>
                                                                        <th scope="col" className=" ">
                                                                            Email
                                                                        </th>
                                                                        <th scope="col" className=" ">
                                                                            Select
                                                                        </th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {searchStudentList.length > 0
                                                                        ? searchStudentList.map(
                                                                            (student, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td className=" ">
                                                                                            {student.firstName}{" "}
                                                                                            {student.lastName}
                                                                                        </td>
                                                                                        <td className=" ">
                                                                                            {student?.user?.email}
                                                                                        </td>
                                                                                        <td className="  pointer-event">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                defaultChecked={true}
                                                                                                onChange={(e) => {
                                                                                                    handleCheckBox(e, student?.user?.id)
                                                                                                }}
                                                                                                style={{
                                                                                                    marginLeft: 12,
                                                                                                    marginTop: 12,
                                                                                                    alignItems: "center",
                                                                                                    fontSize: "26px",
                                                                                                    size: "26px"
                                                                                                }}
                                                                                                // component={CheckBoxComponent}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        )
                                                                        : ""}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>

                                            {studentList.length > 0 ? (
                                                <MUIDataTable
                                                    className="table-bordered table-dark table-light table-striped table-hover shadow-none"
                                                    title={values.channelType === "class"
                                                        ? "Student List"
                                                        : "Member List"}
                                                    data={studentList.flat().map(item => {
                                                        return [
                                                            item?.firstName,
                                                            item?.lastName,
                                                            item?.user?.email,
                                                            item?.user?.id
                                                        ]
                                                    })}
                                                    columns={column}
                                                    options={optionsStudents}
                                                />
                                            ) : (
                                                <td colSpan="12" className="text-center">
                                                    {studentLoader ? <Loader/> : <h5>No member found</h5>}
                                                </td>
                                            )}
                                            <div className="card-default">
                                                <p>
                                                    {" "}
                                                    Select Moderators /Admins {room.channelType === 'departmental' ? "/admins" : null}
                                                </p>
                                                <div className="form-group input-default-white col-4">

                                                    <label
                                                        className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                        {" "}
                                                        Search Select Moderators /Admins {" "}
                                                    </label>
                                                    <AsyncTypeahead
                                                        size={"lg"}
                                                        filterBy={filterBy}
                                                        id="async-example"
                                                        isLoading={isLoadingModerators}
                                                        labelKey="label"
                                                        minLength={3}
                                                        multiple={true}
                                                        selected={moderatorSelectedOption}
                                                        onSearch={handleModeratorSearch}
                                                        options={moderatorTypeAheadResults}
                                                        onChange={typeaheadSelectModerator}
                                                        placeholder="Type moderator name here..."
                                                        renderMenuItemChildren={option => (
                                                            <>
                                                                <div className="flex py-1">
                                                                    <div className="mb-2 text-info">
                                                                        Name: <b>{option.label}</b>
                                                                    </div>
                                                                    {" "}
                                                                    <div
                                                                        className={"border-top-1  text-secondary"}>
                                                                        Email:{option.fullObject.user.email}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            {moderatorSelectedOption.length > 0 ? (
                                                <div>
                                                    <h6 className="text-muted fw-500 mb-3">Selected Moderator List</h6>
                                                    <div
                                                        className="table-responsive table-light table-shadow radius-15">
                                                        <table
                                                            className="table table-light table-hover"
                                                            id="dataTable"
                                                            width="100%"
                                                            cellSpacing="0">
                                                            <thead>
                                                            <tr>
                                                                <th scope="col" className="text-center">
                                                                    Name
                                                                </th>
                                                                <th scope="col" className="text-center">
                                                                    Email
                                                                </th>
                                                                <th scope="col" className="text-center">
                                                                    Actions
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {moderatorSelectedOption.length > 0
                                                                ? moderatorSelectedOption.map(
                                                                    (member, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td className="text-center">
                                                                                    {member.fullObject.user.firstName}{" "}
                                                                                    {member.fullObject.user.lastName}
                                                                                </td>

                                                                                <td className="text-center">
                                                                                    {member.fullObject.user.email}
                                                                                </td>
                                                                                <td className="text-center ">
                                                                                    <DeleteOutlineRounded
                                                                                        onClick={() =>
                                                                                            deleteModerator(
                                                                                                member.fullObject.user.id
                                                                                            )
                                                                                        }
                                                                                        role={"button"}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                )
                                                                : ""}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            {room?.channelType === 'departmental' ?
                                                <div className="col-6 px-0 mb-4">
                                                    <div className="form-group input-default-white bg-transparent">
                                                        <h5
                                                            className="fw-600 mt-5 mb-2">
                                                            {" "}
                                                            Select Employee
                                                        </h5>

                                                        <AsyncTypeahead
                                                            size={"lg"}
                                                            filterBy={filterBy}
                                                            id="async-example"
                                                            isLoading={isLoadingModerators}
                                                            labelKey="label"
                                                            minLength={3}
                                                            multiple={true}
                                                            selected={employeeSelectedOption}
                                                            onSearch={handleModeratorSearch}
                                                            options={moderatorTypeAheadResults}
                                                            onChange={typeaheadSelectEmployee}
                                                            placeholder="Type Employee name here..."
                                                            renderMenuItemChildren={option => (
                                                                <>
                                                                    <div className="flex py-1">
                                                                        <div className="mb-2 text-info">
                                                                            Name: <b>{option.label}</b>
                                                                        </div>
                                                                        {" "}
                                                                        <div
                                                                            className={"border-top-1  text-secondary"}>
                                                                            Email:{option.fullObject.user.email}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </div> : null}
                                            {room.channelType === 'departmental' ?
                                                (employeeSelectedOption.length > 0 ? (
                                                    <div>
                                                        <h6 className="text-muted fw-500 mb-3">Selected Employee
                                                            List</h6>
                                                        <div
                                                            className="table-responsive table-light table-shadow radius-15">
                                                            <table
                                                                className="table table-light table-hover"
                                                                id="dataTable"
                                                                width="100%"
                                                                cellSpacing="0">
                                                                <thead>
                                                                <tr>
                                                                    <th scope="col" className="text-center">
                                                                        Name
                                                                    </th>
                                                                    <th scope="col" className="text-center">
                                                                        Email
                                                                    </th>
                                                                    <th scope="col" className="text-center">
                                                                        Actions
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {employeeSelectedOption.length > 0
                                                                    ? employeeSelectedOption.map(
                                                                        (member, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td className="text-center">
                                                                                        {member.fullObject.user.firstName}{" "}
                                                                                        {member.fullObject.user.lastName}
                                                                                    </td>

                                                                                    <td className="text-center">
                                                                                        {member.fullObject.user.email}
                                                                                    </td>
                                                                                    <td className="text-center ">
                                                                                        <DeleteOutlineRounded
                                                                                            onClick={() =>
                                                                                                deleteEmployee(
                                                                                                    member.fullObject.user.id
                                                                                                )
                                                                                            }
                                                                                            role={"button"}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )
                                                                    : ""}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                ) : null) : null
                                            }
                                            <div className="form-group text-center">
                                                <button
                                                    type="submit"
                                                    disabled={invalid}
                                                    className="btn btn-dark radius-25 mt-4 px-4">
                                                    Save
                                                </button>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                        <br/>
                    </div>
                </main>
            </CommonLayout>
        </>
    )
        ;
}

export default ChannelNew;

export class assignmentTaskDTOClass {
    constructor(name, submitType, attachment, assignment) {
        this.name = name;
        this.submitType = submitType;
        this.attachment = attachment;
        this.assignment = assignment;
    }
}
