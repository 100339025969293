import React, {useEffect, useState} from "react";
import * as UserTypeApi from "../../api/UserTypeApi";

import CommonLayout from "../layout/commonLayout";
import {useParams} from "react-router-dom";

function UserTypeView(props) {
    const params = useParams();
    const [userType, setUserType] = useState([]);
    useEffect(() => {
        UserTypeApi.getUserTypeById(params.id)
            .then(json => {
                console.log(json);
                setUserType(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);

    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h1 className="mt-4">User Type View</h1>
                        <br/>
                        <div className="card mb-4">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table
                                        className="table table-bordered"
                                        id="dataTable"
                                        width="100%"
                                        cellSpacing="0"
                                    >
                                        <thead>
                                        <tr>
                                            <th scope="col">Id</th>
                                            <td>{userType.id}</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">States</th>
                                            <td>{userType.userType}</td>
                                        </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default UserTypeView;
