import interceptor from "./InterceptorApi";

const requests = {
    get: url => {
        return interceptor
            .get(url)
            .then(function (response) {
                // handle success

                return requestUtil.handleRequest(response);
            })
            .catch(function (error) {
                // handle error

                return requestUtil.errorHandling(error);
            })
            .finally(function () {
                // always executed
            });
    },
    getV2: url => {
        return interceptor
            .get(url, {responseType: 'arraybuffer'})
            .then(function (response) {
                // handle success

                return requestUtil.handleRequest(response);
            })
            .catch(function (error) {
                // handle error

                return requestUtil.errorHandling(error);
            })
            .finally(function () {
                // always executed
            });
    },
    post: (url, param) => {
        return interceptor
            .post(url, param)
            .then(function (response) {
                // handle success
                return requestUtil.handleRequest(response);
            })
            .catch(function (error) {
                // handle error

                return requestUtil.errorHandling(error);
            })
            .finally(function () {
                // always executed
            });
    },
    postV2: (url, param) => {
        return interceptor
            .post(url, {'param': param, responseType: 'arraybuffer'})
            .then(function (response) {
                // handle success
                return requestUtil.handleRequest(response);
            })
            .catch(function (error) {
                // handle error

                return requestUtil.errorHandling(error);
            })
            .finally(function () {
                // always executed
            });
    },
    postMultipart: (url, param) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return interceptor
          .post(url, param, config)
          .then(function (response) {
              // handle success
              return requestUtil.handleRequest(response);
          })
          .catch(function (error) {
              // handle error

              return requestUtil.errorHandling(error);
          })
          .finally(function () {
              // always executed
          });
    },
    put: (url, param) => {
        return interceptor
            .put(url, param)
            .then(function (response) {
                // handle success
                return requestUtil.handleRequest(response);
            })
            .catch(function (error) {
                // handle error

                return requestUtil.errorHandling(error);
            })
            .finally(function () {
                // always executed
            });
    },
    delete: url => {
        return interceptor
            .delete(url)
            .then(function (response) {
                // handle success
                return requestUtil.handleRequest(response);
            })
            .catch(function (error) {
                // handle error

                return requestUtil.errorHandling(error);
            })
            .finally(function () {
                // always executed
            });
    }
};

const requestUtil = {
    handleRequest: response => {
        const contentType = response.headers["content-type"];
        if (contentType && contentType.indexOf("json") !== -1) {
            return {
                data: response.data,
                response: response
            };
        } else if (contentType && contentType.indexOf("text/plain") !== -1) {
            return {
                data: response.data,
                response: response
            };
        } else if (contentType && contentType.indexOf("text/xml") !== -1) {
            return response.blob().then(data => ({
                data: data,
                response: response
            }));
        } else if (contentType && contentType.indexOf("application/pdf") !== -1) {
            return {
                data: response.data,
                response: response
            };
        } else {
            return {
                data: undefined,
                response: response
            };
        }
    },
    errorHandling: error => {
        return {
            data: {
                type: "serverError",
                payload: {
                    message: "Server Error Happened " + error
                }
            },
            response: error.response
        };
    }
};

export default {requests};
