import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveChannel = channel => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/channel/", channel);
};
export const saveChannelMessage = channelMessages => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/channel-messages/",
        channelMessages
    );
};
export const deletePost = id => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/channel-messages/" + id);
};
export const createChannelPostWithAttachments = channelPostSaveDTO => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/channel-post-with-attachments/",
        channelPostSaveDTO
    );
};
export const saveChannelAttachment = channelMessagesAttachment => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/channel-attachments/",
        channelMessagesAttachment
    );
};
export const searchChannel = channelSearchDto => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/channel/search/",
        channelSearchDto
    );
};

export const updateChannel = channel => {
    return AxiosAgent.requests.put(API_MAIN_ROOT + "/channel/", channel);
};
export const getChannelById = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/channel/" + id);
};
export const getChannelListForAdmin = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/channel-for-admin");
};
export const getChannelListByUserId = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/channel-by-user-id/" + id);
};
export const getChannelListByModeratorUserId = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/channel-list-by-moderator-user-id/" + id);
};
export const deleteChannelById = id => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/channel/" + id);
};

export const getAll = userId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/channel");
};
// http://localhost:8081/api/channel-messages-by-group-id-v2/5
export const getChannelPostListByGroupId = (id, pageNo = 1) => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT +
        "/channel-messages-by-group-id-v2/" +
        id +
        `?size=5&page=${pageNo}`
    );
};
export const searchChannelPostListByGroupId = searchDto => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/channel-messages-by-group-id-v2",
        searchDto
    );
};

export const getChannelPostByPostId = id => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/channel-post-by-post-id/" + id
    );
};
export const getChannelMemberListByChannelId = id => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/channel-members-by-channel-id/" + id
    );
};
export const getChannelModeratorListByChannelId = id => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/channel-moderators-by-channel-id/" + id
    );
};
export const getChannelEmployeeListByChannelId = id => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/channel-employee/by-channel-id/" + id
    );
};
export const getChannelMemberListByChannelIdAndQuery = (id, query) => {
    console.log("xxxx" + query);
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/channel-members-by-channel-id-query/" + id + "/" + query
    );
};
export const getChannelModeratorListByChannelIdAndQuery = (id, query) => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/channel-moderators-by-channel-id/" + id + "/" + query
    );
};
export const getChannelEmployeeListByChannelIdAndQuery = (id, query) => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/channel-employee/by-channel-id/" + id + "/" + query
    );
};
export const getChannelListByLoggedUser = () => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/channel-by-logged-in-user");
};
export const saveMessageComment = commentDTO => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/post-comments", commentDTO);
};
export const registerMessageLike = likeDTO => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/register-channel-message-like",
        likeDTO
    );
};
export const registerCommentLike = likeDTO => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/register-channel-message-comment-like",
        likeDTO
    );
};
export const channelMaterialSave = channelMaterial => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/channel-materials",
        channelMaterial
    );
};

export const channelMaterialAttachmentSave = channelMaterialAttachment => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/channel-materials-attachments",
        channelMaterialAttachment
    );
};

export const getChannelMaterialsByChannelId = id => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/channel-materials-by-channel-id/" + id
    );
};

export const searchChannelMaterials = param => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/search-channel-materials",
        param
    );
};

export const deleteChannelMaterialByAttachmentId = id => {
    return AxiosAgent.requests.delete(
        API_MAIN_ROOT + "/channel-material-attachment/delete/" + id
    );
};
export const deleteChannelMaterialById = id => {
    return AxiosAgent.requests.delete(
        API_MAIN_ROOT + "/channel-material/delete/" + id
    );
};
export const getSmallCalenderData = channelId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/get-small-calender-data/" + channelId
    );
};
export const getSmallCalenderDataAll = () => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/get-small-calender-data-all/"
    );
};
export const getChannelPostListWithoutAttachment = param => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/channel-post-list-without-content/",
        param
    );
};
export const channelPostApproval = postApprovalDTO => {
    return AxiosAgent.requests.put(
        API_MAIN_ROOT + "/channel-post-approval",
        postApprovalDTO
    );
};
export const getAllDiscussGroupMembersByChannelIdAndEvaluationComplete = channelId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/channel-members/evaluation-complete/" + channelId
    );
};

export const channelCreateXlsx = (file) => {
    const formData = new FormData();
    formData.append('file', file)
    return AxiosAgent.requests.postMultipart(API_MAIN_ROOT + `/channel/upload-xlsx`, formData);
}
