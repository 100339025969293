import React, {useEffect, useState} from "react";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import * as AssignmentApi from "../../api/AssignmentApi";
import CommonLayout from "../layout/commonLayout";
import {getThisMonthNo, getTodayObject, getYear} from "../../util/DateUtils";
import {monthWiseData} from "../../util/commonDataset";
import BigCalender from "../Calendar/bigCalendar";
import MonthSlider from "../Calendar/monthSlider";

const HomeComponent = () => {
    const [monthStartDate, setMonthStartDate] = useState(getTodayObject());
    const [calenderEventList, setCalenderEventList] = useState([]);
    const [monthYear, setMonthYear] = useState({
        month: getThisMonthNo(),
        year: getYear()
    });

    useEffect(() => {
        if (monthYear) {
            let monthYearString = `${monthYear.month + "/" + monthYear.year}`;

            AssignmentApi.getCalenderData(monthYearString).then(res => {
                let events = res.data;
                for (let i = 0; i < events.length; i++) {
                    events[i].start = moment.utc(events[i].start).toDate();
                    events[i].end = moment.utc(events[i].end).toDate();
                    events[i].id = new Date(
                        moment.utc(events[i].start).toDate()
                    ).getUTCDate();
                    events[i].assignmentCount =
                        events[i].calenderEventType.length &&
                        events[i].calenderEventType === "assignment"
                            ? 1
                            : 0;
                    events[i].eventCount =
                        events[i].calenderEventType.length &&
                        events[i].calenderEventType === "event"
                            ? 1
                            : 0;
                }
                setCalenderEventList(events);
            });
        }
    }, [monthYear]);

    function handleMonthCb(month, year, start, end, defaultDate) {
        if (month) {
            setMonthYear({
                month: month,
                year: year
            });
            setMonthStartDate(defaultDate);
        }
    }

    return (
        <CommonLayout headerName={"Home"}>
            <div className="p-3">
                <div className="mx-auto flex items-center">
                    <MonthSlider
                        selected={getThisMonthNo()}
                        month={monthWiseData.map(obj => ({
                            key: obj.no,
                            value: obj.name,
                            no: obj.no
                        }))}
                        monthCb={handleMonthCb}
                    />
                </div>
            </div>
            <div className="h-full mb-32">
                {calenderEventList && (
                    <BigCalender
                        defaultDate={monthStartDate}
                        events={calenderEventList}
                    />
                )}
            </div>
        </CommonLayout>
    );
};

export default HomeComponent;
