import React, {useEffect, useRef, useState} from "react";
import {Field, FieldArray, Form, Formik} from "formik";

import "react-datepicker/dist/react-datepicker.css";
import * as SemesterApi from "../../api/SemesterApi";
import TextFieldComponent from "../common/TextFieldComponent";
import CommonLayout from "../layout/commonLayout";

import * as LinkFactory from "../../util/LinkFactory";
import * as GradingBreakdownApi from "../../api/GradingBreakdownApi";
import * as GradingApi from "../../api/GradingApi";
import * as LetterGradeChannelApi from "../../api/LetterGradeChannelApi";
import {toast} from "react-toastify";
import LoaderOverlay from "../common/LoaderOverlay";
import FormTitle from "../Typhography/formTitle";
import * as Utils from "../../util/Utils";
import {useNavigate, useParams} from "react-router-dom";


export default GradingEvaluationEdit;

function GradingEvaluationEdit(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const params = useParams();
    const [channel, setChannel] = useState({});
    const [semester, setSemester] = useState({});
    const [saving, setSaving] = useState(false);
    const [totalScore, setTotalScore] = useState(0.0);
    const [scores, setScores] = useState([]);
    const [semesterList, setSemesterList] = useState([]);
    const [deletedBreakdowns, setDeletedBreakdowns] = useState([]);
    const [letterGradeChannelList, setLetterGradeChannelList] = useState(null);
    const [grading, setGrading] = useState({});
    const letterGradeFrom = useRef(null);
    const gradingForm = useRef(null);

    const notifyUpdate = () => toast.success("Updated!", {
        position: toast.POSITION.TOP_CENTER
    });

    function handleDeleteBreakdownSelection(id) {
        let tempDeleteBreakdowns = deletedBreakdowns;
        tempDeleteBreakdowns.push(id);
        setDeletedBreakdowns(tempDeleteBreakdowns);
    }

    function handleBreakdownDelete() {
        deletedBreakdowns.forEach(breakdownId => {
            GradingBreakdownApi.deleteGradingBreakdownById(breakdownId);
        })
        redirectToGradingListPage();
    }

    function loadGrading() {
        GradingApi.getGradingById(params.id).then(res => {
            let gradingVar = res.data;
            setChannel(res.data.channel);
            findLetterGradeListByChannelId(res.data.channel.id);
            setSemester(res.data.semester);
            GradingBreakdownApi.getGradingBreakdownByGradingId(params.id).then(res => {
                let breakdownsVar = [];
                res.data.forEach((breakdown, index) => {
                    breakdownsVar.push(
                        {
                            evaluationName: breakdown.evaluationName,
                            fullScore: breakdown.fullScore,
                            id: breakdown.id
                        });
                    handleTotalScoreCalculationForLoading(breakdown.fullScore, index)
                });
                setGrading({
                    semester: gradingVar.semester,
                    breakdowns: breakdownsVar,
                    id: params.id
                })

            });
        })
    }

    const handleTotalScoreCalculationForLoading = (score, index) => {
        var temp = scores;
        temp[index] = parseFloat(score);
        setScores(temp);
        calculateTotalScore();
    };
    const handleTotalScoreCalculation = (score, index) => {
        var temp = scores;
        temp[index] = parseFloat(score.currentTarget.value);
        setScores(temp);
        calculateTotalScore();
    };

    function calculateTotalScore() {
        setTotalScore(0);
        var ttlScore = 0.0;
        for (var i = 0; i < scores.length; i++) {
            ttlScore = ttlScore + parseFloat(scores[i]);
        }
        setTotalScore(ttlScore);
    }

    function loadAllSemester() {
        SemesterApi.getAll().then(response => {
            setSemesterList(response.data);
            loadGrading();
        });

    }

    function findLetterGradeListByChannelId(id) {
        LetterGradeChannelApi.findLetterGradeListByChannelId(id).then(response => {
            setLetterGradeChannelList({list: [...response.data]});
        });
    }

    const handleSemesterSelection = option => {
        setSemester(option)
    };
    const handleLetterGradeSubmit = value => {

        let letterGradeChannelSaveChildDtos = [];
        value.forEach(v => {
            letterGradeChannelSaveChildDtos.push({
                id: v.id,
                serial: v.serial,
                letterGradeName: v.letterGradeName,
                upperValue: v.upperValue,
                gradePoint: v.gradePoint
            })
        });
        let letterGradeMaster = {
            gradingId: grading.id,
            channelId: channel.id,
            letterGradeChannelSaveChildDtoList: letterGradeChannelSaveChildDtos
        };
        if (null != letterGradeMaster.gradingId)
            LetterGradeChannelApi.save(letterGradeMaster).then(res => {
                handleBreakdownDelete();
            })
    }
    useEffect(() => {

        // channelById();
        loadAllSemester();

    }, []);

    const handleSubmit = value => {
        setSaving(true);
        let gradingDTO = {
            id: params.id,
            name: semester.semesterName,
            channel: channel,
            semester: semester
        };
        console.log(gradingDTO);
        GradingApi.updateGrading(gradingDTO).then(response => {
            setGrading(response.data);

            value.breakdowns.forEach(breakdown => {
                let gradingBreakDownDTO = {
                    id: null,
                    grading: "",
                    evaluationName: "",
                    fullScore: ""
                };
                if (null != breakdown.id) {
                    gradingBreakDownDTO.id = breakdown.id;
                }
                gradingBreakDownDTO.grading = response.data;
                gradingBreakDownDTO.evaluationName = breakdown.evaluationName;
                gradingBreakDownDTO.fullScore = breakdown.fullScore;
                null != breakdown.id ? GradingBreakdownApi.updateGradingBreakdown(gradingBreakDownDTO).then((res) => {
                    setSaving(false);
                }) : GradingBreakdownApi.saveGradingBreakdown(gradingBreakDownDTO).then((res) => {
                    setSaving(false);
                });
                notifyUpdate();

            });
        });
    };

    const redirectToGradingListPage = () => {
        navigateTo(
            LinkFactory.gradingByChannelId + "/" + channel.id
        );
    }

    const submitForms = () => {
        gradingForm.current.click();
        letterGradeFrom.current.click();
    };
    return (
        <>
            <CommonLayout headerName={Utils.getChannelName()}>
                <main>
                    <div className="grading-evaluation card-default">
                        {saving ? <LoaderOverlay/> : null}

                        <div className="title ">
                            <div className="mb-0 d-flex align-items-center">
                                <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                    <a href="#" className="pl-0 back-button">
                                        <i className="fa fa-chevron-left"> </i> </a>
                                </div>
                                <p>Evaluations</p>
                            </div>
                        </div>


                        <div>
                            <div>
                                <div className="row grading-table-edit">
                                    <div className="col-md-6">
                                        {letterGradeChannelList != null && (<Formik
                                            initialValues={letterGradeChannelList}
                                            onSubmit={values => {
                                                handleLetterGradeSubmit(values.list)
                                            }}
                                            render={({values, errors, touched, handleReset}) => {
                                                return (
                                                    <Form>
                                                        <h3> Grade Chart</h3>
                                                        <FieldArray
                                                            name="list"
                                                            render={({insert, remove, push}) => (
                                                                <div className="grading-table">
                                                                    <div className="row">
                                                                        <div className="col-4">
                                                                            <div className="form-group input-default-white">
                                                                                <p>Grade</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <div className=" form-group input-default-white">
                                                                                <p>Marks</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <div className="form-group input-default-white">
                                                                                <p>Grade Point</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {values.list.length > 0 &&
                                                                        values.list.map((val, index) => (
                                                                            <div className="row " key={index}>
                                                                                <div className="col-4">
                                                                                    <div className="form-group  input-default-white">
                                                                                        <Field
                                                                                            name={`list.${index}.letterGradeName`}
                                                                                            type="text"
                                                                                            placeholder="Letter Grade Name"
                                                                                            component={TextFieldComponent}
                                                                                            readOnly
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-4">
                                                                                    <div className="form-group input-default-white">
                                                                                        <Field
                                                                                            name={`list.${index}.upperValue`}
                                                                                            type="text"
                                                                                            placeholder="Score"
                                                                                            component={TextFieldComponent}
                                                                                            readOnly
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-4">
                                                                                    <div className="form-group input-default-white">
                                                                                        <Field
                                                                                            name={`list.${index}.gradePoint`}
                                                                                            type="text"
                                                                                            placeholder="Grade Point"
                                                                                            component={TextFieldComponent}
                                                                                            readOnly
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            )}
                                                        />
                                                        <button type="submit" ref={letterGradeFrom} className="hidden"
                                                                style={{display: "none"}}>
                                                            Submit
                                                        </button>
                                                    </Form>
                                                );
                                            }}
                                        />)}
                                    </div>
                                    <div className="col-md-1">
                                    </div>
                                    <div className="col-md-5">
                                        <Formik
                                            initialValues={grading}
                                            enableReinitialize={true}
                                            onSubmit={handleSubmit}
                                        >
                                            {({props, values}) => (
                                                <Form>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h5 className="text-right">Total Score : <b>{totalScore}</b></h5>
                                                        </div>
                                                    </div>
                                                    <br/>

                                                    <FieldArray
                                                        name="breakdowns"
                                                        render={arrayHelpers => (
                                                            <div className="w-100">
                                                                <div className="form-row">
                                                                    <div className="col-5">
                                                                        <div className="form-group input-default-white">
                                                                            <b>Evaluation Name</b>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-5">
                                                                        <div className="form-group input-default-white">
                                                                            <b>Score</b>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {values.breakdowns && values.breakdowns.length > 0
                                                                    ? values.breakdowns.map((breakdown, index) => (
                                                                        <span key={index} className="w-100">
                                                              <div className="form-row">
                                                                <div className="col-5">
                                                                  <div className="form-group input-default-white">
                                                                    <Field
                                                                        name={`breakdowns[${index}].evaluationName`}
                                                                        component={TextFieldComponent}
                                                                    />
                                                                  </div>
                                                                </div>
                                                                <div className="col-5">
                                                                  <div className="form-group input-default-white">
                                                                    <Field
                                                                        name={`breakdowns[${index}].fullScore`}
                                                                        onBlur={e => {
                                                                            handleTotalScoreCalculation(e, index)
                                                                        }}
                                                                        component={TextFieldComponent}
                                                                    />
                                                                  </div>
                                                                </div>
                                                                  <div className="col-2">
                                                                      <div className="form-group input-default-white">
                                                                        {/*<FormTitle> &nbsp;</FormTitle>*/}
                                                                          <button
                                                                              title="Delete"
                                                                              className="btn btn-outline-danger radius-25 d-block position-absolute right-0"
                                                                              type="button"
                                                                              onClick={() => {
                                                                                  arrayHelpers.remove(index);
                                                                                  handleDeleteBreakdownSelection(breakdown.id)
                                                                              }
                                                                              }>
                                                                          <i className="fa fa-trash"/>
                                                                        </button>
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                            </span>
                                                                    ))
                                                                    : ""}
                                                                <div className="form-row">
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-outline-dark-more radius-10 w-100 mt-4"
                                                                                onClick={() =>
                                                                                    arrayHelpers.push({
                                                                                        name: "",
                                                                                        evaluationName: "",
                                                                                        fullScore: ""
                                                                                    })
                                                                                }
                                                                            >
                                                                                <i className="fa fa-plus-circle mr-2"/>
                                                                                Add more evaluations
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    />
                                                    <button type="submit" ref={gradingForm} className="hidden"
                                                            style={{display: "none"}}>
                                                        Submit
                                                    </button>

                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <button
                                            type="submit"
                                            className="btn btn-dark radius-25 px-4 mt-5"
                                            onClick={submitForms}>
                                            <i className="fa fa-bookmark mr-2"/> Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </CommonLayout>
        </>
    );
}
