export const SERVER_NAME_URL = process.env?.REACT_APP_FILES_URL;
export const ENVIRONMENT_NAME = process.env?.REACT_APP_ENV_NAME;
export const OTP_SENT_SUCCESS = "A One-time password valid for 10-minutes has been sent to your email. Please check your spam/promotion folder if not received.";
export const ASSIGNMENT_NOTIFICATION_COUNTER = "assignment_notification_counter";
export const POST_NOTIFICATION_COUNTER = "post_notification_counter";
export const REGISTRATION_STATUS = "registration_status";
export const PARENT_ID = "parentId";
export const POST_COMMENT_NOTIFICATION_COUNTER = "post_comment_notification_counter";
export const EVENT_NOTIFICATION_COUNTER = "event_notification_counter";
export const REFRESH_TOKEN = "refreshToken";
export const TAG_ASSIGNMENT = "assignment";
export const TAG_MESSAGE = "message";
export const TAG_POST = "post";
