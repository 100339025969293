import React, {useEffect, useState} from "react";
import * as ChannelApi from "../../api/ChannelApi";
import {Field, Form, Formik} from "formik";

import "react-datepicker/dist/react-datepicker.css";
import TextFieldComponent from "../common/TextFieldComponent";
import CommonLayout from "../layout/commonLayout";
import * as Utils from "../../util/Utils";
import Swal from "sweetalert2";

import * as LinkFactory from "../../util/LinkFactory";
import LoaderOverlay from "../common/LoaderOverlay";
import {useNavigate, useParams} from "react-router-dom";

function ChannelMaterialUpload(props) {
    const params = useParams();
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    let fileBase64s = [];
    const [channel, setChannel] = useState({});
    const [files, setFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [channelMaterial, setChannelMaterial] = useState({});
    const [uploadLoader, setUploadLoader] = useState(false);
    useEffect(() => {
        ChannelApi.getChannelById(params.id).then(json => {
            setChannel(json.data);
        });
    }, []);

    async function channelMaterialUpload(channelMaterialDTO) {
        return await ChannelApi.channelMaterialAttachmentSave(channelMaterialDTO).then(res => {
            return res
        });
    }

    async function handleSubmit(values) {
        setUploadLoader(true);
        let channelMaterialDTO = {
            channel: channel,
            studyMaterialTitle: values.title
        };

        ChannelApi.channelMaterialSave(channelMaterialDTO).then(async res => {
            for (const value of files) {
                let channelMaterialDTO = {
                    channelStudyMaterials: res.data,
                    channelStudyMaterialBlob: value.blob,
                    channelStudyMaterialBlobType: value.type,
                    channelStudyMaterialFileName: value.fileName
                };
                const result = await channelMaterialUpload(channelMaterialDTO);
            }

        }).finally(function () {
            Swal.fire("Success", "File uploaded", "success");
            navigateTo(LinkFactory.channelStudyMaterialView + "/" + params.id);
        });
    };
    const handleChange = event => {
        fileBase64s = [];
        setFiles(fileBase64s);
        setSelectedFiles(event.target.files);
        Array.from(event.target.files).forEach(file => {
            fileToDataUri(file).then(data => {
                fileBase64s.push({blob: data.split("base64,")[1], type: file.type, fileName: file.name});
            });
        });
        setFiles(fileBase64s);
    };

    function handleUnselectFiles(name) {
        setSelectedFiles(Array.from(selectedFiles).filter((sFiles) => sFiles.name !== name));
        setFiles(Array.from(files).filter((fl) => fl.name !== name));
    }

    const fileToDataUri = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = event => {
                resolve(event.target.result);
            };
            reader.readAsDataURL(file);
        });

    return (
        <>
            <CommonLayout headerName={Utils.getChannelName()}>
                <main>
                    <div className="card-default global-repository-uploads">
                        <div className="title ">
                            <div className="mb-0 ">
                                <div className="card-header p-0 m-0 mr-1 ">
                                    <a href="#" className="pl-0 back-button">
                                        <i className="fa fa-chevron-left"> </i> Back</a>
                                </div>

                                <p className="mt-2"
                                   mb-5> {channel.channelType === 'class' ? 'Study Materials' : 'Global Repository'}</p>
                            </div>
                        </div>
                        {/*<h3 className="mt-4 mb-4"*/}
                        {/*    mb-5> {channel.channelType === 'class' ? 'Study Materials' : 'Global Repository'}</h3>*/}
                        {uploadLoader ? <LoaderOverlay/> : null}
                        <div>
                            <div>
                                <Formik
                                    initialValues={channelMaterial}
                                    enableReinitialize={true}
                                    onSubmit={handleSubmit}>
                                    {({props, values}) => (
                                        <Form>
                                            <div className="row">

                                                <div className="material-title">
                                                    <div className="form-group input-default-white">
                                                        <label>Material Title</label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="title"
                                                            placeholder="Give a title"
                                                            component={TextFieldComponent}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="choose-file">
                                                    <div className="form-group input-default-white">
                                                        {/*<FormTitle> &nbsp;</FormTitle>*/}
                                                        <div className="position-relative">
                                                            <input
                                                                className="custom-file-input"
                                                                id="file"
                                                                name="file"
                                                                type="file"
                                                                multiple
                                                                onChange={handleChange}
                                                            />
                                                            <label className="custom-file-label" htmlFor="file">
                                                                Choose file
                                                            </label>
                                                            <div className="submit-area" style={{marginTop: 20}}>
                                                                {Array.from(selectedFiles).length > 0 ? Array.from(selectedFiles).map((data, index) => {
                                                                    return (
                                                                        <div className="d-flex align-items-center justify-content-between">
                                                                        <p>{(index + 1) + ". " + data.name}</p>
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => handleUnselectFiles(data.name)}
                                                                            className="btn btn-cancel">
                                                                            <i className="fa fa-close"> </i>
                                                                        </button>
                                                                    </div>);
                                                                }) : ""}

                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div className="upload-btn d-flex mt-3">
                                                    {/*<FormTitle> &nbsp;</FormTitle>*/}
                                                    <button
                                                        type="submit"
                                                        className="btn btn-purple-md radius-25 d-block mr-3">
                                                        {/*<i className="fa fa-upload mr-2"/>*/}
                                                        Upload
                                                    </button>
                                                    <div className="cancel-btn">
                                                        {/*<FormTitle> &nbsp;</FormTitle>*/}
                                                        <button
                                                            type="cancel"
                                                            className="btn btn-cancel radius-25 d-block">
                                                            {/*<i className="fa fa-upload mr-2"/>*/}
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">

                                            </div>
                                            <div className="row">

                                            </div>

                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>

                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default ChannelMaterialUpload;
