import React, {useEffect} from "react";
import * as Utils from "../../util/Utils";
import {connect, useDispatch} from "react-redux";
import SideNavMenu from "./sideNavMenu";
import * as LinkFactory from "../../util/LinkFactory";
import {fetchChannelsApi} from "../../storeManager/channel/middleware/ChannelMiddleware";
import SkeletonView from "../SkeletonView";
import {useNavigate, useParams} from "react-router-dom";
import AdSense from 'react-adsense';

const setupRoute = [
    {
        name: "Departments",
        path: LinkFactory.departmentList,
        role: "DEPARTMENT_LEFT_MENU"
    },
    {
        name: "Employees",
        path: LinkFactory.employeeList,
        role: "EMPLOYEE_LEFT_MENU"
    },
    {
        name: "Evaluation Question Set",
        path: LinkFactory.evaluationQuestionMainList,
        role: "EVALUATION_LEFT_MENU"
    },
    {
        name: "Evaluation Question Type List",
        path: LinkFactory.evaluationQuestionTypeList,
        role: "EVALUATION_LEFT_MENU"
    },
    {
        name: "Evaluation Question List",
        path: LinkFactory.evaluationQuestionList,
        role: "EVALUATION_LEFT_MENU"
    },
    {
        name: "Evaluation Question Option List",
        path: LinkFactory.evaluationQuestionOptionList,
        role: "EVALUATION_LEFT_MENU"
    },
    {
        name: "Evaluation Info List",
        path: LinkFactory.evaluationInfoList,
        role: "EVALUATION_LEFT_MENU"
    },
    {
        name: "Faculty",
        path: LinkFactory.facultyList,
        role: "FACULTY_LEFT_MENU"
    },
    {
        name: "Letter Grades",
        path: LinkFactory.letterGradeNew,
        role: "LETTER_GRADE_LEFT_MENU"
    },
    {
        name: "Programs",
        path: LinkFactory.programList,
        role: "PROGRAM_LEFT_MENU"
    },
    // {
    //     name: "Subject ",
    //     path: LinkFactory.subject
    // },
    // {
    //     name: "Institute District ",
    //     path: LinkFactory.instituteDistrict
    // },
    // {
    //     name: "Institute ",
    //     path: LinkFactory.institute
    // },
    {
        name: "Room Setup",
        path: LinkFactory.channelList,
        role: "ROOM_LEFT_MENU"

    },
    {
        name: "Students",
        path: LinkFactory.studentsList,
        role: "STUDENT_LEFT_MENU"
    },
    {
        name: "Semesters",
        path: LinkFactory.semesterList,
        role: "SEMESTER_LEFT_MENU"
    },
    {
        name: "Search Student Result",
        path: LinkFactory.studentCourseHistory,
        role: "STUDENT_RESULT_LEFT_MENU"
    },
    // ,
    // {
    //     name: "Parent Registration Approval",
    //     path: LinkFactory.parentRegistrationApproval
    // }
];

function SideNav(props) {
    const dispatch = useDispatch();
    const params = useParams();
    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }

    useEffect(() => {
        if (params.channels == null) {
            dispatch(fetchChannelsApi());
        }
    }, [params.channels]);


    return (
        <>
            <div id="layoutSidenav_nav">
                <nav
                    className="sb-sidenav accordion border-right-1"
                    id="sidenavAccordion"
                >
                    <div className="sb-sidenav-menu">
                        <div className="nav">
                            {/*<div className="sb-sidenav-menu-heading">Rooms</div>*/}
                            {/*<div className="logo-icon">*/}
                            {/*  <img src="img/logo_icon.png"/>*/}
                            {/*</div>*/}
                            {!props.loading ? (
                                <div className="">
                                    {props.channels &&
                                        props.channels
                                            .filter(obj => obj.channelType === "organizational")
                                            .map((data, index) => (
                                                <a className="nav-link sidenav-border" key={index}>
                                                    <button
                                                        type="button"
                                                        className="dropdown-sidenav py-3"
                                                        onClick={() =>
                                                            navigateTo("/room-board/" + data.id)
                                                        }>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                                                             fill="none">
                                                            <path fill="#141944"
                                                                  d="M15.357.5H8.5a1.144 1.144 0 0 0-1.143 1.143v5.714H1.643A1.144 1.144 0 0 0 .5 8.5v8h16V1.643A1.144 1.144 0 0 0 15.357.5ZM4.5 15.357v-4h2.286v4H4.5Zm10.857 0H7.93v-4.571a.571.571 0 0 0-.572-.572H3.93a.571.571 0 0 0-.572.572v4.571H1.643V8.5H8.5V1.643h6.857v13.714Z"/>
                                                            <path fill="#141944"
                                                                  d="M9.033 4.02H10.1v1.173H9.033V4.02Zm3.2 0H13.3v1.173h-1.067V4.02Zm-3.2 3.52H10.1v1.173H9.033V7.54Zm3.2 0H13.3v1.173h-1.067V7.54Zm-3.2 3.52H10.1v1.173H9.033V11.06Zm3.2 0H13.3v1.173h-1.067V11.06ZM.5 5.193h2.667v1.174H.5V5.193ZM4.767.5h1.066v2.933H4.767V.5Zm-3.2 2.003.754-.83 1.912 2.104-.754.83-1.912-2.104Z"/>
                                                        </svg>
                                                        <div>
                                                            <h5 className="fw-700">{data.channelName}</h5>
                                                        </div>
                                                    </button>
                                                </a>
                                            ))
                                    }

                                    {props.channels && props.channels.length > 0 ? (
                                        <div className="mx-4 sidenav-last-child">
                                            <SideNavMenu
                                                route={props.channels
                                                    .filter(obj => obj.channelType === "faculty")
                                                    .map(function (data) {
                                                        return {
                                                            name: data.channelName,
                                                            path: data.id
                                                        };
                                                    })}
                                                title={"Faculty"}
                                            />
                                            <SideNavMenu
                                                route={props.channels
                                                    .filter(obj => obj.channelType === "departmental")
                                                    .map(function (data) {
                                                        return {
                                                            name: data.channelName,
                                                            path: data.id
                                                        };
                                                    })}
                                                title={"Department"}
                                            />
                                            <SideNavMenu
                                                route={props.channels
                                                    .filter(obj => obj.channelType === "class")
                                                    .map(function (data) {
                                                        return {
                                                            name: null != data.courseCode ? data.courseCode + " :  " + data.channelName : data.channelName,
                                                            path: data.id
                                                        };
                                                    })}
                                                title={"Courses"}
                                            />
                                            <SideNavMenu
                                                route={props.channels
                                                    .filter(obj => obj.channelType === "co-currullar")
                                                    .map(function (data) {
                                                        return {
                                                            name: data.channelName,
                                                            path: data.id
                                                        };
                                                    })}
                                                title={"Co-curricular"}
                                            />

                                            {Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_EXAM_CONTROLLER"]) ? (
                                                <SideNavMenu route={setupRoute} title={"Setup"}/>
                                            ) : (
                                                ""
                                            )}

                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            ) : (
                                <div className="w-100">
                                    <SkeletonView count={17}/>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className=" ">
                        <div className="ad-sense-position-sidenav " style={{height: '300px !important'}}>
                            <AdSense.Google
                                client="ca-pub-2042111752026323"
                                slot="8873178074"
                                format="200x200"
                                responsive="true"
                                style={{display: 'inline-block', width: '200px', height: '200px'}}
                            />
                        </div>
                    </div>

                </nav>
            </div>
        </>
    );

}

const mapStateToProps = state => ({
    channels: state.channels.channels,
    loading: state.channels.loading,
    error: state.channels.error
});

export default connect(mapStateToProps)(SideNav);
