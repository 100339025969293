import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const getGradingCalculatedResultByChannelId = channelId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/grade-book-by-channel-id/" + channelId
    );
};
export const getGradingForCalculatedResultByUserId = userId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/grade-book-by-user-id/" + userId
    );
};

export const resultSheetCalculationByStudentId = userId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/result-sheet-calculation/" + userId
    );
};

export const studentCourseHistory = studentRollOrReg => {
    return AxiosAgent.requests.get(
      API_MAIN_ROOT + "/student-course-history-by-rollno-or-regno/" + studentRollOrReg
    );
};

export const generateReportForResultSheetCalculationByUserId = userId => {
    return AxiosAgent.requests.getV2(
        API_MAIN_ROOT + "/result-sheet-calculation-report/" + userId
    );
};

export const searchGradingCalculatedResult = gradeBookSearchDTO => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/search-grade-book",
        gradeBookSearchDTO
    );
};

export const downloadGradingCalculatedResult = gradeBookSearchDTO => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/download-grade-book",
        gradeBookSearchDTO
    );
};

export const getStudentsGradeBookXlsx = gradeBookSearchDTO => {
    return AxiosAgent.requests.postV2(
        API_MAIN_ROOT + "/students-gradebook-excel",
        gradeBookSearchDTO
    );
};

export const searchChannelList = resultSearchParamDto => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/search-channel-list",
        resultSearchParamDto
    );
};