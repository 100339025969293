import fetch from "cross-fetch";
import Cookies from "js-cookie";
import _ from "lodash";
import * as AuthApi from "./AuthApi";
import *  as Utils from "../util/Utils";
import {REFRESH_TOKEN} from "../constants/Constants";

const API_ROOT = process.env.REACT_APP_JAVA_APP_URL;
const API_MAIN_ROOT = process.env.REACT_APP_JAVA_MAIN_APP_URL;

const Auth = {
    login: form => {
        return requests.login(API_MAIN_ROOT + "/auth/authenticate", {
            email: form.lgEmail,
            password: form.passwd
        });
    },
    createRefreshToken:() =>{
      requests.createRefreshToken();
    },
    forgotPassword: form => {
        return requests.forgotPassword(
            API_MAIN_ROOT + "/users/account/send-otp-by-email",
            {'email':form.email}
        );
    },
    resetPassword: data => {
        return requests.resetPassword(
            API_MAIN_ROOT + "/users/account/reset-password-otp", data);
    }
};

const Register = {
    register: form => {
        return requests.postForUnAuthenticated(
            "/investor-registration/personal-info",
            {
                email: form.email,
                userName: form.email,
                password: form.password,
                firstName: form.firstName,
                middleName: form.middleName,
                lastName: form.lastName,
                phoneNumber: form.phoneNumber,
                phoneCountryCode: form.phoneCountryCode,
                dateOfBirth: form.dateOfBirth,
                agree: form.iAgree,
                referrer: form.referrer
            }
        );
    },

    checkEmailDuplicacy: emailAddress => {
        return requests.postForUnAuthenticated(
            "/users/userEmailNumberDuplicacyCheck",
            {email: emailAddress}
        );
    },
    checkPhoneNumberDuplicacy: phoneNumber => {
        return requests.getForUnAuthenticated(
            "/users/userCellNumberDuplicacyCheck/" + phoneNumber
        );
    },
    verifyOtp: otp => {
        return requests.getForUnAuthenticated(
            "/user/confirm/" + otp
        );
    },
    resendOtp: form => {
        return requests.postForUnAuthenticated("/otp-managements/resend-otp", {
            oldEmail: form.emailToVerify,
            newEmail: form.email
        });
    },
    saveCitizenship: citizenShip => {
        return requests.put("/investor-registration/citizen", {
            citizenShip: citizenShip
        });
    },
    loadCountryById: id => {
        return requests.get("/area-configurations/" + id);
    },
    loadCountryList: () => {
        return requests.get(
            "/area-configurations/getAreaConfigurationForNationality"
        );
    },
    loadResidentialCountryList: () => {
        return requests.get("/area-configurations/getAllResidentialCountries");
    },
    loadCityListByCountry: countryId => {
        return requests.get(
            "/area-configurations/get-cities-of-country/" + countryId + "/city"
        );
    },
    saveAddress: form => {
        return requests.put("/investor-registration/residential-address", {
            addressLine: form.addressLine,
            postalCode: form.postalCode,
            city: _.find(form.cities, {id: +form.city}),
            country: _.find(form.countries, {id: +form.countryId})
        });
    },

    saveEmploymentStatus: form => {
        return requests.put("/investor-registration/employment-status", {
            employmentStatus: form.employment
        });
    },

    saveInvestmentGoal: form => {
        return requests.post("/appropriateness-answer-submit", {
            appropriatenessSubmitDTOSet: form.investmentGoals
        });
    },

    loadEmploymentStatusList: () => {
        return requests.get("/employment-statuses");
    },

    loadInvestmentGoalList: () => {
        return requests.get("/investment-goal-setup-all");
    },

    loadInvestorTypes: () => {
        return requests.get("/investor-three-types");
    },

    loadAppropriatenessList: () => {
        return requests.get("/appropriateness-test-question-setup-with-answer-set");
    },
    saveInvestorCategory: category => {
        return requests.post("/investor-category", {
            category: category
        });
    },
    loadOccupations: () => {
        return requests.get("/occupations");
    },
    loadIndustry: () => {
        return requests.get("/industry-ratings");
    },
    loadNetAssets: () => {
        return requests.get("/net-assets");
    },
    loadAnnualIncome: () => {
        return requests.get("/investor-annual-salaries");
    },
    saveJobDescription: form => {
        return requests.put("/investor-registration/job-description", {
            employerName: form.employerName,
            occupation: _.find(form.occupations, {id: +form.occupation}),
            natureOfEmplBusiness: _.find(form.industries, {id: +form.industry})
        });
    },
    saveNetAsset: form => {
        return requests.put("/investor-registration/net-asset", {
            netAssets: form.netAsset
        });
    },
    saveAnnualIncome: form => {
        return requests.put("/investor-registration/annual-income", {
            annualIncome: form.annualIncome
        });
    },
    saveDeclaration: form => {
        return requests.put("/investor-registration/agree", {
            agree: form.iAgreeTandC,
            acknowledge: form.iAgreeToDeclaration
        });
    },
    registrationPath: () => {
        return requests.put("/investor-registration/inv-reg-path", {});
    },
    removeFile: dto => {
        return requests.deleteForUnAuthenticated(
            API_MAIN_ROOT + "/attachment-managements/" + dto.id,
            {}
        );
    },
    sendBrowserCloseEvent: () => {
        return requests.get(
            "/investor-registrations/send-email-to-investor-on-browser-close"
        );
    },
    getOnfidoSDKToken: () => {
        return requests.get("/investor-registration/sdk-token");
    },
    startOnfidoCheck: data => {
        return requests.put("/investor-registration/start-onfido-check", {
            passport: data.document_front,
            selfie: data.face
        });
    },
    updateResidenceInfo: data => {
        return requests.put("/investor-registration/proof-of-residence", {
            front: data.document_front,
            back: data.document_back
        });
    },
    loadUserNameByReferralLink: referralLink => {
        return requests.getForUnAuthenticatedFromMain(
            "/referral-link/user-name-by-referral-link" +
            "?referralLink=" +
            referralLink
        );
    }
};

const requests = {
    login: (url, param) => {
        let form = new FormData();
        form.append("username", param.email);
        form.append("password", param.password);
        return fetch(url, {
            method: "post",
            body: form,
            headers: {
                "Access-Control-Allow-Credentials": true
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    createRefreshToken:()=> {
        AuthApi.createRefreshToken({"username": Utils.getUserFromLocalStorage().login})
            .then(json => {
                localStorage.setItem(
                    REFRESH_TOKEN,
                    JSON.stringify(json.data.token));
            })
            .catch(errors => {
                console.log(errors);
            })
    },
    forgotPassword: (url, param) => {
        return fetch(url, {
            method: "post",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    resetPassword: (url, param) => {
        return fetch(url, {
            method: "post",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    postForUnAuthenticated: (url, param) => {
        return fetch(API_ROOT + url, {
            method: "post",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    deleteForUnAuthenticated: (url, param) => {
        return fetch(url, {
            method: "delete",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    putForUnAuthenticated: (url, param) => {
        return fetch(API_ROOT + url, {
            method: "put",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    putForUnAuthenticatedWithHeader: (url, param) => {
        return fetch(API_ROOT + url, {
            method: "put",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    post: (url, param) => {
        return fetch(API_ROOT + url, {
            method: "post",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json",
                Authorization: Cookies.get("auth-token")
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    put: (url, param) => {
        return fetch(API_ROOT + url, {
            method: "put",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json",
                Authorization: Cookies.get("auth-token")
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    postFile: (url, fileData, data) => {
        let form = new FormData();
        form.append("file", fileData);
        form.append(
            "attachmentManagement",
            new Blob([JSON.stringify(data)], {
                type: "application/json"
            })
        );

        return fetch(API_MAIN_ROOT + url, {
            method: "post",
            body: form,
            headers: {
                Authorization: Cookies.get("auth-token")
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    get: url => {
        return fetch(API_MAIN_ROOT + url, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                Authorization: Cookies.get("auth-token")
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    getForUnAuthenticatedFromMain: url => {
        return fetch(API_MAIN_ROOT + url, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    getForUnAuthenticated: url => {
        return fetch(API_MAIN_ROOT + url, {
            method: "get",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    }
};

const requestError = {
    errorHandling: error => {
        console.log(error);
        return {
            data: {
                type: "serverError",
                payload: {
                    message: "Server Error Happened " + error
                }
            },
            response: undefined
        };
    }
};

const requestUtil = {
    handleRequest: response => {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(data => ({
                data: data,
                response: response
            }));
        } else if (contentType && contentType.indexOf("text/plain") !== -1) {
            return response.text().then(data => ({
                data: data,
                response: response
            }));
        } else if (contentType && contentType.indexOf("text/xml") !== -1) {
            return response.blob().then(data => ({
                data: data,
                response: response
            }));
        } else {
            return {
                data: undefined,
                response: response
            };
        }
    }
};

export default {
    Auth,
    Register
};
