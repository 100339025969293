import React, {useEffect, useState} from "react";
import * as SportsTypeApi from "../../api/SportsTypeApi";

import CommonLayout from "../layout/commonLayout";
import {useParams} from "react-router-dom";

function SportsTypeView(props) {
    const params = useParams();
    const [sportsType, setSportsType] = useState([]);

    useEffect(() => {
        SportsTypeApi.getSportsTypeById(params.id)
            .then(json => {
                setSportsType(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);

    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h1 className="mt-4">Sports Type View</h1>
                        <br/>
                        <div className="card mb-4">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table
                                        className="table table-bordered"
                                        id="dataTable"
                                        width="100%"
                                        cellSpacing="0"
                                    >
                                        <thead>
                                        <tr>
                                            <th scope="col">Id</th>
                                            <td>{sportsType.id}</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Sports Type</th>
                                            <td>{sportsType.sportsType}</td>
                                        </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default SportsTypeView;
