import React, {useEffect, useState} from "react";
import CommonLayout from "../layout/commonLayout";
import FooterComponent from "../Nav/footer";
import * as StudentApi from "../../api/StudentApi";
import * as Utils from "../../util/Utils";
import SelectDropdown from "../common/SelectDropwodn";
import {Field, Form, Formik} from "formik";
import * as ChannelApi from "../../api/ChannelApi";
import {useDispatch} from "react-redux";
import {fetchChannelsSuccess} from "../../storeManager/channel/action/channelAction";


const ParentsViewChannels = (props) => {
    const [studentList, setStudentList] = useState([]);
    const [studentSelection, setStudentSelection] = useState({firstName: ""});
    const dispatch = useDispatch();
    const loadStudents = () => {

        StudentApi.getAllStudentByLoginParent(Utils.getParentIdFromLocalStorage()).then(
            json => {
                console.log('log');
                console.log(json.data);
                setStudentList(json.data)
            }
        )
    }

    useEffect(() => {
        loadStudents();
    }, []);
    const handleSubmit = () => {

    }
    const handleStudentSelection = (fieldName, value) => {
        console.log(value);
        ChannelApi.getChannelListByUserId(value.user.id).then(
            json => {
                // setChannels(json.data);
                dispatch(fetchChannelsSuccess(json.data));
            }
        )
    }
    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h1 className="mt-4">Children Channels</h1>
                        <Formik
                            initialValues={studentSelection}
                            onSubmit={handleSubmit}>
                            {({props, values}) => (
                                <Form>
                                    <div className="card mb-4">
                                        <div className="block-50">
                                            <label className="form-label">Child</label>
                                            <Field
                                                type="text"
                                                name="Child"
                                                placeholder="Select Your Child"
                                                fieldName={"firstName"}
                                                label="Child"
                                                handleChangeSection={handleStudentSelection}
                                                options={studentList || []}
                                                component={SelectDropdown}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </main>
                <FooterComponent/>
            </CommonLayout>
        </>
    );
}

export default ParentsViewChannels;
