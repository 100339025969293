import React, {useEffect, useState} from "react";
import CommonLayout from "../layout/commonLayout";
import * as EmployeeApi from "../../api/EmployeeApi";
import Loader from "../common/Loader";
import {SERVER_NAME_URL} from "../../constants/Constants";
import * as LinkFactory from "../../util/LinkFactory";
import {FILES_URL} from "../../util/LinkFactory";
import PageError from "../ErrorPage";
import * as ChannelApi from "../../api/ChannelApi";
import * as Utils from "../../util/Utils";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import * as AssessmentApi from "../../api/AssessmentApi";
import {AssignmentStatusEnum} from "../../enum/AssignmentStatusEnum";

const defaultData = {
    id: "",
    firstName: "",
    middleName: "",
    lastName: "",
    grade: "",
    section: "",
    fatherName: "",
    motherName: "",
    permanentAddress: "",
    presentAddress: "",
    photoName: "",
    regId: "",
    emergencyContactPerson: "",
    emergencyContact: "",
    bloodGroup: "",
    uuid: "",
    userId: null
};

function TeacherProfile() {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [employeeInfo, setEmployeeInfo] = useState(defaultData);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const [channelList, setChannelList] = useState(false);
    const [allAssignmentDraftList, setAllAssignmentDraftList] = useState([]);
    const [allAssignmentSavedList, setAllAssignmentSavedList] = useState([]);
    const [allAssignmentArchivedList, setAllAssignmentArchivedList] = useState([]);
    const [user, setUser] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    let fileBase64s = [];
    const [files, setFiles] = useState([]);
    let PhotoRootUrl;

    const [saveListPageNumber, setSaveListPageNumber] = useState(0);
    const [draftListPageNumber, setDraftListPageNumber] = useState(0);
    const [archiveListPageNumber, setArchiveListPageNumber] = useState(0);
    const [saveListPageCount, setSaveListPageCount] = useState(0);
    const [draftListPageCount, setDraftListPageCount] = useState(0);
    const [archiveListPageCount, setArchiveListPageCount] = useState(0);

    useEffect(() => {
        setUser(Utils.getUserFromLocalStorage());
        getEmployeeInformation(Utils.getUserFromLocalStorage().id);
        getChannelLIst(Utils.getUserFromLocalStorage().id);
        allAssignmentDraftByLoggedInUser();
        allAssignmentArchivedByLoggedInUser();
        allAssignmentSavedByLoggedInUser();
    }, []);

    const getChannelLIst = id => {
        ChannelApi.getChannelListByModeratorUserId(id).then(
            json => {
                setChannelList(json.data);
            }
        )
    }
    const channelTypeConversion = channelType => {
        if (channelType === 'class')
            return "Class"
        else if (channelType === 'co-currullar')
            return "Co - Curricular"
        else if (channelType === 'organizational')
            return "Institute Room"
    }

    const getEmployeeInformation = id => {
        setLoader(true);
        EmployeeApi.getEmployeeByUserId(id)
            .then(json => {
                PhotoRootUrl = SERVER_NAME_URL + json.data.photoName;
                let response = json.data || defaultData;
                setEmployeeInfo(response);
            })
            .catch(errors => {
                setError(true);
            })
            .finally(() => setLoader(false));
    };
    const allAssignmentDraftByLoggedInUser = () => {
        AssessmentApi.allAssessmentsByLoggedInUserAndStatus(
            {
                assignmentStatus: AssignmentStatusEnum.DRAFT,
                page: draftListPageNumber
            }
        )
            .then(json => {
                console.log(json.data.content);
                setAllAssignmentDraftList(json.data.content);
                setDraftListPageCount(json?.data?.totalPages);
            })
            .catch(e => console.log(e))
            .finally(() => setLoader(false));
    }

    const allAssignmentSavedByLoggedInUser = () => {
        AssessmentApi.allAssessmentsByLoggedInUserAndStatus(
            {
                assignmentStatus: AssignmentStatusEnum.SAVED,
                page: saveListPageNumber
            }
        )
            .then(json => {
                console.log(json.data.content);
                setAllAssignmentSavedList(json.data.content);
                setSaveListPageCount(json?.data?.totalPages);
            })
            .catch(e => console.log(e))
            .finally(() => setLoader(false));
    }

    const allAssignmentArchivedByLoggedInUser = () => {
        AssessmentApi.allAssessmentsByLoggedInUserAndStatus(
            {
                assignmentStatus: AssignmentStatusEnum.ARCHIVED,
                page: archiveListPageNumber
            }
        )
            .then(json => {
                setAllAssignmentArchivedList(json.data.content);
                setArchiveListPageCount(json?.data?.totalPages);
            })
            .catch(e => console.log(e))
            .finally(() => setLoader(false));
    }

    const handleProfilePicInputChange = event => {
        fileBase64s = [];
        setFiles(fileBase64s);
        setSelectedFiles(event.target.files);
        Array.from(event.target.files).forEach(file => {
            fileToDataUri(file).then(data => {
                fileBase64s.push({blob: data.split("base64,")[1], type: file.type, fileName: file.name});
            });
        });
        console.log(fileBase64s);
        setFiles(fileBase64s);
    };

    const handleEvaluationResult = data => {
        navigateTo(LinkFactory.evaluationResult + "/" + data.id);
    };

    const fileToDataUri = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = event => {
                resolve(event.target.result);
            };
            reader.readAsDataURL(file);
        });

    const handleArchived = (event) => {
        console.log(event.target);
        AssessmentApi.AssessmentsStatusUpdate().then(res => {
            console.log(res.data);
        })
    }


    function proPicUpload() {
        console.log(files);
        let profilePicUploadDto = {
            photoBlob: files[0].blob,
            photoBlobType: files[0].type,
            userId: employeeInfo.user.id
        };
        uploadProfilePicture(profilePicUploadDto).then(r => {
            toast("Profile Picture Uploaded!")
        });
    }

    async function uploadProfilePicture(profilePicUploadDto) {
        await EmployeeApi.uploadEmployeeProfilePicture(profilePicUploadDto).then(
            response => {
                flushFileUpload();
                getEmployeeInformation(Utils.getUserFromLocalStorage().id);
            }
        );
    }

    function flushFileUpload() {
        setFiles([]);
        setSelectedFiles([]);
        fileBase64s = [];
    }

    if (employeeInfo && !loader) {
        return (
            <>
                <CommonLayout headerName={"Teacher's Information"}>
                    <main>
                        <div>
                            {error ? (
                                <PageError title={"No data to show"}/>
                            ) : (
                                <div className="card-default teachers-information">
                                    <div className="title">
                                        <div className="mb-0 d-flex align-items-center">
                                            <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                                <a href="#" className="pl-0 back-button">
                                                    <i className="fa fa-chevron-left"> </i> </a>
                                            </div>

                                        </div>
                                    </div>


                                    <div className="tab-blue">
                                        <div className="tabs">
                                            <ul
                                                className="nav nav-pills mb-4 pl-0"
                                                id="pills-tab3"
                                                role="tablist">
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link active"
                                                        id="pills-assignment-upcoming"
                                                        data-toggle="pill"
                                                        href="#pills-assignment"
                                                        role="tab"
                                                        aria-controls="pills-assignment"
                                                        aria-selected="true">
                                                        Teacher's Info
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        id="pills-assignment-inprogress"
                                                        data-toggle="pill"
                                                        href="#pills-inprogress"
                                                        role="tab"
                                                        aria-controls="pills-inprogress"
                                                        aria-selected="false">
                                                        Rooms
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        id="pills-draft-question"
                                                        data-toggle="pill"
                                                        href="#draft-question"
                                                        role="tab"
                                                        aria-controls="draft-question"
                                                        aria-selected="false">
                                                        Draft Question
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        id="pills-question-saved"
                                                        data-toggle="pill"
                                                        href="#question-saved"
                                                        role="tab"
                                                        aria-controls="question-saved"
                                                        aria-selected="false">
                                                        Save Question
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        id="pills-question-archive"
                                                        data-toggle="pill"
                                                        href="#question-archive"
                                                        role="tab"
                                                        aria-controls="question-archive"
                                                        aria-selected="false">
                                                        Question Archive
                                                    </a>
                                                </li>

                                            </ul>
                                            <div className="tab-content" id="pills-tabContent">
                                                <div
                                                    className="tab-pane fade show active"
                                                    id="pills-assignment"
                                                    role="tabpane2"
                                                    aria-labelledby="pills-assignment-upcoming">
                                                    <div className="tab-heading mx-auto">
                                                        <p>Teacher's Information</p>
                                                        <div
                                                            className="teachers-info-tab table-responsive table-light">
                                                            <table
                                                                className="table table-light table-hover"
                                                                id="dataTable">
                                                                <tbody>
                                                                <tr>
                                                                    <td> Teacher's Info</td>
                                                                </tr>

                                                                <tr>
                                                                    <td> Teacher's Name</td>
                                                                    <td>
                                                                        {employeeInfo?.firstName} {employeeInfo?.middleName}{" "}
                                                                        {employeeInfo?.lastName}
                                                                    </td>
                                                                </tr>


                                                                <tr>
                                                                    <td> Registrar ID</td>
                                                                    <td>
                                                                        12345678
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    {/*<td> Teacher's Photo</td>*/}
                                                                    <td>Photo</td>
                                                                    <td>
                                                                        {employeeInfo?.user?.imageUrl ? (
                                                                            <img
                                                                                style={{
                                                                                    width: "60px",
                                                                                    height: "60px",
                                                                                    objectFit: "cover",
                                                                                    borderRadius: "5px"
                                                                                }}
                                                                                src={FILES_URL + employeeInfo?.user?.imageUrl}
                                                                                alt={employeeInfo?.firstName}
                                                                            />
                                                                        ) : (
                                                                            "No photo available"
                                                                        )}
                                                                        <button onClick={() => {
                                                                            document.getElementById('file').click()
                                                                        }}>
                                                                            Upload Photo
                                                                        </button>
                                                                        <input
                                                                            id="file"
                                                                            name="file"
                                                                            type="file"
                                                                            style={{display: 'none'}}
                                                                            onChange={handleProfilePicInputChange}
                                                                        />
                                                                        <button
                                                                            title="Upload"
                                                                            type="button"
                                                                            disabled={Array.from(selectedFiles).length === 0}
                                                                            onClick={() => proPicUpload()}
                                                                            className="btn btn-light radius-50-percent">
                                                                            <i className="fa fa-cloud-upload-alt"/>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                {/*<tr>*/}
                                                                {/*    <td> Upload Photo</td>*/}
                                                                {/*    <td>*/}
                                                                {/*        <div className="form-row">*/}
                                                                {/*            <div className="col-4">*/}
                                                                {/*                <input*/}
                                                                {/*                    className="custom-file-input"*/}
                                                                {/*                    id="file"*/}
                                                                {/*                    name="file"*/}
                                                                {/*                    type="file"*/}
                                                                {/*                    multiple*/}
                                                                {/*                    onChange={handleProfilePicInputChange}*/}
                                                                {/*                />*/}
                                                                {/*                <label className="custom-file-label"*/}
                                                                {/*                       htmlFor="file">*/}
                                                                {/*                    Choose file*/}
                                                                {/*                </label>*/}
                                                                {/*            </div>*/}
                                                                {/*            <div className="col-4">*/}
                                                                {/*                <button*/}
                                                                {/*                    title="Upload"*/}
                                                                {/*                    type="button"*/}
                                                                {/*                    disabled={Array.from(selectedFiles).length === 0}*/}
                                                                {/*                    onClick={() => proPicUpload()}*/}
                                                                {/*                    className="btn btn-light radius-50-percent">*/}
                                                                {/*                    <i className="fa fa-cloud-upload-alt"/>*/}
                                                                {/*                </button>*/}
                                                                {/*            </div>*/}
                                                                {/*        </div>*/}
                                                                {/*        <div className="form-row">*/}
                                                                {/*            <div className="col-12">*/}
                                                                {/*                <div className="submit-area"*/}
                                                                {/*                     style={{marginTop: 20}}>*/}
                                                                {/*                    {Array.from(selectedFiles).length > 0 ? Array.from(selectedFiles).map((data, index) => {*/}
                                                                {/*                        return (*/}
                                                                {/*                            <p>{(index + 1) + ". " + data.name}</p>);*/}
                                                                {/*                    }) : ""}*/}
                                                                {/*                </div>*/}
                                                                {/*            </div>*/}
                                                                {/*        </div>*/}
                                                                {/*    </td>*/}
                                                                {/*</tr>*/}


                                                                {/*<tr>*/}
                                                                {/*    <td> Father Name</td>*/}
                                                                {/*    <td>{employeeInfo?.fatherName}</td>*/}
                                                                {/*</tr>*/}

                                                                {/*<tr>*/}
                                                                {/*    <td> Mother Name</td>*/}
                                                                {/*    <td>{employeeInfo?.motherName}</td>*/}
                                                                {/*</tr>*/}

                                                                {/*<tr>*/}
                                                                {/*    <td> Grade</td>*/}
                                                                {/*    <td>{employeeInfo?.grade?.gradeName}</td>*/}
                                                                {/*</tr>*/}

                                                                {/*<tr>*/}
                                                                {/*    <td> Section</td>*/}
                                                                {/*    <td>{employeeInfo?.section?.sectionName}</td>*/}
                                                                {/*</tr>*/}

                                                                {/*<tr>*/}
                                                                {/*    <td> Permanent Address</td>*/}
                                                                {/*    <td>{employeeInfo?.permanentAddress}</td>*/}
                                                                {/*</tr>*/}

                                                                {/*<tr>*/}
                                                                {/*    <td> Present Address</td>*/}
                                                                {/*    <td>{employeeInfo?.presentAddress}</td>*/}
                                                                {/*</tr>*/}

                                                                {/*<tr>*/}
                                                                {/*    <td> Emergency Contact Person</td>*/}
                                                                {/*    <td>{employeeInfo?.emergencyContactPerson}</td>*/}
                                                                {/*</tr>*/}

                                                                {/*<tr>*/}
                                                                {/*    <td> Emergency Contact Number</td>*/}
                                                                {/*    <td>{employeeInfo?.emergencyContact}</td>*/}
                                                                {/*</tr>*/}

                                                                {/*<tr>*/}
                                                                {/*    <td>Blood Group</td>*/}
                                                                {/*    <td>{employeeInfo?.bloodGroup}</td>*/}
                                                                {/*</tr>*/}

                                                                {/*<tr>*/}
                                                                {/*    <td>Health Concern</td>*/}
                                                                {/*    <td>{employeeInfo?.healthConcern}</td>*/}
                                                                {/*</tr>*/}
                                                                {/*<tr>*/}
                                                                {/*    <td>Contact Number</td>*/}
                                                                {/*    <td>{employeeInfo?.contactNo}</td>*/}
                                                                {/*</tr>*/}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    className="tab-pane fade"
                                                    id="pills-inprogress"
                                                    role="tabpane2"
                                                    aria-labelledby="pills-assignment-inprogress"
                                                >
                                                    <div className="tab-heading ">
                                                        <p>Room Information</p>
                                                        <div className="room-information table-responsive table-light">
                                                            <table
                                                                className="table table-light table-hover"
                                                                id="dataTable"
                                                                width="100%"
                                                                cellSpacing="0">
                                                                <thead>
                                                                <tr>
                                                                    {/*<th scope="col">sl</th>*/}
                                                                    <th scope="col">Room Name</th>
                                                                    <th scope="col">Room Type</th>
                                                                    <th scope="col">Semester</th>
                                                                    <th scope="col">Teacher's Evaluation</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {channelList.length > 0
                                                                    ? channelList.map((data, index) => {
                                                                        return (
                                                                            <tr>
                                                                                {/*<td scope="row">{index + 1}</td>*/}
                                                                                <td>{data.channelName}</td>
                                                                                <td>{channelTypeConversion(data.channelType)}</td>
                                                                                <td>{data?.semester?.semesterName}</td>
                                                                                <td>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-outline-dark radius-25 w-25"
                                                                                        onClick={() => handleEvaluationResult(data)}>
                                                                                        <svg width="17" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path
                                                                                                d="M6.5 1.333a5.297 5.297 0 0 1 3.074.987c.922.648 1.663 1.577 2.138 2.68-.975 2.247-2.967 3.667-5.212 3.667-2.245 0-4.237-1.42-5.212-3.667.475-1.103 1.216-2.032 2.138-2.68A5.297 5.297 0 0 1 6.5 1.333ZM6.5 0C3.545 0 1.022 2.073 0 5c1.022 2.927 3.545 5 6.5 5s5.478-2.073 6.5-5c-1.022-2.927-3.545-5-6.5-5Zm0 3.333c.392 0 .768.176 1.045.488.277.313.432.737.432 1.179 0 .442-.155.866-.432 1.179a1.398 1.398 0 0 1-1.045.488c-.392 0-.768-.176-1.045-.488A1.781 1.781 0 0 1 5.023 5c0-.442.155-.866.432-1.179A1.398 1.398 0 0 1 6.5 3.333ZM6.5 2C5.035 2 3.84 3.347 3.84 5S5.036 8 6.5 8s2.66-1.347 2.66-3S7.964 2 6.5 2Z"
                                                                                                fill="#475467"/>
                                                                                        </svg>
                                                                                        View
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                    : ""}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="draft-question"
                                                    role="tabpane2"
                                                    aria-labelledby="pills-draft-question">
                                                    <div className="tab-heading ">
                                                        <p>Draft Questions</p>
                                                        <div className="table-responsive table-light">
                                                            <h3><span>1 results</span> (You can save upto 100 questions)</h3>
                                                            <table className="table table-light table-hover"
                                                                   id="dataTable"
                                                                   width="100%"
                                                                   cellSpacing="0">
                                                                <thead>
                                                                <tr>
                                                                    <th scope="col">Semester</th>
                                                                    <th scope="col">Course Name</th>
                                                                    <th scope="col">Evaluation Name</th>
                                                                    <th scope="col">Assignment Title</th>
                                                                    <th scope="col" className="text-center">Actions</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {allAssignmentDraftList.length > 0 ?
                                                                    allAssignmentDraftList.map((data, index) => {
                                                                        return
                                                                        <>
                                                                            <tr>
                                                                                <td>{data?.channel?.semester?.semesterName}</td>
                                                                                <td>{data?.channel?.channelName}</td>
                                                                                <td>{data?.title}</td>
                                                                                <td>{data?.assignment?.name}</td>
                                                                                <td className="text-center pl-0">
                                                                                    <button
                                                                                        title="Edit"
                                                                                        type="button"
                                                                                        className="btn btn-sm bg-c-light-orange mr-1"
                                                                                    >
                                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                                             width="18" height="15" fill="none">
                                                                                            <path fill="#000"
                                                                                                  d="m16.202 8.72-2.01-2.011L15.54 5.36l2.01 2.01-1.348 1.348Zm-7.687 5.676v-2.01l5.014-5.015 2.01 2.01-5.014 5.015h-2.01ZM0 9.666V7.772h6.623v1.892H0ZM0 5.88V3.99h10.407V5.88H0Zm0-3.784V.205h10.407v1.892H0Z"/>
                                                                                        </svg>
                                                                                    </button>
                                                                                    &nbsp;
                                                                                    <button
                                                                                        title="Delete"
                                                                                        type="button"
                                                                                        className="btn btn-sm bg-c-red-light"
                                                                                        data-toggle="modal"
                                                                                        data-target="#deleteModal"
                                                                                    >
                                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                                             width="13" height="14" fill="none">
                                                                                            <path fill="#D81414"
                                                                                                  d="M2.8 13.704c-.412 0-.766-.147-1.06-.44a1.442 1.442 0 0 1-.44-1.06v-9.75H.55v-1.5H4.3v-.75h4.5v.75h3.75v1.5h-.75v9.75c0 .413-.147.766-.441 1.06-.294.294-.647.441-1.059.44H2.8Zm7.5-11.25H2.8v9.75h7.5v-9.75Zm-6 8.25h1.5v-6.75H4.3v6.75Zm3 0h1.5v-6.75H7.3v6.75Z"/>
                                                                                        </svg>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    })
                                                                    : <td colSpan="12" className="text-center">
                                                                        {loader ? <Loader/> : <h5>No data found</h5>}
                                                                    </td>}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="question-saved"
                                                    role="tabpane2"
                                                    aria-labelledby="pills-question-saved"
                                                >
                                                    <div className="tab-heading ">
                                                        <p>Question Saved</p>
                                                        <div className="table-responsive table-light">
                                                            <table className="table table-light table-hover"
                                                                   id="dataTable"
                                                                   width="100%"
                                                                   cellSpacing="0">
                                                                <thead>
                                                                <tr>
                                                                    <th scope="col">Sl</th>
                                                                    <th scope="col">Semester</th>
                                                                    <th scope="col">Course Name</th>
                                                                    <th scope="col">Evaluation Name</th>
                                                                    <th scope="col">Assignment Title</th>
                                                                    <th scope="col" className="text-center">Actions</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {allAssignmentSavedList.length > 0 ?
                                                                    allAssignmentSavedList.map((data, index) => {
                                                                        return <>
                                                                            <tr>
                                                                                <td>{index + 1}</td>
                                                                                <td>{data?.channel?.semester?.semesterName}</td>
                                                                                <td>{data?.channel?.channelName}</td>
                                                                                <td>{data?.title}</td>
                                                                                <td>{data?.assignment?.name}</td>
                                                                                <td className="text-center pl-0">
                                                                                    <button
                                                                                        title="Archive"
                                                                                        type="button"
                                                                                        className="btn btn-sm bg-c-blue-light mr-1"
                                                                                        disabled={data?.archived === null}
                                                                                        onClick={handleArchived}
                                                                                    >
                                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                                             width="17" height="12" fill="none">
                                                                                            <path fill="#4575EE"
                                                                                                  d="M8.5 1.75A7.55 7.55 0 0 1 15.316 6 7.541 7.541 0 0 1 8.5 10.25 7.541 7.541 0 0 1 1.685 6 7.55 7.55 0 0 1 8.5 1.75Zm0-1.545C4.636.205 1.337 2.608 0 6c1.337 3.392 4.636 5.795 8.5 5.795S15.663 9.392 17 6C15.663 2.608 12.364.205 8.5.205Zm0 3.863a1.932 1.932 0 1 1 0 3.864 1.932 1.932 0 0 1 0-3.864Zm0-1.545A3.483 3.483 0 0 0 5.023 6 3.483 3.483 0 0 0 8.5 9.477 3.483 3.483 0 0 0 11.977 6 3.483 3.483 0 0 0 8.5 2.523Z"/>
                                                                                        </svg>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    })
                                                                    : <td colSpan="12" className="text-center">
                                                                        {loader ? <Loader/> : <h5>No data found</h5>}
                                                                    </td>}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="question-archive"
                                                    role="tabpane2"
                                                    aria-labelledby="pills-question-archive"
                                                >
                                                    <div className="tab-heading ">
                                                        <p>Question Archive</p>
                                                        <div className="table-responsive table-light">
                                                            <h3><span>1 results</span> (You can save upto 100 questions)</h3>
                                                            <table className="table table-light table-hover"
                                                                   id="dataTable"
                                                                   width="100%"
                                                                   cellSpacing="0">
                                                                <thead>
                                                                <tr>
                                                                    <th scope="col">Semester</th>
                                                                    <th scope="col">Course Name</th>
                                                                    <th scope="col">Evaluation Name</th>
                                                                    <th scope="col">Assignment Title</th>
                                                                    <th scope="col" className="text-center">Actions</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {allAssignmentArchivedList.length > 0 ?
                                                                    allAssignmentArchivedList.map((data, index) => {
                                                                        return <>
                                                                            <tr>
                                                                                <td>{data?.channel?.semester?.semesterName}</td>
                                                                                <td>{data?.channel?.channelName}</td>
                                                                                <td>{data?.title}</td>
                                                                                <td>{data?.assignment?.name}</td>
                                                                                <td className="text-center pl-0">
                                                                                    <button
                                                                                        title="View"
                                                                                        type="button"
                                                                                        className="btn btn-sm bg-c-blue-light mr-1"
                                                                                    >
                                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                                             width="17" height="12" fill="none">
                                                                                            <path fill="#4575EE"
                                                                                                  d="M8.5 1.75A7.55 7.55 0 0 1 15.316 6 7.541 7.541 0 0 1 8.5 10.25 7.541 7.541 0 0 1 1.685 6 7.55 7.55 0 0 1 8.5 1.75Zm0-1.545C4.636.205 1.337 2.608 0 6c1.337 3.392 4.636 5.795 8.5 5.795S15.663 9.392 17 6C15.663 2.608 12.364.205 8.5.205Zm0 3.863a1.932 1.932 0 1 1 0 3.864 1.932 1.932 0 0 1 0-3.864Zm0-1.545A3.483 3.483 0 0 0 5.023 6 3.483 3.483 0 0 0 8.5 9.477 3.483 3.483 0 0 0 11.977 6 3.483 3.483 0 0 0 8.5 2.523Z"/>
                                                                                        </svg>
                                                                                    </button>
                                                                                    &nbsp;
                                                                                    <button
                                                                                        title="Delete"
                                                                                        type="button"
                                                                                        className="btn btn-sm bg-c-red-light"
                                                                                        data-toggle="modal"
                                                                                        data-target="#deleteModal"
                                                                                    >
                                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                                             width="13" height="14" fill="none">
                                                                                            <path fill="#D81414"
                                                                                                  d="M2.8 13.704c-.412 0-.766-.147-1.06-.44a1.442 1.442 0 0 1-.44-1.06v-9.75H.55v-1.5H4.3v-.75h4.5v.75h3.75v1.5h-.75v9.75c0 .413-.147.766-.441 1.06-.294.294-.647.441-1.059.44H2.8Zm7.5-11.25H2.8v9.75h7.5v-9.75Zm-6 8.25h1.5v-6.75H4.3v6.75Zm3 0h1.5v-6.75H7.3v6.75Z"/>
                                                                                        </svg>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    })
                                                                    : <td colSpan="12" className="text-center">
                                                                        {loader ? <Loader/> : <h5>No data found</h5>}
                                                                    </td>}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )}
                        </div>
                    </main>
                </CommonLayout>
            </>
        );
    }
    return (
        <div className="h-100 my-auto mx-auto pt-4  ">
            <Loader/>
        </div>
    );
}

export default TeacherProfile;
