import React, {useState} from "react";
import * as ParentsApi from "../../api/ParentsApi";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import * as StudentApi from "../../api/StudentApi";
import {toast} from "react-toastify";
import {FormikTextField} from "../FormikTextField";

import * as LinkFactory from "../../util/LinkFactory";
import {useNavigate} from "react-router-dom";

function ParentsRegistration(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const validate = Yup.object({
        firstName: Yup.string()
            .max(15, "Must be 15 characters or less!")
            .required('First Name is required'),
        lastName: Yup.string()
            .max(20, "Must be 20 characters or less!")
            .required('Last Name is required'),
        email: Yup.string()
            .email('Email is invalid')
            .required('email is required'),
        phoneNo: Yup.string()
            .max(15, "Must be 15 characters or less!")
            .required('Phone no is required'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Password must match')
            .required('Confirm Password is required')
    })

    const initialState = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        phoneNo: "",
        studentId:""
    }

    const parentRegistration = (parentSave) => {
        ParentsApi.registerParent(parentSave).then(res => {
            if(res.data.status==='201') {
                toast("Registration Complete!");
                navigateTo(LinkFactory.login);
            } else {
                toast("Registration Failed!");
            }
        });
    }

    const handleSubmit = data => {
        console.log(data);
        const parentSave = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            password: data.password,
            studentId: data.studentId,
            phoneNo: data.phoneNo
        };
        StudentApi.isAlreadyRegisteredByEmail({'email': parentSave.email}).then(res=> {
            if(res.data) {
                toast("This email already registered!");
            } else {
                parentRegistration(parentSave);
            }
        })
    };

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <div className="parents-registration bg-default d-flex">
                <div id="reg_layout">
                    <div id="reg_content" >
                        <main>
                            <div id="registrations">
                                <div id="parentsReg">
                                    <div className="container">
                                        <div className="brand">
                                            <h2>{process.env.REACT_APP_ENV_NAME}</h2>
                                            <img src="img/Edooket.png" alt={"Edoocate logo"}/>
                                            <h3>Parents Registration</h3>
                                        </div>
                                        <div className="card-default">
                                            <div className="input-reg">
                                                <Formik
                                                    initialValues={initialState}
                                                    validationSchema={validate}
                                                    enableReinitialize={true}
                                                    onSubmit={handleSubmit}
                                                >
                                                    {props => (
                                                        <Form>
                                                            <div className="block-group">
                                                                <div className="block-50">
                                                                    <FormikTextField label="First Name" name="firstName"
                                                                                     type="text"/>
                                                                </div>
                                                                <div className="block-50">
                                                                    <FormikTextField label="Last Name" name="lastName"
                                                                                     type="text"/>
                                                                </div>
                                                            </div>
                                                            <div className="block-group">
                                                                <div className="block-50">
                                                                    <FormikTextField label="Contact No" name="phoneNo"
                                                                                     type="text"/>
                                                                </div>
                                                                <div className="block-50">
                                                                    <FormikTextField label="Email" name="email"
                                                                                     autocomplete="off"
                                                                                     autoComplete="off"
                                                                                     type="text"/>
                                                                </div>
                                                            </div>

                                                            <div className="block-group">
                                                                <div className="block-50">
                                                                    <div className="block-50">
                                                                        <FormikTextField label="Student Code"
                                                                                         name="studentId"
                                                                                         type="text"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="block-group">
                                                                <div className="block-50 password-box">
                                                                    <FormikTextField label="Password" name="password"
                                                                                     autocomplete="off"
                                                                                     autoComplete="off"
                                                                                     type={showPassword ? "text" : "password"}/>
                                                                    <svg
                                                                        onClick={togglePasswordVisibility}
                                                                        className="password-icon"
                                                                        width="13" height="9" fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            d="M6.5 2.7c.47 0 .921.19 1.254.527.332.338.519.796.519 1.273 0 .477-.187.935-.52 1.273A1.76 1.76 0 0 1 6.5 6.3c-.47 0-.921-.19-1.254-.527A1.814 1.814 0 0 1 4.727 4.5c0-.477.187-.935.52-1.273A1.76 1.76 0 0 1 6.5 2.7Zm0-2.7c2.955 0 5.478 1.866 6.5 4.5C11.978 7.134 9.455 9 6.5 9S1.022 7.134 0 4.5C1.022 1.866 3.545 0 6.5 0ZM1.288 4.5a5.862 5.862 0 0 0 2.14 2.408A5.738 5.738 0 0 0 6.5 7.8c1.086 0 2.15-.31 3.071-.893a5.862 5.862 0 0 0 2.14-2.408 5.862 5.862 0 0 0-2.14-2.408A5.738 5.738 0 0 0 6.5 1.199c-1.086 0-2.15.31-3.071.893A5.862 5.862 0 0 0 1.289 4.5Z"
                                                                            fill="#878787"/>
                                                                    </svg>
                                                                </div>
                                                                <div className="block-50 password-box">
                                                                    <FormikTextField label="Confirm Password"
                                                                                     name="confirmPassword"
                                                                                     type={showPassword ? "text" : "password"}/>
                                                                    <svg
                                                                        onClick={togglePasswordVisibility}
                                                                        className="password-icon"
                                                                        width="13" height="9" fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            d="M6.5 2.7c.47 0 .921.19 1.254.527.332.338.519.796.519 1.273 0 .477-.187.935-.52 1.273A1.76 1.76 0 0 1 6.5 6.3c-.47 0-.921-.19-1.254-.527A1.814 1.814 0 0 1 4.727 4.5c0-.477.187-.935.52-1.273A1.76 1.76 0 0 1 6.5 2.7Zm0-2.7c2.955 0 5.478 1.866 6.5 4.5C11.978 7.134 9.455 9 6.5 9S1.022 7.134 0 4.5C1.022 1.866 3.545 0 6.5 0ZM1.288 4.5a5.862 5.862 0 0 0 2.14 2.408A5.738 5.738 0 0 0 6.5 7.8c1.086 0 2.15-.31 3.071-.893a5.862 5.862 0 0 0 2.14-2.408 5.862 5.862 0 0 0-2.14-2.408A5.738 5.738 0 0 0 6.5 1.199c-1.086 0-2.15.31-3.071.893A5.862 5.862 0 0 0 1.289 4.5Z"
                                                                            fill="#878787"/>
                                                                    </svg>
                                                                </div>
                                                            </div>

                                                            <div className="block-group">
                                                                <div className="block-50 m-auto">
                                                                    <button type="submit" className="btn-deep-blue-lg box-btn">
                                                                        <p>Register</p>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                        </div>
                                        <div className="footer-instruction">
                                            <p>
                                                Already Registered?
                                                <div>
                                                    <a href=" " className="back-button p-0">
                                                        <i
                                                            className="fa fa-arrow-left "> </i>
                                                        Back to Log in
                                                    </a>
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>

                <div className="login-image">
                    <div className="gradient-background">
                        <div className="h-100 flex-column d-flex justify-content-center align-item-center">
                            <div className="d-flex  justify-content-center">
                                <p>The first ever <span>smart school solution </span> to automate your whole institution</p>
                            </div>

                            <div className="image-container">
                                <img src="img/login-page_Sun.png" alt="Sun" className="sun"/>
                                <img src="img/login-page_Clouds.png" alt="Clouds" className="clouds" />
                                <img src="img/login-page_Birds.png"  alt="Flying Birds" className="birds"/>
                                <img src="img/login-page_man-hovering-with-books.png" alt="Person with Books" className="person"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default ParentsRegistration;
