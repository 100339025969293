import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveEventComments = eventComments => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/event-comments/", eventComments);
};
export const updateEventComments = eventComments => {
    return AxiosAgent.requests.put(API_MAIN_ROOT + "/event-comments/", eventComments);
};
export const getEventCommentsByCommentId = eventCommentsId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/event-comments/" + eventCommentsId);
};
export const findCommentListByEventId = eventId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/event-comments/by-event-id/" + eventId
    );
};
export const deleteEventCommentsById = eventId => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/event-comments/" + eventId);
};

