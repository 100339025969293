import React, {useEffect, useState} from "react";
import * as AssignmentApi from "../../api/AssignmentApi";
import moment from "moment-timezone";
import {Field, Form, Formik} from "formik";
import * as Utils from "../../util/Utils";
import Loader from "../common/Loader";

import * as LinkFactory from "../../util/LinkFactory";
import Swal from "sweetalert2";
import FileUploader from "../Uploader/fileUploader";
import FormTitle from "../Typhography/formTitle";
import {useNavigate, useParams} from "react-router-dom";
import CommonLayout from "../layout/commonLayout";
import RightBar from "../common/rightBar";
import SingleAttachmentComponent from "../ChannelClient/Components/SingleAttachmentComponent";
import AdSense from "react-adsense";

function AssignmentSubmitNew(props) {
    const params = useParams();
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [assignment, setAssignment] = useState({});
    const [assignmentTask, setAssignmentTask] = useState([]);
    const [assignmentTaskSubmit, setAssignmentTaskSubmit] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        AssignmentApi.getAssignmentById(params.id)
            .then(response => {
                if (response && response.response && response.response.status === 200) {
                    setAssignment(response.data);
                    console.log(response.data, ">>>>>>>>>>>....");
                    AssignmentApi.getAssignmentTaskListByAssignmentId(params.id).then(taskListResponse => {
                        setAssignmentTask(taskListResponse.data);
                    });
                }
                // AssignmentApi.getAssignmentTaskListByLoggedInId().then((taskListResponse) => {
                //     console.log(taskListResponse.data);
                // });
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);


    const handleSubmit = values => {
        console.log(values);
        setLoader(true);
        values.forEach(val => {
            console.log(val);
            let assignmentTaskDTO = {
                submitContentBlob: val.submitContentBlob,
                submitContentBlobType: val.submitContentBlobType,
                assignmentTask: val,
                submit: val.submit
            };

            AssignmentApi.saveAssignmentTaskSubmitAttachments(assignmentTaskDTO)
                .then(response => {
                    setLoader(false);
                    if (
                        response &&
                        response.response &&
                        (response.response.status === 201 || response.response.status === 200)
                    ) {
                        Swal.fire("Success", "Assignment submitted", "success");

                        navigateTo(
                            LinkFactory.channelBoard + "/" + assignment.channel.id
                        );
                    } else {
                        Swal.fire("Failed", response.response.data.errorMsg, "error");
                        // console.log(response,response.response.status,'res')
                    }
                    //
                })
                .catch(error => {
                    setLoader(false);
                });
        });
    };


    const TextAreaComponent = ({field, form: {touched, errors}}) => {
        return (
            <textarea {...field} className="form-control mt-4" type="text" rows="8"/>
        );
    };

    const fileToDataUri = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = event => {
                resolve(event.target.result);
            };
            reader.readAsDataURL(file);
        });

    const handleChangeAnswer = (event, index) => {
        console.log(event.target.value);
        const newAssignmentTaskSubmit = [...assignmentTaskSubmit];
        // const index = newAssignmentTaskSubmit.findIndex((event) => event)
    };

    const handleBack = () => {
        history(-1);
    };

    return (
        <>
            <CommonLayout headerName={Utils.getChannelName()}>
                <main>
                    <div id="contentArea">
                        <div className="block-center">
                            <div className="card-default assignment-submit-regular">
                                {assignmentTask ? <div id="newAssigments">
                                    <div className="title-header  ">
                                        <div className="mb-0 w-100">
                                            <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                                <a href="#" className="pl-0 back-button">
                                                    <i className="fa fa-chevron-left mr-2"> </i>Back  </a>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className=" mt-3">
                                                    {/*<FormTitle color="blue">{Utils.initialCase(assignment?.gradingEvaluation?.examType)} Submit</FormTitle>*/}
                                                    <FormTitle color="blue">{Utils.initialCase(assignment?.gradingEvaluation?.examType)} name</FormTitle>
                                                    <div className=" assignment-name mb-3">

                                                        <p> {assignment.name}</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="btn bg-c-blue-light mr-3">
                                                        <p>{assignment?.gradingEvaluation?.examType ===
                                                        "Exam"
                                                            ? "Exam Date"
                                                            : "Deadline"}</p>
                                                        <span>{moment(assignment.deadline).format("MM/DD/YY")}</span>
                                                    </div>
                                                    <div className="btn bg-c-light-orange ">
                                                        <p>Full Score</p>
                                                        <span>{assignment.score}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block-group ">
                                        <div className="block ">
                                            <div className="task-list">
                                                {loader ? <Loader/> : ""}

                                                <Formik
                                                    initialValues={assignmentTask}
                                                    enableReinitialize={true}
                                                    onSubmit={handleSubmit}>
                                                    {({props, values, setFieldValue}) => (
                                                        <Form>
                                                            <div className=" ">
                                                                <div>
                                                                    {values.length > 0 ? (
                                                                        values.map((data, index) => (
                                                                            <div key={index} className="assignment-questions">
                                                                                {(index === 0) ? <AdSense.Google
                                                                                    client="ca-pub-2042111752026323"
                                                                                    slot="6135172396"
                                                                                    format="auto"
                                                                                    style={{display: 'block'}}
                                                                                    responsive="true"
                                                                                    layoutKey='-gw-1+2a-9x+5c'
                                                                                /> : null}
                                                                                <div className="d-flex justify-content-end">
                                                                                    <div className="form-group mb-0">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn savefor-later w-100 ">
                                                                                            {" "}
                                                                                            <svg width="15" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path
                                                                                                    d="M10.204 7.823v5.793l-4.156-1.779-4.156 1.779V2.836H6.88V1.173H1.892C.978 1.173.23 1.921.23 2.836v13.298l5.818-2.493 5.818 2.493V7.823h-1.662Zm.69-1.663L8.542 3.808l1.172-1.172 1.172 1.172L13.828.866 15 2.038 10.894 6.16Z"
                                                                                                    fill="#475467"/>
                                                                                            </svg>
                                                                                            &nbsp; Save for later
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="form-group mb-0 marks">
                                                                                        {data.score} Marks
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <p>
                                                                                        {index + 1} .<b> {data.name}</b>
                                                                                    </p>
                                                                                    {null != data?.attachmentName ?
                                                                                        <SingleAttachmentComponent
                                                                                            imageurl={data.attachmentName}
                                                                                        /> : null}
                                                                                    <Field
                                                                                        name={`[${index}].submit`}
                                                                                        className="form-control mt-4"
                                                                                        component={TextAreaComponent}
                                                                                        placeholder="Write your answer here..."
                                                                                    />
                                                                                    <br/>
                                                                                    <div className="form-group w-100 mb-0">
                                                                                        {/*<FormTitle>Attachment</FormTitle>*/}
                                                                                        <FileUploader
                                                                                            onChange={files => {
                                                                                                setFieldValue(
                                                                                                    `[${index}].submitContentBlobType`,
                                                                                                    files[0].type
                                                                                                );
                                                                                                fileToDataUri(files[0]).then(
                                                                                                    data => {
                                                                                                        setFieldValue(
                                                                                                            `[${index}].submitContentBlob`,
                                                                                                            data.split(",")[1]
                                                                                                        );
                                                                                                    }
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <Loader/>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            {// assignment && assignment.name && assignment.deadline && assignment.assessmentTitle
                                                                !loader ? (
                                                                    <div
                                                                        className="form-row d-flex justify-content-center align-items-center">
                                                                        <div className="form-group">
                                                                            <button
                                                                                type="submit"
                                                                                className="btn btn-purple-md  w-100">
                                                                                Submit
                                                                            </button>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <button
                                                                                onClick={handleBack}
                                                                                type="button"
                                                                                className="btn btn-cancel w-100 ml-3">
                                                                                {" "}
                                                                                Cancel
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                        </div>
                                    </div>
                                </div> : <p>Something went wrong</p>}
                            </div>

                        </div>
                        <RightBar/>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default AssignmentSubmitNew;
