import FormActions from "../action/FormActions";
import * as ModalActions from "../action/ModalActions";
import * as LinkFactory from "./LinkFactory";
import SystemErrorAction from "../action/SystemErrorAction";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import AuthActions from "../action/AuthActions";
import parse from "html-react-parser";
import * as InvestorRegistrationApi from "./../api/InvestorRegistrationApi";
import {ASSIGNMENT_NOTIFICATION_COUNTER, EVENT_NOTIFICATION_COUNTER, POST_COMMENT_NOTIFICATION_COUNTER, POST_NOTIFICATION_COUNTER, REFRESH_TOKEN} from "../constants/Constants";
import moment from "moment";
import * as AuthApi from "../api/AuthApi";

export const errorOrAlertExists = (reply, dispatch, isLogin) => {
    dispatch(FormActions.loadingComplete());
    dispatch(ModalActions.loadingComplete());
    let response = reply.response;
    let json = reply.data;

    if (json && json.errorCode === 500) {
        dispatch(SystemErrorAction.setError("Internal Server Error"));
        window.scrollTo({top: 0, behavior: "smooth"});
        return true;
    } else if (response && response.status === 500) {
        dispatch(SystemErrorAction.setError("Internal Server Error"));
        window.scrollTo({top: 0, behavior: "smooth"});
        return true;
    } else if (response && response.status === 401) {
        dispatch(SystemErrorAction.setError("You do not have permission"));
        window.scrollTo({top: 0, behavior: "smooth"});
        if (!isLogin) {
            setTimeout(() => {
                LinkFactory.goToUrl(LinkFactory.login);
            }, 3000);
        }

        return true;
    } else if (json && json.errorCode === 400) {
        dispatch(SystemErrorAction.setError(parse(json.errorMessage)));
        window.scrollTo({top: 0, behavior: "smooth"});
        return true;
    } else if (response && response.errorCode === 400) {
        dispatch(SystemErrorAction.setError(parse(response.errorMessage)));
        window.scrollTo({top: 0, behavior: "smooth"});
        return true;
    } else if (response && response.status === 400) {
        dispatch(SystemErrorAction.setError("Bad Request"));
        window.scrollTo({top: 0, behavior: "smooth"});
        return true;
    } else if (json && json.status === 404) {
        dispatch(SystemErrorAction.setError("API not found"));
        window.scrollTo({top: 0, behavior: "smooth"});
        return true;
    } else if (json && json.type === "serverError") {
        dispatch(SystemErrorAction.setError("Connection Refused"));
        window.scrollTo({top: 0, behavior: "smooth"});
        return true;
    }
    return false;
}

export const setCookie = (cookie) => {
    Cookies.remove("auth-token");
    Cookies.set("auth-token", cookie, {
        path: "/", expires: new Date(Date.now() + 60 * 60 * 1000)
    });
}

export const formatDateOfBirth = (form) => {
    if (form.day && form.month && form.year) {
        let day = form.day.length === 1 ? "0" + form.day : form.day;
        let month = form.month.length === 1 ? "0" + form.month : form.month;
        form.dateOfBirth = form.year + "-" + month + "-" + day;
    }
};

export const sendRequest = (dispatch, errors, apiCall) => {
    if (errors.length > 0) {
        dispatch(FormActions.formError(errors));
    } else {
        dispatch(FormActions.loading());
        window.scrollTo({top: 0, behavior: "smooth"});
        apiCall();
    }
};

export const sendRequestModal = (dispatch, errors, apiCall) => {
    if (errors.length > 0) {
        dispatch(ModalActions.formError(errors));
    } else {
        dispatch(ModalActions.loading());
        apiCall();
    }
};

export const addUserToLocalStorage = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
}

export const setChannelNameAndId = (channelName, channelId) => {
    localStorage.setItem("channelName", channelName);
    localStorage.setItem("channelId", channelId);
}

export const setEmail = (email) => {
    localStorage.setItem("email", email);
}

export const getEmail = () => {
    return localStorage.getItem("email");
}

export const getChannelId = () => {
    return localStorage.getItem("channelId");
}

export const getChannelName = () => {
    return localStorage.getItem("channelName");
}

export const getAssignmentNotificationCount = () => {
    return localStorage.getItem(ASSIGNMENT_NOTIFICATION_COUNTER);
}

export const eventNotificationCount = () => {
    return localStorage.getItem(EVENT_NOTIFICATION_COUNTER);
}

export const getPostNotificationCount = () => {
    return localStorage.getItem(POST_NOTIFICATION_COUNTER);
}

export const postCommentNotificationCount = () => {
    return localStorage.getItem(POST_COMMENT_NOTIFICATION_COUNTER);
}

export const getUserFromLocalStorage = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user && user.id ? user : null;
}

export const getRefreshTokenFromLocalStorage = () => {
    const refreshToken = JSON.parse(localStorage.getItem(REFRESH_TOKEN));
    return refreshToken ? refreshToken : null;
}

export const getParentIdFromLocalStorage = () => {
    const parentId = localStorage.getItem("parentId");
    return parentId ? parentId : null;
}

export const isApproved = (user) => {
    return user && user.approveStatus === "Approved";
};

export const isAuthenticatedAndApproved = (user) => {
    return isAuthenticated() && user && user.approveStatus === "Approved";
};


export const LogOut = (dispatch) => {
    AuthApi.logoutUser({'userId': getUserFromLocalStorage().id}).then(json => {
    });
    Cookies.remove("auth-token");
    localStorage.removeItem("auth-token");
    localStorage.removeItem("user");
    localStorage.removeItem("channelId");
    localStorage.removeItem("channelName");
    dispatch(AuthActions.removeUser());
};

export const tryToMakeUserAvailable = (dispatch) => {
    let user = getUserFromLocalStorage();
    if (user) {
        dispatch(AuthActions.addUserToStoreOnly(user));
    } else {
        InvestorRegistrationApi.getCurrentInvRegistration().then(function (json) {
            if (!errorOrAlertExists(json, dispatch)) {
                if (json.data) {
                    dispatch(AuthActions.addUser(json.data));
                    user = json.data;
                }
            }
        });
    }
    return user;
};


export const hasNumber = (myString) => {
    return /\d/.test(myString);
};

export const twoDecimalPoint = (amount) => {
    let zero = 0;
    if (!amount) {
        return zero.toFixed(2);
    }
    if (typeof amount.toFixed === "function") {
        const number = amount.toFixed(3).slice(0, -1);
        return toThousandSeparator(number);
    }
    if (isNaN(amount)) {
        return toThousandSeparator(amount);
    }

    return zero.toFixed(2);
}

export const fourDecimalPoint = (value) => {
    if (value > 0 || value < 0) {
        const x = value + "";
        const a = x.lastIndexOf(".") >= 0 ? parseFloat(x.substr(0, x.lastIndexOf(".") + 5)) : value;
        const c = a.toString();
        const b = c.split(".");
        if (b.length === 1) {
            b[1] = "00";
        }
        const mainValue = String(b[0])
            .split("")
            .reverse()
            .join("")
            .replace(/(\d{3}\B)/g, "$1,")
            .split("")
            .reverse()
            .join("");
        const calculated = mainValue + "." + b[1];
        return calculated;
    } else {
        return value;
    }
}

export const toThousandSeparator = (amount) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const integerOnly = (amount) => {
    let zero = 0;
    if (!amount) {
        return zero.toFixed(0);
    }
    if (typeof amount.toFixed === "function") {
        return toThousandSeparator(amount.toFixed(0));
    }
    if (isNaN(amount)) {
        return toThousandSeparator(amount);
    }

    return zero.toFixed(0);
}

export const formatMonth = (months) => {
    let quotient = 0;
    let monthInput = 0;
    let result = "";
    if (months) {
        if (months >= 12) {
            const rawQuotient = months / 12;
            const remainder = rawQuotient % 1;
            quotient = rawQuotient - remainder;
            if (quotient <= 0) {
                quotient = 0;
            }
            monthInput = Math.round(remainder * 12);
            if (monthInput <= 0) {
                monthInput = 0;
            }
            if (monthInput === 0) {
                if (quotient > 1) {
                    result = quotient + " Years ";
                } else {
                    result = quotient + " Year ";
                }
            } else {
                if (quotient > 1 && monthInput > 1) {
                    result = quotient + " Years " + monthInput + " Months ";
                } else if (quotient > 0 && monthInput > 1) {
                    result = quotient + " Year " + monthInput + " Months ";
                } else if (quotient > 1 && monthInput > 0) {
                    result = quotient + " Years " + monthInput + " Month ";
                } else {
                    result = quotient + " Year " + monthInput + " Month ";
                }
            }
        } else {
            if (months < 0) {
                result = 0 + " Month";
            } else if (months > 1) {
                result = months + " Months";
            } else {
                result = months + " Month";
            }
        }
    } else {
        return "0 Month";
    }
    return result;
}

export const checkRolePermission = (rolesToBeAllowed) => {
    let roles = [];
    let allowed = false;
    if (null != getUserFromLocalStorage())
        getUserFromLocalStorage().authorities.forEach(data => {
            roles.push(data.name);
        });
    for (let i = 0; i < rolesToBeAllowed.length; i++) {
        if (roles.indexOf(rolesToBeAllowed[i]) > -1) {
            allowed = true;
            break;
        }
    }
    return allowed;
};

export const isLoggedIn = () => {
    if (localStorage.getItem("user") && Cookies.get("auth-token")) return true; else return null;
}

export const camelCase = (str) => {
    if (!str) return
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

export const initialCase = (str) => {
    if (!str) return
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const checkNOTNullEmptyStringUndefinedAndEmptyObject = (object) => {
    return (null != object && "" !== object && undefined !== object)
}
export const getFormattedDate = (dateTime, format) => {
    return moment(dateTime).format(format);
};
// return {
//     getFormattedDate,
//     isLoggedIn,
//     errorOrAlertExists,
//     setCookie,
//     formatDateOfBirth,
//     sendRequest,
//     sendRequestModal,
//     addUserToLocalStorage,
//     setChannelNameAndId,
//     setEmail,
//     getEmail,
//     getChannelId,
//     getChannelName,
//     getAssignmentNotificationCount,
//     eventNotificationCount,
//     getPostNotificationCount,
//     postCommentNotificationCount,
//     getUserFromLocalStorage,
//     getRefreshTokenFromLocalStorage,
//     getParentIdFromLocalStorage,
//     isApproved,
//     isAuthenticatedAndApproved,
//     isAuthenticatedWithStatus,
//     isAuthenticatedAndNotApproved,
//
//     logOut,
//     tryToMakeUserAvailable,
//     redirectToRegistrationPath,
//     checkLogin,
//     getCurrentRegistrationPath,
//     hasNumber,
//     twoDecimalPoint,
//     fourDecimalPoint,
//     toThousandSeparator,
//     integerOnly,
//     formatMonth,
//     checkRolePermission,
//     camelCase,
//     initialCase,
//     checkNOTNullEmptyStringUndefinedAndEmptyObject
// };
// }


export const isAuthenticated = () => {
    let token = Cookies.get("auth-token");
    if (!token) {
        token = localStorage.getItem("auth-token");
        if (token && token !== "" && token !== "undefined") {
            token = "Bearer " + token.substring(1, token.length - 1);
            Cookies.set("auth-token", token, {
                path: "/", expires: new Date(Date.now() + 60 * 60 * 1000)
            });
        }
    }
    if (token && token !== "undefined") {
        const details = jwt_decode(token);
        const expTime = new Date(details.exp * 1000);
        return expTime >= new Date();
    } else {
        return false;
    }
}


