import React, {useState} from "react";

import "react-datepicker/dist/react-datepicker.css";
import {Field, Form, Formik} from "formik";
import * as Utils from "../../util/Utils";
import CommonLayout from "../layout/commonLayout";
import FileUploader from "../Uploader/fileUploader";
import Loader from "../common/Loader";
import * as Yup from "yup";
import * as CourseMaterial from "../../api/CourseMaterialApi";
import Swal from "sweetalert2";

import * as LinkFactory from "../../util/LinkFactory";
import {useNavigate} from "react-router-dom";

function StudyMaterialUpload({ materialId, channelId, initialState }) {
    const [loader, setLoader] = useState(false);
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const ValidateSchema = Yup.object().shape({
        name: !materialId
            ? Yup.string()
                .min(1, "Too Short!")
                .required("Name is Required")
            : Yup.string().notRequired(),
        attachmentType: materialId
            ? Yup.string().notRequired()
            : Yup.string().required("Attachment is required")
    });

  // async function updateCourseMaterials(body) {
  //   try {
  //     const response = await CourseMaterial.saveCourseMaterial(body);
  //     if (!response.ok) {
  //       throw new Error(`HTTP error: ${response.status}`);
  //     }
  //     return response;
  //   } catch (error) {
  //     setLoader(false);
  //     console.error(`Could not update study materials: ${error}`);
  //   }
  // }

  const channelStudyMaterials = channelStudyMaterials || {};
  const handleSubmit = values => {
    setLoader(true);
    if (materialId) {
      const body = {
        id: materialId,
        channelStudyMaterials: channelStudyMaterials,
        channelStudyMaterialBlob: values?.attachmentName || null,
        channelStudyMaterialBlobType: values?.attachmentType || null
      };
      CourseMaterial.updateAttachment(body)
        .then(res => {
          if (res && res.response && res.response.status === 201) {
              Swal.fire("Success", "Materials updated", "success");
              navigateTo(
                  LinkFactory.channelStudyMaterials + "/" + channelId
              );
          } else Swal.fire("Failed", res.response.data.errorMsg, "error");
          console.log(res, "final");
        })
        .catch(err => {
          throw Error(err);
        })
        .finally(() => setLoader(false));
    } else {
      let body = {
        channel: { id: channelId },
        studyMaterialTitle: values.name
      };
      CourseMaterial.saveCourseMaterial(body)
        .then(response => {
          if (
            response &&
            response.response &&
            response.response.status === 201
          ) {
            const attachmentBody = {
              channelStudyMaterials: response.response.data,
              channelStudyMaterialBlob: values.attachmentName,
              channelStudyMaterialBlobType: values.attachmentType
            };
            CourseMaterial.saveAttachment(attachmentBody)
              .then(res => {
                if (res && res.response && res.response.status === 201) {
                    Swal.fire("Success", "Assignment submitted", "success");
                    navigateTo(
                        LinkFactory.channelStudyMaterials + "/" + channelId
                    );
                } else Swal.fire("Failed", res.response.data.errorMsg, "error");
                console.log(res, "final");
              })
              .catch(err => {
                throw Error(err);
              });
          } else {
            Swal.fire("Failed", response.response.data.errorMsg, "error");
            // console.log(response,response.response.status,'res')
          }
          //
        })
        .catch(error => {
          throw Error(error);
        })
        .finally(() => setLoader(false));
    }
  };

  const TextField = ({ field, form: { touched, errors } }) => {
    return (
      <div className="input-group mb-3">
        <input
          {...field}
          type="text"
          className="form-control"
          aria-label="name"
          aria-describedby="basic-addon1"
        />
      </div>
    );
  };

  const fileToDataUri = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = event => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });

  return (
    <>
      <CommonLayout headerName={Utils.getChannelName()}>
        <div>
          <div>
            <div id="contentArea">
              <div id="studyMaterial">
                <div className="block-group">
                  <div className="block">
                    <h6>
                      {materialId
                        ? "Edit study materials"
                        : "Upload study materials"}
                    </h6>
                    <br />
                    <Formik
                      initialValues={initialState}
                      enableReinitialize={true}
                      onSubmit={handleSubmit}
                      validationSchema={ValidateSchema}
                    >
                      {({ values, errors, touched }) => (
                        <Form>
                          <div className="table-responsive">
                            <label>Attachment Name</label>
                            <Field
                              type="text"
                              name="name"
                              placeholder="Attachment Name"
                              component={TextField}
                            />
                            {errors.name && touched.name && (
                              <div
                                style={{ color: "red", marginBottom: "12px" }}
                              >
                                {errors.name}
                              </div>
                            )}

                            <label>Attachment Description</label>
                            <Field
                              type="text"
                              name="description"
                              placeholder="Description"
                              component={TextField}
                            />

                            <label>Attachment</label>
                            <FileUploader
                              onChange={files => {
                                values.attachmentType = files[0].type;
                                fileToDataUri(files[0]).then(data => {
                                  values.attachmentName = data.split(",")[1];
                                });
                              }}
                            />
                            {errors.attachmentType &&
                              touched.attachmentType && (
                                <div
                                  style={{ color: "red", marginTop: "12px" }}
                                >
                                  {errors.attachmentType}
                                </div>
                              )}
                          </div>

                          <br />
                          <br />
                          {loader ? (
                            <Loader />
                          ) : (
                            <button
                              disabled={loader}
                              type="submit"
                              className="btn btn-lg btn-primary float-right"
                            >
                              {materialId ? "Update" : "Submit"}
                            </button>
                          )}
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
    </>
  );
}

export default StudyMaterialUpload;
