import React, {useEffect, useState} from "react";

import CommonLayout from "../layout/commonLayout";
import {FILES_URL} from "../../util/LinkFactory";
import * as EmployeeApi from "../../api/EmployeeApi";
import moment from "moment";
import {useNavigate, useParams} from "react-router-dom";

function EmployeeView(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const params = useParams();
    const [employee, setEmployee] = useState([]);

    function getEmployeeById() {
        EmployeeApi.getEmployeeById(params.id)
            .then(json => {
                console.log(json);

                setEmployee(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }

    useEffect(() => {
        getEmployeeById()
    }, []);
    return (
        <>
            <CommonLayout headerName={"Employee Information"}>
                <main>
                    <div className=" pt-3 pb-3 p-2">
                        <div className="">
                            <div className="">
                                <div className="">
                                    <h5 className="avatar">
                                        <img
                                            style={{
                                                width: "60px",
                                                height: "auto",
                                                marginRight: "10px"
                                            }}
                                            src={employee?.photo ? FILES_URL + employee?.photo : "img/student_avatar.svg"}
                                            className="p-1"
                                        />
                                        {employee?.firstName} {employee?.middleName}{" "}
                                        {employee?.lastName}
                                    </h5>
                                </div>
                            </div>
                            <div className="tab-blue">
                                <div className="tabs">
                                    <ul
                                        className="nav nav-pills mb-3 mt-4 p-0"
                                        id="pills-tab3"
                                        role="tablist"
                                    >
                                        <li className="nav-item">
                                            <a
                                                className="nav-link active"
                                                id="pills-assignment-upcoming"
                                                data-toggle="pill"
                                                href="#pills-assignment"
                                                role="tab"
                                                aria-controls="pills-assignment"
                                                aria-selected="true"
                                            >
                                                Employee Info
                                            </a>
                                        </li>

                                    </ul>
                                    <div className="tab-content" id="pills-tabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="pills-assignment"
                                            role="tabpane2"
                                            aria-labelledby="pills-assignment-upcoming">
                                            <div className=" mx-auto">
                                                <div className="table-responsive table-light table-shadow radius-15">
                                                    <table
                                                        className="table table-light table-hover"
                                                        id="dataTable"
                                                        width="100%"
                                                        cellSpacing="0">
                                                        <tbody>
                                                        <tr>
                                                            <td>Employee Full Name</td>
                                                            <td>
                                                                {employee?.firstName} {employee?.middleName}{" "}
                                                                {employee?.lastName}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Employee Photo</td>
                                                            <td>
                                                                {employee?.photo ? (
                                                                    <img
                                                                        style={{width: "200px", height: "auto"}}
                                                                        src={FILES_URL + employee?.photo}
                                                                    />
                                                                ) : (
                                                                    "No photo available"
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Employee Role</td>
                                                            <td>{employee?.employeeRole?.employeeRole}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Date Of Birth</td>
                                                            <td> {employee?.dob ? moment(employee?.dob).format(
                                                                "DD/MM/YYYY"
                                                            ) : ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Permanent Address</td>
                                                            <td>{employee?.permanentAddress}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Present Address</td>
                                                            <td>{employee?.presentAddress}</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Emergency Contact Person</td>
                                                            <td>{employee?.emergencyContactPerson}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Emergency Contact Number</td>
                                                            <td>{employee?.emergencyContact}</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Blood Group</td>
                                                            <td>{employee?.bloodGroup}</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Health Concern</td>
                                                            <td>{employee?.healthConcern}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Contact Number</td>
                                                            <td>{employee?.phoneNo}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Registration ID</td>
                                                            <td>{employee?.regId}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default EmployeeView;
