import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveInstitute = institute => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/institute/", institute);
};
export const updateInstitute = institute => {
    return AxiosAgent.requests.put(API_MAIN_ROOT + "/institute/", institute);
};
export const getInstituteById = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/institute/" + id);
};
export const deleteInstituteById = id => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/institute/" + id);
};
export const getAll = userId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/institute");
};
