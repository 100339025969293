import React, {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import * as StudentApi from "../../api/StudentApi";

import * as LinkFactory from "../../util/LinkFactory";
import {FormikTextField} from "../FormikTextField";
import * as Yup from 'yup';
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2";
import * as DepartmentApi from "../../api/DepartmentApi";
import * as ProgramApi from "../../api/ProgramApi";
import * as FacultyApi from "../../api/FacultyApi";
import SelectDropdown from "../common/SelectDropwodn";
import {useNavigate} from "react-router-dom";

toast.configure()


function StudentRegistration(props) {
    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }

    const [faculties, setFaculties] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [programs, setPrograms] = useState([]);

    useEffect(() => {
        loadFaculty();
    }, []);
    const setFacultyObj = value => {
        getDepartmentsByFaculty(value.id);
    }
    const setDepartmentObj = value => {
        getProgramsByDepartmentId(value.id);
    }

    function loadFaculty() {
        FacultyApi.getAllFaculties().then(json => {
            setFaculties(json.data);
        });
    }

    const getDepartmentsByFaculty = (facultyId) => {
        DepartmentApi.getAllByFacultyId(facultyId).then(json => {
            setDepartments(json.data);
        });
    }
    const getProgramsByDepartmentId = (departmentId) => {
        ProgramApi.getAllByDepartmentId(departmentId).then(json => {
            setPrograms(json.data);
        });
    }
    const validate = Yup.object({
        firstName: Yup.string()
            .max(15, "Must be 15 characters or less!")
            .required('First Name is required'),
        lastName: Yup.string()
            .max(20, "Must be 20 characters or less!")
            .required('Last Name is required'),
        email: Yup.string()
            .email('Email is invalid')
            .required('email is required'),
        phoneNo: Yup.string()
            .max(15, "Must be 15 characters or less!")
            .required('Phone no is required'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Password must match')
            .required('Confirm Password is required')
    });

    const initialState = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        phoneNo: ""
    }

    const studentRegistration = (student) => {
        StudentApi.saveStudentDTO(student).then(res => {
            console.log(res);
            if (res.response.status === 201 || res.response.status === 200) {
                Swal.fire(student.email, "Student registration complete. Please Verify Your mail!", "success");
                navigateTo(LinkFactory.verifyEmail);
            } else
                Swal.fire(student.email, "Registration Failed", "error");
        });
    }

    const handleSubmit = data => {
        const studentSave = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            password: data.password,
            phoneNo: data.phoneNo,
            departmentId: data.departmentName.id,
            programId: data.programName.id,
            registrationBy: 'WEB_APP'
        };

        StudentApi.isAlreadyRegisteredByEmail({'email': studentSave.email}).then(res => {
            if (res.data) {
                toast("This email already registered!");
            } else {
                studentRegistration(studentSave);
            }
        })
    };
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <div className="student-registration bg-default d-flex">
                <div id="reg_layout">
                    <div id="reg_content">
                        <main>
                            <div id="registrations">
                                <div id="studentReg">
                                    <div className="container">
                                        <div className="brand">
                                            <h2>{process.env.REACT_APP_ENV_NAME}</h2>
                                            <img src="img/Edooket.png" alt={"Edoocate logo"}/>
                                            <h3>Student Registration</h3>
                                        </div>

                                        <div className="card-default">
                                            <div className="input-reg">
                                                <Formik
                                                    initialValues={initialState}
                                                    validationSchema={validate}
                                                    enableReinitialize={true}
                                                    onSubmit={handleSubmit}>
                                                    {formik => (
                                                        <Form>
                                                            <div className="block-group">
                                                                <div className="block-50">
                                                                    <FormikTextField label="First Name" name="firstName"
                                                                                     type="text"/>
                                                                </div>
                                                                <div className="block-50">
                                                                    <FormikTextField label="Last Name" name="lastName"
                                                                                     type="text"/>
                                                                </div>
                                                            </div>
                                                            <div className="block-group">
                                                                <div className="block-50">
                                                                    <FormikTextField label="Contact No" name="phoneNo"
                                                                                     autoComplete='off'
                                                                                     type="text"/>
                                                                </div>
                                                                <div className="block-50">
                                                                    <FormikTextField label="Email" name="email"
                                                                                     autoComplete="new-email"
                                                                                     type="text"/>
                                                                </div>
                                                            </div>
                                                            <div className="block-group">
                                                                <div className="block-50">
                                                                    <label
                                                                        className="form-label">
                                                                        {" "}
                                                                        Select Faculty{" "}
                                                                    </label>

                                                                    <Field
                                                                        type="text"
                                                                        name="faculty"
                                                                        placeholder=" "
                                                                        fieldName={"name"}
                                                                        handleChangeSection={(fieldName, value) => {
                                                                            setFacultyObj(value)
                                                                        }}
                                                                        label="facultyName"
                                                                        required
                                                                        options={faculties || []}
                                                                        component={SelectDropdown}
                                                                    />
                                                                </div>
                                                                <div className="block-50">
                                                                    <label
                                                                        className="form-label">
                                                                        {" "}
                                                                        Select Department{" "}
                                                                    </label>

                                                                    <Field
                                                                        type="text"
                                                                        name="departmentName"
                                                                        placeholder=" "
                                                                        fieldName={"name"}
                                                                        label="departmentName"
                                                                        handleChangeSection={(fieldName, value) => {
                                                                            setDepartmentObj(value)
                                                                        }}

                                                                        options={departments || []}
                                                                        component={SelectDropdown}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="block-group">
                                                                <div className="block-50">
                                                                    <label
                                                                        className="form-label">
                                                                        {" "}
                                                                        Select Program{" "}
                                                                    </label>
                                                                    <Field
                                                                        type="text"
                                                                        name="programName"
                                                                        placeholder=" "
                                                                        fieldName={"name"}
                                                                        label="departmentName"
                                                                        handleChangeSection={(fieldName, value) => {
                                                                        }}
                                                                        options={programs || []}
                                                                        component={SelectDropdown}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="block-group">
                                                                <div className="block-50 password-box">
                                                                    <FormikTextField
                                                                        label="Password"
                                                                        name="password"
                                                                        autoComplete="off"
                                                                        autoComplete="new-password"
                                                                        type={showPassword ? "text" : "password"}
                                                                    />
                                                                    <svg
                                                                        onClick={togglePasswordVisibility}
                                                                        className="password-icon"
                                                                        width="13"
                                                                        height="9"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M6.5 2.7c.47 0 .921.19 1.254.527.332.338.519.796.519 1.273 0 .477-.187.935-.52 1.273A1.76 1.76 0 0 1 6.5 6.3c-.47 0-.921-.19-1.254-.527A1.814 1.814 0 0 1 4.727 4.5c0-.477.187-.935.52-1.273A1.76 1.76 0 0 1 6.5 2.7Zm0-2.7c2.955 0 5.478 1.866 6.5 4.5C11.978 7.134 9.455 9 6.5 9S1.022 7.134 0 4.5C1.022 1.866 3.545 0 6.5 0ZM1.288 4.5a5.862 5.862 0 0 0 2.14 2.408A5.738 5.738 0 0 0 6.5 7.8c1.086 0 2.15-.31 3.071-.893a5.862 5.862 0 0 0 2.14-2.408 5.862 5.862 0 0 0-2.14-2.408A5.738 5.738 0 0 0 6.5 1.199c-1.086 0-2.15.31-3.071.893A5.862 5.862 0 0 0 1.289 4.5Z"
                                                                            fill="#878787"/>
                                                                    </svg>
                                                                </div>
                                                                <div className="block-50 password-box">
                                                                    <FormikTextField
                                                                        label="Confirm Password"
                                                                        autoComplete="off"
                                                                        autoComplete="new-password"
                                                                        name="confirmPassword"
                                                                        type={showPassword ? "text" : "password"}
                                                                    />
                                                                    <svg
                                                                        onClick={togglePasswordVisibility}
                                                                        className="password-icon"
                                                                        width="13"
                                                                        height="9"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M6.5 2.7c.47 0 .921.19 1.254.527.332.338.519.796.519 1.273 0 .477-.187.935-.52 1.273A1.76 1.76 0 0 1 6.5 6.3c-.47 0-.921-.19-1.254-.527A1.814 1.814 0 0 1 4.727 4.5c0-.477.187-.935.52-1.273A1.76 1.76 0 0 1 6.5 2.7Zm0-2.7c2.955 0 5.478 1.866 6.5 4.5C11.978 7.134 9.455 9 6.5 9S1.022 7.134 0 4.5C1.022 1.866 3.545 0 6.5 0ZM1.288 4.5a5.862 5.862 0 0 0 2.14 2.408A5.738 5.738 0 0 0 6.5 7.8c1.086 0 2.15-.31 3.071-.893a5.862 5.862 0 0 0 2.14-2.408 5.862 5.862 0 0 0-2.14-2.408A5.738 5.738 0 0 0 6.5 1.199c-1.086 0-2.15.31-3.071.893A5.862 5.862 0 0 0 1.289 4.5Z"
                                                                            fill="#878787"/>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            <div className="block-group">
                                                                <div className="block-50 m-auto">
                                                                    <button type="submit" className="btn-deep-blue-lg box-btn">
                                                                        <p>Register</p>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                        </div>
                                        <div className="footer-instruction">
                                            <p>
                                                Already Registered?
                                                <div>
                                                    <a href=" " className="back-button p-0">
                                                        <i
                                                            className="fa fa-arrow-left "> </i>
                                                          Back to Log in
                                                    </a>
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>

                <div className="login-image">
                    <div className="gradient-background">
                        <div className="h-100 flex-column d-flex justify-content-center align-item-center">
                            <div className="d-flex  justify-content-center">
                                <p>The first ever <span>smart school solution </span> to automate your whole institution</p>
                            </div>

                            <div className="image-container">
                                <img src="img/login-page_Sun.png" alt="Sun" className="sun"/>
                                <img src="img/login-page_Clouds.png" alt="Clouds" className="clouds" />
                                <img src="img/login-page_Birds.png"  alt="Flying Birds" className="birds"/>
                                <img src="img/login-page_man-hovering-with-books.png" alt="Person with Books" className="person"/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default StudentRegistration;
