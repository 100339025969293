import {fetchRoomBoardSearchBegin, fetchRoomBoardSearchSuccess} from "../action/roomBoardSearchAction";

export function storeSearchQuery(query) {
    return dispatch => {
        dispatch(fetchRoomBoardSearchBegin());
        dispatch(fetchRoomBoardSearchSuccess(query));
    };
}


