import React, {useState} from "react";
import SideNav from "../Nav/SideNavBar";
import TopNavComponent from "../Nav/TopNavBar";

const CommonLayout = ({children, headerName = ""}) => {
    const [hide, setHide] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState("282px");

    function handleHide(data) {
        setHide(data);
        if (data === true) setSidebarWidth("0px");
        else setSidebarWidth("282px");
    }

    return (
        <div id="layoutSidenav">
                {hide === false ? (
                    <SideNav width={sidebarWidth} hide={handleHide}/>
                ) : (
                    ""
                )}

                <div id="layoutSidenav_content">
                    <main>
                        <div
                            className={`container-fluid  ${hide !== true ? 'custom-width-full' : 'custom-width-sidebar'} `}>
                            <div id="topBar" style={{zIndex: 999, position: 'fixed', overflow: 'hidden'}}>
                                <div style={{
                                    position: 'fixed',
                                    top: 0,
                                    blockSize: '20px',
                                    height: '20px',
                                    backgroundColor: '#f7f9ff',
                                    width: '100%'
                                }}/>
                                <div className="card-default fixed-top">
                                    <TopNavComponent headerName={headerName} hide={handleHide}/>
                                </div>
                            </div>

                            <div
                                className="content-wrap"
                            >
                                {children}
                            </div>
                        </div>
                    </main>
                </div>

        </div>
    );
};

export default CommonLayout;
