import React, {Fragment, useCallback, useEffect, useRef, useState} from "react";
import {Field, FieldArray, Form, Formik} from "formik";
import TextFieldComponent from "../common/TextFieldComponent";
import FormTitle from "../Typhography/formTitle";
import {useNavigate, useParams} from "react-router-dom";
import * as Yup from "yup";
import GenerateQuestions from "./GenerateQuestions";
import Select from "react-select";
import Error from "../Error";
import MarkdownEditor from "../MarkdownEditor";
import * as Utils from "../../util/Utils";
import * as GradingEvaluationApi from "../../api/GradingEvaluationApi";
import * as McqApi from "../../api/McqApi";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";

import LoaderOverlay from "../common/LoaderOverlay";
import {AssignmentStatusEnum} from "../../enum/AssignmentStatusEnum";

// const defaultDate = moment()
//     .tz("Asia/Dhaka")
//     .format("YYYY-MM-DD HH:mm");
const defaultDate = new Date();
const defaultED = {
    score: 1,
    title: "",
    details: "",
    gradingBreakdown: "",
    show: false,
    examType: "MCQ",
    examDate: defaultDate,
    examEndDate: defaultDate,
    examStartDate: defaultDate,
    assignmentStatus: AssignmentStatusEnum
};
const initialState = {
    mcqQuestionDTOList: [
        {
            question: "",
            sl_no: 1,
            marks: 1,
            mcqOptionsDTOList: [
                {
                    answer: "",
                    sl_no: 1,
                    isRight: false
                }
            ]
        }
    ]
    // evaluationDTO: defaultED
};
const mySchema = Yup.object().shape({
    // evaluationDTO: Yup.object().shape({
    //   // title: Yup.string().notRequired(),
    //   score: Yup.number().required("Score required"),
    //   examDate: Yup.string().required("Exam Date  required")
    // }),
    // mcqQuestionDTOList: Yup.object().shape({
    //   question: Yup.string().required("Required"),
    //   marks: Yup.number().required("Required"),
    //   mcqOptionsDTOList: Yup.object().shape({
    //     answer: Yup.string().required("Required")
    //   })
    // })
});

function McqNew(props) {
    const params = useParams();
    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }

    const [assignment, setAssignment] = useState(initialState);
    const [assignmentStatus, setAssignmentStatus] = useState(AssignmentStatusEnum.SAVED);
    const [description, setDescription] = useState("");
    const [isMinusMarkingEnabled, setIsMinusMarkingEnabled] = useState(true);
    const [customError, setCustomError] = useState(false);
    const [loader, setLoader] = useState(false);
    const formRef = useRef();
    const [preValue, setPreValue] = useState(null);
    const [evaluationDTO, setEvaluationDTO] = useState(defaultED);

    useEffect(() => {
        if (!Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY","ROLE_EXAM_CONTROLLER"])) navigateTo("/");
    }, [history]);
    const setStatusForAssignment = () => {
        setAssignmentStatus(AssignmentStatusEnum.SAVED);
    }

    const setStatusForAssignmentDraft = () => {
        setAssignmentStatus(AssignmentStatusEnum.DRAFT);
    }

    const updateEvaluationDTO = (key, value) => {
        const new_obj = {...evaluationDTO, [key]: value};
        setEvaluationDTO(new_obj);
    };
    const handleSubmit = async values => {
        let evDTO = evaluationDTO;
        evDTO.gradingBreakdown = props.selectedGradingBreakdown;
        evDTO.assignmentStatus = assignmentStatus
        evDTO.examStartDate = evDTO.examDate;
        setLoader(true);
        GradingEvaluationApi.saveGradingEvaluation(evaluationDTO)
            .then(response => {
                if (response && response.response && response.response.status === 201) {
                    if (
                        description === "<p></p>\n" ||
                        description === "<p></p>" ||
                        description.length < 1
                    )
                        setCustomError(true);
                    else {
                        const data = {...values};
                        let newObj = [];
                        let hasError = [];
                        data.mcqQuestionDTOList.forEach(item => {
                            let new1 = {
                                question: item.question,
                                sl_no: item.sl_no,
                                marks: item.marks,
                                isMultipleAnswer:
                                    item.mcqOptionsDTOList &&
                                    item.mcqOptionsDTOList.filter(option => option.isRight) &&
                                    item.mcqOptionsDTOList.filter(option => option.isRight)
                                        .length > 1,
                                mcqOptionsDTOList: item.mcqOptionsDTOList
                            };
                            newObj.push(new1);
                            const isValidQuestion =
                                item.mcqOptionsDTOList.filter(op => op.isRight).length === 0;
                            if (isValidQuestion) hasError.push(item.sl_no);
                        });

                        if (hasError.length === 0) {
                            data.name = description;
                            data.totalMark = 0;
                            data.channel = props.channel;
                            data.isMinusMarkingEnabled = isMinusMarkingEnabled;
                            data.mcqQuestionDTOList = newObj;
                            data.gradingEvaluation = response.data;
                            McqApi.saveMcqNew(data)
                                .then(response => {
                                    if (
                                        response &&
                                        response.response &&
                                        (response.response.status === 200 ||
                                            response.response.status === 201)
                                    ) {
                                        Swal.fire(
                                            "Success",
                                            "MCQ Question Saved Successfully",
                                            "success"
                                        ).then(ok =>
                                            navigateTo(`/room-board/${props.channel.id}`)
                                        );
                                    }
                                })
                                .catch(er => console.error(er));
                        } else {
                            Swal.fire(
                                "Select right answer ",
                                `Choose at least one right answer for question  no:${hasError.map(
                                    item => item
                                )}`,
                                "error"
                            );
                        }
                    }
                }
            })
            .finally(() => setLoader(false));
    };

    useEffect(() => {
        if (preValue)
            setAssignment({
                mcqQuestionDTOList: preValue.mcqQuestionDTOList
            });
    }, [preValue]);

    const handleBack = () => {
        history(-1);
    };
    const handleMcqQuestionListAuto = useCallback(mcqQuestionDTOList => {
        setPreValue(mcqQuestionDTOList);
    }, []);

    const setQuestionDescriptionValueHandler = useCallback(
        value => {
            setDescription(value);

            if (
                description === "<p></p>\n" ||
                description === "<p></p>" ||
                description.length < 1
            )
                setCustomError(false);
        },
        [description]
    );

    return (
        <Fragment>
            <GenerateQuestions mcqQuestionDTOList={handleMcqQuestionListAuto}/>
            <br/>
            <div className="mcq-creation mx-3">
                {loader ? <LoaderOverlay/> : null}
                <div>
                    <Formik
                        innerRef={formRef}
                        initialValues={assignment}
                        enableReinitialize={true}
                        onSubmit={handleSubmit}
                        validationSchema={mySchema}
                    >
                        {({values, setFieldValue}) => {
                            return (
                                <Form>
                                    <div className="left-col">
                                        <div className="mcq-left-col">

                                            {/*{Boolean(*/}
                                            {/*  getIn(touched, "evaluationDTO.title") &&*/}
                                            {/*    getIn(errors, "evaluationDTO.title")*/}
                                            {/*) ? (*/}
                                            {/*  <Error>*/}
                                            {/*    {getIn(touched, "evaluationDTO.title") &&*/}
                                            {/*      getIn(errors, "evaluationDTO.title")}*/}
                                            {/*  </Error>*/}
                                            {/*) : (*/}
                                            {/*  ""*/}
                                            {/*)}*/}


                                            <div className="   ">

                                                <div className=" ">
                                                    <div className="form-group">
                                                        <FormTitle>Total score</FormTitle>
                                                        <input
                                                            required={true}
                                                            value={evaluationDTO.score}
                                                            type="number"
                                                            name="score"
                                                            placeholder="Score"
                                                            height={"34px"}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                updateEvaluationDTO("score", e.target.value);
                                                            }}
                                                            className="w-100 form-control input-lg  "
                                                        />
                                                    </div>
                                                    {/*{Boolean(*/}
                                                    {/*  getIn(touched, "evaluationDTO.score") &&*/}
                                                    {/*    getIn(errors, "evaluationDTO.score")*/}
                                                    {/*) ? (*/}
                                                    {/*  <Error>*/}
                                                    {/*    {getIn(touched, "evaluationDTO.score") &&*/}
                                                    {/*      getIn(errors, "evaluationDTO.score")}*/}
                                                    {/*  </Error>*/}
                                                    {/*) : (*/}
                                                    {/*  ""*/}
                                                    {/*)}*/}
                                                </div>

                                                <div className=" ">
                                                    <div className="form-group">
                                                        <FormTitle>Negative Marking </FormTitle>
                                                        <Select
                                                            placeholder={
                                                                isMinusMarkingEnabled ? "True" : "False"
                                                            }
                                                            value={isMinusMarkingEnabled}
                                                            onChange={option =>
                                                                setIsMinusMarkingEnabled(option.value)
                                                            }
                                                            options={[
                                                                {value: true, label: "True"},
                                                                {value: false, label: "False"}
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="   ">
                                                    <div className="form-group">
                                                        <FormTitle>Exam Start DateTime </FormTitle>
                                                        <DatePicker
                                                            showTimeInput2
                                                            selected={Date.parse(evaluationDTO.examDate)}
                                                            placeholderText="Pick date and time"
                                                            onChange={date => {
                                                                updateEvaluationDTO(
                                                                    "examDate",
                                                                    date
                                                                );
                                                            }}
                                                            className="form-control btn-block w-100 overflow-auto "
                                                            timeInputLabel="Time:"
                                                            dateFormat="MM/dd/yyyy HH:mm"
                                                            showTimeInput
                                                        />
                                                    </div>
                                                </div>
                                                <div className="   ">
                                                    <div className="form-group">
                                                        <FormTitle>Exam End Date Time</FormTitle>
                                                        <DatePicker
                                                            showTimeInput2
                                                            selected={Date.parse(evaluationDTO.examEndDate)}
                                                            placeholderText="Pick date and time"
                                                            onChange={date => {
                                                                updateEvaluationDTO(
                                                                    "examEndDate",
                                                                    date
                                                                );
                                                            }}
                                                            className="form-control btn-block w-100 overflow-auto "
                                                            timeInputLabel="Time:"
                                                            dateFormat="MM/dd/yyyy HH:mm"
                                                            showTimeInput
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>


                                    <FieldArray
                                        name="mcqQuestionDTOList"
                                        render={questionArray => (
                                            <div className=" right-col mt-4">
                                                <div className="form-group d-flex ">
                                                    {/*<FormTitle>Question Title</FormTitle>*/}
                                                    <input
                                                        value={evaluationDTO.title}
                                                        required={true}
                                                        type="text"
                                                        name="title"
                                                        placeholder="Assignment title name here"
                                                        height={"34px"}
                                                        onChange={e => {
                                                            e.preventDefault();
                                                            updateEvaluationDTO("title", e.target.value);
                                                        }}
                                                        className="w-100 form-control input-lg  "
                                                    />
                                                    <button
                                                        title="Edit"
                                                        type="button"
                                                        className="btn btn-sm mr-1"
                                                    >
                                                        <svg width="18" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m16.202 9.42-2.01-2.011L15.54 6.06l2.01 2.01-1.348 1.348Zm-7.687 5.676v-2.01l5.014-5.015 2.01 2.01-5.014 5.015h-2.01ZM0 10.366V8.472h6.623v1.892H0ZM0 6.58V4.689h10.407V6.58H0Zm0-3.785V.904h10.407v1.892H0Z"
                                                                fill="#4575EE"/>
                                                        </svg>
                                                    </button>
                                                </div>

                                                <div className="form-group">
                                                    {/*<FormTitle>Question Description</FormTitle>*/}
                                                    <div className=" mb-3 question-description">
                                                        <MarkdownEditor
                                                            cbValue={setQuestionDescriptionValueHandler}
                                                        />
                                                        {customError ? (
                                                            <Error> Description can't be empty</Error>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </div>


                                                {values.mcqQuestionDTOList.map(
                                                    (questionObject, findex) => (
                                                        <div className="card-body">
                                                            <div className="form-row ">
                                                                <div className="col-12">
                                                                    <div className="form-group">
                                                                        <div className="question-heading">
                                                                            <FormTitle>
                                                                                Question{" "}
                                                                                {values.mcqQuestionDTOList.length === 1
                                                                                    ? 1
                                                                                    : findex + 1}
                                                                            </FormTitle>
                                                                            <button
                                                                                type="button"
                                                                                className={
                                                                                    "btn btn-outline-danger py-2 px-4"
                                                                                }
                                                                                onClick={() => {
                                                                                    questionArray.remove(findex);
                                                                                }}
                                                                            >
                                                                                <i className="fa fa-trash"/> Delete
                                                                            </button>
                                                                        </div>

                                                                        <MarkdownEditor
                                                                            name={`mcqQuestionDTOList[${findex}].question`}
                                                                            cbValue={value =>
                                                                                setFieldValue(
                                                                                    `mcqQuestionDTOList[${findex}].question`,
                                                                                    value
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {/*<div className="col-6">*/}
                                                                {/*    <FormTitle>Serial No</FormTitle>*/}

                                                                {/*    <Field*/}
                                                                {/*        type="number"*/}
                                                                {/*        name={`mcqQuestionDTOList[${findex}].sl_no`}*/}
                                                                {/*        placeholder="Serial No"*/}
                                                                {/*        component={TextFieldComponent}*/}
                                                                {/*    />*/}
                                                                {/*</div>*/}
                                                                <div className="col-6 d-flex align-items-center">
                                                                    <FormTitle>Marks - </FormTitle>
                                                                    <Field
                                                                        type="text"
                                                                        name={`mcqQuestionDTOList[${findex}].marks`}
                                                                        placeholder="Marks"
                                                                        component={TextFieldComponent}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <FieldArray
                                                                name={`mcqQuestionDTOList[${findex}].mcqOptionsDTOList`}
                                                                render={answerArray => (
                                                                    <div className="w-100 mt-2">
                                                                        <div
                                                                            className="form-row ">
                                                                            <h6>Answers</h6>
                                                                        </div>
                                                                        {/*{answerArray &&*/}
                                                                        {/*values.mcqQuestionDTOList[findex]*/}
                                                                        {/*    .mcqOptionsDTOList.length ? (*/}
                                                                        {/*    <div className="form-row gap-2 ">*/}
                                                                        {/*        <div className="col-2">*/}
                                                                        {/*            <FormTitle>Serial </FormTitle>*/}
                                                                        {/*        </div>*/}
                                                                        {/*        <div className="col-6">*/}
                                                                        {/*            <FormTitle>Option</FormTitle>*/}
                                                                        {/*        </div>*/}
                                                                        {/*        <div className="col-2">*/}
                                                                        {/*            <FormTitle>*/}
                                                                        {/*                Is Right Answer?*/}
                                                                        {/*            </FormTitle>*/}
                                                                        {/*        </div>*/}
                                                                        {/*        <div className="col-2">*/}
                                                                        {/*            <FormTitle>Actions</FormTitle>*/}
                                                                        {/*        </div>*/}
                                                                        {/*    </div>*/}
                                                                        {/*) : (*/}
                                                                        {/*    ""*/}
                                                                        {/*)}*/}
                                                                        {values.mcqQuestionDTOList[findex] &&
                                                                            values.mcqQuestionDTOList[findex]
                                                                                .mcqOptionsDTOList &&
                                                                            values.mcqQuestionDTOList[
                                                                                findex
                                                                                ].mcqOptionsDTOList.map(
                                                                                (options, index) => (
                                                                                    <div
                                                                                        key={index}
                                                                                        className="w-100 py-2 mcq-answers">
                                                                                        <div>
                                                                                            <div
                                                                                                className="form-row d-flex justify-content-between ">
                                                                                                <div className="w-70 d-flex">
                                                                                                    <div className=" ">
                                                                                                        {/*<Field*/}
                                                                                                        {/*    type="number"*/}
                                                                                                        {/*    name={`mcqQuestionDTOList[${findex}].mcqOptionsDTOList[${index}].sl_no`}*/}
                                                                                                        {/*    placeholder="Serial"*/}
                                                                                                        {/*    component={*/}
                                                                                                        {/*        TextFieldComponent*/}
                                                                                                        {/*    }*/}
                                                                                                        {/*/>*/}
                                                                                                        <div>{String.fromCharCode(97 + index)}.</div>

                                                                                                    </div>
                                                                                                    <div className=" pl-2">
                                                                                                        <MarkdownEditor
                                                                                                            size={"small"}
                                                                                                            name={`mcqQuestionDTOList[${findex}].mcqOptionsDTOList[${index}].answer`}
                                                                                                            cbValue={value =>
                                                                                                                setFieldValue(
                                                                                                                    `mcqQuestionDTOList[${findex}].mcqOptionsDTOList[${index}].answer`,
                                                                                                                    value
                                                                                                                )
                                                                                                            }
                                                                                                        />
                                                                                                        {/*<p> option 1</p>*/}
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="w-40 d-flex align-item-center pr-2">
                                                                                                    <div className=" d-flex align-items-center">
                                                                                                        <Field
                                                                                                            type="checkbox"
                                                                                                            name={`mcqQuestionDTOList[${findex}].mcqOptionsDTOList[${index}].isRight`}
                                                                                                            placeholder="Right Answer"
                                                                                                            style={{
                                                                                                                marginRight: 8,
                                                                                                                alignItems: "center",
                                                                                                                fontSize: "26px",
                                                                                                                size: "26px"
                                                                                                            }}
                                                                                                        />
                                                                                                        <p className="m-0">Right Answer</p>
                                                                                                    </div>
                                                                                                    <div className="pl-3 d-flex align-items-center  ">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className={
                                                                                                                "bg-c-red-light"
                                                                                                            }
                                                                                                            onClick={() => {
                                                                                                                answerArray.remove(index);
                                                                                                            }}
                                                                                                        >
                                                                                                            <svg width="10"
                                                                                                                 height="10"
                                                                                                                 fill="none"
                                                                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                                                                <path
                                                                                                                    d="M2.188 10a1.09 1.09 0 0 1-.795-.327c-.22-.217-.33-.479-.33-.784V1.667H.5V.556h2.813V0h3.374v.556H9.5v1.11h-.563V8.89c0 .305-.11.567-.33.785-.22.218-.486.326-.794.326H2.188Zm5.624-8.333H2.188v7.222h5.626V1.667Zm-4.5 6.11h1.126v-5H3.313v5Zm2.25 0h1.125v-5H5.563v5Z"
                                                                                                                    fill="#D81414"/>
                                                                                                            </svg>
                                                                                                            {" "}
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>


                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        <div className="form-group">
                                                                            <button
                                                                                type="button"
                                                                                className={"btn bg-c-blue-light p-2"}
                                                                                onClick={() => {
                                                                                    answerArray.push({
                                                                                        sl_no:
                                                                                            values?.mcqQuestionDTOList[
                                                                                                findex
                                                                                                ]?.mcqOptionsDTOList.length +
                                                                                            1 || 0,
                                                                                        answer: "",
                                                                                        isRight: false
                                                                                    });
                                                                                }}
                                                                            >
                                                                                <svg width="13" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path
                                                                                        d="M5.85 7.65V9.6a.63.63 0 0 0 .187.463c.125.125.28.187.463.187a.63.63 0 0 0 .463-.187.626.626 0 0 0 .187-.463V7.65H9.1a.63.63 0 0 0 .463-.187A.626.626 0 0 0 9.75 7a.63.63 0 0 0-.187-.463.626.626 0 0 0-.463-.187H7.15V4.4a.63.63 0 0 0-.187-.463.626.626 0 0 0-.463-.187.63.63 0 0 0-.463.187.626.626 0 0 0-.187.463v1.95H3.9a.63.63 0 0 0-.463.187A.626.626 0 0 0 3.25 7a.63.63 0 0 0 .187.463c.125.125.28.187.463.187h1.95Zm.65 5.85c-.9 0-1.744-.17-2.535-.512a6.574 6.574 0 0 1-2.064-1.39A6.56 6.56 0 0 1 .512 9.536 6.34 6.34 0 0 1 0 7c0-.9.17-1.744.512-2.535a6.574 6.574 0 0 1 1.39-2.064 6.56 6.56 0 0 1 2.063-1.389A6.34 6.34 0 0 1 6.5.5c.9 0 1.744.17 2.535.512.79.342 1.479.805 2.064 1.39a6.568 6.568 0 0 1 1.39 2.063c.34.79.511 1.636.511 2.535 0 .9-.17 1.744-.512 2.535a6.574 6.574 0 0 1-1.39 2.064 6.567 6.567 0 0 1-2.063 1.39A6.318 6.318 0 0 1 6.5 13.5Zm0-1.3c1.452 0 2.681-.504 3.689-1.511C11.196 9.68 11.7 8.452 11.7 7s-.504-2.681-1.511-3.689C9.18 2.304 7.952 1.8 6.5 1.8s-2.681.504-3.689 1.511C1.804 4.32 1.3 5.548 1.3 7s.504 2.681 1.511 3.689C3.82 11.696 5.048 12.2 6.5 12.2Z"
                                                                                        fill="#4575EE"/>
                                                                                </svg>
                                                                                Add option
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                    )
                                                )}
                                                {!loader ? (
                                                    <div className="form-row">
                                                        <div className="form-group mt-2">
                                                            <button onClick={setStatusForAssignment}
                                                                    type="submit"
                                                                    disabled={loader}
                                                                    className="btn btn-purple-md  w-100">
                                                                Save
                                                            </button>
                                                        </div>
                                                        <div className="form-group mt-2">
                                                            <button
                                                                onClick={handleBack}
                                                                type="button"
                                                                className="btn btn-cancel w-100 ml-2">
                                                                {" "}
                                                                Cancel
                                                            </button>
                                                        </div>
                                                        <div className="form-group mt-2 pl-2">
                                                            <button type="button" onClick={setStatusForAssignmentDraft} className="btn btn-cancel w-100 ml-2">
                                                                {" "}
                                                                Save as Draft
                                                            </button>
                                                        </div>
                                                        <div className="form-group mt-2 pl-2">
                                                            <button type="button" className="btn btn-cancel w-100 ml-2">
                                                                {" "}
                                                                Upload to assignment archive
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}

                                                <div className="form-group justify-content-center mx-auto">
                                                    <button
                                                        type="button"
                                                        className={"btn btn-primary py-2 px-4"}
                                                        onClick={() => {
                                                            questionArray.push({
                                                                sl_no: values.mcqQuestionDTOList.length + 1,
                                                                question: "",
                                                                marks: 0,
                                                                mcqOptionsDTOList: [
                                                                    {
                                                                        answer: "",
                                                                        sl_no: 1,
                                                                        isRight: false
                                                                    }
                                                                ]
                                                            });
                                                        }}
                                                    >
                                                        <i className="fa fa-plus-circle mr-2"/>
                                                        Add another Question
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    />


                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </Fragment>
    );
}

export default McqNew;
