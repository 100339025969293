import React, {useState} from "react";
import {useDispatch} from "react-redux";
import * as AssignmentTaskSubmitApi from "../../api/AssignmentTaskSubmitApi";
import * as ModalActions from "../../action/ModalActions";

const AssignmentTaskScoreEntryPopup = props => {
    const [score, setScore] = useState("");
    const dispatch = useDispatch();
    const marksEntry = () => {
        AssignmentTaskSubmitApi.saveMarksByAssignmentTaskSubmitId(
            props.taskId,
            score
        ).then(response => {
            dispatch(ModalActions.closeModal());
        });
    };
    const onEmailChange = event => {
        setScore(event.target.value);
    };
    return (
        <div>
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group input-default-white">
                            <label>Score</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Enter here..."
                                name={"score"}
                                value={score}
                                onChange={onEmailChange}
                            />
                            <div className="w-100">
                                <button className="btn btn-dark radius-25 mt-3 w-100 d-flex justify-content-between align-items-center" onClick={() => marksEntry()} type="button">
                                    Submit Score
                                    <i className="fa fa-chevron-circle-right"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AssignmentTaskScoreEntryPopup;
